@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap");

:root {
  --clr-primary: #7380;
  --clr-danger: #7380;
  --clr-color-background: #f6f6f9;
  --clr-color-txt: #5e6062;
}

/* You can add global styles to this file, and also import other style files */
body {
  background-color: #f3f3f3;
  font-size: 0.875em;
  overflow-x: hidden;
  color: #353c4e;
  font-family: "Poppins", sans-serif;
}

.main-content {
  position: fixed;
  border-left: 1.5px solid #d5d3d3;
  width: calc(100% - 9rem) !important;
  margin-left: 8rem;
  height: calc(100vh - 12rem);
  overflow-x: auto;
  overflow-y: auto;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-logo>span {
  color: #fff;
  font-size: 1.4rem;
  margin-left: 1rem;
}

.navbar-logo>span>small {
  color: orange;
  font-size: 1.4rem;
}

* {
  &:focus {
    outline: none;
  }

  a {
    font-size: 14px;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

p {
  font-size: 13px;
}

ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.media-left {
  padding-right: 20px;
}

@media only screen and (min-width: 1400px) {
  p {
    font-size: 14px;
  }
}


.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0rem !important; 
    margin-left: 0px !important;
    border: none !important;
}


.input-group-text {
  border-radius: 0 5px 5px 0 !important;
}

// Sub menu css start
/* Sub menu css start*/

.sub_menu {
  overflow-x: hidden;
  width: 8rem !important;
  height: calc(100vh - 12rem);
  padding-left: 0px !important;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  overflow-y: auto;
}

@media only screen and (max-width: 567px) {
  .main-body .page-wrapper {
    padding: 0px !important;
  }

  .main-body {
    margin-top: 0px !important;
  }

  .navbar-logo {
    height: 55px !important;
  }

  .header-navbar {
    min-height: 65px !important;
  }

  .pcoded-main-container {
    margin-top: 65px !important;
  }

  .sub_menu {
    overflow-x: auto;
    width: 100vb !important;
    display: flex;
    height: fit-content;
    border-top: 1px solid;
    padding-left: 0px;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    overflow-y: hidden;
  }

  .sub_menu>a {
    min-width: 6rem !important;
    margin: 0.5rem 0.5rem 0.5rem 0rem !important;
  }

  .sub_menu>a>span {
    font-size: 1.8rem !important;
  }

  .sub_menu>a>h5 {
    font-size: 0.7rem !important;
  }

  .main-content {
    position: absolute;
    border-left: none;
    padding-left: 0px !important;
    width: 100% !important;
    margin-left: 0px;
    margin-top: 6.5rem !important;
    height: calc(100vh - 20rem);
    overflow-x: auto;
    overflow-y: auto;
  }

  .main-content-dashboard {
    height: calc(100vh - 17rem) !important;
  }
}

.main-content-dashboard {
  display: block;
  border-left: none;
  width: 100%;
  height: calc(100vh - 14rem);
  overflow-x: auto;
  overflow-y: auto;
}

.main-body {
  position: fixed;
  margin-top: 10px;
  width: -webkit-fill-available;
}

.sub_menu>a {
  display: flex;
  flex-direction: column;
  border: 1px solid #2a2a2a;
  margin: 0rem 0.3rem 1rem 0rem;
  text-align: center;
  border-radius: 0.4rem;
  padding: 0.7rem;
  text-decoration: none;
  justify-content: space-evenly;
}

.sub_menu>a:hover {
  border: 1px solid #2a2a2a;
  box-shadow: 3px 3px #696969;
}

a {
  text-decoration: none;
}

.sub_menu>a.active {
  box-shadow: 3px 3px #696969;
}

.sub_menu>a>span {
  font-size: 2rem;
  color: var(--clr-color-txt);
}

.sub_menu>a>h5 {
  font-size: 0.8rem;
  color: var(--clr-color-txt);
}

.main-body .page-wrapper {
  // padding: 1.8rem;
  transition: all ease-in 0.3s;

  .menu-rtl {
    .page-header-title i {
      margin-left: 20px;
    }

    .breadcrumb-title .breadcrumb-item+.breadcrumb-item {
      &:after {
        display: inline-block;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        color: #868e96;
        content: "/";
      }

      &::before {
        content: none;
      }
    }
  }

  .page-header {
    margin-bottom: 30px;
    background-color: #fff;
    //padding: 30px;
    padding: 10px;
  }

  .page-header-title {
    display: inline-flex;

    h4 {
      display: block;
      margin-bottom: 0;
      font-weight: 600;
      color: #303548;
      font-size: 20px;
      text-transform: capitalize;
    }

    span {
      font-size: 13px;
      color: #919aa3;
      display: inline-block;
      margin-top: 10px;
      text-transform: capitalize;
    }

    i {
      display: inline-flex;
      float: left;
      width: 50px;
      height: 50px;
      border-radius: 4px;
      justify-content: center;
      vertical-align: middle;
      font-size: 25px;
      color: #fff;
      box-shadow: 0 2px 12px -3px rgba(0, 0, 0, 0.5);
      margin-right: 20px;
      align-items: center;
    }
  }

  .page-header-breadcrumb {
    display: inline-flex;
    float: right;
  }
}

.author-details {
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  padding: 10px 0;
  margin-bottom: 10px;

  img~ {

    .dot1,
    .dot2 {
      height: 14px;
      width: 14px;
      border-radius: 100%;
      top: 60%;
      position: absolute;
      padding: 5px;
    }

    .dot1 {
      left: 40px;
    }

    .dot2 {
      left: 50px;
    }
  }

  .recent-contain h6 {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  .header-right {
    text-align: right;
    margin-top: 6px;

    ul {
      position: relative;
    }

    h4 {
      color: #919aa3;
      font-size: 0.875rem;
      margin-top: 5px;
    }

    span {
      font-size: 1rem;
    }

    li {
      display: inline-block;
      margin-right: 60px;
      text-align: left;

      &:last-child {
        margin-right: 0;
        position: absolute;
        right: 0;
        top: 10px;
        font-size: 20px;
      }
    }
  }
}

.progress-bar {
  height: 100%;
}

.filter-bar .navbar .navbar-nav .dropdown-menu {
  position: absolute;
}

.wrapper {
  padding: 0;
}

.card-block {
  padding: 1.25rem;
}

.card {
  border-radius: 5px;
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.12);
  border: none;
  margin-bottom: 30px;

  .card-footer {
    background-color: #fff;
    border-top: none;
  }

  .card-header {
    background-color: transparent;
    border-bottom: none;
    padding: 25px 20px;

    .card-header-left {
      display: inline-block;
    }

    .card-header-right {
      border-radius: 0 0 0 7px;
      right: 10px;
      top: 18px;
      display: inline-block;
      float: right;
      padding: 7px 0;
      position: absolute;

      i {
        margin: 0 8px;
        cursor: pointer;
        font-size: 14px;
        color: #505458;
        line-height: 20px;

        &.icofont.icofont-spinner-alt-5 {
          display: none;
        }
      }

      .card-option {
        height: 20px;
        overflow: hidden;
        transition: 0.3s ease-in-out;

        li {
          display: inline-block;
        }
      }
    }

    span {
      color: #919aa3;
      display: block;
      font-size: 13px;
      margin-top: 5px;
    }

    + {

      .card-block,
      .card-block-big {
        padding-top: 0;
      }
    }
  }

  h5 {
    margin-bottom: 0;
    color: #505458;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin-right: 10px;
    line-height: 1.4;
  }

  .card-block {
    table tr {
      padding-bottom: 20px;
    }

    .sub-title {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
    }

    code {
      background-color: #eee;
      margin: 5px;
      display: inline-block;
    }

    .dropdown-menu {
      top: 38px;
    }

    p {
      line-height: 25px;
    }

    a.dropdown-item {
      margin-bottom: 0;
      font-size: 14px;
      transition: 0.25s;

      &:active,
      .active {
        background-color: #4680ff;
      }
    }

    &.remove-label i {
      margin: 0;
      padding: 0;
    }

    &.button-list span.badge {
      margin-left: 5px;
    }

    .dropdown-menu {
      background-color: #fff;
      padding: 0;

      .dropdown-divider {
        background-color: #ddd;
        margin: 3px 0;
      }

      > {
        a {
          padding: 10px 16px;
          line-height: 1.429;
        }

        li {
          >a {

            &:focus,
            &:hover {
              background-color: rgba(202, 206, 209, 0.5);
            }
          }

          &:first-child>a:first-child {
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
          }
        }
      }
    }

    .badge-box {
      border: 1px solid #ccc;
      padding: 10px;
      margin: 12px 0;
    }
  }

  .card-block-big {
    padding: 30px 35px;
  }

  .card-block-small {
    padding: 15px 20px;
  }

  .inner-card-block {
    border: 1px solid #ddd;
  }
}

.pcoded {
  .card {
    &.full-card {
      position: fixed;
      top: 80px;
      z-index: 99999;
      box-shadow: none;
      right: 0;
      border-radius: 0;
      border: 1px solid #ddd;
      width: calc(100vw - 287px);
      height: calc(100vh - 80px);

      &.card-load {
        position: fixed;
      }
    }

    &.card-load {
      position: relative;
      overflow: hidden;

      .card-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 999;

        i {
          margin: 0 auto;
          color: #ab7967;
          font-size: 20px;
        }
      }
    }
  }

  &[vertical-nav-type="expanded"] .card.full-card {
    width: calc(100vw - 287px);
  }

  &[vertical-nav-type="collapsed"] .card.full-card {
    width: calc(100vw - 97px);
  }

  &[vertical-nav-type="offcanvas"] .card.full-card {
    width: 100vw;
  }
}

.card-header-text {
  margin-bottom: 0;
  font-size: 1rem;
  color: rgba(51, 51, 51, 0.85);
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
}

.icofont-rounded-down {
  -webkit-transition: all ease-in 0.3s;
  display: inline-block;
  transition: all ease-in 0.3s;
}

.icon-up {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block;
}

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.breadcrumb-title {
  a {
    font-size: 14px;
    color: #4a6076;
  }

  li:last-child a {
    color: #7e7e7e;
  }
}

img {
  border-radius: 5px;
}

.sub-title {
  border-bottom: 1px solid rgba(204, 204, 204, 0.35);
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  color: #2c3e50;
}

.blockquote {
  border-left: 0.25rem solid #eceeef;
  padding: 0.5rem 1rem;

  &.blockquote-reverse {
    text-align: right;
    padding-right: 1rem;
    border-right: 0.25rem solid #eceeef;
    border-left: none;
  }
}

.typography {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 20px;
  }

  small {
    margin-left: 10px;
    font-weight: 600;
    color: #777;
  }
}

.card-block.list-tag {
  ul li {
    display: block;
    float: none;
    margin-bottom: 5px;
  }

  ol li {
    margin-bottom: 5px;
  }
}

.inline-order-list {
  margin-top: 50px;

  h4,
  p {
    margin-bottom: 0;
  }
}

.card-block ul li.list-inline-item {
  display: inline-block;
  float: left;
}

.modal {
  z-index: 1111;
}

.bd-example .modal {
  display: block;
  position: inherit;
  background-color: #2c3e50;
  margin-bottom: 20px;
}

.card .overflow-container h5 {
  margin-bottom: 5px;
}

.button-page .card-block a.nav-link {
  margin-bottom: 0;
}

.sweet-alert {
  button.confirm {
    background-color: #4680ff !important;
  }

  .sa-input-error {
    top: 23px;
  }
}

.location-selector {
  width: 100%;
  height: 250px;
  background-color: #fff;
  border: 2px dashed #e5e9ec;
  position: relative;
  margin-bottom: 20px;

  .bit {
    background-color: #e5e9ec;
    cursor: pointer;
    position: absolute;

    &:hover {
      background-color: #ddd;
    }

    &.bottom {
      height: 25%;
      width: 40%;
      margin: 0 30%;
    }

    &.top {
      height: 25%;
      width: 40%;
      margin: 0 30%;
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }

    &.left {
      height: 20%;
      width: 20%;
      margin-left: 0;
      margin-right: 0;
    }

    &.right {
      height: 20%;
      width: 20%;
      margin-left: 0;
      margin-right: 0;
      right: 0;
    }

    &.left {
      left: 0;
    }
  }
}

button.close {
  margin-top: 7px;
  margin-bottom: 0;
}

.mytooltip {
  display: inline;
  position: relative;
  z-index: 9999;

  .tooltip-item {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    padding: 0 10px;
  }

  .tooltip-content {
    position: absolute;
    z-index: 9999;
    width: 360px;
    left: 50%;
    margin: 0 0 20px -180px;
    bottom: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 30px;
    box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
    background: #2b2b2b;
    opacity: 0;
    cursor: default;
    pointer-events: none;

    &::after {
      content: "";
      top: 100%;
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: #2a3035 transparent transparent;
      border-width: 10px;
      margin-left: -10px;
    }

    img {
      position: relative;
      height: 140px;
      display: block;
      float: left;
      margin-right: 1em;
    }
  }

  .tooltip-item::after {
    content: "";
    position: absolute;
    width: 360px;
    height: 20px;
    bottom: 100%;
    left: 50%;
    pointer-events: none;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  &:hover {
    .tooltip-item::after {
      pointer-events: auto;
    }

    .tooltip-content {
      pointer-events: auto;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
      transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
    }

    .tooltip-content2 {
      opacity: 1;
      font-size: 18px;

      i {
        opacity: 1;
        font-size: 18px;
        opacity: 1;
        font-size: 18px;
        pointer-events: auto;
        -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      }

      opacity: 1;
      font-size: 18px;
      pointer-events: auto;
      -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }

    .tooltip-item2 {
      color: #fff;
      -webkit-transform: translate3d(0, -0.9em, 0);
      transform: translate3d(0, -0.9em, 0);
    }

    .tooltip-text3 {
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }

    .tooltip-content3 {
      opacity: 1;
      pointer-events: auto;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }

    .tooltip-content4,
    .tooltip-text2 {
      pointer-events: auto;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    .tooltip-inner2 {
      -webkit-transition-delay: 0.3s;
      transition-delay: 0.3s;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    .tooltip-content5 {
      opacity: 1;
      pointer-events: auto;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }
  }

  .tooltip-text {
    font-size: 14px;
    line-height: 24px;
    display: block;
    padding: 1.31em 1.21em 1.21em 0;
    color: #fff;
  }

  .tooltip-item2 {
    color: #4680ff;
    cursor: pointer;
    z-index: 100;
    position: relative;
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    -webkit-transition: background-color 0.3s, color 0.3s,
      -webkit-transform 0.3s;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
  }
}

.tooltip.tooltip-effect-2:hover .tooltip-content {
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
  transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
}

.tooltip-effect-5 .tooltip-text {
  padding: 1.4em;
}

.tooltip-effect-1 .tooltip-content {
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  color: #fff;
}

.tooltip-effect-2 .tooltip-content {
  -webkit-transform-origin: 50% calc(110%);
  transform-origin: 50% calc(110%);
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
  transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
}

.tooltip-effect-3 .tooltip-content {
  -webkit-transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
  transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-4 .tooltip-content {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: scale3d(0.7, 0.3, 1);
  transform: scale3d(0.7, 0.3, 1);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
}

.tooltip-effect-5 .tooltip-content {
  width: 180px;
  margin-left: -90px;
  -webkit-transform-origin: 50% calc(106%);
  transform-origin: 50% calc(106%);
  -webkit-transform: rotate3d(0, 0, 1, 15deg);
  transform: rotate3d(0, 0, 1, 15deg);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
  -webkit-transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
  transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
}

.tooltip-effect-6 .tooltip-content2 {
  -webkit-transform: translate3d(0, 10px, 0) rotate3d(1, 1, 1, 45deg);
  transform: translate3d(0, 10px, 0) rotate3d(1, 1, 1, 45deg);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;

  i {
    -webkit-transform: scale3d(0, 0, 1);
    transform: scale3d(0, 0, 1);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
  }
}

.tooltip-effect-7 .tooltip-content2 {
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;

  i {
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
  }
}

.tooltip-effect-8 .tooltip-content2 {
  -webkit-transform: translate3d(0, 10px, 0) rotate3d(0, 1, 0, 90deg);
  transform: translate3d(0, 10px, 0) rotate3d(0, 1, 0, 90deg);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;

  i {
    -webkit-transform: scale3d(0, 0, 1);
    transform: scale3d(0, 0, 1);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
  }
}

.tooltip-effect-9 .tooltip-content2 {
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;

  i {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
  }
}

.tooltip-effect-6:hover .tooltip-content2 i {
  -webkit-transform: rotate3d(1, 1, 1, 0);
  transform: rotate3d(1, 1, 1, 0);
}

.tooltip-content2 {
  position: absolute;
  z-index: 9999;
  width: 80px;
  height: 80px;
  padding-top: 25px;
  left: 50%;
  margin-left: -40px;
  bottom: 100%;
  border-radius: 50%;
  text-align: center;
  background: #4680ff;
  color: #fff;
  opacity: 0;
  margin-bottom: 20px;
  cursor: default;
  pointer-events: none;

  i {
    opacity: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin: -7px 0 0 -15px;
    width: 30px;
    height: 20px;
    background: #f1f1f1;
    background-size: 100%;
  }
}

.tooltip-content3 {
  position: absolute;
  background: #f1f1f1;
  background-size: 100% 100%;
  z-index: 9999;
  width: 200px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  padding: 50px 30px;
  text-align: center;
  color: #fff;
  opacity: 0;
  cursor: default;
  font-size: 14px;
  line-height: 27px;
  pointer-events: none;
  -webkit-transform: scale3d(0.1, 0.2, 1);
  transform: scale3d(0.1, 0.2, 1);
  -webkit-transform-origin: 50% 120%;
  transform-origin: 50% 120%;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, transform 0.4s;
  -webkit-transition-timing-function: ease, cubic-bezier(0.6, 0, 0.4, 1);
  transition-timing-function: ease, cubic-bezier(0.6, 0, 0.4, 1);

  &::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    left: 50%;
    margin-left: -8px;
    top: 100%;
    background: #00aeef;
    -webkit-transform: translate3d(0, -60%, 0) rotate3d(0, 0, 1, 45deg);
    transform: translate3d(0, -60%, 0) rotate3d(0, 0, 1, 45deg);
  }
}

.tooltip-content4 {
  position: absolute;
  z-index: 99;
  width: 360px;
  left: 50%;
  margin-left: -180px;
  bottom: -5px;
  text-align: left;
  background: #4680ff;
  opacity: 0;
  font-size: 14px;
  line-height: 27px;
  padding: 1.5em;
  color: #fff;
  border-bottom: 55px solid #303548;
  cursor: default;
  pointer-events: none;
  border-radius: 5px;
  -webkit-transform: translate3d(0, -0.5em, 0);
  transform: translate3d(0, -0.5em, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;

  a {
    color: #2b2b2b;
  }

  .tooltip-text2 {
    opacity: 0;
    -webkit-transform: translate3d(0, 1.5em, 0);
    transform: translate3d(0, 1.5em, 0);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
  }
}

.tooltip-content5 {
  position: absolute;
  z-index: 9999;
  width: 300px;
  left: 50%;
  bottom: 100%;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  font-weight: 400;
  color: #fff;
  background: 0 0;
  opacity: 0;
  margin: 0 0 20px -150px;
  cursor: default;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: opacity 0.3s 0.3s;
  transition: opacity 0.3s 0.3s;

  span {
    display: block;
  }

  &::after {
    content: "";
    bottom: -20px;
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: #4680ff transparent transparent;
    border-width: 10px;
    margin-left: -10px;
  }

  .tooltip-text3 {
    border-bottom: 10px solid #4680ff;
    overflow: hidden;
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
    -webkit-transition: -webkit-transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s;
  }

  .tooltip-inner2 {
    background: #2b2b2b;
    padding: 40px;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
  }
}

a.mytooltip {
  font-weight: 700;
  color: #4680ff;
  z-index: 9;
}

.tooltip-link a {
  margin-left: 10px;
  color: #4680ff;
}

.tooltip-icon button i {
  margin-right: 0;
}

/**====== Tooltip css end ======**/
/**  =====================
      Slider css start
==========================  **/
/*========= Owl-carousel css start ============*/

.owl-theme {
  .owl-nav [class*="owl-"]:hover {
    background: #4680ff;
  }

  .owl-dots .owl-dot {

    &.active span,
    &:hover span {
      background: #4680ff;
    }
  }
}

/*========= Owl-carousel css end ============*/
/**  =====================
      Animation css start
==========================  **/

.animation-image img {
  margin: 10px auto 20px;
  cursor: pointer;
}

/**====== Animation css end ======**/
/**  =====================
      Tree-view css start
==========================  **/

.card-block.tree-view ul {
  display: inherit;

  li {
    display: inherit;
    float: none;
  }
}

/**====== Tree-view css end ======**/
/**  =====================
      File Upload css start
==========================  **/

.jFiler-theme-default {
  .jFiler-input-button {
    background-color: #4680ff;
    background-image: none;
    color: #fff;
  }

  .jFiler-input {
    width: 100%;
  }
}

.jFiler-input-dragDrop {
  width: 100%;
  background-color: #fafafa;
}

.jFiler-items-grid .jFiler-item .jFiler-item-container .jFiler-item-info {
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 6px 10px;
  z-index: 9;
  opacity: 0;
  -webkit-transition: all 0.12s;
  -moz-transition: all 0.12s;
  transition: all 0.12s;
}

.jFiler-input-choose-btn {
  border: 1px solid #4680ff;
  padding: 10px 25px;
  background: #4680ff;
  color: #fff !important;

  &:hover {
    background: #fff;
    border: 1px solid #4680ff;
    color: #fff !important;
  }
}

.card .jFiler-item-assets ul li {
  margin-right: 0;
}

/**====== File Upload css end ======**/
/**  =====================
      Cropper css start
==========================  **/

.img-container,
.img-preview {
  background-color: #f7f7f7;
  width: 100%;
  text-align: center;
}

.img-container {
  min-height: 200px;
  max-height: 516px;
  margin-bottom: 20px;

  >img {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .img-container {
    min-height: 516px;
  }
}

.docs-preview {
  margin-right: -15px;
}

.img-preview {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;

  >img {
    max-width: 100%;
  }
}

.preview-lg {
  width: 100% !important;
}

.preview-md {
  width: 139px;
  height: 78px;
}

.preview-sm {
  width: 69px;
  height: 39px;
}

.preview-xs {
  width: 35px;
  height: 20px;
  margin-right: 0;
}

.docs-data>.input-group {
  margin-bottom: 10px;

  > {
    label {
      min-width: 80px;
    }

    span {
      min-width: 50px;
    }
  }
}

.docs-buttons> {

  .btn,
  .form-control,
  .btn-group {
    margin-right: 5px;
  }
}

.docs-toggles> {

  .btn,
  .dropdown,
  .btn-group {
    margin-bottom: 10px;
  }
}

.docs-tooltip {
  display: block;
  margin: -6px -12px;
  padding: 6px 12px;

  >.icon {
    margin: 0 -3px;
    vertical-align: top;
  }
}

.tooltip-inner {
  white-space: normal;
}

.btn-upload .tooltip-inner {
  white-space: nowrap;
}

@media (max-width: 400px) {
  .btn-group-crop {
    margin-right: -15px !important;

    >.btn {
      padding-left: 5px;
      padding-right: 5px;
    }

    .docs-tooltip {
      margin-left: -5px;
      margin-right: -5px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.docs-options .dropdown-menu {
  width: 100%;

  >li {
    padding: 3px 20px;
    font-size: 14px;

    &:hover {
      background-color: #f7f7f7;
    }

    >label {
      display: block;
    }
  }
}

.docs-cropped .modal-body {
  text-align: center;

  > {

    img,
    canvas {
      max-width: 100%;
    }
  }
}

.card-block .docs-options .dropdown-menu {
  top: inherit;
}

label.btn-upload {
  height: 40px;
}

/**====== Cropper css end ======**/
/**  =====================
      Accordion css start
==========================  **/

.accordion-msg {
  display: block;
  color: #222222;
  padding: 14px 20px;
  border-top: 1px solid #ddd;
  font-weight: 600;
  cursor: pointer;

  &:focus,
  &:hover {
    text-decoration: none;
    outline: none;
  }
}

.faq-accordion .accordion-desc {
  padding: 20px;
}

.accordion-desc {
  color: #222222;
  padding: 0 20px 20px;
}

#color-accordion .accordion-desc {
  margin-top: 14px;
}

.ui-accordion-header-icon {
  float: right;
  font-size: 20px;
}

.accordion-title {
  margin-bottom: 0;
}

.accordion-block {
  padding: 0;

  p {
    margin-bottom: 0;
  }
}

.color-accordion-block a {

  &:focus,
  &:hover,
  &.ui-state-active {
    color: #fff;
    background: #4680ff;
  }
}

a {
  &.bg-default {

    &:focus,
    &:hover {
      background-color: #fafafa !important;
      color: #fff;
    }
  }

  &.bg-primary {

    &:focus,
    &:hover {
      background-color: #79a3ff !important;
      color: #fff;
    }
  }

  &.bg-success {

    &:focus,
    &:hover {
      background-color: #aacc77 !important;
      color: #fff;
    }
  }

  &.bg-info {

    &:focus,
    &:hover {
      background-color: #91dff7 !important;
      color: #fff;
    }
  }

  &.bg-warning {

    &:focus,
    &:hover {
      background-color: #ffcb80 !important;
      color: #fff;
    }
  }

  &.bg-danger {

    &:focus,
    &:hover {
      background-color: #fd93a8 !important;
      color: #fff;
    }
  }
}

/**====== Accordion css end ======**/
/**  =====================
      Tabs css start
==========================  **/

.tab-list p {
  padding: 10px;
}

.tab-with-img i {
  position: absolute;
  padding: 5px;
}

.tab-icon {
  margin-bottom: 30px;

  i {
    padding-right: 10px;
  }
}

.tab-below {
  border-top: 1px solid #ddd;
  border-bottom: none;

  &.nav-tabs .nav-link.active {
    border-color: transparent #ddd #ddd #ddd;
  }

  .nav-item {
    margin-top: -2px;
  }

  &.nav-tabs .nav-link {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
}

.card-header~.tab-icon .tab-with-img .sub-title i {
  right: 10px;
}

.tab-with-img .nav-link {
  position: relative;
}

.tabs-left,
.tabs-right {
  min-width: 120px;
  vertical-align: top;
  width: 150px;
}

.tabs-left,
.tabs-left-content,
.tabs-right,
.tabs-right-content {
  display: table-cell;
}

.nav-tabs {
  &.tabs-left .slide {
    height: 35px;
    width: 4px;
    bottom: 15px;
  }

  &.tabs-right .slide {
    height: 35px;
    width: 4px;
    bottom: 15px;
    right: 0;
  }
}

.product-edit .md-tabs .nav-item a {
  padding: 0 0 20px !important;
}

.md-tabs {

  &.tabs-left .nav-item,
  &.tabs-right .nav-item {
    width: 100%;
    position: relative;
  }
}

.tabs-left .nav-item,
.tabs-right .nav-item {
  width: 100%;
  position: relative;
}

.md-tabs {
  position: relative;

  .nav-item+.nav-item {
    margin: 0;
  }

  .nav-link {
    border: none;
    color: #1b8bf9;
  }

  .nav-item {
    background-color: #fff;
    width: calc(100% / 4);
    text-align: center;

    .nav-link {
      &.active~.slide {
        opacity: 1;
        transition: all 0.3s ease-out;
      }

      ~.slide {
        opacity: 0;
        transition: all 0.3s ease-out;
      }
    }

    &.open .nav-link {
      color: #4680ff;
      border: none;
      background-color: transparent;
      border-radius: 0;

      &:focus,
      &:hover {
        color: #4680ff;
        border: none;
        background-color: transparent;
        border-radius: 0;
      }
    }
  }

  .nav-link.active {
    color: #4680ff;
    border: none;
    background-color: transparent;
    border-radius: 0;

    &:focus,
    &:hover {
      color: #4680ff;
      border: none;
      background-color: transparent;
      border-radius: 0;
    }
  }

  .nav-item {
    &:first-child {
      border-bottom: 1px solid #ddd;
    }

    a {
      padding: 20px 0 !important;
      color: #4680ff;
    }
  }
}

/*.md-tabs .nav-link:focus,*/
/*.md-tabs .nav-link:hover {*/
/*border: none;*/
/*}*/

.nav-tabs .slide {
  background: #4680ff;
  width: calc(100% / 4);
  height: 4px;
  position: absolute;
  -webkit-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;
  bottom: 0;

  .nav-item.show .nav-link,
  .nav-link {
    color: #4680ff;
  }
}

.img-tabs {
  img {
    width: 100px;
    margin: 0 auto;
  }

  a {
    opacity: 0.5;
    transition: all ease-in-out 0.3s;

    span i {
      height: 25px;
      width: 25px;
      border-radius: 100%;
      bottom: 10px;
      right: 70px;
    }

    img {
      border: 3px solid;
    }

    &.active {
      opacity: 1;
      transition: all ease-in-out 0.3s;
    }
  }

  .nav-item:first-child {
    border-bottom: none;
  }
}

#pc-left-panel-menu {
  margin-bottom: 20px;
}

.h-active a {
  color: #1b8bf9 !important;
  font-weight: 600;
}

.img-circle {
  border-radius: 50%;
}

.b-none {
  border: none !important;
}

/**====== Tabs css end ======**/
/**  =====================
      Table css start
==========================  **/

.table-primary {
  background-color: #4680fe;

  > {

    td,
    th {
      background-color: #4680fe;
    }
  }
}

table{
  width: 100% !important;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table {
  &.table-xl {

    td,
    th {
      padding: 1.25rem 2rem;
    }
  }

  &.table-lg {

    td,
    th {
      padding: 0.9rem 2rem;
    }
  }

  &.table-de {

    td,
    th {
      padding: 0.75rem 2rem;
    }
  }

  &.table-sm {

    td,
    th {
      padding: 0.6rem 2rem;
    }
  }

  &.table-xs {

    td,
    th {
      padding: 0.4rem 2rem;
    }
  }
}

.table-columned> {
  tbody>tr> {

    td:first-child,
    th:first-child {
      border-left: 0;
    }
  }

  tfoot>tr> {

    td:first-child,
    th:first-child {
      border-left: 0;
    }
  }

  tbody>tr> {

    td,
    th {
      border: 0;
      border-left: 1px solid #ddd;
    }
  }

  tfoot>tr> {

    td,
    th {
      border: 0;
      border-left: 1px solid #ddd;
    }
  }
}

.table-border-style {
  padding: 0;

  .table {
    margin-bottom: 0;
  }
}

.table>thead>tr>th {
  border-bottom-color: #ccc;
}

.table-borderless tbody tr {

  td,
  th {
    border: 0;
  }
}

.table-bordered>thead>tr {
  &.border-solid {
    > {

      td,
      th {
        border-bottom-width: 2px;
      }
    }

    &:first-child {

      >td,
      th {
        border-bottom-width: 2px;
      }
    }
  }

  &.border-double {
    > {

      td,
      th {
        border-bottom-width: 3px;
        border-bottom-style: double;
      }
    }

    &:first-child {

      >td,
      th {
        border-bottom-width: 3px;
        border-bottom-style: double;
      }
    }
  }
}

/**====== Table css end ======**/
/**  =====================
      User-Profile css start
==========================  **/

.rounded-card img {
  margin: 0 auto;
  display: block;
  width: 100%;
}

.user-img img {
  margin: 0 auto;
  display: block;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.user-about ul li {
  border-top: 1px solid rgba(204, 204, 204, 0.28);
  padding: 10px 0 10px 10px;
  text-align: left;

  a {
    font-size: 16px;
    color: #666;
  }

  &.active {
    a {
      color: #fff;
    }

    font-weight: 600;
    background: #1b8bf9;
  }
}

.card-title,
h5 {
  margin: 0;
}

.card-header.followers a {
  font-weight: 500;
  color: rgba(51, 51, 51, 0.85);
}

.user-profile {
  padding: 20px 0;
}

.follow-btn button:first-child,
.user-about i {
  margin-right: 10px;
}

.btn-inline {
  i {
    color: #fff;
  }

  .fb-btn {
    background-color: #3b5998;
  }

  .twitter-btn {
    background-color: #55acee;
  }

  .pinterest-btn {
    background-color: #cb2027;
  }

  .linkedin-btn {
    background-color: #007bb5;
  }

  .dribbble-btn {
    background-color: #ea4c89;
  }
}

.user-post {
  font-style: italic;
}

.connection-list img {
  display: inline-block;
}

.contact-user {

  h4,
  img {
    display: inline-block;
  }
}

.earn-heading {
  display: inline-block;
}

.list-inline {
  display: inline-block;

  li {
    display: inline-block;
  }
}

.service-header {
  display: inline-block;
}

.connection-list img {
  width: 55px;
  height: 55px;
  margin: 5px;
}

.border-post {
  border: 1px solid #ccc;
}

.earn-sub-header {
  font-size: 15px;
  color: #ccc;
}

.btn-inline {
  margin-top: 20px;
}

.order-summary .progress {
  margin-bottom: 32px;
}

.services .service-btn::after {
  top: 20px;
  right: 15px;
}

.services-list {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
  top: 46px;
}

.contact-menu i,
.fa-cog,
.services-list i {
  margin-right: 10px;
}

.contact-details table {

  .fa-star,
  .fa-star-o {
    color: #4680ff;
  }
}

.review-star i {
  color: #4680ff;

  &:last-child {
    color: #ccc;
  }
}

.card-body.user-info {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.profile-bg-img {
  width: 100%;
}

.user-title {
  position: relative;
  bottom: 20px;

  h2 {
    color: #fff;
    text-shadow: 1px 1px 4px #373a3c;
    font-size: 20px;
  }
}

.profile-image img {
  border: 4px solid #fff;
}

.user-info {

  .media-body,
  .media-left {
    display: table-cell;
    vertical-align: middle;
  }
}

.cover-btn {
  bottom: 38px;
  right: 35px;
  position: absolute;
}

.cover-profile .profile-bg-img {
  margin-bottom: 25px;
}

.contact-user h4 {
  font-size: 20px;
  padding-left: 10px;
}

.groups-contact span {
  float: right;

  h4 {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.contact-menu {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
  top: 15%;
  right: 10%;

  .dropdown-item {
    padding: 8px 20px;
  }
}

.tab-header {
  margin-bottom: 20px;
}

/*====== User-Profile End ======*/
/**  =====================
      User-card css start
==========================  **/

.card.user-card {
  border-top: none;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05),
    0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
  transition: all 150ms linear;

  &:hover {
    box-shadow: 0 0 25px -5px #9e9c9e;
  }
}

.card-header-img~.btn-group i {
  margin-right: 0;
}

.card.business-info {
  border-top: none;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05),
    0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
  transition: all 150ms linear;

  &:hover {
    box-shadow: 0 0 25px -5px #9e9c9e;
  }
}

.top-cap-text {
  padding: 20px;

  p {
    padding: 10px 0;
  }
}

.user-content {
  text-align: center;
  margin-top: 20px;

  h4 {
    font-size: 16px;
    font-weight: 600;
  }

  h5 {
    font-size: 14px;
  }
}

.img-overlay {
  bottom: 0;
  color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: absolute;
  transform: scale(0);
  margin: 0 auto;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }

  i {
    margin-right: 0;
  }
}

.img-hover-main {
  padding: 0 40px;
}

.img-hover {
  position: relative;
  margin: 0 auto;
}

.img-overlay span .btn i {
  margin-right: 0;
}

.simple-cards .user-card .label-icon {
  margin-top: 15px;
}

.img-hover:hover .img-overlay {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: scale(1);
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.card-header-img {
  img {
    margin: 0 auto;
    display: block;
  }

  h4 {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  h5,
  h6 {
    margin-top: 15px;
    font-size: 15px;
    color: #222;
    font-weight: 500;
    text-align: center;
  }
}

.simple-cards {
  .btn-group {
    margin: 20px auto 0;

    button {
      margin: 0;
    }
  }

  .user-card {
    padding: 20px 0;
    text-align: center;

    .label-icon .badge-top-right {
      margin-left: 3px;
      top: -8px;
    }
  }
}

.card-icon {
  display: block;
  margin-bottom: 5px;
}

.btn-outline-primary {
  border-color: #4680ff;
  font-weight: 500;
  padding: 10px 16px;
  font-size: 15px;

  &:hover {
    background-color: #4680ff;
    border-color: #4680ff;
  }
}

.simple-cards p {
  margin: 20px;
  font-size: 15px;
}

.user-profile {

  #edit-btn,
  #edit-info-btn {
    margin-bottom: 0;
  }
}

.card-block {

  ul.list-contacts,
  &.groups-contact ul,
  ul.list-contacts li,
  &.groups-contact ul li {
    display: block;
    float: none;
  }
}

ul.list-contacts .list-group-item {
  a {
    color: #292b2c;
  }

  &.active a {
    color: #fff;
  }
}

.pagination li {
  display: inline-block;
}

.card-block {
  &.groups-contact {
    margin-bottom: 0;
  }

  .connection-list {
    margin-bottom: 20px;
  }
}

.table button {
  margin-bottom: 0;
}

#crm-contact .img-circle,
img.comment-img {
  width: 40px;
  height: 40px;
}

.page-link {
  color: #4680ff;
}

.page-item.active .page-link {
  background-color: #4680ff;
  border-color: #4680ff;
}

#main {
  margin-bottom: 20px;
}

/*====== User-card End ======*/
/**  =====================
      Offline css start
==========================  **/

.offline-box iframe {
  width: 100%;
  border: 1px solid #ddd;
}

.login.offline-404 {
  background-color: #f3f3f3;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  height: calc(100vh - 80px);
}

/*==== Offline page css end ====*/
/**  =====================
      Blog css start
==========================  **/

.blog-page {
  border-top: none;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05),
    0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);

  .blog-box {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;

    h5 {
      border-bottom: 1px solid #ccc;
      color: #4680ff;
      margin-top: 0;
      padding-bottom: 10px;
      margin-bottom: 15px;
      font-size: 18px;
      display: block;
    }

    .option-font {
      background-color: #4680ff;
      border-radius: 50%;
      bottom: 320px;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      line-height: 35px;
      text-align: center;
      width: 35px;
    }

    .blog-detail {
      padding: 10px;
    }
  }

  .blog-img {
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    margin-bottom: -20px;

    &:hover {
      opacity: 0.8;
      transition: all 0.3s ease-in-out;
    }

    .sharing {
      position: relative;
      bottom: 50px;
      left: 15px;
    }

    .share {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      bottom: 3px;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      left: 50px;
      padding: 3px 5px;
      position: absolute;

      &::before {
        border-bottom: 6px solid transparent;
        border-left: 6px solid transparent;
        border-right: 6px solid rgba(0, 0, 0, 0.5);
        border-top: 6px solid rgba(0, 0, 0, 0.5);
        bottom: 8px;
        content: "";
        height: 42%;
        left: -6px;
        position: absolute;
        transform: rotate(-135deg);
        width: 12px;
      }

      i {
        color: #fff;
        font-size: 15px;
        border-right: 1px solid #fff;
        padding: 0 5px;

        &:last-child {
          border: none;
        }

        a {
          padding: 0 5px;
          color: #fff;
        }
      }
    }
  }
}

.author {
  display: inline-block;
  float: right;

  a {
    font-size: 13px;
  }
}

.blog_post_img h5 {
  display: block;
}

.blog-date {
  font-size: 18px;
  padding-bottom: 10px;
  display: block;

  i {
    margin-left: 5px;
    font-size: 14px;
    color: #4680ff;
  }

  .icon-calendar {
    font-size: 16px;
    margin-top: 5px;
  }
}

.day {
  color: #bbb;
  padding-left: 10px;
  font-size: 14px;
}

.btn-blog a {
  color: #fff !important;
}

.blog-single {
  h4 {
    margin-top: 20px;
    color: #4680ff;
    font-weight: 400;
  }

  p {
    margin: 20px 0;

    &:last-child {
      color: #777;
    }
  }

  .qutoe-text {
    font-size: 15px;
    color: #4680ff;
    border-left: 3px solid #4680ff;
    padding-left: 25px;
    font-weight: 400;
  }

  img:first-child {
    width: 100%;
  }
}

.blog-article .articles img {
  width: 100%;
}

.blog-big-user {

  h6,
  p {
    color: #757575;
  }
}

.blog-s-reply h6 {
  color: #4680ff;
}

.shares-like li {

  &:first-child,
  i {
    color: #777;
  }
}

.blog-tag li {
  display: inline-block;
  padding: 5px 15px;
  border: 1px solid #ccc;
  margin: 5px 0;
  margin-right: 15px;

  a,
  i {
    color: #777;
  }

  &:first-child {
    border: none;
    padding: 5px 0;
  }

  i {
    font-size: 20px;
    vertical-align: middle;
  }
}

.shares-like {
  li {
    display: inline-block;
    margin: 5px 0;
    margin-right: 15px;
    font-size: 20px;

    &:first-child {
      font-size: 17px;
    }

    i {
      color: #fff;
    }
  }

  .btn-facebook,
  .btn-google-plus,
  .btn-linkedin,
  .btn-pinterest,
  .btn-twitter {
    color: #fff;
    padding: 10px 15px;
    display: inline-block;
  }
}

.btn-dribbble i,
.btn-dropbox i,
.btn-facebook i,
.btn-flickr i,
.btn-github i,
.btn-google-plus i,
.btn-instagram i,
.btn-linkedin i,
.btn-pinterest i,
.btn-skype i,
.btn-tumblr i,
.btn-twitter i,
.btn-youtube i {
  display: inline-block;
  border-radius: 3px 0 0 3px;
}

.blog-article .articles {
  h6 {
    padding-top: 20px;
    font-weight: 400;
  }

  a {
    font-weight: 400;
    font-size: 15px;
    color: #4680ff;
    margin: 20px 0;
    display: block;
  }
}

.blog-s-reply h6 span {
  font-size: 12px;
  color: #777;
  margin-left: 5px;
}

.blog-u-comment {
  span {
    font-size: 14px;
  }

  .blog-edit a,
  .blog-reply a {
    margin-right: 10px;
    font-size: 12px;
  }
}

.system_font_color {
  font-size: 18px;
  color: #4680ff;

  a {
    color: #4680ff;
    margin-left: 5px;
  }
}

.port_detail_next_search {
  h5 {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #cccccc;
    display: block;
    font-weight: lighter;
  }

  a {
    color: #333;
    transition: all 0.3s 0s;

    i {
      float: right;
      margin-top: 6px;
    }
  }
}

.blog_detail_social_icon span {
  font-size: 18px;
  padding: 10px;
  border: 1px solid #999;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 10px;
  transition: all 0.5s ease;
  display: inline-block;
  margin-bottom: 10px;

  &:hover {
    background-color: #4680ff;
    color: #fff;
  }
}

.latest_blog h5 a {
  color: #333;
  font-weight: 600;
}

/*====== Blog css ends =======*/
/**  =====================
      Gallery css start
==========================  **/

.gallery-page .card-block {
  margin-bottom: -20px;
}

.thumbnail {
  margin-bottom: 20px;

  .thumb {
    position: relative;
    display: block;
  }
}

.card.gallery-desc {
  box-shadow: 0 0 1px 2px rgba(128, 128, 128, 0.16);
}

.masonry-media {
  overflow: hidden;
  transition: all 0.5s;

  img:hover {
    transform: scale(1.1);
    overflow: hidden;
    transition: all 0.5s;
  }
}

.masonry-image .masonry-media {
  margin-bottom: 20px;
}

figure.effect-goliath {
  background: #4680ff;
}

/**====== Gallery css end ======**/
/**  =====================
      List-chart css start
==========================  **/

.ct-series-a .ct-line {
  stroke: #1ce3bb;
}

.ct-series-b .ct-line {
  stroke: rgba(255, 157, 136, 0.62);
}

.ct-series-c .ct-line {
  stroke: rgba(79, 84, 103, 0.45);
}

.ct-series-d .ct-line {
  stroke: rgba(129, 142, 219, 0.61);
}

.ct-series-a .ct-point,
.ct-series-b .ct-point,
.ct-series-c .ct-point,
.ct-series-d .ct-point {
  stroke: rgba(52, 54, 70, 0.47);
  stroke-width: 8px;
  stroke-linecap: round;
}

.ct-series-a .ct-slice-donut {
  stroke: #01c0c8;
}

.ct-series-b .ct-slice-donut {
  stroke: #83d6de;
}

.ct-series-c .ct-slice-donut {
  stroke: #1abc9c;
}

.ct-series-d .ct-slice-donut {
  stroke: #4f5467;
}

/*===== List-chart Css End ====*/
/**  =====================
      Task-list css start
==========================  **/

.task-list {
  select {
    width: 92%;

    .task-list img {
      margin-right: 5px;
      display: inline-block;
    }
  }

  input {
    width: 92%;
  }
}

.task-list-table img {
  width: 40px;

  i {
    color: #333;
    margin-right: 5px;
  }
}

.task-page {
  td:last-child {
    position: relative;
  }

  a {
    cursor: pointer;
  }

  tr td:last-child i {
    margin-right: 10px;
  }
}

/*===== Task-list css end ====*/
/**  =====================
      Task-detiails css start
==========================  **/

.thumb-img {
  position: relative;
  display: block;

  &:hover .caption-hover {
    background-color: rgba(0, 0, 0, 0.7);
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.caption-hover {
  top: 0;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  >span {
    top: 38%;
    width: 100%;
    position: absolute;
    text-align: center;
  }
}

.media .b-2-primary {
  border: 2px solid #4680ff;
}

.thumb-block {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 3px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;

  a {
    font-size: 12px;
  }

  i {
    margin-right: 0;
  }
}

.comment-block i {
  margin-right: 0;
}

.dropdown-item {
  color: #666;
  padding: 6px 20px;

  &.active,
  &:active {
    background-color: #4680ff;
  }
}

.task-detail-right .counter {
  text-align: center;
  color: #777;
}

.task-details .table.table-xs {

  td,
  th {
    padding: 1rem 0.3rem;
  }
}

.assign-user .media-left img {
  margin-bottom: 0;
}

.photo-table img {
  display: inline-block;
  width: 40px;
  margin-bottom: 5px;
}

.v-middle {
  vertical-align: middle;
}

.revision-block {
  .form-group {
    margin-bottom: 0;
  }

  i {
    margin-right: 0;
  }
}

.task-setting {
  .switchery {
    display: block !important;
    float: right;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.task-attachment i {
  cursor: pointer;
}

/**====== Task-details css end ======**/
/**  =====================
      Task-board css start
==========================  **/

.filter-bar {

  .nav,
  .nav-item {
    display: inline-block;
  }

  >.navbar {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05),
      0 3px 1px -2px rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08);
    padding: 0.5rem 1rem;
  }
}

.navbar-nav .nav-item {
  float: left;
  line-height: 26px;
}

.nav-item button i {
  margin-right: 0;
}

.filter-bar .navbar-light .navbar-nav .nav-link {
  margin-right: 10px;
}

.card-footer .task-list-table {
  display: inline-block;

  a img {
    display: inline-block;
  }
}

.task-board {
  margin-top: 10px;
  float: right;

  .dropdown {
    display: inline-block;
  }
}

p {
  &.task-detail {
    margin-bottom: 5px;
  }

  &.task-due {
    margin-bottom: 0;
  }
}

.task-right-header-revision,
.task-right-header-status,
.task-right-header-users {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
}

.taskboard-right-progress,
.taskboard-right-revision,
.taskboard-right-users {
  margin-top: 10px;
}

.task-right {
  h6 {
    font-size: 12px;
  }

  .icofont {
    margin-top: 5px;
  }
}

.taskboard-right-revision .media .media-body .chat-header {
  font-size: 13px;
}

.media-left i {
  margin-right: 0;
}

.nav-item.nav-grid {
  float: right;
}

.faq-progress .progress {
  position: relative;
  background-color: #eeeded;
  height: 10px;

  .faq-text1,
  .faq-text2,
  .faq-text3,
  .faq-text4,
  .faq-text5 {
    font-weight: 600;
    margin-right: -37px;
  }

  .faq-bar1,
  .faq-bar2,
  .faq-bar3,
  .faq-bar4,
  .faq-bar5 {
    background: #29aecc;
    height: 10px;
    border-radius: 0;
    position: absolute;
    top: 0;
  }

  .faq-bar1 {
    background-color: #ffb64d;
  }

  .faq-text1 {
    color: #2196f3;
  }

  .faq-bar2,
  .faq-bar5 {
    background-color: #93be52;
  }

  .faq-text2,
  .faq-text5 {
    color: #4caf50;
  }

  .faq-bar3 {
    background-color: #fc6180;
  }

  .faq-text3 {
    color: #ff5252;
  }

  .faq-bar4 {
    background-color: #4680ff;
  }

  .faq-text4 {
    color: #f57c00;
  }
}

.card-faq h4 {
  color: #2196f3;
}

.faq-progress .progress {
  margin-bottom: 10px;
}

/**====== Tsak-board css end ======**/
/**  =====================
      Issue-list css start
==========================  **/

.issue-list-progress {
  border-bottom: 1px solid #ccc;

  .progress {
    position: relative;
    background-color: #eeeded;
    height: 9px;
    width: 100%;
    margin: 20px 0;
    overflow: visible;
  }
}

.issue-progress .progress {
  .issue-text1 {
    font-weight: 600;
    position: absolute;
  }

  .issue-bar1 {
    background: #4680ff;
    height: 10px;
    border-radius: 0;
    position: absolute;
    top: 0;
  }
}

.matrics-issue .sub-title {
  padding-top: 20px;
  padding-bottom: 10px;
  display: block;
}

.bg-white {
  background-color: #fff !important;
}

.matrics-issue div h6 {
  padding-top: 10px;
  color: #777;
}

table.matrics-table tr:first-child td {
  border-top: none !important;
}

#issue-list-table>thead>tr>th {
  border-bottom: none;
}

.note-card .notes-list {
  margin-bottom: 20px;
}

/**====== Issue-list css end ======**/
/**  =====================
      Product css start
==========================  **/

.prod-img {
  position: relative;
}

.prod-item .prod-img .option-hover {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  left: 0;
}

.hvr-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.prod-img .p-new a {
  position: absolute;
  top: 15px;
  right: 0;
  padding: 8px 13px;
  line-height: 1;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 2px 0 0 2px;
  background: #62d1f3;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 600;
}

.prod-info {
  a {
    font-size: 18px;
  }

  .br-wrapper {
    margin: 0 auto 20px;
  }

  .br-widget {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  h6 {
    font-size: 18px;
    font-weight: 600;
  }
}

.prod-view:hover .option-hover {
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 1;
}

.prod-item {
  .prod-info {
    background: #fff;
    padding: 30px 0 20px;
  }

  .br-widget {
    min-height: inherit;
  }
}

.prod-img .p-sale {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 40px;
  height: 40px;
  font-size: 11px;
  text-transform: uppercase;
  border-radius: 50%;
  background-color: #ff5252;
  color: #fff;
  font-weight: 800;
  letter-spacing: 1px;
  padding: 11px 4px;
}

.prod-info .br-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.option-icon i {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-size: 20px;
  line-height: 36px;
  margin: 30px 0;
  padding: 17px 5px;
}

/**====== Product css end ======**/
/**  =====================
      Product-details css start
==========================  **/

.product-detail-page {
  border-top: none;
}

.product-detail {
  .br-wrapper {
    margin: 10px 0;
  }

  .product-detail .btn i {
    margin-right: 0;
  }

  .br-widget {
    min-height: 35px;
    margin-top: 0;
    display: block;
  }

  .btn-number {
    background-color: #e0e0e0;
    border-color: #d4d4d4;
    border-radius: 0;
    color: #000;
  }

  .product-price {
    display: inline-block;
    margin-right: 50px;
    font-size: 24px;
  }

  .pro-desc,
  hr {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

#small_banner {
  .slick-slide {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.5;
  }

  img {
    cursor: pointer;
  }

  .slick-center {
    filter: grayscale(0);
    opacity: 1;
  }

  &:hover {
    .slick-prev {
      left: 0;
      transition: all ease-in 0.3s;
    }

    .slick-next {
      right: 0;
      transition: all ease-in 0.3s;
    }
  }
}

.port_details_all_img {
  overflow: hidden;
}

.slick-prev {
  left: -45px;
  z-index: 1;
  height: 100%;
  background: rgba(0, 0, 0, 0.62);
  width: 4%;
  transition: all ease-in 0.3s;

  &:focus,
  &:hover {
    color: transparent;
    outline: none;
    background: rgba(0, 0, 0, 0.8);
  }
}

.slick-next {
  right: -45px;
  z-index: 1;
  height: 100%;
  background: rgba(0, 0, 0, 0.62);
  width: 4%;
  transition: all ease-in 0.3s;

  &:focus,
  &:hover {
    color: transparent;
    outline: none;
    background: rgba(0, 0, 0, 0.8);
  }
}

.counter-input .input-group {
  width: 20%;
}

.pro-det-tab .tab-content {
  border: 1px solid #ccc;
  border-top: 0;
}

.big_banner .port_big_img {
  margin-bottom: 15px;
}

.cd-price .cd-price-month {
  font-size: 64px;
}

.product-edit .br-wrapper {
  margin: 10px 0 30px;
}

.addcontact .md-content>div ul {
  padding-left: 0;
}

/**====== Product-details css end ======**/
/* ======================
    Panels and wells css start
========================= */

.panel {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body p {
  overflow: hidden;
  margin-bottom: 0;
}

.panels-wells .panel {
  margin-bottom: 20px;

  .panel-body {
    padding: 15px;
  }
}

.panel-heading {
  padding: 10px 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-footer {
  padding: 10px 15px;
  background-color: #fafafa;
  border-top: 1px solid #eeeeee;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-sm {
  padding: 9px;
  border-radius: 3px;
}

.well-lg {
  padding: 24px;
  border-radius: 6px;
}

/**====== Panels and wells css end ======**/
/**  =====================
      Search css start
==========================  **/

.search-result .card {
  border-top: none;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
}

.seacrh-header {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.search-content {
  img {
    width: 120px;
  }

  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.search-result i {
  float: right;
}

.search2 .card-block:first-child {
  padding-top: 0;
}

/**====== Search css end ======**/
/**  =====================
      Wizard css start
==========================  **/

.cart-page .wizard>.content>.body {
  width: 100%;
  padding: 0;
}

.wizard>.content>.body {
  width: 100%;
}

.payment-card {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-bottom: 15px;
  padding: 20px;

  table {
    margin-bottom: 0;
  }
}

.confirmation {
  text-align: center;
  font-size: 80px;
}

.confirmation-icon {
  color: #fc6180;
}

.width-100 {
  width: 100%;
}

.post-input {
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  resize: none;
}

.file-upload-lbl {
  max-width: 15px;
  padding: 5px 0 0;
}

/**====== Wizard css end ======**/
/**====== Timeline css start ======**/

.post-timelines .media {
  align-items: center;
}

a {

  &[data-toggle="lightbox"] .img-fluid,
  &[data-lightbox="roadtrip"] .img-fluid,
  &[data-lightbox="example-set"] .img-fluid {
    margin: 10px 0;
  }
}

/**====== Timeline css end ======**/
/**  =====================
      Wall css start
==========================  **/

.post-new-footer {
  border-top: 1px solid #ccc;

  i {
    font-size: 18px;
    margin-right: 15px;
    cursor: pointer;
  }
}

.inline-editable {
  .btn {
    padding: 10px;
  }

  .input-group .form-control {
    width: 100%;
  }
}

.input-group {
  span {
    cursor: pointer;
  }

  a {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }
}

.msg-send {
  background-color: #f3f3f3;

  &:focus {
    background-color: #f3f3f3;
  }
}

.wall-dropdown:after {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
}

.wall-img-preview {
  display: inline-block;
  text-align: center;

  .wall-item {
    display: block;
    float: left;
    position: relative;
    overflow: hidden;
    border: 2px solid #fff;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;

    &:first-child {
      &:nth-last-child(2) {
        width: 50%;

        ~div,
        ~div {
          width: 50%;
        }

        width: 50%;
      }

      &:nth-last-child(10) {
        width: 33.333333%;

        ~div:not(:last-child) {
          width: 33.333333%;
        }
      }

      &:nth-last-child(11)~div:nth-last-of-type(-n + 3) {
        width: 33.333333%;
      }

      &:nth-last-child(3) {
        width: 33.333333%;

        ~div {
          width: 33.333333%;
        }
      }

      &:nth-last-child(4) {
        width: 33.333333%;

        ~div:not(:last-child) {
          width: 33.333333%;
        }
      }

      &:nth-last-child(5) {
        width: 33.333333%;

        ~div:not(:nth-last-of-type(-n + 2)) {
          width: 33.333333%;
        }
      }

      &:nth-last-child(6) {
        width: 33.333333%;

        ~div {
          width: 33.333333%;
        }
      }

      &:nth-last-child(7)~div:nth-last-of-type(-n + 3) {
        width: 33.333333%;
      }

      &:nth-last-child(9) {
        width: 33.333333%;

        ~div {
          width: 33.333333%;
        }
      }

      &:nth-last-child(5)~div:nth-last-of-type(-n + 2) {
        width: 50%;
      }

      &:nth-last-child(11) {
        width: 25%;

        ~div:not(:nth-last-of-type(-n + 3)) {
          width: 25%;
        }
      }

      &:nth-last-child(12) {
        width: 25%;

        ~div {
          width: 25%;
        }
      }

      &:nth-last-child(7) {
        width: 25%;

        ~div:not(:nth-last-of-type(-n + 3)) {
          width: 25%;
        }
      }

      &:nth-last-child(8) {
        width: 25%;

        ~div {
          width: 25%;
        }
      }

      &:nth-last-child(10)~div:nth-child(10),
      &:nth-last-child(4)~div:nth-child(4) {
        width: 100%;
      }
    }

    >img {
      display: none;
    }

    &:only-child {
      width: 100%;
    }
  }
}

.fb-timeliner {
  h2 {
    background: #4680ff;
    color: #fff;
    margin-top: 0;
    padding: 15px;
    font-size: 16px;
    border-radius: 2px;
    -webkit-border-radius: 4px;
    font-weight: 300;
  }

  ul {
    margin-left: 15px;
    margin-bottom: 20px;

    li {
      margin-bottom: 3px;

      a {
        color: #999797;
        border-left: 4px solid #d3d7dd;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        display: block;

        &:hover {
          color: #999797;
          border-left: 4px solid #b1b1b1;
          padding-left: 10px;
        }
      }

      &.active a {
        color: #7a7a7a;
        border-left: 4px solid #7a7a7a;
        padding-left: 10px;
      }
    }
  }
}

/*====== Wall css End ======*/
/**  =====================
      X-editable css start
==========================  **/

.dotted-line-theme {
  .no_edit {
    width: 100% !important;

    .i_text {
      font-size: 13px;
    }

    padding: 15px 2px;
  }

  .just_edit input[type="radio"] {
    opacity: 0;
  }

  .ibtn_container {
    padding-left: 0;
    margin-top: 2px;
    position: absolute;
    top: 16px;
    z-index: 999;
    width: 120px;

    i {
      color: #fff;
    }
  }
}

#msform #progressbar li.active {
  color: #4680ff;

  &:before {
    background: #4680ff;
  }
}

#progressbar li.active:after {
  background: #4680ff;
}

#msform a {
  color: #4680ff;
  font-weight: 600;
}

/*====== X-editable css End ======*/
/**  =====================
      Invoice css start
==========================  **/

.invoice-contact {
  display: flex;
  margin-bottom: 30px;
  padding-top: 30px;
  align-items: center;
}

.invoice-table {
  border-left: 1px solid #fff;
  padding-left: 20px;

  td,
  th {
    border-top: none;
    padding: 3px 0;
  }

  > {

    tbody>tr:last-child>td,
    tfoot>tr:last-child>td {
      padding-bottom: 0;
    }
  }
}

.invoice-box h1 {
  font-size: 7rem;
}

.invoice-order {
  padding-left: 0;

  th:first-child {
    padding-left: 0;
    width: 80px;
  }
}

.invoice-detail-table {
  th:first-child {
    width: 450px;
    text-align: left;
  }

  thead th {
    text-align: center;
  }

  td {
    vertical-align: middle;
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }
}

.invoice-total {
  background: #f3f3f3;
  padding: 30px 0;

  td,
  th {
    text-align: right;
  }

  td {
    padding-left: 30px;
  }
}

.invoive-info {
  display: flex;
  margin-bottom: 30px;

  h6 {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #757575;
  }
}

.invoice-total tbody {
  padding-right: 20px;
  float: right;
}

.dropdown-menu i {
  margin-right: 5px;
}

/**====== Invoice css end ======**/

.grid-layout span {
  display: block;
  border: 1px solid #ddd;
  padding: 10px;
}

.vertical-alignment .row {
  padding: 15px 0;
  min-height: 10rem;
  border: 1px solid #ddd;
}

.inte-benifits {
  border: 1px solid #ddd;

  ul {
    list-style: square;
    padding-left: 20px;
  }
}

/**  =====================
      Version css start
==========================  **/

.version .card-block {
  padding: 0;
}

.card.version .card-header .card-header-right {
  background-color: #fff;
  top: 8px;
}

.version {
  .nav {
    display: block;

    li {
      display: block;
      padding: 10px 20px;
      cursor: pointer;

      &:hover {
        background-color: #f6f7f7;
      }

      a {
        color: #666;
      }

      span {
        position: absolute;
        right: 20px;
      }
    }
  }

  .navigation-header a {
    color: #999;
  }
}

.support-btn {
  padding: 20px;
}

.version .nav li:first-child {
  border-top: 1px solid #ddd;
  color: #666;
}

/**====== Version css end ======**/
/**====== Date-dropper css ======**/

.dd-w,
.sp-container {
  z-index: 99;
}

/*====== Color css ends ======= */
/**  =====================
      Session Timeout css start
==========================  **/

#sessionTimeout-dialog .close {
  display: none;
}

/*====== Session Timeout css ends ======= */

.pull-0 {
  right: auto;
}

.pull-1 {
  right: 8.333333%;
}

.pull-2 {
  right: 16.666667%;
}

.pull-3 {
  right: 25%;
}

.pull-4 {
  right: 33.333333%;
}

.pull-5 {
  right: 41.666667%;
}

.pull-6 {
  right: 50%;
}

.pull-7 {
  right: 58.333333%;
}

.pull-8 {
  right: 66.666667%;
}

.pull-9 {
  right: 75%;
}

.pull-10 {
  right: 83.333333%;
}

.pull-11 {
  right: 91.666667%;
}

.pull-12 {
  right: 100%;
}

.push-0 {
  left: auto;
}

.push-1 {
  left: 8.333333%;
}

.push-2 {
  left: 16.666667%;
}

.push-3 {
  left: 25%;
}

.push-4 {
  left: 33.333333%;
}

.push-5 {
  left: 41.666667%;
}

.push-6 {
  left: 50%;
}

.push-7 {
  left: 58.333333%;
}

.push-8 {
  left: 66.666667%;
}

.push-9 {
  left: 75%;
}

.push-10 {
  left: 83.333333%;
}

.push-11 {
  left: 91.666667%;
}

.push-12 {
  left: 100%;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-sm-0 {
    right: auto;
  }

  .pull-sm-1 {
    right: 8.333333%;
  }

  .pull-sm-2 {
    right: 16.666667%;
  }

  .pull-sm-3 {
    right: 25%;
  }

  .pull-sm-4 {
    right: 33.333333%;
  }

  .pull-sm-5 {
    right: 41.666667%;
  }

  .pull-sm-6 {
    right: 50%;
  }

  .pull-sm-7 {
    right: 58.333333%;
  }

  .pull-sm-8 {
    right: 66.666667%;
  }

  .pull-sm-9 {
    right: 75%;
  }

  .pull-sm-10 {
    right: 83.333333%;
  }

  .pull-sm-11 {
    right: 91.666667%;
  }

  .pull-sm-12 {
    right: 100%;
  }

  .push-sm-0 {
    left: auto;
  }

  .push-sm-1 {
    left: 8.333333%;
  }

  .push-sm-2 {
    left: 16.666667%;
  }

  .push-sm-3 {
    left: 25%;
  }

  .push-sm-4 {
    left: 33.333333%;
  }

  .push-sm-5 {
    left: 41.666667%;
  }

  .push-sm-6 {
    left: 50%;
  }

  .push-sm-7 {
    left: 58.333333%;
  }

  .push-sm-8 {
    left: 66.666667%;
  }

  .push-sm-9 {
    left: 75%;
  }

  .push-sm-10 {
    left: 83.333333%;
  }

  .push-sm-11 {
    left: 91.666667%;
  }

  .push-sm-12 {
    left: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-md-0 {
    right: auto;
  }

  .pull-md-1 {
    right: 8.333333%;
  }

  .pull-md-2 {
    right: 16.666667%;
  }

  .pull-md-3 {
    right: 25%;
  }

  .pull-md-4 {
    right: 33.333333%;
  }

  .pull-md-5 {
    right: 41.666667%;
  }

  .pull-md-6 {
    right: 50%;
  }

  .pull-md-7 {
    right: 58.333333%;
  }

  .pull-md-8 {
    right: 66.666667%;
  }

  .pull-md-9 {
    right: 75%;
  }

  .pull-md-10 {
    right: 83.333333%;
  }

  .pull-md-11 {
    right: 91.666667%;
  }

  .pull-md-12 {
    right: 100%;
  }

  .push-md-0 {
    left: auto;
  }

  .push-md-1 {
    left: 8.333333%;
  }

  .push-md-2 {
    left: 16.666667%;
  }

  .push-md-3 {
    left: 25%;
  }

  .push-md-4 {
    left: 33.333333%;
  }

  .push-md-5 {
    left: 41.666667%;
  }

  .push-md-6 {
    left: 50%;
  }

  .push-md-7 {
    left: 58.333333%;
  }

  .push-md-8 {
    left: 66.666667%;
  }

  .push-md-9 {
    left: 75%;
  }

  .push-md-10 {
    left: 83.333333%;
  }

  .push-md-11 {
    left: 91.666667%;
  }

  .push-md-12 {
    left: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-lg-0 {
    right: auto;
  }

  .pull-lg-1 {
    right: 8.333333%;
  }

  .pull-lg-2 {
    right: 16.666667%;
  }

  .pull-lg-3 {
    right: 25%;
  }

  .pull-lg-4 {
    right: 33.333333%;
  }

  .pull-lg-5 {
    right: 41.666667%;
  }

  .pull-lg-6 {
    right: 50%;
  }

  .pull-lg-7 {
    right: 58.333333%;
  }

  .pull-lg-8 {
    right: 66.666667%;
  }

  .pull-lg-9 {
    right: 75%;
  }

  .pull-lg-10 {
    right: 83.333333%;
  }

  .pull-lg-11 {
    right: 91.666667%;
  }

  .pull-lg-12 {
    right: 100%;
  }

  .push-lg-0 {
    left: auto;
  }

  .push-lg-1 {
    left: 8.333333%;
  }

  .push-lg-2 {
    left: 16.666667%;
  }

  .push-lg-3 {
    left: 25%;
  }

  .push-lg-4 {
    left: 33.333333%;
  }

  .push-lg-5 {
    left: 41.666667%;
  }

  .push-lg-6 {
    left: 50%;
  }

  .push-lg-7 {
    left: 58.333333%;
  }

  .push-lg-8 {
    left: 66.666667%;
  }

  .push-lg-9 {
    left: 75%;
  }

  .push-lg-10 {
    left: 83.333333%;
  }

  .push-lg-11 {
    left: 91.666667%;
  }

  .push-lg-12 {
    left: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-xl-0 {
    right: auto;
  }

  .pull-xl-1 {
    right: 8.333333%;
  }

  .pull-xl-2 {
    right: 16.666667%;
  }

  .pull-xl-3 {
    right: 25%;
  }

  .pull-xl-4 {
    right: 33.333333%;
  }

  .pull-xl-5 {
    right: 41.666667%;
  }

  .pull-xl-6 {
    right: 50%;
  }

  .pull-xl-7 {
    right: 58.333333%;
  }

  .pull-xl-8 {
    right: 66.666667%;
  }

  .pull-xl-9 {
    right: 75%;
  }

  .pull-xl-10 {
    right: 83.333333%;
  }

  .pull-xl-11 {
    right: 91.666667%;
  }

  .pull-xl-12 {
    right: 100%;
  }

  .push-xl-0 {
    left: auto;
  }

  .push-xl-1 {
    left: 8.333333%;
  }

  .push-xl-2 {
    left: 16.666667%;
  }

  .push-xl-3 {
    left: 25%;
  }

  .push-xl-4 {
    left: 33.333333%;
  }

  .push-xl-5 {
    left: 41.666667%;
  }

  .push-xl-6 {
    left: 50%;
  }

  .push-xl-7 {
    left: 58.333333%;
  }

  .push-xl-8 {
    left: 66.666667%;
  }

  .push-xl-9 {
    left: 75%;
  }

  .push-xl-10 {
    left: 83.333333%;
  }

  .push-xl-11 {
    left: 91.666667%;
  }

  .push-xl-12 {
    left: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

/*====== Warning css starts ======= */

.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0;

  p {
    font-size: 17px;
  }

  .iew-container {
    min-width: 1024px;
    width: 100%;
    height: 200px;
    background: #fff;
    margin: 50px 0;
  }

  .iew-download {
    list-style: none;
    padding: 30px 0;
    margin: 0 auto;
    width: 720px;

    >li {
      float: left;
      vertical-align: top;

      >a {
        display: block;
        color: #000;
        width: 140px;
        font-size: 15px;
        padding: 15px 0;

        >div {
          margin-top: 10px;
        }

        &:hover {
          background-color: #eee;
        }
      }
    }
  }
}

/*====== Warning css ends ======= */

.login .container-fluid {
  width: auto;
}

/**  =====================
      Generic-class css start
========================== **/

.generic-card-block code {
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.generic-image-body {
  li {
    padding: 0 20px;
    display: inline-block;
  }

  code {
    display: block;
  }

  img {
    display: block;
    margin: 10px auto 0;
  }
}

/*====== Padding css starts ======*/

.p-0 {
  padding: 0px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

/*====== Padding css ends ======*/
/*====== Padding-top css starts ======*/

.p-t-0 {
  padding-top: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

/*====== Padding-top css ends ======*/
/*====== Padding-bottom css starts ======*/

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

/*====== Padding-bottom css ends ======*/
/*====== Padding-left css starts ======*/

.p-l-0 {
  padding-left: 0px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-l-50 {
  padding-left: 50px;
}

/*====== Padding-left css ends ======*/
/*====== Padding-right css starts ======*/

.p-r-0 {
  padding-right: 0px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-r-35 {
  padding-right: 35px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-45 {
  padding-right: 45px;
}

.p-r-50 {
  padding-right: 50px;
}

/*====== Padding-right css ends ======*/
/*====== Margin css starts ======*/

.m-0 {
  margin: 0px;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 25px;
}

.m-30 {
  margin: 30px;
}

.m-35 {
  margin: 35px;
}

.m-40 {
  margin: 40px;
}

.m-45 {
  margin: 45px;
}

.m-50 {
  margin: 50px;
}

/*====== Margin css ends ======*/
/*====== Margin-top css starts ======*/

.m-t-0 {
  margin-top: 0px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-t-50 {
  margin-top: 50px;
}

/*====== Margin-top css ends ======*/
/*====== Margin-Bottom css starts ======*/

.m-b-0 {
  margin-bottom: 0px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-45 {
  margin-bottom: 45px;
}

.m-b-50 {
  margin-bottom: 50px;
}

/*====== Margin-Bottom css ends ======*/
/*====== Margin-left css starts ======*/

.m-l-0 {
  margin-left: 0px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-l-35 {
  margin-left: 35px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-l-45 {
  margin-left: 45px;
}

.m-l-50 {
  margin-left: 50px;
}

/*====== Margin-left css ends ======*/
/*====== Margin-right css starts ======*/

.m-r-0 {
  margin-right: 0px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-r-50 {
  margin-right: 50px;
}

/*====== Margin-right css ends ======*/
/*====== Display css starts ======*/

.d-none {
  display: none;
}

.d-inline-block {
  display: inline-block;
}

.d-inline-flex {
  display: inline-flex;
}

/*====== display css ends ======*/
/*====== Border-radius css starts ======*/

.b-radius-0 {
  border-radius: 0px !important;
}

.b-radius-5 {
  border-radius: 5px !important;
}

.b-radius-10 {
  border-radius: 10px !important;
}

/*====== Border-radius css ends ======*/
/*====== Font-size css starts ======*/

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-36 {
  font-size: 36px;
}

.f-38 {
  font-size: 38px;
}

.f-40 {
  font-size: 40px;
}

.f-42 {
  font-size: 42px;
}

.f-44 {
  font-size: 44px;
}

.f-46 {
  font-size: 46px;
}

.f-48 {
  font-size: 48px;
}

.f-50 {
  font-size: 50px;
}

.f-52 {
  font-size: 52px;
}

.f-54 {
  font-size: 54px;
}

.f-56 {
  font-size: 56px;
}

.f-58 {
  font-size: 58px;
}

.f-60 {
  font-size: 60px;
}

.f-62 {
  font-size: 62px;
}

.f-64 {
  font-size: 64px;
}

.f-66 {
  font-size: 66px;
}

.f-68 {
  font-size: 68px;
}

.f-70 {
  font-size: 70px;
}

.f-72 {
  font-size: 72px;
}

.f-74 {
  font-size: 74px;
}

.f-76 {
  font-size: 76px;
}

.f-78 {
  font-size: 78px;
}

.f-80 {
  font-size: 80px;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/

.f-w-100 {
  font-weight: 100;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-900 {
  font-weight: 600;
}

/*====== Font-weight css ends ======*/
/*====== Font-style css starts ======*/

.f-s-normal {
  font-style: normal;
}

.f-s-italic {
  font-style: italic;
}

.f-s-oblique {
  font-style: oblique;
}

.f-s-initial {
  font-style: initial;
}

.f-s-inherit {
  font-style: inherit;
}

/*====== Font-style css ends ======*/
/*====== Text-align css starts ======*/

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

/*====== Text-align css ends ======*/
/*====== Text-Transform css starts ======*/

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

/*====== Text-Transform css ends ======*/
/*====== Text-Decoration css starts ======*/

.text-overline {
  text-decoration: overline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

/*====== Text-Decoration css ends ======*/
/*====== Vertical-Align css starts ======*/

.baseline {
  vertical-align: baseline;
}

.sub {
  vertical-align: sub;
}

.super {
  vertical-align: super;
}

.top {
  vertical-align: top;
}

.text-top {
  vertical-align: text-top;
}

.middle {
  vertical-align: middle;
}

.bottom {
  vertical-align: bottom;
}

.text-bottom {
  vertical-align: text-bottom;
}

.initial {
  vertical-align: initial;
}

.inherit {
  vertical-align: inherit;
}

/*====== Vertical-Align css ends ======*/
/*====== Position css starts ======*/

.pos-static {
  position: static;
}

.pos-absolute {
  position: absolute;
}

.pos-fixed {
  position: fixed;
}

.pos-relative {
  position: relative;
}

.pos-initial {
  position: initial;
}

.pos-inherit {
  position: inherit;
}

/*====== Position css ends ======*/
/*====== Float css starts ======*/

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.f-none {
  float: none;
}

/*====== Float css ends ======*/
/*====== Overflow css starts ======*/

.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

.o-auto {
  overflow: auto;
}

/*====== Overflow css ends ======*/
/*====== Image-sizes css starts ======*/

.img-20 {
  width: 20px;
}

.img-30 {
  width: 30px;
}

.img-40 {
  width: 40px;
}

.img-50 {
  width: 50px;
}

.img-60 {
  width: 60px;
}

.img-70 {
  width: 70px;
}

.img-80 {
  width: 80px;
}

.img-90 {
  width: 90px;
}

.img-100 {
  width: 100px;
}

/*====== Image-sizes css ends ======*/
/*======= Text css starts ===========*/

.text-primary {
  color: #4680ff !important;
}

.text-warning {
  color: #ffb64d !important;
}

.text-default {
  color: #e0e0e0 !important;
}

.text-danger {
  color: #fc6180 !important;
}

.text-success {
  color: #93be52 !important;
}

.text-inverse {
  color: #303548 !important;
}

.text-info {
  color: #62d1f3 !important;
}

.text-custom {
  color: #64b0f2 !important;
}

.text-pink {
  color: #ff7aa3 !important;
}

.text-muted {
  color: #919aa3 !important;
}

.text-dark {
  color: #2b3d51 !important;
}

.table-dark {
  background-color: #373b3e;
}

.text-purple {
  color: #9261c6 !important;
}

.text-muted {
  color: #919aa3 !important;
}

/*======= Text css ends ===========*/
/*======= Label-color css starts  ======= */

.label {
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

.label-primary {
  background-color: #4680ff;
}

.label-warning {
  background-color: #ffb64d;
}

.label-default {
  background-color: #e0e0e0;
}

.label-danger {
  background-color: #fc6180;
}

.label-success {
  background-color: #93be52;
}

.label-inverse {
  background-color: #303548;
}

.label-info {
  background-color: #62d1f3;
}

/*======= Label-color css starts  ======= */
/*======= Badge-color css starts  ======= */

.badge-primary {
  background-color: #4680ff;
}

.badge-warning {
  background-color: #ffb64d;
}

.badge-default {
  background-color: #e0e0e0;
}

.badge-danger {
  background-color: #fc6180;
}

.badge-success {
  background-color: #93be52;
}

.badge-inverse {
  background-color: #303548;
}

.badge-info {
  background-color: #62d1f3;
}

/*======= Badge-color css end  ======= */
/*======= Background-color css starts  ======= */

.bg-color-box {
  text-align: center;
  display: inline-block;
  padding: 10px 20px;
  box-shadow: 0 0 5px 0 rgba(128, 128, 128, 0.49);
  font-weight: 600;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  span {
    color: #fff;
  }
}

.bg-primary {
  background-color: #4680ff !important;
  color: #fff;
}

.bg-warning {
  background-color: #ffb64d !important;
  color: #fff;
}

.bg-default {
  background-color: #e0e0e0 !important;
  color: #fff;
}

.bg-danger {
  background-color: #fc6180 !important;
  color: #fff;
}

.bg-success {
  background-color: #93be52 !important;
  color: #fff;
}

.bg-inverse {
  background-color: #303548 !important;
  color: #fff;
}

.bg-info {
  background-color: #62d1f3 !important;
  color: #fff;
}

/*======= Background-color css end  ======= */
/*======= Button-color css starts  ======= */

.color-primary {
  background-color: #4680ff;
}

.color-warning {
  background-color: #ffb64d;
}

.color-default {
  background-color: #e0e0e0;
}

.color-danger {
  background-color: #fc6180;
}

.color-success {
  background-color: #93be52;
}

.color-inverse {
  background-color: #303548;
}

.color-info {
  background-color: #62d1f3;
}

/*======= Button-color css ends  ======= */
/*======= Loader-color css starts  ======= */

.loader-primary {
  background-color: #4680ff !important;
}

.loader-warning {
  background-color: #ffb64d !important;
}

.loader-default {
  background-color: #e0e0e0 !important;
}

.loader-danger {
  background-color: #fc6180 !important;
}

.loader-success {
  background-color: #93be52 !important;
}

.loader-inverse {
  background-color: #303548 !important;
}

.loader-info {
  background-color: #62d1f3 !important;
}

/*======= Loader-color css ends  ======= */
/*======= Nestable-color css starts  ======= */

.nestable-primary {
  background-color: #4680ff !important;
  border-color: #4680ff;
  color: #fff !important;
}

.nestable-warning {
  background-color: #ffb64d !important;
  border-color: #ffb64d;
  color: #fff !important;
}

.nestable-default {
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0;
  color: #fff !important;
}

.nestable-danger {
  background-color: #fc6180 !important;
  border-color: #fc6180;
  color: #fff !important;
}

.nestable-success {
  background-color: #93be52 !important;
  border-color: #93be52;
  color: #fff !important;
}

.nestable-inverse {
  background-color: #303548 !important;
  border-color: #303548;
  color: #fff !important;
}

.nestable-info {
  background-color: #62d1f3 !important;
  border-color: #62d1f3;
  color: #fff !important;
}

/*======= Nestable-color css ends  ======= */
/*======= Table-Border-Bottom-color css starts  ======= */

table {
  thead .border-bottom-primary th {
    border-bottom: 1px solid #4680ff;
  }

  tbody .border-bottom-primary {

    th,
    td {
      border-bottom: 1px solid #4680ff;
    }
  }

  thead .border-bottom-warning th {
    border-bottom: 1px solid #ffb64d;
  }

  tbody .border-bottom-warning {

    th,
    td {
      border-bottom: 1px solid #ffb64d;
    }
  }

  thead .border-bottom-default th {
    border-bottom: 1px solid #e0e0e0;
  }

  tbody .border-bottom-default {

    th,
    td {
      border-bottom: 1px solid #e0e0e0;
    }
  }

  thead .border-bottom-danger th {
    border-bottom: 1px solid #fc6180;
  }

  tbody .border-bottom-danger {

    th,
    td {
      border-bottom: 1px solid #fc6180;
    }
  }

  thead .border-bottom-success th {
    border-bottom: 1px solid #93be52;
  }

  tbody .border-bottom-success {

    th,
    td {
      border-bottom: 1px solid #93be52;
    }
  }

  thead .border-bottom-inverse th {
    border-bottom: 1px solid #303548;
  }

  tbody .border-bottom-inverse {

    th,
    td {
      border-bottom: 1px solid #303548;
    }
  }

  thead .border-bottom-info th {
    border-bottom: 1px solid #62d1f3;
  }

  tbody .border-bottom-info {

    th,
    td {
      border-bottom: 1px solid #62d1f3;
    }
  }
}

/*======= Table-Border-Bottom-color css ends  ======= */
/*======= Table styling css starts  ======= */

.table-styling {

  .table-primary,
  &.table-primary {
    background-color: #4680ff;
    color: #fff;
    border: 3px solid #4680ff;
  }

  .table-primary thead,
  &.table-primary thead {
    background-color: #135dff;
    border: 3px solid #135dff;
  }

  .table-warning,
  &.table-warning {
    background-color: #ffb64d;
    color: #fff;
    border: 3px solid #ffb64d;
  }

  .table-warning thead,
  &.table-warning thead {
    background-color: #ffa11a;
    border: 3px solid #ffa11a;
  }

  .table-default,
  &.table-default {
    background-color: #e0e0e0;
    color: #fff;
    border: 3px solid #e0e0e0;
  }

  .table-default thead,
  &.table-default thead {
    background-color: #c7c7c7;
    border: 3px solid #c7c7c7;
  }

  .table-danger,
  &.table-danger {
    background-color: #fc6180;
    color: #fff;
    border: 3px solid #fc6180;
  }

  .table-danger thead,
  &.table-danger thead {
    background-color: #fb2f58;
    border: 3px solid #fb2f58;
  }

  .table-success,
  &.table-success {
    background-color: #93be52;
    color: #fff;
    border: 3px solid #93be52;
  }

  .table-success thead,
  &.table-success thead {
    background-color: #79a13c;
    border: 3px solid #79a13c;
  }

  .table-inverse,
  &.table-inverse {
    background-color: #303548;
    color: #fff;
    border: 3px solid #303548;
  }

  .table-inverse thead,
  &.table-inverse thead {
    background-color: #1c1e29;
    border: 3px solid #1c1e29;
  }

  .table-info,
  &.table-info {
    background-color: #62d1f3;
    color: #fff;
    border: 3px solid #62d1f3;
  }

  .table-info thead,
  &.table-info thead {
    background-color: #33c3ef;
    border: 3px solid #33c3ef;
  }

  tfoot.table-info,
  &tfoot.table-info {
    background-color: #33c3ef;
    border: none;
    border-top: 3px solid #33c3ef;
    border-bottom: 3px solid #33c3ef;
  }
}

/*======= Table styling css ends  ======= */
/*======= Toolbar css starts  ======= */

.toolbar-primary {
  .tool-item {
    background: #4680ff !important;

    &.selected,
    &:hover {
      background: #135dff !important;
    }
  }

  &.tool-top .arrow {
    border-color: #4680ff transparent transparent;
  }

  &.tool-bottom .arrow {
    border-color: transparent transparent #4680ff;
  }

  &.tool-left .arrow {
    border-color: transparent transparent transparent #4680ff;
  }

  &.tool-right .arrow {
    border-color: transparent #4680ff transparent transparent;
  }
}

.btn-toolbar-primary.pressed {
  background-color: #4680ff;
}

.toolbar-warning {
  .tool-item {
    background: #ffb64d !important;

    &.selected,
    &:hover {
      background: #ffa11a !important;
    }
  }

  &.tool-top .arrow {
    border-color: #ffb64d transparent transparent;
  }

  &.tool-bottom .arrow {
    border-color: transparent transparent #ffb64d;
  }

  &.tool-left .arrow {
    border-color: transparent transparent transparent #ffb64d;
  }

  &.tool-right .arrow {
    border-color: transparent #ffb64d transparent transparent;
  }
}

.btn-toolbar-warning.pressed {
  background-color: #ffb64d;
}

.toolbar-light {
  .tool-item {
    background: #e0e0e0 !important;

    &.selected,
    &:hover {
      background: #c7c7c7 !important;
    }
  }

  &.tool-top .arrow {
    border-color: #e0e0e0 transparent transparent;
  }

  &.tool-bottom .arrow {
    border-color: transparent transparent #e0e0e0;
  }

  &.tool-left .arrow {
    border-color: transparent transparent transparent #e0e0e0;
  }

  &.tool-right .arrow {
    border-color: transparent #e0e0e0 transparent transparent;
  }
}

.btn-toolbar-light.pressed {
  background-color: #e0e0e0;
}

.toolbar-danger {
  .tool-item {
    background: #fc6180 !important;

    &.selected,
    &:hover {
      background: #fb2f58 !important;
    }
  }

  &.tool-top .arrow {
    border-color: #fc6180 transparent transparent;
  }

  &.tool-bottom .arrow {
    border-color: transparent transparent #fc6180;
  }

  &.tool-left .arrow {
    border-color: transparent transparent transparent #fc6180;
  }

  &.tool-right .arrow {
    border-color: transparent #fc6180 transparent transparent;
  }
}

.btn-toolbar-danger.pressed {
  background-color: #fc6180;
}

.toolbar-success {
  .tool-item {
    background: #93be52 !important;

    &.selected,
    &:hover {
      background: #79a13c !important;
    }
  }

  &.tool-top .arrow {
    border-color: #93be52 transparent transparent;
  }

  &.tool-bottom .arrow {
    border-color: transparent transparent #93be52;
  }

  &.tool-left .arrow {
    border-color: transparent transparent transparent #93be52;
  }

  &.tool-right .arrow {
    border-color: transparent #93be52 transparent transparent;
  }
}

.btn-toolbar-success.pressed {
  background-color: #93be52;
}

.toolbar-dark {
  .tool-item {
    background: #303548 !important;

    &.selected,
    &:hover {
      background: #1c1e29 !important;
    }
  }

  &.tool-top .arrow {
    border-color: #303548 transparent transparent;
  }

  &.tool-bottom .arrow {
    border-color: transparent transparent #303548;
  }

  &.tool-left .arrow {
    border-color: transparent transparent transparent #303548;
  }

  &.tool-right .arrow {
    border-color: transparent #303548 transparent transparent;
  }
}

.btn-toolbar-dark.pressed {
  background-color: #303548;
}

.toolbar-info {
  .tool-item {
    background: #62d1f3 !important;

    &.selected,
    &:hover {
      background: #33c3ef !important;
    }
  }

  &.tool-top .arrow {
    border-color: #62d1f3 transparent transparent;
  }

  &.tool-bottom .arrow {
    border-color: transparent transparent #62d1f3;
  }

  &.tool-left .arrow {
    border-color: transparent transparent transparent #62d1f3;
  }

  &.tool-right .arrow {
    border-color: transparent #62d1f3 transparent transparent;
  }
}

.btn-toolbar-info.pressed {
  background-color: #62d1f3;
}

/*======= Toolbar css ends  ======= */
/*======= Card-Border-Top-color css starts  ======= */

.card-border-primary {
  border-top: 4px solid #4680ff;
}

.card-border-warning {
  border-top: 4px solid #ffb64d;
}

.card-border-default {
  border-top: 4px solid #e0e0e0;
}

.card-border-danger {
  border-top: 4px solid #fc6180;
}

.card-border-success {
  border-top: 4px solid #93be52;
}

.card-border-inverse {
  border-top: 4px solid #303548;
}

.card-border-info {
  border-top: 4px solid #62d1f3;
}

/*======= Card-Border-Top-color css ends  ======= */
/*======= Panels-wells-color css starts  ======= */

.panels-wells {
  .panel-primary {
    border-color: #4680ff;
  }

  .panel-warning {
    border-color: #ffb64d;
  }

  .panel-default {
    border-color: #e0e0e0;
  }

  .panel-danger {
    border-color: #fc6180;
  }

  .panel-success {
    border-color: #93be52;
  }

  .panel-inverse {
    border-color: #303548;
  }

  .panel-info {
    border-color: #62d1f3;
  }
}

/*======= Panels-wells-color css ends  ======= */
/*======= All-Borders-color css starts  ======= */

.b-t-primary {
  border-top: 1px solid #4680ff;
}

.b-b-primary {
  border-bottom: 1px solid #4680ff;
}

.b-l-primary {
  border-left: 1px solid #4680ff;
}

.b-r-primary {
  border-right: 1px solid #4680ff;
}

.b-t-warning {
  border-top: 1px solid #ffb64d;
}

.b-b-warning {
  border-bottom: 1px solid #ffb64d;
}

.b-l-warning {
  border-left: 1px solid #ffb64d;
}

.b-r-warning {
  border-right: 1px solid #ffb64d;
}

.b-t-default {
  border-top: 1px solid #e0e0e0;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.b-l-default {
  border-left: 1px solid #e0e0e0;
}

.b-r-default {
  border-right: 1px solid #e0e0e0;
}

.b-t-danger {
  border-top: 1px solid #fc6180;
}

.b-b-danger {
  border-bottom: 1px solid #fc6180;
}

.b-l-danger {
  border-left: 1px solid #fc6180;
}

.b-r-danger {
  border-right: 1px solid #fc6180;
}

.b-t-success {
  border-top: 1px solid #93be52;
}

.b-b-success {
  border-bottom: 1px solid #93be52;
}

.b-l-success {
  border-left: 1px solid #93be52;
}

.b-r-success {
  border-right: 1px solid #93be52;
}

.b-t-inverse {
  border-top: 1px solid #303548;
}

.b-b-inverse {
  border-bottom: 1px solid #303548;
}

.b-l-inverse {
  border-left: 1px solid #303548;
}

.b-r-inverse {
  border-right: 1px solid #303548;
}

.b-t-info {
  border-top: 1px solid #62d1f3;
}

.b-b-info {
  border-bottom: 1px solid #62d1f3;
}

.b-l-info {
  border-left: 1px solid #62d1f3;
}

.b-r-info {
  border-right: 1px solid #62d1f3;
}

.b-t-theme {
  border-top: 1px solid #ddd;
}

.b-b-theme {
  border-bottom: 1px solid #ddd;
}

.b-l-theme {
  border-left: 1px solid #ddd;
}

.b-r-theme {
  border-right: 1px solid #ddd;
}

/*======= All-Borders-color css ends  ======= */
/**====== Generic-class css end ======**/
/*
$col:#FF5370,#E53935,#f07178,#FF5370,#F78C6C,#F76D47,#FFCB6B,#FFB62C,#C3E88D,#91B859,#B2CCD6,#8796B0,#89DDFF,#39ADB5,#82AAFF,#6182B8,#C792EA,#7C4DFF,#bb80b3,#945EB8,#ab7967;
*/

.pcoded {
  .pcoded-navbar .pcoded-item>li.active>a {
    font-weight: 600;
  }

  .d-color>ul {
    &:nth-child(1)>li>a>.pcoded-micon {
      background-color: #ff3366;
    }

    &:nth-child(2)>li>a>.pcoded-micon {
      background-color: #4680ff;
    }

    &:nth-child(3)>li>a>.pcoded-micon {
      background-color: #fc6180;
    }

    &:nth-child(4)>li>a>.pcoded-micon {
      background-color: #93be52;
    }

    &:nth-child(5)>li>a>.pcoded-micon {
      background-color: #ffb64d;
    }

    &:nth-child(6)>li>a>.pcoded-micon {
      background-color: #ab7967;
    }

    &:nth-child(7)>li>a>.pcoded-micon {
      background-color: #39adb5;
    }

    &:nth-child(8)>li>a>.pcoded-micon {
      background-color: #7c4dff;
    }

    &:nth-child(9)>li>a>.pcoded-micon {
      background-color: #ff5370;
    }

    &:nth-child(10)>li>a>.pcoded-micon {
      background-color: #ff3366;
    }

    &:nth-child(11)>li>a>.pcoded-micon {
      background-color: #4680ff;
    }

    &:nth-child(12)>li>a>.pcoded-micon {
      background-color: #fc6180;
    }

    &:nth-child(13)>li>a>.pcoded-micon {
      background-color: #93be52;
    }

    &:nth-child(14)>li>a>.pcoded-micon {
      background-color: #ffb64d;
    }

    &:nth-child(15)>li>a>.pcoded-micon {
      background-color: #ab7967;
    }

    &:nth-child(16)>li>a>.pcoded-micon {
      background-color: #39adb5;
    }

    &:nth-child(17)>li>a>.pcoded-micon {
      background-color: #7c4dff;
    }

    &:nth-child(18)>li>a>.pcoded-micon {
      background-color: #ff5370;
    }

    &:nth-child(19)>li>a>.pcoded-micon {
      background-color: #ff3366;
    }

    &:nth-child(20)>li>a>.pcoded-micon {
      background-color: #4680ff;
    }

    &:nth-child(21)>li>a>.pcoded-micon {
      background-color: #fc6180;
    }

    &:nth-child(22)>li>a>.pcoded-micon {
      background-color: #93be52;
    }

    &:nth-child(23)>li>a>.pcoded-micon {
      background-color: #ffb64d;
    }

    &:nth-child(24)>li>a>.pcoded-micon {
      background-color: #ab7967;
    }

    &:nth-child(25)>li>a>.pcoded-micon {
      background-color: #39adb5;
    }

    &:nth-child(26)>li>a>.pcoded-micon {
      background-color: #7c4dff;
    }

    &:nth-child(27)>li>a>.pcoded-micon {
      background-color: #ff5370;
    }
  }

  &[nav-type="st1"] .d-color>ul>li>a>.pcoded-micon i,
  &[nav-type="st3"] .d-color>ul>li>a>.pcoded-micon i,
  &[nav-type="st2"] .d-color>ul>li>a>.pcoded-micon b,
  &[nav-type="st4"] .d-color>ul>li>a>.pcoded-micon b {
    display: none;
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(1)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(1)>li>a>.pcoded-micon {
    background-color: rgba(255, 128, 159, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(1)>li>a>.pcoded-micon {

    b,
    i {
      color: #cc0033;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(1)>li>a>.pcoded-micon {

    b,
    i {
      color: #cc0033;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(2)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(2)>li>a>.pcoded-micon {
    background-color: rgba(147, 181, 255, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(2)>li>a>.pcoded-micon {

    b,
    i {
      color: #0046df;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(2)>li>a>.pcoded-micon {

    b,
    i {
      color: #0046df;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(3)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(3)>li>a>.pcoded-micon {
    background-color: rgba(253, 172, 188, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(3)>li>a>.pcoded-micon {

    b,
    i {
      color: #f20534;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(3)>li>a>.pcoded-micon {

    b,
    i {
      color: #f20534;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(4)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(4)>li>a>.pcoded-micon {
    background-color: rgba(182, 211, 138, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(4)>li>a>.pcoded-micon {

    b,
    i {
      color: #5d7c2e;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(4)>li>a>.pcoded-micon {

    b,
    i {
      color: #5d7c2e;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(5)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(5)>li>a>.pcoded-micon {
    background-color: rgba(255, 213, 154, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(5)>li>a>.pcoded-micon {

    b,
    i {
      color: #e68800;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(5)>li>a>.pcoded-micon {

    b,
    i {
      color: #e68800;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(6)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(6)>li>a>.pcoded-micon {
    background-color: rgba(198, 164, 152, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(6)>li>a>.pcoded-micon {

    b,
    i {
      color: #6f4a3d;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(6)>li>a>.pcoded-micon {

    b,
    i {
      color: #6f4a3d;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(7)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(7)>li>a>.pcoded-micon {
    background-color: rgba(106, 202, 208, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(7)>li>a>.pcoded-micon {

    b,
    i {
      color: #216367;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(7)>li>a>.pcoded-micon {

    b,
    i {
      color: #216367;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(8)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(8)>li>a>.pcoded-micon {
    background-color: rgba(180, 154, 255, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(8)>li>a>.pcoded-micon {

    b,
    i {
      color: #3d00e6;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(8)>li>a>.pcoded-micon {

    b,
    i {
      color: #3d00e6;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(9)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(9)>li>a>.pcoded-micon {
    background-color: rgba(255, 160, 176, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(9)>li>a>.pcoded-micon {

    b,
    i {
      color: #ec0028;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(9)>li>a>.pcoded-micon {

    b,
    i {
      color: #ec0028;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(10)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(10)>li>a>.pcoded-micon {
    background-color: rgba(255, 128, 159, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(10)>li>a>.pcoded-micon {

    b,
    i {
      color: #cc0033;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(10)>li>a>.pcoded-micon {

    b,
    i {
      color: #cc0033;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(11)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(11)>li>a>.pcoded-micon {
    background-color: rgba(147, 181, 255, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(11)>li>a>.pcoded-micon {

    b,
    i {
      color: #0046df;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(11)>li>a>.pcoded-micon {

    b,
    i {
      color: #0046df;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(12)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(12)>li>a>.pcoded-micon {
    background-color: rgba(253, 172, 188, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(12)>li>a>.pcoded-micon {

    b,
    i {
      color: #f20534;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(12)>li>a>.pcoded-micon {

    b,
    i {
      color: #f20534;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(13)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(13)>li>a>.pcoded-micon {
    background-color: rgba(182, 211, 138, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(13)>li>a>.pcoded-micon {

    b,
    i {
      color: #5d7c2e;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(13)>li>a>.pcoded-micon {

    b,
    i {
      color: #5d7c2e;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(14)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(14)>li>a>.pcoded-micon {
    background-color: rgba(255, 213, 154, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(14)>li>a>.pcoded-micon {

    b,
    i {
      color: #e68800;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(14)>li>a>.pcoded-micon {

    b,
    i {
      color: #e68800;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(15)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(15)>li>a>.pcoded-micon {
    background-color: rgba(198, 164, 152, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(15)>li>a>.pcoded-micon {

    b,
    i {
      color: #6f4a3d;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(15)>li>a>.pcoded-micon {

    b,
    i {
      color: #6f4a3d;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(16)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(16)>li>a>.pcoded-micon {
    background-color: rgba(106, 202, 208, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(16)>li>a>.pcoded-micon {

    b,
    i {
      color: #216367;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(16)>li>a>.pcoded-micon {

    b,
    i {
      color: #216367;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(17)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(17)>li>a>.pcoded-micon {
    background-color: rgba(180, 154, 255, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(17)>li>a>.pcoded-micon {

    b,
    i {
      color: #3d00e6;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(17)>li>a>.pcoded-micon {

    b,
    i {
      color: #3d00e6;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(18)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(18)>li>a>.pcoded-micon {
    background-color: rgba(255, 160, 176, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(18)>li>a>.pcoded-micon {

    b,
    i {
      color: #ec0028;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(18)>li>a>.pcoded-micon {

    b,
    i {
      color: #ec0028;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(19)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(19)>li>a>.pcoded-micon {
    background-color: rgba(255, 128, 159, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(19)>li>a>.pcoded-micon {

    b,
    i {
      color: #cc0033;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(19)>li>a>.pcoded-micon {

    b,
    i {
      color: #cc0033;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(20)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(20)>li>a>.pcoded-micon {
    background-color: rgba(147, 181, 255, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(20)>li>a>.pcoded-micon {

    b,
    i {
      color: #0046df;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(20)>li>a>.pcoded-micon {

    b,
    i {
      color: #0046df;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(21)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(21)>li>a>.pcoded-micon {
    background-color: rgba(253, 172, 188, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(21)>li>a>.pcoded-micon {

    b,
    i {
      color: #f20534;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(21)>li>a>.pcoded-micon {

    b,
    i {
      color: #f20534;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(22)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(22)>li>a>.pcoded-micon {
    background-color: rgba(182, 211, 138, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(22)>li>a>.pcoded-micon {

    b,
    i {
      color: #5d7c2e;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(22)>li>a>.pcoded-micon {

    b,
    i {
      color: #5d7c2e;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(23)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(23)>li>a>.pcoded-micon {
    background-color: rgba(255, 213, 154, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(23)>li>a>.pcoded-micon {

    b,
    i {
      color: #e68800;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(23)>li>a>.pcoded-micon {

    b,
    i {
      color: #e68800;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(24)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(24)>li>a>.pcoded-micon {
    background-color: rgba(198, 164, 152, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(24)>li>a>.pcoded-micon {

    b,
    i {
      color: #6f4a3d;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(24)>li>a>.pcoded-micon {

    b,
    i {
      color: #6f4a3d;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(25)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(25)>li>a>.pcoded-micon {
    background-color: rgba(106, 202, 208, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(25)>li>a>.pcoded-micon {

    b,
    i {
      color: #216367;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(25)>li>a>.pcoded-micon {

    b,
    i {
      color: #216367;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(26)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(26)>li>a>.pcoded-micon {
    background-color: rgba(180, 154, 255, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(26)>li>a>.pcoded-micon {

    b,
    i {
      color: #3d00e6;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(26)>li>a>.pcoded-micon {

    b,
    i {
      color: #3d00e6;
    }
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(27)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(27)>li>a>.pcoded-micon {
    background-color: rgba(255, 160, 176, 0.5);
  }

  &[nav-type="st3"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(27)>li>a>.pcoded-micon {

    b,
    i {
      color: #ec0028;
    }
  }

  &[nav-type="st4"] .d-color .pcoded-navbar[navbar-theme*="themelight"]>ul:nth-child(27)>li>a>.pcoded-micon {

    b,
    i {
      color: #ec0028;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(1)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(1)>li>a>.pcoded-micon {
    background-color: #ff809f;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(1)>li>a>.pcoded-micon {

    b,
    i {
      color: #cc0033;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(1)>li>a>.pcoded-micon {

    b,
    i {
      color: #cc0033;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(2)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(2)>li>a>.pcoded-micon {
    background-color: #93b5ff;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(2)>li>a>.pcoded-micon {

    b,
    i {
      color: #0046df;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(2)>li>a>.pcoded-micon {

    b,
    i {
      color: #0046df;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(3)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(3)>li>a>.pcoded-micon {
    background-color: #fdacbc;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(3)>li>a>.pcoded-micon {

    b,
    i {
      color: #f20534;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(3)>li>a>.pcoded-micon {

    b,
    i {
      color: #f20534;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(4)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(4)>li>a>.pcoded-micon {
    background-color: #b6d38a;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(4)>li>a>.pcoded-micon {

    b,
    i {
      color: #5d7c2e;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(4)>li>a>.pcoded-micon {

    b,
    i {
      color: #5d7c2e;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(5)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(5)>li>a>.pcoded-micon {
    background-color: #ffd59a;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(5)>li>a>.pcoded-micon {

    b,
    i {
      color: #e68800;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(5)>li>a>.pcoded-micon {

    b,
    i {
      color: #e68800;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(6)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(6)>li>a>.pcoded-micon {
    background-color: #c6a498;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(6)>li>a>.pcoded-micon {

    b,
    i {
      color: #6f4a3d;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(6)>li>a>.pcoded-micon {

    b,
    i {
      color: #6f4a3d;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(7)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(7)>li>a>.pcoded-micon {
    background-color: #6acad0;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(7)>li>a>.pcoded-micon {

    b,
    i {
      color: #216367;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(7)>li>a>.pcoded-micon {

    b,
    i {
      color: #216367;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(8)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(8)>li>a>.pcoded-micon {
    background-color: #b49aff;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(8)>li>a>.pcoded-micon {

    b,
    i {
      color: #3d00e6;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(8)>li>a>.pcoded-micon {

    b,
    i {
      color: #3d00e6;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(9)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(9)>li>a>.pcoded-micon {
    background-color: #ffa0b0;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(9)>li>a>.pcoded-micon {

    b,
    i {
      color: #ec0028;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(9)>li>a>.pcoded-micon {

    b,
    i {
      color: #ec0028;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(10)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(10)>li>a>.pcoded-micon {
    background-color: #ff809f;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(10)>li>a>.pcoded-micon {

    b,
    i {
      color: #cc0033;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(10)>li>a>.pcoded-micon {

    b,
    i {
      color: #cc0033;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(11)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(11)>li>a>.pcoded-micon {
    background-color: #93b5ff;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(11)>li>a>.pcoded-micon {

    b,
    i {
      color: #0046df;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(11)>li>a>.pcoded-micon {

    b,
    i {
      color: #0046df;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(12)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(12)>li>a>.pcoded-micon {
    background-color: #fdacbc;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(12)>li>a>.pcoded-micon {

    b,
    i {
      color: #f20534;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(12)>li>a>.pcoded-micon {

    b,
    i {
      color: #f20534;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(13)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(13)>li>a>.pcoded-micon {
    background-color: #b6d38a;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(13)>li>a>.pcoded-micon {

    b,
    i {
      color: #5d7c2e;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(13)>li>a>.pcoded-micon {

    b,
    i {
      color: #5d7c2e;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(14)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(14)>li>a>.pcoded-micon {
    background-color: #ffd59a;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(14)>li>a>.pcoded-micon {

    b,
    i {
      color: #e68800;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(14)>li>a>.pcoded-micon {

    b,
    i {
      color: #e68800;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(15)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(15)>li>a>.pcoded-micon {
    background-color: #c6a498;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(15)>li>a>.pcoded-micon {

    b,
    i {
      color: #6f4a3d;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(15)>li>a>.pcoded-micon {

    b,
    i {
      color: #6f4a3d;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(16)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(16)>li>a>.pcoded-micon {
    background-color: #6acad0;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(16)>li>a>.pcoded-micon {

    b,
    i {
      color: #216367;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(16)>li>a>.pcoded-micon {

    b,
    i {
      color: #216367;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(17)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(17)>li>a>.pcoded-micon {
    background-color: #b49aff;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(17)>li>a>.pcoded-micon {

    b,
    i {
      color: #3d00e6;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(17)>li>a>.pcoded-micon {

    b,
    i {
      color: #3d00e6;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(18)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(18)>li>a>.pcoded-micon {
    background-color: #ffa0b0;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(18)>li>a>.pcoded-micon {

    b,
    i {
      color: #ec0028;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(18)>li>a>.pcoded-micon {

    b,
    i {
      color: #ec0028;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(19)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(19)>li>a>.pcoded-micon {
    background-color: #ff809f;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(19)>li>a>.pcoded-micon {

    b,
    i {
      color: #cc0033;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(19)>li>a>.pcoded-micon {

    b,
    i {
      color: #cc0033;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(20)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(20)>li>a>.pcoded-micon {
    background-color: #93b5ff;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(20)>li>a>.pcoded-micon {

    b,
    i {
      color: #0046df;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(20)>li>a>.pcoded-micon {

    b,
    i {
      color: #0046df;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(21)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(21)>li>a>.pcoded-micon {
    background-color: #fdacbc;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(21)>li>a>.pcoded-micon {

    b,
    i {
      color: #f20534;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(21)>li>a>.pcoded-micon {

    b,
    i {
      color: #f20534;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(22)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(22)>li>a>.pcoded-micon {
    background-color: #b6d38a;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(22)>li>a>.pcoded-micon {

    b,
    i {
      color: #5d7c2e;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(22)>li>a>.pcoded-micon {

    b,
    i {
      color: #5d7c2e;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(23)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(23)>li>a>.pcoded-micon {
    background-color: #ffd59a;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(23)>li>a>.pcoded-micon {

    b,
    i {
      color: #e68800;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(23)>li>a>.pcoded-micon {

    b,
    i {
      color: #e68800;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(24)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(24)>li>a>.pcoded-micon {
    background-color: #c6a498;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(24)>li>a>.pcoded-micon {

    b,
    i {
      color: #6f4a3d;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(24)>li>a>.pcoded-micon {

    b,
    i {
      color: #6f4a3d;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(25)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(25)>li>a>.pcoded-micon {
    background-color: #6acad0;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(25)>li>a>.pcoded-micon {

    b,
    i {
      color: #216367;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(25)>li>a>.pcoded-micon {

    b,
    i {
      color: #216367;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(26)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(26)>li>a>.pcoded-micon {
    background-color: #b49aff;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(26)>li>a>.pcoded-micon {

    b,
    i {
      color: #3d00e6;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(26)>li>a>.pcoded-micon {

    b,
    i {
      color: #3d00e6;
    }
  }

  &[nav-type="st3"] .d-color>ul:nth-child(27)>li>a>.pcoded-micon,
  &[nav-type="st4"] .d-color>ul:nth-child(27)>li>a>.pcoded-micon {
    background-color: #ffa0b0;
  }

  &[nav-type="st3"] .d-color>ul:nth-child(27)>li>a>.pcoded-micon {

    b,
    i {
      color: #ec0028;
    }
  }

  &[nav-type="st4"] .d-color>ul:nth-child(27)>li>a>.pcoded-micon {

    b,
    i {
      color: #ec0028;
    }
  }
}

/**  =====================
      Left-side-menu css start
==========================  **/

.main-menu {
  background: #2c3e50;
  float: left;
  width: 270px;
  z-index: 99;

  .main-menu-header {
    align-items: center;
    background: #4a6076;
    display: flex;
    padding: 30px 20px 15px 20px;

    .user-details {
      display: inline-block;
      margin-left: 15px;
      margin-right: 15px;

      #more-details {
        cursor: pointer;
      }

      span {
        color: #000;
        display: block;
        font-size: 14px;
        text-transform: capitalize;

        i {
          font-size: 10px;
          margin-left: 10px;
        }

        &:first-child {
          margin-bottom: 5px;
          font-weight: 600;
        }

        &:last-child {
          color: #999;
          font-size: 12px;
        }
      }
    }
  }

  .main-menu-content {
    .more-details a {
      padding: 10px 25px;
      display: block;
      color: #000;
      transition: all ease-in 0.3s;

      &:hover {
        color: #4680ff;
      }

      i {
        margin-right: 10px;
      }
    }

    .menu-search {
      padding: 20px;
      padding-bottom: 10px;
    }

    li {
      position: relative;
    }
  }
}

/*.main-menu .main-menu-content .more-details {
  display: none;
}*/

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] {

  .main-menu-header,
  .main-menu-content {
    display: none;
  }

  .main-menu {
    width: 80px;
  }
}

.sidebar_toggle a {
  margin: -5px;
  line-height: 1;
}

/**  =====================
      Main-header css start
==========================  **/
.top_menu {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: auto;
  scrollbar-width: none;
}

.top_menu:hover {
  scroll-behavior: auto;
  scrollbar-width: thin;
}

.top_menu>a {
  display: flex;
  flex-direction: column;
  border: 1px solid #2a2a2a;
  box-shadow: none;
  text-decoration: none;
  margin: 0.6rem 0.3rem 0.3rem 0.3rem;
  min-width: 7rem;
  text-align: center;
  border-radius: 0.4rem;
  padding: 0.7rem;
  justify-content: space-evenly;
}

.top_menu>a:hover {
  border: 1px solid #2a2a2a;
  box-shadow: 3px 3px #696969;
}

.top_menu>a.active {
  border: 1px solid #2a2a2a;
  box-shadow: 3px 3px #696969;
}

.top_menu>a>span {
  font-size: 2rem;
  color: var(--clr-color-txt);
}

.top_menu>a>h5 {
  font-size: 0.8rem;
  color: var(--clr-color-txt);
}

.header-navbar {
  min-height: 80px;
  padding: 0;
  position: fixed;

  .navbar-wrapper {
    .navbar-logo {
      position: relative;
      align-items: center;
      display: flex;
      float: left;
      height: 65px;
      text-align: center;
      text-transform: uppercase;
      width: 270px;
      padding: 10px;

      h5 {
        margin-bottom: 0;
      }

      .mobile-menu {
        position: absolute;
        right: 22px;
        border: 1px solid #fff;
        border-radius: 50%;
        padding: 5px 9px;
      }

      .mobile-search,
      .mobile-options {
        display: none;
      }
    }

    .navbar-container {

      .nav-left,
      .nav-right {
        float: left;
        margin-bottom: 0;
      }

      .nav-left li,
      .nav-right li {
        float: left;
        line-height: 5;
        padding: 0 10px;
        position: relative;
      }

      .nav-left li .sidebar_toggle a,
      .nav-right li .sidebar_toggle a {
        display: none;
      }

      .nav-left li>a,
      .nav-right li>a {
        font-size: 16px;
      }

      .nav-left li>a>i.flag-icon,
      .nav-right li>a>i.flag-icon {
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }

      .nav-left a,
      .nav-right a {
        padding: 0 0.6rem;
      }

      .nav-left .mega-menu-top i {
        font-size: 10px;
        margin-left: 10px;
      }

      .nav-right {
        .mega-menu-top i {
          font-size: 10px;
          margin-left: 10px;
        }

        float: right;

        .user-profile {
          img {
            margin-right: 10px;
            width: 40px;
          }

          i {
            font-size: 10px;
            margin-left: 10px;
          }
        }
      }

      .header-notification,
      .mega-menu-top {
        perspective: 1000px;
        z-index: 99;
      }

      .header-notification .badge,
      .mega-menu-top .badge {
        border-radius: 100px;
        right: 15px;
        position: absolute;
        top: 20px;
        padding: 4px;
      }

      .header-notification .show-notification,
      .mega-menu-top .show-notification,
      .header-notification .profile-notification,
      .mega-menu-top .profile-notification {
        -webkit-transform: translateY(30px);
        background: #fff;
        box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.03),
          0 -2px 1px -2px rgba(0, 0, 0, 0.02), 0 0 0 -1px rgba(0, 0, 0, 0.03);
        opacity: 0;
        position: absolute;
        right: 0;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 24rem;
      }

      .header-notification .show-notification a,
      .mega-menu-top .show-notification a,
      .header-notification .profile-notification a,
      .mega-menu-top .profile-notification a {
        color: #666;
      }

      .header-notification .show-notification li,
      .mega-menu-top .show-notification li,
      .header-notification .profile-notification li,
      .mega-menu-top .profile-notification li {
        line-height: initial;
        padding: 1em 20px;
        width: 100%;
        transition: all 0.3s ease-in-out;
      }

      .header-notification .show-notification li:first-child,
      .mega-menu-top .show-notification li:first-child,
      .header-notification .profile-notification li:first-child,
      .mega-menu-top .profile-notification li:first-child {
        padding: 10px 20px;
      }

      .header-notification .show-notification li:first-child h6,
      .mega-menu-top .show-notification li:first-child h6,
      .header-notification .profile-notification li:first-child h6,
      .mega-menu-top .profile-notification li:first-child h6 {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
      }

      .header-notification .show-notification li:first-child label,
      .mega-menu-top .show-notification li:first-child label,
      .header-notification .profile-notification li:first-child label,
      .mega-menu-top .profile-notification li:first-child label {
        float: right;
      }

      .header-notification .show-notification li:first-child:hover,
      .mega-menu-top .show-notification li:first-child:hover,
      .header-notification .profile-notification li:first-child:hover,
      .mega-menu-top .profile-notification li:first-child:hover {
        background-color: transparent;
      }

      .header-notification .show-notification li img,
      .mega-menu-top .show-notification li img,
      .header-notification .profile-notification li img,
      .mega-menu-top .profile-notification li img {
        width: 40px;
        margin-right: 10px;
        align-self: flex-start !important;
      }

      .header-notification .show-notification li:hover,
      .mega-menu-top .show-notification li:hover,
      .header-notification .profile-notification li:hover,
      .mega-menu-top .profile-notification li:hover {
        background-color: #f1f1f1;
      }

      .header-notification .show-notification .notification-user,
      .mega-menu-top .show-notification .notification-user,
      .header-notification .profile-notification .notification-user,
      .mega-menu-top .profile-notification .notification-user {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .header-notification .show-notification .notification-msg,
      .mega-menu-top .show-notification .notification-msg,
      .header-notification .profile-notification .notification-msg,
      .mega-menu-top .profile-notification .notification-msg {
        margin-bottom: 5px;
      }

      .header-notification .show-notification .notification-time,
      .mega-menu-top .show-notification .notification-time,
      .header-notification .profile-notification .notification-time,
      .mega-menu-top .profile-notification .notification-time {
        font-size: 12px;
        color: #919aa3;
      }

      .header-notification .profile-notification,
      .mega-menu-top .profile-notification {
        width: 17em;
      }

      .header-notification .profile-notification a,
      .mega-menu-top .profile-notification a {
        font-size: 15px;
        padding-left: 0;
      }

      .header-notification .profile-notification i,
      .mega-menu-top .profile-notification i {
        font-size: 15px;
        margin-left: 0;
        margin-right: 5px;
      }

      .header-notification .profile-notification li,
      .mega-menu-top .profile-notification li {
        border-top: none;
        padding: 0.7em 20px;
      }

      .header-notification .profile-notification li:first-child,
      .mega-menu-top .profile-notification li:first-child {
        padding: 0.7em 20px;
      }

      .header-notification:hover .show-notification,
      .mega-menu-top:hover .show-notification,
      .header-notification:hover .profile-notification,
      .mega-menu-top:hover .profile-notification {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        visibility: visible;
      }

      .lng-dropdown {

        .show-notification,
        .header-notification .profile-notification {
          width: 14em;
        }
      }

      .header-notification .lng-dropdown .profile-notification,
      .lng-dropdown .mega-menu-top .profile-notification {
        width: 14em;
      }

      .mega-menu-top {
        .lng-dropdown .profile-notification {
          width: 14em;
        }

        perspective: inherit;
        position: static !important;

        .show-notification,
        .profile-notification {
          left: 0;
          margin: 0 50px;
          width: calc(100% - 70px);
          z-index: 9999;
        }

        .show-notification li,
        .profile-notification li,
        .show-notification li:first-child,
        .profile-notification li:first-child {
          padding: 20px;
        }

        .show-notification li:first-child h6,
        .profile-notification li:first-child h6 {
          border-bottom: 1px solid #f1f1f1;
          display: block;
          margin-bottom: 20px;
          padding-bottom: 10px;
        }

        .show-notification li h6,
        .profile-notification li h6 {
          border-bottom: 1px solid #ddd;
          display: block;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 20px;
          padding-bottom: 10px;
          text-transform: uppercase;
        }

        .show-notification li img,
        .profile-notification li img {
          border-radius: 0;
          transition: all ease-in-out 0.3s;
          width: auto;
        }

        .show-notification li img:hover,
        .profile-notification li img:hover {
          box-shadow: 0px 0px 10px 2px rgba(128, 128, 128, 0.37);
        }

        .show-notification .mega-mailbox li,
        .profile-notification .mega-mailbox li {
          border-top: none;
          padding-left: 0;
          padding-top: 0;
        }

        .show-notification .mega-mailbox li a,
        .profile-notification .mega-mailbox li a {
          padding: 0;
        }

        .show-notification .mega-mailbox li a i,
        .profile-notification .mega-mailbox li a i {
          font-size: 20px;
          margin-left: 0;
          margin-right: 15px;
        }

        .show-notification .mega-mailbox li h5,
        .profile-notification .mega-mailbox li h5 {
          font-size: 15px;
          font-weight: 600;
        }

        .mega-menu-links {
          li {
            border-top: none;
            padding: 10px 0;

            &:first-child {
              padding-top: 0;
              padding: 10px 0;
            }
          }

          a {
            display: block;
            font-size: 14px;
            padding: 0;
            text-transform: capitalize;
          }
        }

        .mega-menu-contact {

          label,
          .form-control {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .header-navbar {
    justify-content: center;
  }
}

@media only screen and (max-width: 575px) {
  .header-navbar {
    display: block !important;
  }
}

@media only screen and (max-width: 575px) {
  .header-navbar {

    .nav-left li,
    .nav-right li {
      padding: 0 5px;
    }
  }
}

/*======= Main-header css end ======*/

.pcoded {
  .pcoded-container {
    position: relative;
    background: #f6f7fb;
  }

  .pcoded-header {
    position: sticky;
    display: block;
    width: 100%;
  }

  .pcoded-navbar {
    display: block;
    position: fixed;
    width: 100%;
    background: #f6f7fb;
    // z-index: 1;
  }
}

.pcoded-main-container {
  display: block;
  position: relative;
  min-height: calc(100vh - 80px);
}

.pcoded-inner-content {
  margin-top: 100px;
  position: fixed;
  height: calc(100vh - 150px);
  width: 100%;
  overflow-x: auto;
}

.pcoded .pcoded-content {
  position: relative;
  display: block;
  background: dark;
}

.pcoded-inner-navbar {
  display: block;
  position: relative;
}

.pcoded-inner-content {
  padding: 5px;
}

.pcoded {
  .pcoded-navbar .pcoded-item {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    >li {
      display: block;
      list-style: outside none none;
      margin: 0;
      padding: 0;
      position: relative;

      >a {
        display: block;
        font-size: 14px;
        padding: 0 15px;
        text-decoration: none;
        position: relative;
        transition: all 0.3s ease-in-out;
      }
    }

    .pcoded-hasmenu .pcoded-submenu {
      list-style: outside none none;
      margin: 0;
      padding: 0;

      li {
        display: block;
        list-style: outside none none;
        margin: 0;
        padding: 0;
        position: relative;

        >a {
          display: block;
          font-size: 14px;
          font-weight: 100;
          padding: 0 15px;
          text-decoration: none;
          position: relative;

          >.pcoded-mtext {
            transition: all 0.3s ease-in-out;

            &:before {
              transition: all 0.3s ease-in-out;
            }
          }

          .pcoded-micon {
            font-size: 10px;
            padding-right: 5px;
          }
        }
      }
    }

    >li>a>.pcoded-micon {
      font-size: 15px;
      padding: 4px;
      margin-right: 10px;
      color: #fff;
      border-radius: 4px;
      width: 30px;
      display: inline-block;
      height: 30px;
      text-align: center;

      b {
        margin: 0 2px;
        font-size: 14px;
        line-height: 1;
      }
    }
  }

  &[vertical-placement="right"] .main-body .page-wrapper .page-header-title i {
    margin-right: 0;
    margin-left: 20px;
  }
}

.pcoded-inner-navbar {
  height: 100%;
}

.pcoded[theme-layout="vertical"] {
  .pcoded-header {
    width: 100%;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
    z-index: 1029;

    .pcoded-left-header {
      display: block;
      z-index: 1028;

      .pcoded-logo {
        opacity: 1;
        text-align: center;
        visibility: visible;
        height: 50px;
        white-space: nowrap;

        a {
          display: block;
          text-decoration: none;
          padding: 9px 0;
        }

        .logo-icon {
          bottom: -21px;
          font-size: 60px;
          left: 8px;
          position: absolute;
          z-index: -1;
          transition: all 0.3s linear;
          -webkit-transition: all 0.3s linear;
          -ms-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear;
          -o-transition: all 0.3s linear;
        }
      }
    }
  }

  &[vertical-nav-type="collapsed"] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-icon {
    font-size: 45px;
    bottom: -7px;
    left: 5px;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
  }

  &[pcoded-device-type="desktop"] .pcoded-header .pcoded-left-header .pcoded-logo .logo-text,
  &[pcoded-device-type="phone"] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-text,
  &[pcoded-device-type="tablet"] .pcoded-header .pcoded-left-header .pcoded-logo .logo-text {
    font-family: open sans;
    font-size: 16px;
    font-weight: 600;
    left: 80px;
    margin: 0 auto !important;
    opacity: 1;
    position: absolute;
    text-transform: uppercase;
    top: 25px;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    visibility: visible;
  }

  &[vertical-nav-type="compact"] .pcoded-header .navbar-logo {
    width: 200px;
    padding: 25px 15px;

    .mobile-menu {
      right: 15px;
    }
  }

  &[pcoded-device-type="phone"] .pcoded-header.nocollapsed .pcoded-left-header .pcoded-logo .logo-text {
    font-family: open sans;
    font-size: 16px;
    font-weight: 400;
    margin: 0 auto !important;
    opacity: 1;
    visibility: visible;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    position: relative;
    top: 5px;
  }

  &[vertical-nav-type="collapsed"] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-text {
    opacity: 0;
    position: absolute;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    visibility: hidden;
  }

  &[vertical-nav-type="offcanvas"] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo {
    opacity: 0;
    visibility: hidden;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
  }

  &[vertical-nav-type="compact"] .pcoded-header .pcoded-left-header .pcoded-logo a .hide-in-smallsize {
    display: none;
  }

  .pcoded-header .sidebar_toggle a {
    border-radius: 4px;
    float: left;
    font-size: 18px;
    height: 35px;
    margin-right: 5px;
    position: relative;
    text-align: center;
    top: 7px;
    width: 40px;
    border-width: 0;
    border-style: solid;

    i {
      position: relative;
      top: -7px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.sidebar_toggle a:focus {
  text-decoration: none;
}

.pcoded[theme-layout="vertical"] {
  .pcoded-header .pcoded-right-header {
    line-height: 50px;
    padding: 0 15px;

    .pcoded-rl-header {
      float: left;
    }

    .pcoded-rr-header {
      float: right;
    }

    .pcoded-rl-header {
      padding-left: 20px;
    }
  }

  &[pcoded-device-type="phone"] .pcoded-header .pcoded-right-header .pcoded-rl-header {
    padding-left: 0;
  }

  .pcoded-header .pcoded-right-header .pcoded-rl-header ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
  }
}

.pcoded-right-header .pcoded-rr-header ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.pcoded[theme-layout="vertical"] {
  .pcoded-header .pcoded-right-header {

    .pcoded-rl-header ul>li,
    .pcoded-rr-header ul>li {
      float: left;
      list-style: outside none none;
      margin: 0;
      padding: 0 5px;
      position: relative;
    }

    .pcoded-rl-header ul>li>a,
    .pcoded-rr-header ul>li>a {
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
    }

    .pcoded-rl-header li.icons>a>i,
    .pcoded-rr-header li.icons>a>i {
      font-size: 18px;
      position: relative;
      top: 3px;
    }

    .pcoded-rl-header li.icons>a,
    .pcoded-rr-header li.icons>a {
      padding: 0 5px;
    }
  }

  .pcoded-right-header .pcoded-badge {
    background-color: #777;
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    min-height: 17px;
    min-width: 10px;
    opacity: 1;
    padding: 4px 7px;
    position: absolute;
    right: -4px;
    text-align: center;
    top: 9px;
    transition: opacity 0.3s linear 0s;
    -webkit-transition: opacity 0.3s linear 0s;
    -ms-transition: opacity 0.3s linear 0s;
    -moz-transition: opacity 0.3s linear 0s;
    -o-transition: opacity 0.3s linear 0s;
    vertical-align: middle;
    visibility: visible;
    white-space: nowrap;
  }

  &[pcoded-device-type="phone"] .hide-small-device {
    display: none;
  }

  .pcoded-navbar {
    z-index: 1027;
    height: 100%;
  }

  .pcoded-content {
    position: relative;
    display: block;
  }

  .pcoded-container {
    overflow: hidden;
    position: relative;
    margin: 0 auto;
  }

  &.pcoded-wrapper,
  .pcoded-main-container {
    position: relative;
    margin: 0 auto;
  }

  .pcoded-navbar .pcoded-search {
    padding: 20px 20px 5px;
    position: relative;
  }

  &[vnavigation-view="view2"] .pcoded-navbar .pcoded-search {
    border-radius: 4px 4px 0 0;
  }
}

.pcoded-search input[type="text"] {
  display: block;
  margin: 0;
  width: 100%;
  font-size: 14px;
  -webkit-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #fbfcfe;
  padding: 6px 22px 6px 10px;
  border: none;
  border: 1px solid #ddd;

  &:focus {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
}

.style-4 input[type="text"].focus {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.pcoded-search .search-icon {
  cursor: pointer;
  margin-top: -33px;
  padding: 6px;
  position: absolute;
  right: 25px;
}

.pcoded[vertical-nav-type="collapsed"] {
  .pcoded-search-box {
    visibility: hidden;
    opacity: 0;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    position: absolute;
  }

  .pcoded-search input[type="text"] {
    padding: 6px 22px 6px 10px;
  }
}

.searchbar-toggle:before {
  content: "\f002";
  cursor: pointer;
  float: right;
  font-family: FontAwesome;
  left: 0;
  position: absolute;
  top: 10px;
  visibility: hidden;
  opacity: 0;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
}

.pcoded {
  &[vertical-nav-type="collapsed"] {
    .searchbar-toggle:before {
      left: 35px;
      top: 10px;
      visibility: visible;
      opacity: 1;
      transform: rotateX(0deg);
      -webkit-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
    }

    .pcoded-search {
      &.open {
        .searchbar-toggle:before {
          content: "\f05c";
        }

        .pcoded-search-box {
          margin-left: 60px;
          opacity: 1;
          position: absolute;
          top: 0;
          transform: rotateX(0deg);
          -webkit-transform: rotateX(0deg);
          -ms-transform: rotateX(0deg);
          -moz-transform: rotateX(0deg);
          -o-transform: rotateX(0deg);
          visibility: visible;
          width: 200px;
          padding-right: 10px;
        }
      }

      .search-icon {
        margin-top: -35px;
      }
    }

    &[vertical-placement="right"] .pcoded-search.open .pcoded-search-box {
      margin-left: 0;
      margin-right: 70px;
    }
  }

  &[theme-layout="vertical"] {
    .pcoded-navbar .pcoded-item {
      >li>a {
        text-align: left;
        padding: 10px 20px;
      }

      .pcoded-hasmenu .pcoded-submenu {
        position: relative;
        width: 100%;

        li>a {
          text-align: left;
          padding: 8.7px 10px;
        }
      }
    }

    &[vertical-nav-type="expanded"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a {
      padding-left: 10px;
    }

    .pcoded-navbar .pcoded-item {
      .pcoded-hasmenu {
        .pcoded-submenu {
          width: 80%;
          list-style: outside none none;
          margin: 0 auto;
          padding: 10px 0;
          opacity: 0;
          visibility: hidden;
          position: absolute;
        }

        &.pcoded-trigger .pcoded-submenu {
          -webkit-transform-origin: 0 0;
          transform-origin: 10% 10%;
          -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
          transition: transform 0.5s, opacity 0.5s;
        }

        .pcoded-submenu {
          transform-style: preserve-3d;
          -webkit-transform-style: preserve-3d;
          transform: rotateX(-90deg);
          -webkit-transform: rotateX(-90deg);
        }

        &.pcoded-trigger>.pcoded-submenu {
          position: relative;
          opacity: 1;
          visibility: visible;
          transform: rotateX(0deg);
          -webkit-transform: rotateX(0deg);
        }
      }

      &[item-border="true"] {
        &[item-border-style="dashed"] li>a {
          border-bottom-style: dashed;
        }

        &[item-border-style="dotted"] li>a {
          border-bottom-style: dotted;
        }

        &[item-border-style="solid"] li>a {
          border-bottom-style: solid;
        }

        &[item-border-style="none"] li>a {
          border: none;
        }
      }

      &[item-border="false"]>li>a {
        border-bottom-width: 0;
      }

      &[item-border="true"]>li>a {
        border-bottom-width: 1px;
      }

      &[subitem-border="false"] .pcoded-hasmenu .pcoded-submenu li>a {
        border-bottom-width: 0;
      }

      &[subitem-border="true"] .pcoded-hasmenu .pcoded-submenu li {
        >a {
          border-bottom-width: 1px;
        }

        &:last-child>a {
          border-bottom-width: 0;
        }
      }

      li.pcoded-hasmenu {
        >a:after {
          float: right;
          font-style: normal;
          margin-left: 3px;
          font-size: 10px;
          position: absolute;
          right: 15px;
          top: 10px;
          font-family: "themify";
          padding-top: 12px;
          transition: opacity 0.3s linear;
          -webkit-transition: opacity 0.3s linear;
          -ms-transition: opacity 0.3s linear;
          -moz-transition: opacity 0.3s linear;
          -o-transition: opacity 0.3s linear;
        }

        &[dropdown-icon="style1"]>a:after {
          content: "\e649";
        }

        &.pcoded-trigger[dropdown-icon="style1"]>a:after {
          content: "\e64b";
        }

        &[dropdown-icon="style2"]>a:after {
          content: "\e661";
        }

        &.pcoded-trigger[dropdown-icon="style2"]>a:after {
          content: "\e663";
        }

        &[dropdown-icon="style3"]>a:after {
          content: "\e61a";
        }

        &.pcoded-trigger[dropdown-icon="style3"]>a:after {
          content: "\e622";
        }
      }

      >li.pcoded-hasmenu {
        .pcoded-submenu li.pcoded-hasmenu>a:after {
          float: right;
          font-style: normal;
          margin-left: 3px;
          font-size: 10px;
          position: absolute;
          right: 15px;
          top: 0;
          padding-top: 13px;
        }

        &.pcoded-trigger {
          >a:after {
            float: right;
            font-style: normal;
            margin-left: 3px;
            position: absolute;
            right: 15px;
            font-size: 12px;
            top: 0;
            padding-top: 12px;
          }

          .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
            float: right;
            font-style: normal;
            margin-left: 3px;
            font-size: 10px;
            position: absolute;
            right: 15px;
            top: 10px;
            color: #353c4e !important;
            font-family: "themify";
            padding-top: 12px;
            transition: opacity 0.3s linear;
            -webkit-transition: opacity 0.3s linear;
            -ms-transition: opacity 0.3s linear;
            -moz-transition: opacity 0.3s linear;
            -o-transition: opacity 0.3s linear;
          }
        }
      }
    }

    .pcoded-header .pcoded-left-header {
      &[pcoded-lheader-position="relative"] {
        position: relative;
      }

      &[pcoded-lheader-position="fixed"] {
        position: fixed;
      }

      &[pcoded-lheader-position="absolute"] {
        position: absolute;
      }
    }

    .pcoded-navbar {
      &[pcoded-navbar-position="relative"] {
        position: relative;
      }

      &[pcoded-navbar-position="fixed"] {
        position: fixed;
      }

      &[pcoded-navbar-position="absolute"] {
        position: absolute;
      }
    }

    .pcoded-header {
      &[pcoded-header-position="relative"] {
        position: relative;
      }

      &[pcoded-header-position="fixed"] {
        position: fixed;
        z-index: 1028;
      }

      &[pcoded-header-position="absolute"] {
        position: absolute;
      }
    }

    &[vertical-layout="wide"] .pcoded-container {
      width: 100%;
      margin: 0 auto;
    }

    &[vertical-layout="box"] {

      #styleSelector,
      .p-chat-user,
      .showChat_inner {
        position: absolute;
      }
    }

    &[pcoded-device-type="desktop"][vertical-layout="box"] .pcoded-container {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;

      .pcoded-header {
        max-width: 1200px;
      }
    }

    &[pcoded-device-type="tablet"][vertical-layout="box"] .pcoded-container {
      max-width: 900px;
      width: 100%;
      margin: 0 auto;
    }

    &[pcoded-device-type="phone"][vertical-layout="box"] .pcoded-container {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }

    &[vertical-layout="widebox"] .pcoded-container {
      width: 100%;
      margin: 0 auto;
    }

    &[pcoded-device-type="desktop"][vertical-layout="widebox"] {

      .pcoded-main-container,
      .pcoded-wrapper {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
      }
    }

    &[pcoded-device-type="tablet"][vertical-layout="widebox"] {

      .pcoded-main-container,
      .pcoded-wrapper {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
      }
    }

    &[pcoded-device-type="phone"][vertical-layout="widebox"] {

      .pcoded-main-container,
      .pcoded-wrapper {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
      }
    }

    &[vertical-layout="widebox"] .pcoded-wrapper {
      overflow: hidden;
    }
  }

  .pcoded-overlay-box {
    background: #000 none repeat scroll 0 0;
    bottom: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    overflow: hidden;
  }

  &[vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-overlay-box,
  &[vertical-nav-type="ex-popover"][vertical-effect="overlay"] .pcoded-overlay-box,
  &[vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-overlay-box,
  &[vertical-nav-type="fullsub-collapsed"][vertical-effect="overlay"] .pcoded-overlay-box,
  &[vertical-nav-type="sub-expanded"][vertical-effect="overlay"] .pcoded-overlay-box {
    visibility: visible;
    z-index: 1026;
    opacity: 0.3;
    transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    overflow: hidden;
  }

  .pcoded-navbar .pcoded-item .pcoded-hasmenu {
    .pcoded-submenu li>a {
      .pcoded-micon {
        display: none;
        font-size: 10px;
        padding-right: 5px;
      }

      .pcoded-mtext:before {
        font-style: normal;
        padding-right: 4px;
        font-size: 10px;
        position: relative;
        left: 0;
        top: 0;
        font-family: themify;
        opacity: 1;
        visibility: visible;
      }
    }

    &[subitem-icon="style1"] .pcoded-submenu li>a .pcoded-mtext:before {
      content: "\e71b";
      padding-right: 5px;
      font-size: 12px;
    }

    &[subitem-icon="style2"] .pcoded-submenu li>a .pcoded-mtext:before {
      content: "\e6ad";
      padding-right: 5px;
      font-size: 12px;
    }

    &[subitem-icon="style3"] .pcoded-submenu li>a .pcoded-mtext:before {
      content: "\e6af";
    }

    &[subitem-icon="style4"] .pcoded-submenu li>a .pcoded-mtext:before {
      content: "\e628";
      padding-right: 10px;
      font-size: 12px;
    }

    &[subitem-icon="style5"] .pcoded-submenu li>a .pcoded-mtext:before {
      content: "\e65d";
      padding-right: 10px;
      font-size: 12px;
    }

    &[subitem-icon="style6"] .pcoded-submenu li>a .pcoded-mtext:before {
      content: "\e649";
      padding-right: 10px;
      font-size: 8px;
    }
  }

  &[theme-layout="vertical"] .pcoded-navbar .pcoded-item>li {
    &.active>a>.pcoded-mcaret {
      background: transparent none repeat scroll 0 0;
      border-bottom: 0 solid transparent;
      border-right: 0 solid #fff;
      border-top: 0 solid transparent;
      display: block;
      float: right;
      height: 0;
      position: absolute;
      right: 0;
      top: calc(50% - 10px);
      width: 0;
    }

    &:hover>a:before {
      border-left-style: solid;
      border-left-width: 5px;
      content: " ";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a:before {
    border-left-style: solid;
    border-left-width: 5px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }

  &[theme-layout="vertical"] {
    .pcoded-navbar {
      box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);

      .pcoded-item>li.active>a:before {
        border-left-style: solid;
        border-left-width: 5px;
        content: " ";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
      }
    }

    &[vertical-placement="right"] {
      .pcoded-navbar {
        float: right;
        right: 0;

        .main-menu-content,
        .main-menu-header {
          direction: rtl;
        }

        .main-menu-content ul {
          padding-right: 0;
        }
      }

      .pcoded-content {
        float: unset;
        left: 0;
      }
    }

    &[vertical-placement="left"] {
      .pcoded-navbar {
        float: left;
      }

      .pcoded-content {
        float: unset;
        right: 0;
      }
    }

    &[vertical-placement="right"] .pcoded-header {
      .pcoded-left-header {
        float: right;
        right: 0;
      }

      .pcoded-right-header {
        float: unset;
        left: unset;
      }
    }

    &[vertical-placement="left"] .pcoded-header {
      .pcoded-left-header {
        float: left;
      }

      .pcoded-right-header {
        float: unset;
        right: unset;
      }
    }

    &[vertical-nav-type="expanded"] {

      .pcoded-header .pcoded-left-header,
      .pcoded-navbar {
        width: 270px;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="push"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 270px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-left: 270px;
          margin-right: -270px;
        }
      }

      .pcoded-content {
        margin-left: 270px;
        margin-right: -270px;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="push"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 270px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-right: 0;
          margin-left: -270px;
        }
      }

      .pcoded-content {
        margin-right: 270px;
        margin-left: -270px;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="shrink"] {
      .pcoded-header {

        &.iscollapsed .pcoded-right-header,
        .pcoded-right-header {
          margin-left: 270px;
        }
      }

      .pcoded-content {
        margin-left: 270px;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="shrink"] {
      .pcoded-header {

        &.iscollapsed .pcoded-right-header,
        .pcoded-right-header {
          margin-right: 270px;
        }
      }

      .pcoded-content {
        margin-right: 270px;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="overlay"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 270px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-left: 0;
        }
      }

      .pcoded-content {
        margin-left: 0;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="overlay"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 270px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-right: 0;
        }
      }

      .pcoded-content {
        margin-right: 0;
      }
    }

    &[vertical-effect="overlay"] .pcoded-navbar .pcoded-item>li.active>a>.pcoded-mcaret {
      background: transparent !important;
      border-bottom: transparent !important;
      border-right: transparent !important;
      border-top: 15px solid transparent;
    }

    &[vertical-nav-type="expanded"] {
      &[pcoded-device-type="desktop"] .pcoded-navbar {

        .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
        &.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
          left: 100%;
          position: absolute;
          width: 270px;
          z-index: 1024;
          top: 0;
        }
      }

      &[pcoded-device-type="tablet"] .pcoded-navbar {

        .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
        &.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
          left: 100%;
          position: absolute;
          width: 270px;
          z-index: 1024;
          top: 0;
        }
      }
    }

    &[vertical-nav-type="collapsed"] {
      .pcoded-header .pcoded-left-header {
        width: 270px;
      }

      .pcoded-navbar {
        width: 80px;

        .pcoded-item>li>a {
          padding: 10px 25px;
        }
      }
    }

    &[vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="push"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 270px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 80px;
          }

          .pcoded-right-header {
            margin-left: 80px;
            margin-right: 0;
          }
        }
      }

      .pcoded-content {
        margin-left: 80px;
        margin-right: 0;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="push"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 270px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 80px;
          }

          .pcoded-right-header {
            margin-right: 45px;
            margin-left: 0;
          }
        }
      }

      .pcoded-content {
        margin-right: 80px;
        margin-left: 0;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="shrink"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 270px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 80px;
          }

          .pcoded-right-header {
            margin-left: 80px;
          }
        }
      }

      .pcoded-content {
        margin-left: 80px;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="shrink"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 270px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 80px;
          }

          .pcoded-right-header {
            margin-right: 80px;
          }
        }
      }

      .pcoded-content {
        margin-right: 80px;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="overlay"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 270px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 80px;
          }

          .pcoded-right-header {
            margin-left: 80px;
          }
        }
      }

      .pcoded-content {
        margin-left: 80px;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="overlay"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 270px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 80px;
          }

          .pcoded-right-header {
            margin-right: 80px;
          }
        }
      }

      .pcoded-content {
        margin-right: 80px;
      }
    }

    .pcoded-navbar .pcoded-item {
      >li>a .pcoded-mtext {
        opacity: 1;
        visibility: visible;
        position: absolute;
        top: 16px;
      }

      li.pcoded-hasmenu>a:after {
        opacity: 1;
        visibility: visible;
      }
    }

    &[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item {
      >li {
        >a .pcoded-mtext {
          opacity: 0;
          visibility: hidden;
          position: absolute;
        }

        &.pcoded-trigger>a .pcoded-mtext {
          opacity: 1;
          visibility: visible;
          position: absolute;
          padding-left: 22px;
        }
      }

      li.pcoded-hasmenu {
        >a:after {
          opacity: 0;
          visibility: hidden;
        }

        &.pcoded-trigger a:after {
          opacity: 1;
          visibility: visible;
        }
      }

      > {
        li.pcoded-trigger {
          display: block;
          position: relative;
          width: 290px;
          z-index: 1024;
          border-right: none;
          box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.13);
        }

        .pcoded-hasmenu {
          &.pcoded-trigger>.pcoded-submenu {
            left: 80px;
            position: absolute;
            width: 210px;
            z-index: 1024;
            border-left-style: solid;
            border-left-width: 1px;

            .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
              position: relative;
            }
          }

          >.pcoded-submenu .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
            position: absolute;
          }
        }

        li.pcoded-trigger.active>a>.pcoded-mcaret {
          display: none;
        }
      }
    }

    &[vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] {
      .pcoded-header {
        .pcoded-left-header {
          width: 270px;
        }

        .pcoded-right-header {
          margin-left: 270px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 0;
          }

          .pcoded-right-header {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 270px;
        margin-left: -270px;
      }

      .pcoded-content {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] {
      .pcoded-header {
        .pcoded-left-header {
          width: 270px;
        }

        .pcoded-right-header {
          margin-right: 0;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 0;
          }

          .pcoded-right-header {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 270px;
        margin-right: -270px;
      }

      .pcoded-content {
        margin-right: 0;
        margin-left: 0;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] {
      .pcoded-header {
        .pcoded-left-header {
          width: 270px;
        }

        .pcoded-right-header {
          margin-left: 270px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 0;
          }

          .pcoded-right-header {
            margin-left: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 270px;
        margin-left: -270px;
      }

      .pcoded-content {
        margin-left: 0;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] {
      .pcoded-header {
        .pcoded-left-header {
          width: 270px;
        }

        .pcoded-right-header {
          margin-right: 270px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 0;
          }

          .pcoded-right-header {
            margin-right: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 270px;
        margin-right: -270px;
      }

      .pcoded-content {
        margin-right: 0;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] {
      .pcoded-header {
        .pcoded-left-header {
          width: 270px;
        }

        .pcoded-right-header {
          margin-left: 270px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 0;
          }

          .pcoded-right-header {
            margin-left: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 270px;
        margin-left: -270px;
      }

      .pcoded-content {
        margin-left: 0;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] {
      .pcoded-header {
        .pcoded-left-header {
          width: 270px;
        }

        .pcoded-right-header {
          margin-right: 270px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 0;
          }

          .pcoded-right-header {
            margin-right: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 270px;
        margin-right: -270px;
      }

      .pcoded-content {
        margin-right: 0;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="push"] {
      .pcoded-header {
        &.iscollapsed {
          .pcoded-left-header {
            width: 200px;
          }

          .pcoded-right-header {
            margin-left: 200px;
            margin-right: -200px;
          }
        }

        .pcoded-left-header {
          width: 200px;
        }

        .pcoded-right-header {
          margin-left: 200px;
        }
      }

      .pcoded-navbar {
        width: 200px;
      }

      .pcoded-content {
        margin-left: 200px;
        margin-right: -200px;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="push"] {
      .pcoded-header {
        &.iscollapsed {
          .pcoded-left-header {
            width: 200px;
          }

          .pcoded-right-header {
            margin-right: 200px;
            margin-left: -200px;
          }
        }

        .pcoded-left-header {
          width: 200px;
        }

        .pcoded-right-header {
          margin-right: 200px;
        }
      }

      .pcoded-navbar {
        width: 200px;
      }

      .pcoded-content {
        margin-right: 200px;
        margin-left: -200px;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="shrink"] {
      .pcoded-header {
        &.iscollapsed {
          .pcoded-left-header {
            width: 200px;
          }

          .pcoded-right-header {
            margin-left: 200px;
          }
        }

        .pcoded-left-header {
          width: 200px;
        }

        .pcoded-right-header {
          margin-left: 200px;
        }
      }

      .pcoded-navbar {
        width: 200px;
      }

      .pcoded-content {
        margin-left: 200px;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="shrink"] {
      .pcoded-header {
        &.iscollapsed {
          .pcoded-left-header {
            width: 200px;
          }

          .pcoded-right-header {
            margin-right: 200px;
          }
        }

        .pcoded-left-header {
          width: 200px;
        }

        .pcoded-right-header {
          margin-right: 200px;
        }
      }

      .pcoded-navbar {
        width: 200px;
      }

      .pcoded-content {
        margin-right: 200px;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="overlay"] {
      .pcoded-header {
        &.iscollapsed {
          .pcoded-left-header {
            width: 200px;
          }

          .pcoded-right-header {
            margin-left: 0;
          }
        }

        .pcoded-left-header {
          width: 200px;
        }

        .pcoded-right-header {
          margin-left: 200px;
        }
      }

      .pcoded-navbar {
        width: 200px;
      }

      .pcoded-content {
        margin-left: 0;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="overlay"] {
      .pcoded-header {
        .pcoded-left-header {
          width: 200px;
        }

        .pcoded-right-header {
          margin-right: 200px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 200px;
          }

          .pcoded-right-header {
            margin-right: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 200px;
      }

      .pcoded-content {
        margin-right: 0;
      }
    }

    &[vertical-nav-type="compact"] {

      .main-menu-content,
      .main-menu-header {
        display: none;
      }

      .pcoded-navbar .pcoded-item>li {
        >a {
          line-height: 1;
          text-align: center;
          padding: 10px 20px 20px;

          .pcoded-mtext {
            opacity: 1;
            position: relative;
            text-align: center;
            visibility: visible;
            top: 10px;
          }

          >.pcoded-micon {
            display: block;
            font-size: 18px;
            padding: 5px;
            margin: 0 auto;
          }
        }

        &.active>a {
          >.pcoded-mcaret {
            background: transparent none repeat scroll 0 0;
            border-bottom: 0 solid transparent;
            border-right: 0 solid #e9e9e9;
            border-top: 0 solid transparent;
            top: 18px;
          }

          &:before {
            height: 100%;
          }
        }

        &:hover>a:before {
          height: 100%;
        }

        &.pcoded-hasmenu {

          &.pcoded-trigger[dropdown-icon="style1"]>a:after,
          &[dropdown-icon="style1"]>a:after {
            top: 18px;
          }
        }
      }

      &[pcoded-device-type="desktop"] .pcoded-navbar {

        .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
        &.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
          left: 100%;
          position: absolute;
          width: 210px;
          z-index: 1024;
          top: 0;
        }
      }

      &[pcoded-device-type="tablet"] .pcoded-navbar {

        .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
        &.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
          left: 100%;
          position: absolute;
          width: 210px;
          z-index: 1024;
          top: 0;
        }
      }
    }

    &[vertical-nav-type="sub-expanded"] {

      .pcoded-header .pcoded-left-header,
      .pcoded-navbar {
        width: 270px;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="push"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 270px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-left: 270px;
          margin-right: -270px;
        }
      }

      .pcoded-content {
        margin-left: 270px;
        margin-right: -270px;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="push"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 270px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-right: 0;
          margin-left: -270px;
        }
      }

      .pcoded-content {
        margin-right: 270px;
        margin-left: -270px;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="shrink"] {
      .pcoded-header {

        &.iscollapsed .pcoded-right-header,
        .pcoded-right-header {
          margin-left: 270px;
        }
      }

      .pcoded-content {
        margin-left: 270px;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="shrink"] {
      .pcoded-header {

        &.iscollapsed .pcoded-right-header,
        .pcoded-right-header {
          margin-right: 270px;
        }
      }

      .pcoded-navbar {
        width: 270px;
      }

      .pcoded-content {
        margin-right: 270px;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="overlay"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 270px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-left: 0;
        }
      }

      .pcoded-navbar {
        width: 270px;
      }

      .pcoded-content {
        margin-left: 0;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="overlay"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 270px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-right: 0;
        }
      }

      .pcoded-content {
        margin-right: 0;
      }
    }

    &[vertical-nav-type="sub-expanded"] {

      &[pcoded-device-type="desktop"],
      &[pcoded-device-type="tablet"] {
        .pcoded-inner-navbar {
          position: unset;
        }

        .pcoded-navbar .pcoded-item {
          position: unset;

          >li {
            position: unset;
          }
        }
      }

      &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
      &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
        left: 100%;
        position: absolute;
        width: 230px;
        z-index: 1024;
        top: 0;
        bottom: 0;
      }

      &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li,
      &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
        position: unset;
      }

      &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger .pcoded-submenu,
      &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
        left: 100%;
        position: absolute;
        width: 230px;
        z-index: 1024;
        top: 0;
        bottom: 0;
      }

      .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
        border-left-style: solid;
        border-left-width: 1px;
      }
    }

    &[vertical-nav-type="ex-popover"] {
      .pcoded-header {

        &.iscollapsed .pcoded-left-header,
        .pcoded-left-header {
          width: 270px;
        }
      }

      .pcoded-navbar {
        width: 270px;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="push"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 270px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-left: 270px;
          margin-right: -270px;
        }
      }

      .pcoded-content {
        margin-left: 270px;
        margin-right: -270px;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="push"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 270px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-right: 0;
          margin-left: -270px;
        }
      }

      .pcoded-content {
        margin-right: 270px;
        margin-left: -270px;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="shrink"] {
      .pcoded-header {

        &.iscollapsed .pcoded-right-header,
        .pcoded-right-header {
          margin-left: 270px;
        }
      }

      .pcoded-content {
        margin-left: 270px;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="shrink"] {
      .pcoded-header {

        &.iscollapsed .pcoded-right-header,
        .pcoded-right-header {
          margin-right: 270px;
        }
      }

      .pcoded-content {
        margin-right: 270px;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="overlay"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 270px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-left: 0;
        }
      }

      .pcoded-content {
        margin-left: 0;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="overlay"] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 270px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-right: 0;
        }
      }

      .pcoded-content {
        margin-right: 0;
      }
    }

    &[vertical-nav-type="ex-popover"] {

      &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item>li,
      &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item>li {
        position: relative;
      }

      &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
      &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
        left: 100%;
        position: absolute;
        width: 230px;
        z-index: 1024;
        top: 0;
      }

      &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li,
      &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
        position: relative;
      }

      &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger .pcoded-submenu,
      &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
        left: 100%;
        position: absolute;
        width: 230px;
        z-index: 1024;
        top: 0;
      }

      &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item li.pcoded-trigger>a>.pcoded-mcaret,
      &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item li.pcoded-trigger>a>.pcoded-mcaret {
        background: transparent none repeat scroll 0 0;
        border-bottom: 19px solid transparent;
        border-right: 12px solid;
        border-top: 19px solid transparent;
        display: block;
        float: right;
        height: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        z-index: 1025;
      }

      .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
        border-left-style: solid;
        border-left-width: 1px;
      }
    }

    &[vertical-placement="left"][vertical-nav-type="fullpage"] .pcoded-header {
      .pcoded-left-header {
        width: 270px;
      }

      .pcoded-right-header {
        margin-left: 270px;
      }

      &.iscollapsed .pcoded-left-header {
        width: 270px;
      }
    }

    &[vertical-nav-type="fullpage"] {
      .pcoded-navbar {
        width: 100%;
      }

      .pcoded-content {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[vertical-placement="right"][vertical-nav-type="fullpage"] .pcoded-header {
      .pcoded-left-header {
        width: 270px;
      }

      .pcoded-right-header {
        margin-right: 270px;
      }

      &.iscollapsed .pcoded-left-header {
        width: 270px;
      }
    }

    &[vertical-nav-type="fullpage"] {
      .pcoded-navbar {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1030;
        opacity: 1;
        visibility: visible;
        width: 100%;

        .pcoded-item {
          >li.active>a {
            background: transparent !important;
          }

          &[item-border="true"]>li>a {
            border-bottom-width: 0 !important;
          }
        }

        &[pcoded-navbar-position="absolute"] {
          position: absolute;
        }

        &[pcoded-navbar-position="fixed"] {
          position: fixed;
        }

        .pcoded-inner-navbar {
          margin-top: 40px;
        }
      }

      .pcoded-main-container {
        display: block;
        position: unset;
      }

      .pcoded-navbar {
        .pcoded-item>li>a {
          height: 45px;
          line-height: 45px;
          text-align: center;
          font-size: 24px;
          font-weight: 500;

          .pcoded-mtext {
            opacity: 1;
            position: relative;
            visibility: visible;
          }

          >.pcoded-micon {
            display: none;
          }
        }

        .pcoded-navigatio-lavel {
          display: none !important;
        }

        .pcoded-item>li {

          &.active>a:before,
          a .pcoded-badge {
            display: none;
          }

          &:hover>a {
            background: transparent !important;

            &:before {
              display: none;
            }
          }

          &.active>a>.pcoded-mcaret {
            display: none;
          }

          >a {
            padding: 0;
          }
        }

        .sidebar_toggle {
          height: 30px;
        }
      }
    }
  }
}

.pcoded-navbar .sidebar_toggle a {
  opacity: 1;
  visibility: visible;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  position: absolute;
}

.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .sidebar_toggle a {
  color: #f1f1f1;
  float: right;
  font-size: 36px;
  opacity: 1;
  position: relative;
  right: 10px;
  text-decoration: none;
  top: 0;
  transition: opacity 0.8s linear 0s;
  -webkit-transition: opacity 0.8s linear 0s;
  -ms-transition: opacity 0.8s linear 0s;
  -moz-transition: opacity 0.8s linear 0s;
  -o-transition: opacity 0.8s linear 0s;
  visibility: visible;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
}

.pcoded-navbar .sidebar_toggle a:hover {
  color: #fff;
}

.pcoded {
  &[theme-layout="vertical"] {
    &[vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item {
      >li {
        &.pcoded-hasmenu>a:after {
          display: none;
        }

        &.pcoded-trigger>a {
          background: transparent !important;
          border-bottom-color: transparent !important;
        }
      }

      .pcoded-hasmenu .pcoded-submenu {
        background: transparent !important;
      }

      &[subitem-border="true"] .pcoded-hasmenu .pcoded-submenu li>a {
        border-bottom-width: 0;
      }

      .pcoded-hasmenu .pcoded-submenu li {
        >a {
          text-align: center;
          font-size: 24px;
          font-weight: 500;

          .pcoded-mtext:before {
            display: none;
          }
        }

        &:hover>a {
          background: transparent !important;

          &:before {
            border-left-width: 0;
          }
        }
      }

      .pcoded-submenu>li.active>a {
        background: transparent !important;
        color: #fff !important;
      }
    }

    &[pcoded-device-type="phone"] {
      .pcoded-header.nocollapsed .pcoded-left-header {
        width: 100% !important;
        padding-left: 270px;
      }

      &[vertical-nav-type="collapsed"] .pcoded-header.nocollapsed .pcoded-left-header {
        padding-left: 45px;
      }

      &[vertical-effect="overlay"] .pcoded-header.nocollapsed .pcoded-left-header,
      &[vertical-nav-type="fullpage"] .pcoded-header.nocollapsed .pcoded-left-header,
      &[vertical-nav-type="offcanvas"] .pcoded-header.nocollapsed .pcoded-left-header {
        padding-left: 0;
      }

      .pcoded-header.nocollapsed {
        height: 100px !important;

        .pcoded-right-header {
          padding: 50px 5px 0 !important;
        }
      }

      &[vertical-effect="overlay"] .pcoded-header.nocollapsed .pcoded-right-header,
      &[vertical-nav-type="offcanvas"] .pcoded-header.nocollapsed .pcoded-right-header {
        margin: 0 !important;
      }

      .pcoded-header.nocollapsed .sidebar_toggle a {
        background: transparent none repeat scroll 0 0;
        border-radius: 4px;
        color: #fff;
        float: left;
        font-size: 18px;
        height: 35px;
        margin-right: 5px;
        position: fixed;
        right: 0;
        text-align: center;
        top: 6px;
        width: 40px;
        z-index: 1028;
      }
    }
  }

  &.nocollapsed[theme-layout="vertical"][pcoded-device-type="phone"] {
    .pcoded-navbar {
      top: 0;
      z-index: 1030;
    }

    .pcoded-main-container {
      position: unset;
    }

    #styleSelector {
      top: 100px;
    }
  }

  .pcoded-navbar .pcoded-navigatio-lavel {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    opacity: 1;
    padding: 20px 20px 10px;
    text-transform: capitalize;
    visibility: visible;
    width: 100%;
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    border-bottom: 2px solid;

    &[menu-title-theme="theme1"] {
      color: #8c8c8c !important;
    }

    &[menu-title-theme="theme2"] {
      color: #624e89 !important;
    }

    &[menu-title-theme="theme3"] {
      color: #3c4fb1 !important;
    }

    &[menu-title-theme="theme4"] {
      color: #919aa3 !important;
    }

    &[menu-title-theme="theme5"] {
      color: #999 !important;
    }

    &[menu-title-theme="theme6"] {
      color: #fff !important;
    }
  }

  &[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-navigatio-lavel {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
  }

  &[vertical-nav-type="compact"] .pcoded-navbar .pcoded-navigatio-lavel {
    display: none;
  }

  .pcoded-navbar .pcoded-item {
    li a .pcoded-badge {
      position: absolute;
      right: 30px;
      text-align: center;
      top: 18px;
      vertical-align: middle;
      white-space: nowrap;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s linear;
    }

    .pcoded-submenu>li a .pcoded-badge {
      top: 10px;
      right: 2px;
    }
  }

  &[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item>li {
    >a .pcoded-badge {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s linear;
      -webkit-transition: opacity 0.3s linear;
      -ms-transition: opacity 0.3s linear;
      -moz-transition: opacity 0.3s linear;
      -o-transition: opacity 0.3s linear;
    }

    &.pcoded-trigger>a .pcoded-badge {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s linear;
      -webkit-transition: opacity 0.3s linear;
      -ms-transition: opacity 0.3s linear;
      -moz-transition: opacity 0.3s linear;
      -o-transition: opacity 0.3s linear;
    }
  }

  &[theme-layout="vertical"][vertical-placement="right"] {
    .pcoded-navbar {
      .pcoded-item {
        >li {
          &.active>a {
            &:before {
              left: auto;
              right: 0;
            }

            >.pcoded-mcaret {
              background: transparent none repeat scroll 0 0;
              border-bottom: 0 solid transparent;
              border-left: 0 solid #e9e9e9;
              border-top: 0 solid transparent;
              left: 0;
              right: auto;
              top: 4px;
              border-right: 0;
            }
          }

          &.pcoded-hasmenu>a:after {
            left: 8px;
            right: auto;
            top: -1px;
          }

          >a .pcoded-mtext {
            left: auto;
            right: 0;
            padding-right: 60px;
          }
        }

        li a .pcoded-badge {
          position: relative;
          right: 30px;
          top: -5px;
        }

        >li:hover>a:before {
          left: auto;
          right: 0;
        }

        .pcoded-hasmenu .pcoded-submenu li {
          &:hover>a:before {
            left: auto;
            right: 0;
          }

          >a {
            text-align: right;

            .pcoded-mtext:before {
              float: right;
              left: 6px;
              top: 3px;
              padding-right: 10px;
            }
          }
        }

        li .pcoded-submenu li>a .pcoded-badge {
          left: 8px;
          position: absolute;
          right: auto;
          top: 11px;
        }
      }

      .pcoded-navigatio-lavel {
        text-align: right;
        padding: 10px 40px 2px 20px;

        &:before {
          left: auto;
          right: 20px;
        }
      }

      .pcoded-item li.pcoded-hasmenu {
        &[dropdown-icon="style1"]>a:after {
          content: "\e64b";
          /*font-family: 'icofont';*/
        }

        &.pcoded-trigger[dropdown-icon="style1"]>a:after {
          content: "\eb89";
          font-family: "icofont";
        }
      }
    }

    &[vertical-nav-type="expanded"] {
      &[pcoded-device-type="desktop"] .pcoded-navbar {

        .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
        &.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
          left: auto;
          right: 100%;
        }
      }

      &[pcoded-device-type="tablet"] .pcoded-navbar {

        .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
        &.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
          left: auto;
          right: 100%;
        }
      }
    }

    .pcoded-navbar .pcoded-item>li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu>a:after {
      left: 7px;
      right: auto;
      top: 0;
    }

    &[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item {
      > {
        li.pcoded-trigger {
          left: auto;
          right: 210px;
        }

        .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
          left: auto;
        }
      }

      li a .pcoded-badge {
        position: absolute;
        right: auto;
        top: 10px;
      }
    }

    .pcoded-header {
      .pcoded-right-header {
        .pcoded-rl-header {
          float: right;
        }

        .pcoded-rr-header {
          float: left;
        }
      }

      .sidebar_toggle {
        float: right;
      }
    }

    &[vertical-nav-type="collapsed"] .pcoded-search.open .pcoded-search-box {
      right: 0;
    }

    .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a {
      text-align: right;

      .pcoded-mtext:before {
        padding-left: 10px;
        transform: rotate(180deg);
      }
    }
  }
}

body {
  background-color: #f6f7fb;
}

.pcoded {
  .pcoded-navbar {
    &[active-item-theme="theme1"] {
      .pcoded-item {
        li {
          .pcoded-submenu li {

            &.active>a,
            &:hover>a {
              color: #fe8a7d !important;
            }

            &.active>a {
              font-weight: 600;
            }
          }

          &.pcoded-hasmenu:hover>a {
            color: #fff !important;
          }

          &:hover>a {
            color: #fff !important;
            background: #fe8a7d;

            &:before {
              border-left-color: transparent !important;
            }
          }
        }

        >li.active>a:after {
          color: #fff !important;
        }
      }

      .searchbar-toggle {
        color: #fe8a7d;
      }
    }

    &[active-item-theme="theme2"] {
      .pcoded-item {
        li {
          .pcoded-submenu li {

            &.active>a,
            &:hover>a {
              color: #fc6180 !important;
            }

            &.active>a {
              font-weight: 600;
            }
          }

          &.pcoded-hasmenu:hover>a {
            color: #fff !important;
          }

          &:hover>a {
            color: #fff !important;
            background: #fc6180;

            &:before {
              border-left-color: transparent !important;
            }
          }
        }

        >li.active>a:after {
          color: #fff !important;
        }
      }

      .searchbar-toggle {
        color: #fc6180;
      }
    }

    &[active-item-theme="theme3"] {
      .pcoded-item {
        li {
          .pcoded-submenu li {

            &.active>a,
            &:hover>a {
              color: #93be52 !important;
            }

            &.active>a {
              font-weight: 600;
            }
          }

          &.pcoded-hasmenu:hover>a {
            color: #fff !important;
          }

          &:hover>a {
            color: #fff !important;
            background: #93be52;

            &:before {
              border-left-color: transparent !important;
            }
          }
        }

        >li.active>a:after {
          color: #fff !important;
        }
      }

      .searchbar-toggle {
        color: #93be52;
      }
    }

    &[active-item-theme="theme4"] {
      .pcoded-item li {
        .pcoded-submenu li {

          &.active>a,
          &:hover>a {
            color: #4680ff !important;
          }

          &.active>a {
            font-weight: 600;
          }
        }

        &.pcoded-hasmenu:hover>a {
          color: #fff !important;
        }

        &:hover>a {
          color: #fff !important;
          background: #4680ff;

          &:before {
            border-left-color: transparent !important;
          }
        }
      }

      .searchbar-toggle {
        color: #4680ff;
      }
    }

    &[active-item-theme="theme5"] {
      .pcoded-item {
        li {
          .pcoded-submenu li {

            &.active>a,
            &:hover>a {
              color: #ffb64d !important;
            }

            &.active>a {
              font-weight: 600;
            }
          }

          &.pcoded-hasmenu:hover>a {
            color: #fff !important;
          }

          &:hover>a {
            color: #fff !important;
            background: #ffb64d;

            &:before {
              border-left-color: transparent !important;
            }
          }
        }

        >li.active>a:after {
          color: #fff !important;
        }
      }

      .searchbar-toggle {
        color: #ffb64d;
      }
    }

    &[active-item-theme="theme6"] {
      .pcoded-item {
        li {
          .pcoded-submenu li {

            &.active>a,
            &:hover>a {
              color: #ff5e3a !important;
            }

            &.active>a {
              font-weight: 600;
            }
          }

          &.pcoded-hasmenu:hover>a {
            color: #fff !important;
          }

          &:hover>a {
            color: #fff !important;
            background: #ff5e3a;

            &:before {
              border-left-color: transparent !important;
            }
          }
        }

        >li.active>a:after {
          color: #fff !important;
        }
      }

      .searchbar-toggle {
        color: #ff5e3a;
      }
    }

    &[active-item-theme="theme7"] {
      .pcoded-item {
        li {
          .pcoded-submenu li {

            &.active>a,
            &:hover>a {
              color: #e64056 !important;
            }

            &.active>a {
              font-weight: 600;
            }
          }

          &.pcoded-hasmenu:hover>a {
            color: #fff !important;
          }

          &:hover>a {
            color: #fff !important;
            background: #e64056;

            &:before {
              border-left-color: transparent !important;
            }
          }
        }

        >li.active>a:after {
          color: #fff !important;
        }
      }

      .searchbar-toggle {
        color: #e64056;
      }
    }

    &[active-item-theme="theme8"] {
      .pcoded-item {
        li {
          .pcoded-submenu li {

            &.active>a,
            &:hover>a {
              color: #3c4fb1 !important;
            }

            &.active>a {
              font-weight: 600;
            }
          }

          &.pcoded-hasmenu:hover>a {
            color: #fff !important;
          }

          &:hover>a {
            color: #fff !important;
            background: #3c4fb1;

            &:before {
              border-left-color: transparent !important;
            }
          }
        }

        >li.active>a:after {
          color: #fff !important;
        }
      }

      .searchbar-toggle {
        color: #3c4fb1;
      }
    }

    &[active-item-theme="theme9"] {
      .pcoded-item {
        li {
          .pcoded-submenu li {

            &.active>a,
            &:hover>a {
              color: #ff5370 !important;
            }

            &.active>a {
              font-weight: 600;
            }
          }

          &.pcoded-hasmenu:hover>a {
            color: #fff !important;
          }

          &:hover>a {
            color: #fff !important;
            background: #ff5370;

            &:before {
              border-left-color: transparent !important;
            }
          }
        }

        >li.active>a:after {
          color: #fff !important;
        }
      }

      .searchbar-toggle {
        color: #ff5370;
      }
    }

    &[active-item-theme="theme10"] {
      .pcoded-item {
        li {
          .pcoded-submenu li {

            &.active>a,
            &:hover>a {
              color: #f76d47 !important;
            }

            &.active>a {
              font-weight: 600;
            }
          }

          &.pcoded-hasmenu:hover>a {
            color: #fff !important;
          }

          &:hover>a {
            color: #fff !important;
            background: #f76d47;

            &:before {
              border-left-color: transparent !important;
            }
          }
        }

        >li.active>a:after {
          color: #fff !important;
        }
      }

      .searchbar-toggle {
        color: #f76d47;
      }
    }

    &[active-item-theme="theme11"] {
      .pcoded-item {
        li {
          .pcoded-submenu li {

            &.active>a,
            &:hover>a {
              color: #39adb5 !important;
            }

            &.active>a {
              font-weight: 600;
            }
          }

          &.pcoded-hasmenu:hover>a {
            color: #fff !important;
          }

          &:hover>a {
            color: #fff !important;
            background: #39adb5;

            &:before {
              border-left-color: transparent !important;
            }
          }
        }

        >li.active>a:after {
          color: #fff !important;
        }
      }

      .searchbar-toggle {
        color: #39adb5;
      }
    }

    &[active-item-theme="theme12"] {
      .pcoded-item {
        li {
          .pcoded-submenu li {

            &.active>a,
            &:hover>a {
              color: #7c4dff !important;
            }

            &.active>a {
              font-weight: 600;
            }
          }

          &.pcoded-hasmenu:hover>a {
            color: #fff !important;
          }

          &:hover>a {
            color: #fff !important;
            background: #7c4dff;

            &:before {
              border-left-color: transparent !important;
            }
          }
        }

        >li.active>a:after {
          color: #fff !important;
        }
      }

      .searchbar-toggle {
        color: #7c4dff;
      }
    }

    .pcoded-header {
      &[header-theme="theme1"] {
        color: #fff;

        .sidebar_toggle a {
          background: transparent;
          color: #fff;
          border-color: #fff;
        }
      }

      &.nocollapsed .pcoded-left-header[lheader-theme="theme1"] {
        background: transparent;
      }

      &.iscollapsed .pcoded-left-header[lheader-theme="theme1"] {
        background: #303548;
      }
    }

    &[navbar-theme="theme1"] {
      background: #303548;

      .main-menu {
        background-color: #303548;

        .main-menu-header {
          background-color: #303548;

          .user-details span {
            color: #fff;
          }
        }

        .main-menu-content .more-details a {
          color: #fff;
        }
      }

      .pcoded-item {
        >li {
          >a {
            color: #fff;
          }

          &.active>a {
            background: #303548;
            color: #fff;
            border-bottom-color: #303548;

            &:before {
              border-left-color: #303548;
            }
          }

          >a {
            border-bottom-color: #303548;
          }
        }

        li.pcoded-hasmenu .pcoded-submenu {
          background: #303548;

          li>a {
            border-bottom-color: #444c67;
            color: #fff;
          }
        }

        >li {
          &.pcoded-hasmenu {

            >a:after,
            .pcoded-submenu li.pcoded-hasmenu>a:after {
              color: #fff;
            }

            &.pcoded-trigger {

              >a:after,
              .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                color: #fff;
              }
            }
          }

          &.active:hover>a,
          &:hover>a {
            background: #303548;
            color: #fff;
          }
        }

        .pcoded-hasmenu .pcoded-submenu li {
          &:hover>a {
            background-color: #303548;
            color: #fff;

            &:before {
              border-left-color: #303548;
            }
          }

          &.active>a {
            color: #fff;
            background-color: #303548;

            .pcoded-mtext {
              &:before {
                border-bottom-color: #303548;
              }

              &:after {
                border-left-color: #303548;
              }
            }
          }

          >a .pcoded-mtext {
            &:before {
              border-bottom-color: #ffff;
            }

            &:after {
              border-left-color: #fff;
            }
          }
        }

        >li {
          &:hover>a:before {
            border-left-color: #303548;
          }

          &.pcoded-hasmenu:hover>a:after {
            color: #fff;
          }

          &.pcoded-trigger {
            >a {
              background: #303548;
              border-bottom-color: #303548;
            }

            &.active>a {
              background: #303548;
            }
          }

          &.pcoded-hasmenu {
            .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
              color: #fff;
            }

            &.pcoded-trigger.active>a:after {
              color: #303548;
            }
          }
        }
      }

      .pcoded-navigatio-lavel {
        color: #303548;
        border-bottom-color: transparent;
      }
    }

    &[theme-layout="vertical"] {

      &[vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
      &[vertical-nav-type="sub-expanded"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
        border-left-color: transparent;
      }

      &[vertical-nav-type="ex-popover"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item {
        li.pcoded-trigger>a>.pcoded-mcaret {
          border-right-color: #303548 !important;
        }

        >.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
          border-left-color: transparent;
        }
      }

      &[vertical-nav-type="fullpage"] .pcoded-navbar[navbar-theme="theme1"] {
        background-color: #303548;

        .pcoded-item>li:hover>a {
          color: #fff;
        }

        .sidebar_toggle a {
          color: #fff;
        }
      }
    }

    &[navbar-theme="themelight1"] {
      .pcoded-item li {
        .pcoded-submenu li.active>a {
          font-weight: 600;
        }

        &.pcoded-hasmenu:hover>a {
          color: #000 !important;
        }

        &:hover>a {
          color: #000 !important;
          background: #fff;

          &:before {
            border-left-color: transparent !important;
          }
        }
      }

      background: #fff;

      .main-menu {
        background-color: #fff;

        .main-menu-header {
          background-color: #fff;

          .user-details span {
            color: #000;
          }
        }

        .main-menu-content .more-details a {
          color: #000;
        }
      }

      .pcoded-item {
        li.pcoded-hasmenu .pcoded-submenu {
          background: #fff;

          li>a {
            border-bottom-color: #e6e6e6;
            color: #000;
          }
        }

        >li {
          &.pcoded-hasmenu {

            >a:after,
            .pcoded-submenu li.pcoded-hasmenu>a:after {
              color: #000;
            }

            &.pcoded-trigger {

              >a:after,
              .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                color: #000;
              }
            }
          }

          &.active:hover>a,
          &:hover>a {
            background: #fff;
            color: #000;
          }
        }

        .pcoded-hasmenu .pcoded-submenu li {
          &:hover>a {
            background-color: #fff;
            color: #000;

            &:before {
              border-left-color: #fff;
            }
          }

          &.active>a {
            color: #000;

            .pcoded-mtext {
              &:before {
                border-bottom-color: #fff;
              }

              &:after {
                border-left-color: #fff;
              }
            }
          }

          >a .pcoded-mtext {
            &:before {
              border-bottom-color: #fff;
            }

            &:after {
              border-left-color: #fff;
            }
          }
        }

        >li {
          &:hover>a:before {
            border-left-color: #fff;
          }

          &.pcoded-hasmenu:hover>a:after {
            color: #000;
          }

          &.pcoded-trigger {
            >a {
              background: #fff;
              border-bottom-color: #fff;
            }

            &.active>a {
              background: #fff;
            }
          }

          &.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
            color: #fff;
          }

          >a {
            color: #000 !important;
          }

          &.active>a {
            background: #fff !important;
            color: #fff;

            &:before {
              border-left-color: #fff;
            }
          }

          >a {
            border-bottom-color: #fff;
          }
        }
      }

      .pcoded-navigatio-lavel {
        color: #fff;
        border-bottom-color: transparent;
      }
    }
  }

  &[theme-layout="vertical"] {
    &[vertical-nav-type="compact"] .pcoded-navbar {
      &[navbar-theme="themelight1"] .pcoded-item>li>a {
        border-bottom-color: #e6e6e6;
      }

      &[navbar-theme="theme1"] .pcoded-item>li>a {
        border-bottom-color: #444c67;
      }
    }

    &[vertical-nav-type="collapsed"] .pcoded-navbar {
      &[navbar-theme="themelight1"] .pcoded-item> {
        .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
          border-left-color: #ededed;
          box-shadow: 3px 2px 11px -2px rgba(0, 0, 0, 0.13);
        }

        li.pcoded-trigger>a {
          border-bottom: 1px solid #e6e6e6;
          border-top: 1px solid #e6e6e6;
        }
      }

      &[navbar-theme="theme1"] .pcoded-item> {
        .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
          border-left-color: #3e455d;
          box-shadow: 3px 2px 11px -2px rgba(0, 0, 0, 0.13);
        }

        li.pcoded-trigger>a {
          border-bottom: 1px solid #444c67;
          border-top: 1px solid #444c67;
        }
      }
    }
  }

  #styleSelector {
    min-height: 300px;
    height: calc(100vh - 80px);
    border: 1px solid #e9e9e9;
    background: #fff;
    position: fixed;
    margin: 0;
    padding: 20px;
    width: 400px;
    top: 80px;
    z-index: 100;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -o-transition: 0.5s;

    &.open {
      background: #fff none repeat scroll 0 0;
      -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    }

    a {
      >i {
        padding: 0;
        margin: 0;
        margin-right: 5px;
      }

      &.soc-icon {
        width: 55px;
        height: 55px;
        padding: 15px;
        border-radius: 50%;
      }
    }

    .soc-icon i {
      font-size: 25px;
      margin-right: 0px;
    }
  }

  &[theme-layout="horizontal"] #styleSelector,
  &[vertical-placement="left"] #styleSelector {
    right: -400px;
    border-left: 2px solid rgba(48, 53, 73, 0.28);
  }

  &[theme-layout="horizontal"] #styleSelector.open,
  &[vertical-placement="left"] #styleSelector.open {
    right: 0;
  }

  &[vertical-placement="right"] #styleSelector {
    left: -400px;
    border-right: 2px solid rgba(48, 53, 73, 0.28);

    &.open {
      left: 0;
    }
  }
}

.selector-toggle {
  position: relative;
}

.pcoded {
  .selector-toggle>a {
    position: absolute;
    top: 200px;
    width: 60px;
    height: 60px;
    display: block;
    cursor: pointer;
    text-align: center;
    background: #4680ff;
    color: #fff;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 14px 8px;
  }

  &[theme-layout="horizontal"] .selector-toggle>a,
  &[vertical-placement="left"] .selector-toggle>a {
    left: -80px;
    border-right: 0;
    border-radius: 7px 0 0 7px;
  }

  &[vertical-placement="right"] .selector-toggle>a {
    right: -80px;
    border-left: 0;
    border-radius: 0 7px 7px 0;
  }
}

.selector-toggle>a:before {
  font-family: "themify";
  font-size: 20px;
  content: "\e60f";
  position: relative;
  float: left;
  left: 13px;
  line-height: 35px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-animation: mymove 1.3s infinite linear;
  animation: mymove 1.3s infinite linear;
}

.open .selector-toggle>a:before {
  font-family: "themify";
  font-size: 20px;
  content: "\e646";
  position: relative;
  float: left;
  left: 13px;
  line-height: 35px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-animation: mymove 0s infinite linear;
  animation: mymove 0s infinite linear;
}

#styleSelector {
  .nav-tabs {
    margin-bottom: 20px;
    border-bottom: 2px solid #4680ff;
    padding-bottom: 5px;

    .nav-item {
      width: calc(100% / 2);
      margin: 0;
      padding: 0;
      background-color: transparent;
      float: left;

      a {
        color: #303548;
        text-align: center;
        font-weight: 600;
        font-size: 13px;
        background-color: transparent;
        padding: 0.66rem 1rem;
        border-radius: 2px;
        border: none;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        &.active {
          color: #fff;
          background-color: #4680ff;
        }
      }
    }
  }

  .style-cont {
    background-color: #f6f7fb;
    padding: 10px;
  }

  .st-main-title {
    font-size: 18px;
  }

  .btn-success {
    background-color: #11c15b;
  }

  .checkbox-fade label {
    margin-bottom: 0.8rem;
  }

  .sub-title {
    text-transform: capitalize;
  }

  #menu-effect {
    padding-bottom: 15px;

    .radio {
      >label {
        padding-left: 0rem;
      }

      .helper {
        bottom: -30px;
        left: 3px;

        &:after,
        &:before {
          top: auto;
          bottom: 0px;
        }
      }
    }

    .micon {
      font-size: 15px;
      padding: 4px;
      border-radius: 4px;
      width: 30px;
      display: block;
      height: 30px;
      text-align: center;

      &.st1 i,
      &.st3 i,
      &.st2 b,
      &.st5 b,
      &.st4 b {
        display: none;
      }

      &.st1 {
        background-color: #4680ff;

        b,
        i {
          color: #fff;
        }
      }

      &.st2 {
        background-color: #93be52;

        b,
        i {
          color: #fff;
        }
      }

      &.st3 {
        background-color: #ffd59a;

        b,
        i {
          color: #e68800;
        }
      }

      &.st4 {
        background-color: #fdacbc;

        b,
        i {
          color: #f20534;
        }
      }

      &.st5 {
        background-color: transparent;

        i {
          color: #4680ff;
        }
      }
    }
  }

  #bg-pattern-visiblity {
    padding-left: 30px;
  }

  .form-control {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #f1f1f1;
    margin-bottom: 10px;

    &:focus {
      border: none;
      background-color: #f1f1f1;
    }
  }

  ul {
    border: 0 none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      border-bottom: 0 solid #e9e9e9;
      display: block;
      margin: 0;
      padding: 0;
      text-align: left;
      width: 100%;

      p.selector-title,
      span.selector-title {
        color: #444;
        font-size: 14px;
        margin: 0 0 15px;
        padding: 10px 0;
        text-align: left;
      }

      p {
        &.selector-title {
          font-weight: 600;
          border-bottom: 1px solid #e9e9e9;

          &.main-title {
            font-weight: 600;
          }
        }

        &.sub-title.drp-title {
          border-bottom: 0 solid transparent;
          margin-bottom: 0;
          margin-top: 10px;
        }
      }
    }
  }

  >ul>li>.sub-title {
    color: #9c9c9c;
    display: block;
    font-size: 13px;
    margin: 0;
    padding: 5px 0;
    position: relative;
    text-align: left;
  }

  li {
    padding: 5px;
  }
}

.pcoded #styleSelector .soc-icon {
  width: 55px;
  height: 55px;
  padding: 15px;
  border-radius: 50%;
}

.theme-option select {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 3px 10px;
}

@-moz-document url-prefix() {
  .theme-option select {
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 2px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 3px 10px;
    margin: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    &.minimal {
      background-image: -webkit-linear-gradient(45deg,
          transparent 50%,
          gray 50%),
        -webkit-linear-gradient(315deg, gray 50%, transparent 50%),
        -webkit-linear-gradient(left, #ccc, #ccc);
      background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position: calc(100% - 18px) calc(1em + -1px),
        calc(100% - 13px) calc(1em + -1px), calc(100% - 2.5em) 4px;
      background-size: 5px 5px, 5px 5px, 1px 1.5em;
      background-repeat: no-repeat;

      &:active,
      &:focus {
        background-image: -webkit-linear-gradient(45deg,
            gray 50%,
            transparent 50%),
          -webkit-linear-gradient(315deg, transparent 50%, gray 50%),
          -webkit-linear-gradient(left, gray, gray);
        background-image: linear-gradient(45deg, gray 50%, transparent 50%),
          linear-gradient(135deg, transparent 50%, gray 50%),
          linear-gradient(to right, gray, gray);
        background-position: calc(100% - 15px) 13px, calc(100% - 20px) 13px,
          calc(100% - 2.5em) 0.3em;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        background-repeat: no-repeat;
        border-color: #66afe9;
        outline: 0;
      }
    }
  }
}

.theme-color {
  padding: 0;
  width: 100%;

  a {
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    margin: 5px 4px 5px 0;
    -webkit-box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);
    box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);
    overflow: hidden;
    width: 45px;
    height: 40px;

    &:focus,
    &:hover {
      text-decoration: none;
    }

    &.small {
      width: 20px;
      height: 20px;
    }

    .cont,
    .head {
      display: block;
      position: relative;
    }

    .cont {

      &:after,
      &:before {
        content: "";
        height: 100%;
        display: inline-block;
        position: absolute;
      }
    }

    .head {

      &:after,
      &:before {
        content: "";
        height: 100%;
        display: inline-block;
        position: absolute;
      }
    }

    .cont:after,
    .head:after {
      width: 40%;
      left: 0;
    }

    .cont:before {
      width: 60%;
      right: 0;
    }

    .head {
      &:before {
        width: 60%;
        right: 0;
        background: #fff;
      }

      height: 10px;
    }

    .cont {
      height: 40px;

      &:before {
        background: #f6f7fb;
      }
    }

    &[lheader-theme="theme1"] {
      background: #8c8c8c;
    }

    &[lheader-theme="theme2"] {
      background: #624e89;
    }

    &[lheader-theme="theme3"] {
      background: #3c4fb1;
    }

    &[lheader-theme="theme4"] {
      background: #919aa3;
    }

    &[lheader-theme="theme5"] {
      background: #999;
    }

    &[lheader-theme="theme6"] {
      background: #fff;
    }

    &[header-theme="theme1"] .head {
      &:after {
        background-color: #303549;
      }

      &:before {
        background-color: #fff;
      }
    }

    &[header-theme="theme2"] .head {

      &:after,
      &:before {
        background: #ef5350;
      }
    }

    &[header-theme="theme3"] .head {

      &:after,
      &:before {
        background: #00bcd4;
      }
    }

    &[header-theme="theme4"] .head {

      &:after,
      &:before {
        background: #66bb6a;
      }
    }

    &[header-theme="theme5"] .head {

      &:after,
      &:before {
        background: #4680ff;
      }
    }

    &[header-theme="theme6"] .head {

      &:after,
      &:before {
        background: #303548;
      }
    }

    &[navbar-theme="theme1"] {
      background: #303548;
    }

    &[logo-theme="theme1"] .head:after {
      background: #303549;
    }

    &[logo-theme="theme2"] .head:after {
      background: #ef5350;
    }

    &[logo-theme="theme3"] .head:after {
      background: #00bcd4;
    }

    &[logo-theme="theme4"] .head:after {
      background: #66bb6a;
    }

    &[logo-theme="theme5"] .head:after {
      background: #4680ff;
    }

    &[logo-theme="theme6"] .head:after {
      background: #303548;
    }

    &[navbar-theme="themelight1"] {
      background: #fff;
    }

    &[active-item-theme="theme1"] {
      background: #fe8a7d;
    }

    &[active-item-theme="theme2"] {
      background: #fc6180;
    }

    &[active-item-theme="theme3"] {
      background: #93be52;
    }

    &[active-item-theme="theme4"] {
      background: #4680ff;
    }

    &[active-item-theme="theme5"] {
      background: #ffb64d;
    }

    &[active-item-theme="theme6"] {
      background: #ff5e3a;
    }

    &[active-item-theme="theme7"] {
      background: #e64056;
    }

    &[active-item-theme="theme8"] {
      background: #3c4fb1;
    }

    &[active-item-theme="theme9"] {
      background: #ff5370;
    }

    &[active-item-theme="theme10"] {
      background: #f76d47;
    }

    &[active-item-theme="theme11"] {
      background: #39adb5;
    }

    &[active-item-theme="theme12"] {
      background: #7c4dff;
    }

    &[fream-type="1"] {

      .cont:after,
      .head:after {
        background: #000;
      }
    }

    &[fream-type="2"] {

      .cont:after,
      .head:after {
        background: #16d39a;
      }
    }

    &[fream-type="3"] {

      .cont:after,
      .head:after {
        background: #ff7588;
      }
    }

    &[fream-type="4"] {

      .cont:after,
      .head:after {
        background: #303548;
      }
    }

    &[fream-type="5"] {

      .cont:after,
      .head:after {
        background: #2196f3;
      }
    }

    &[fream-type="6"] {

      .cont:after,
      .head:after {
        background: #e91e63;
      }
    }

    &[sub-item-theme="theme1"] {
      background: #70ca63;
    }

    &[sub-item-theme="theme2"] {
      background: #3498db;
    }

    &[sub-item-theme="theme3"] {
      background: #485bbd;
    }

    &[sub-item-theme="theme4"] {
      background: #e7604a;
    }

    &[sub-item-theme="theme5"] {
      background: #333843;
    }

    &[sub-item-theme="theme6"] {
      background: #624e89;
    }

    &[sub-item-theme="theme7"] {
      background: #fff;
    }

    &[sub-item-theme="theme8"] {
      background: #384b5f;
    }

    &[sub-item-theme="theme9"] {
      background: #578ebe;
    }

    &[layout-type="dark"] {
      .cont {
        &:after {
          background: #303548;
        }

        &:before {
          background: #444c67;
        }
      }

      .head {
        &:after {
          background: #444c67;
        }

        &:before {
          background: #303548;
        }
      }
    }
  }
}

.sparkline-chart {
  text-align: center;
}

.pcoded[theme-layout="vertical"] {

  &[pcoded-device-type="phone"] .spark-chart,
  &[pcoded-device-type="tablet"] .spark-chart {
    display: none;
  }
}

.spark-chart-title {
  text-align: center;
}

.content-title {
  margin-bottom: 10px;
}

.main-title {
  color: #424242;
  font-size: 22px;
  font-weight: 400;
  margin: 0;
}

.small-text {
  font-size: 14px;
}

.breadcrumb {
  background-color: transparent;
  border-radius: 0;
  padding: 8px 0;
  margin-bottom: 10px;
}

.pcoded {
  &[theme-layout="vertical"] {
    &[pcoded-device-type="phone"] .breadcrumb {
      display: none;
    }

    &[vertical-placement="right"] .pcoded-navbar .pcoded-item>li>a .pcoded-micon {
      float: none;
      position: relative;
      z-index: 1051;
      left: 90%;
      top: 1px;
    }

    &[vertical-nav-type="collapsed"][vertical-placement="right"] .pcoded-navbar .pcoded-item>li {
      >a .pcoded-micon i {
        left: 0;
      }

      &.pcoded-hasmenu.pcoded-trigger>a .pcoded-micon i,
      &.pcoded-trigger.active>a .pcoded-micon i {
        left: 94%;
      }
    }

    &[vertical-nav-type="sub-expanded"] {
      &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item {
        position: static;

        .pcoded-hasmenu .pcoded-submenu li,
        >li {
          position: static;
        }
      }

      &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item {
        position: static;

        .pcoded-hasmenu .pcoded-submenu li,
        >li {
          position: static;
        }
      }
    }
  }

  &.nocollapsed[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-main-container {
    position: static;
  }

  &[theme-layout="vertical"] {

    &[vertical-nav-type="fullpage"] .pcoded-main-container,
    &[vnavigation-view="view3"] .pcoded-main-container {
      position: static;
    }
  }

  .pcoded-header {
    &[header-theme="theme1"] {
      background: #fff;

      a {
        color: #303548;
      }

      .navbar-logo a {
        color: #fff;
      }
    }

    &[header-theme="theme2"] {
      background: #ef5350;

      a {
        color: #fff;
      }
    }

    &[header-theme="theme3"] {
      background: #00bcd4;

      a {
        color: #fff;
      }
    }

    &[header-theme="theme4"] {
      background: #66bb6a;

      a {
        color: #fff;
      }
    }

    &[header-theme="theme5"] {
      background: #4680ff;

      a {
        color: #fff;
      }
    }

    &[header-theme="theme6"] {
      background: #303548;

      a {
        color: #fff;
      }
    }
  }
}

body {
  .pcoded {
    &[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] {
      .pcoded-navbar .pcoded-item>li {
        >a .pcoded-micon {
          left: 0;
        }

        &.pcoded-trigger>a .pcoded-micon {
          left: calc(100% - 30px);
        }
      }

      .pcoded-item>li.pcoded-trigger>a>.pcoded-mtext {
        padding-right: 90px;
      }
    }

    &[vertical-placement="right"] {

      .showChat_inner,
      .users {
        right: auto;
        left: 0;
      }

      .main-body {
        direction: rtl;

        .page-wrapper .page-header-breadcrumb {
          float: left;
        }

        .language-markup {
          direction: ltr;
        }
      }

      .header-navbar .navbar-wrapper {
        .navbar-logo {
          float: right !important;
        }

        .navbar-container {
          margin-left: 0;
          margin-right: 240px;

          .nav-left li,
          .nav-right li {
            float: right !important;
          }

          .nav-left {
            float: right;
          }

          .nav-right {
            float: right;
            float: left;
          }

          .header-notification .profile-notification,
          .mega-menu-top .profile-notification,
          .header-notification .show-notification,
          .mega-menu-top .show-notification,
          .header-notification .profile-notification {
            right: auto;
            left: 0;
          }

          .mega-menu-top {

            .profile-notification,
            .show-notification,
            .profile-notification {
              right: auto;
              left: 0;
            }
          }
        }
      }

      &[vertical-nav-type="collapsed"] .header-navbar .navbar-wrapper .navbar-container {
        margin-right: 0;
      }
    }

    &[vertical-nav-type="collapsed"] .header-navbar {
      .navbar-wrapper .navbar-container {
        margin-left: 0;
      }

      .navbar-logo {
        width: 80px;

        a img {
          display: none;
        }
      }
    }

    &[vertical-nav-type="compact"] .main-menu {
      width: 200px;

      .pcoded-badge {
        display: none;
      }
    }
  }

  &.menu-bottom {
    .navbar-wrapper {
      z-index: 1050;
    }

    .pcoded .pcoded-main-container {
      margin-top: 0 !important;
    }

    .p-chat-user,
    .showChat_inner {
      top: 0;
    }

    .navbar-wrapper {
      bottom: 0;
      top: auto;
      z-index: 1050;
    }

    .header-navbar {
      position: fixed !important;
      bottom: 0;
      z-index: 9999 !important;
      -webkit-box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08) !important;
      box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08) !important;
    }

    .mega-menu-top {

      .show-notification,
      .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
        bottom: 0 !important;
      }
    }

    .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
      bottom: 0 !important;
    }
  }
}

@media only screen and (max-width: 992px) {
  body.menu-bottom .navbar-wrapper .pcoded-navbar {
    top: -56px;
  }
}

.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .mega-menu-top .profile-notification {
  bottom: 0 !important;
}

body.menu-bottom {

  .header-notification .show-notification,
  .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification {
    bottom: 65px;
  }
}

.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .mega-menu-top .show-notification,
body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .header-notification .profile-notification,
body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification,
.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .mega-menu-top .profile-notification {
  bottom: 65px;
}

body.menu-bottom #collapse-menu {
  display: none;
}

.mCSB_container,
.mCustomScrollBox {
  overflow: visible !important;
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(48, 53, 72, 0.5) !important;
}

@media only screen and (min-width: 992px) {
  .header-navbar .navbar-wrapper .navbar-container .nav-right {
    overflow: visible !important;
    height: auto !important;
  }
}

#pcoded[vertical-nav-type="collapsed"] {

  .scroll-sidebar,
  .slimScrollDiv {
    overflow: visible !important;
  }
}

/**  =====================
      Theme-Buttons css start
==========================  **/

.btn-default .badge {
  background-color: #fff;
  color: #e0e0e0;
}

.btn {
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 15px;
  padding: 10px 19px;
  cursor: pointer;
}

.button-page {
  .card-block {
    margin-bottom: -20px;

    ul {
      margin-bottom: 0;
      display: inline-block;
    }

    a {
      margin-bottom: 20px;
    }

    ul li {
      display: inline-block;
      margin-right: 20px;
      float: left;
    }
  }

  .btn {
    margin-bottom: 20px;
    margin-right: 10px;
  }
}

.btn {
  &.btn-round {
    border-radius: 2rem;
  }

  &.btn-square {
    border-radius: 0;
  }

  &.btn-skew {
    transform: skew(-15deg);
  }

  i {
    margin-right: 5px;
  }

  &.btn-icon {
    border-radius: 50%;
    width: 40px;
    line-height: 30px;
    height: 40px;
    padding: 3px;
    text-align: center;
  }

  &.btn-out {
    outline: 1px solid #fff;
    outline-offset: -5px;
  }

  &.btn-out-dashed {
    outline: 1px dashed #fff;
    outline-offset: -5px;
  }

  &.btn-out-dotted {
    outline: 1px dotted #fff;
    outline-offset: -5px;
  }
}

.btn-group,
.btn-group-vertical {
  display: inline-block;
}

.btn-group .btn {
  float: left;
}

.icon-btn i {
  margin-right: 0 !important;
}

.button-page .btn-group {
  margin-right: 10px;
}

.show>.dropdown-menu {
  overflow: hidden;
}

.dropdown-danger,
.dropdown-default,
.dropdown-disabled,
.dropdown-info,
.dropdown-inverse,
.dropdown-primary,
.dropdown-success,
.dropdown-warning {
  display: inline-block;
  vertical-align: center;
  position: relative;
}

.btn-group [class*="btn-"],
.dropdown-split [class*="btn-"] {
  margin-right: 0;
}

.list-group-item.active {
  background-color: #4680ff;
  border-color: #4680ff;
}

.show>.btn-primary.dropdown-toggle,
.sweet-alert .show>button.dropdown-toggle.confirm,
.wizard>.actions .show>a.dropdown-toggle {
  background-color: #0956ff !important;
  border-color: #0956ff;
  box-shadow: none;
  color: #fff;
}

#Note-list li:hover .Note-delete {
  opacity: 1;
  transform: translateX(0px);

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
}

.button-list .btn {
  margin-bottom: 10px;
  margin-right: 30px;
}

.Note-delete {
  margin-bottom: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  position: absolute;
  right: 20px;
  color: #fff;
  transition: all ease-in 0.3s;
  border-radius: 2px;
  cursor: pointer;
  transform: translateX(10px);
}

.popover-body {
  .color-code {
    margin-bottom: 10px;
  }

  .display-color {
    width: 75px;
    height: 2.5rem;
    border-radius: 0.25rem;
    margin-right: 30px;
    background-color: rgba(70, 128, 255, 0.5);
    border-color: rgba(70, 128, 255, 0.5);
  }

  span.block {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
  }
}

.popover-title {
  text-align: center;
}

.btn.btn-disabled,
.dropdown-disabled,
.dropdown-split-disabled {
  cursor: not-allowed;
}

.nav-pills .nav-link.active {
  background: transparent;
  color: #0275d8;
  text-align: center;
  border-bottom: 1px solid #0275d8;

  &:focus,
  &:hover,
  &:focus,
  &:hover {
    background: transparent;
    color: #0275d8;
    text-align: center;
    border-bottom: 1px solid #0275d8;
  }

  &.active {
    background: transparent;
    color: #0275d8;
    text-align: center;
    border-bottom: 1px solid #0275d8;

    &:focus,
    &:hover {
      background: transparent;
      color: #0275d8;
      text-align: center;
      border-bottom: 1px solid #0275d8;
    }
  }
}

/**====== Basic-Buttons css Start ======**/

.btn-primary,
.sweet-alert button.confirm,
.wizard>.actions a {
  background-color: #4680ff;
  border-color: #4680ff;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;
}

.btn-primary:hover,
.sweet-alert button.confirm:hover,
.wizard>.actions a:hover {
  background-color: #79a3ff;
  border-color: #79a3ff;
}

.btn-primary:active,
.sweet-alert button.confirm:active,
.wizard>.actions a:active {
  background-color: #0956ff !important;
  border-color: #0956ff;
  box-shadow: none;
  color: #fff;
}

.btn-primary:focus,
.sweet-alert button.confirm:focus,
.wizard>.actions a:focus {
  box-shadow: none;
  color: #fff;
}

.btn-primary.disabled,
.sweet-alert button.disabled.confirm,
.wizard>.actions a.disabled {
  background-color: rgba(70, 128, 255, 0.5);
  border-color: rgba(70, 128, 255, 0.5);
}

.btn-warning {
  background-color: #ffb64d;
  border-color: #ffb64d;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;

  &:hover {
    background-color: #ffcb80;
    border-color: #ffcb80;
    color: #fff;
  }

  &:active {
    background-color: #ff9d10 !important;
    border-color: #ff9d10;
    box-shadow: none;
    color: #fff;
  }

  &:focus {
    box-shadow: none;
    color: #fff;
  }

  &.disabled {
    background-color: rgba(255, 182, 77, 0.5);
    border-color: rgba(255, 182, 77, 0.5);
  }
}

.btn-danger {
  background-color: #fc6180;
  border-color: #fc6180;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;

  &:hover {
    background-color: #fd93a8;
    border-color: #fd93a8;
  }

  &:active {
    background-color: #fb2550 !important;
    border-color: #fb2550;
    box-shadow: none;
    color: #fff;
  }

  &:focus {
    box-shadow: none;
    color: #fff;
  }

  &.disabled {
    background-color: rgba(252, 97, 128, 0.5);
    border-color: rgba(252, 97, 128, 0.5);
  }
}

.btn-success {
  background-color: #93be52;
  border-color: #93be52;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;

  &:hover {
    background-color: #aacc77;
    border-color: #aacc77;
  }

  &:active {
    background-color: #73993a !important;
    border-color: #73993a;
    box-shadow: none;
    color: #fff;
  }

  &:focus {
    box-shadow: none;
    color: #fff;
  }

  &.disabled {
    background-color: rgba(147, 190, 82, 0.5);
    border-color: rgba(147, 190, 82, 0.5);
  }
}

.btn-inverse {
  background-color: #303548;
  border-color: #303548;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;

  &:hover {
    background-color: #444c67;
    border-color: #444c67;
  }

  &:active {
    background-color: #181a23 !important;
    border-color: #181a23;
    box-shadow: none;
    color: #fff;
  }

  &:focus {
    box-shadow: none;
    color: #fff;
  }

  &.disabled {
    background-color: rgba(48, 53, 72, 0.5);
    border-color: rgba(48, 53, 72, 0.5);
  }
}

.btn-info {
  background-color: #62d1f3;
  border-color: #62d1f3;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;

  &:hover {
    background-color: #91dff7;
    border-color: #91dff7;
  }

  &:active {
    background-color: #29c0ef !important;
    border-color: #29c0ef;
    box-shadow: none;
    color: #fff;
  }

  &:focus {
    box-shadow: none;
    color: #fff;
  }

  &.disabled {
    background-color: rgba(98, 209, 243, 0.5);
    border-color: rgba(98, 209, 243, 0.5);
  }
}

.btn-disabled {
  background-color: rgba(70, 128, 254, 0.5);
  border-color: rgba(70, 128, 254, 0.5);
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;
}

/**====== Basic-Buttons css end ======**/
/**====== Outline-Buttons css Start ======**/

.btn-outline-primary {
  color: #0956ff;
  background-color: #fff;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ff9d10;
  background-color: #fff;
  background-color: transparent;
}

.btn-outline-danger {
  color: #fb2550;
  background-color: #fff;
  background-color: transparent;
}

.btn-outline-success {
  color: #73993a;
  background-color: #fff;
  background-color: transparent;
}

.btn-outline-inverse {
  color: #181a23;
  background-color: #fff;
  background-color: transparent;

  &:hover {
    color: #fff;
  }
}

.btn-outline-warning {
  color: #ff9d10;
  background-color: #fff;
  background-color: transparent;
}

.btn-outline-info {
  color: #29c0ef;
  background-color: #fff;
  background-color: transparent;
}

.btn-outline-disabled {
  color: #0956fe;
  background-color: #fff;
}

/**====== Outline-Buttons css end ======**/
/**====== gradient css Start ======**/

.btn-grd-danger,
.btn-grd-disabled,
.btn-grd-info,
.btn-grd-inverse,
.btn-grd-primary,
.btn-grd-success,
.btn-grd-warning {
  background-size: 200% auto;
  transition: 0.5s ease-in-out;
  color: #fff;
}

.btn-grd-danger:hover,
.btn-grd-disabled:hover,
.btn-grd-info:hover,
.btn-grd-inverse:hover,
.btn-grd-primary:hover,
.btn-grd-success:hover,
.btn-grd-warning:hover {
  background-position: right center;
}

.btn-grd-danger.hor-grd,
.btn-grd-disabled.hor-grd,
.btn-grd-info.hor-grd,
.btn-grd-inverse.hor-grd,
.btn-grd-primary.hor-grd,
.btn-grd-success.hor-grd,
.btn-grd-warning.hor-grd {
  background-size: auto 200%;
}

.btn-grd-danger.hor-grd:hover,
.btn-grd-disabled.hor-grd:hover,
.btn-grd-info.hor-grd:hover,
.btn-grd-inverse.hor-grd:hover,
.btn-grd-primary.hor-grd:hover,
.btn-grd-success.hor-grd:hover,
.btn-grd-warning.hor-grd:hover {
  background-position: bottom center;
}

.btn-grd-primary {
  background-image: linear-gradient(to right,
      #79a3ff 0%,
      #0956ff 51%,
      #79a3ff 100%);

  &.hor-grd {
    background-image: linear-gradient(to top,
        #79a3ff 0%,
        #0956ff 51%,
        #79a3ff 100%);
  }
}

.btn-grd-warning {
  background-image: linear-gradient(to right,
      #ffcb80 0%,
      #ff9d10 51%,
      #ffcb80 100%);

  &.hor-grd {
    background-image: linear-gradient(to top,
        #ffcb80 0%,
        #ff9d10 51%,
        #ffcb80 100%);
  }
}

.btn-grd-danger {
  background-image: linear-gradient(to right,
      #fd93a8 0%,
      #fb2550 51%,
      #fd93a8 100%);

  &.hor-grd {
    background-image: linear-gradient(to top,
        #fd93a8 0%,
        #fb2550 51%,
        #fd93a8 100%);
  }
}

.btn-grd-success {
  background-image: linear-gradient(to right,
      #aacc77 0%,
      #73993a 51%,
      #aacc77 100%);

  &.hor-grd {
    background-image: linear-gradient(to top,
        #aacc77 0%,
        #73993a 51%,
        #aacc77 100%);
  }
}

.btn-grd-inverse {
  background-image: linear-gradient(to right,
      #444c67 0%,
      #181a23 51%,
      #444c67 100%);

  &.hor-grd {
    background-image: linear-gradient(to top,
        #444c67 0%,
        #181a23 51%,
        #444c67 100%);
  }
}

.btn-grd-info {
  background-image: linear-gradient(to right,
      #91dff7 0%,
      #29c0ef 51%,
      #91dff7 100%);

  &.hor-grd {
    background-image: linear-gradient(to top,
        #91dff7 0%,
        #29c0ef 51%,
        #91dff7 100%);
  }
}

.btn-grd-disabled {
  background-image: linear-gradient(to right,
      #79a3fe 0%,
      #0956fe 51%,
      #79a3fe 100%);

  &.hor-grd {
    background-image: linear-gradient(to top,
        #79a3fe 0%,
        #0956fe 51%,
        #79a3fe 100%);
  }
}

/**====== gradient-Buttons css end ======**/
/**====== matrialized-Buttons css Start ======**/

.btn.btn-mat {
  position: relative;
  border-radius: 0;
  border: none;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    height: 3px;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    border: none;

    &:before {
      left: 0;
      right: 0;
    }
  }
}

.btn-primary.btn-mat,
.sweet-alert button.btn-mat.confirm,
.wizard>.actions a.btn-mat {
  background-color: #4680ff;
}

.btn-primary.btn-mat:before,
.sweet-alert button.btn-mat.confirm:before,
.wizard>.actions a.btn-mat:before {
  background-color: #0956ff;
}

.btn-warning.btn-mat {
  background-color: #ffb64d;

  &:before {
    background-color: #ff9d10;
  }
}

.btn-danger.btn-mat {
  background-color: #fc6180;

  &:before {
    background-color: #fb2550;
  }
}

.btn-success.btn-mat {
  background-color: #93be52;

  &:before {
    background-color: #73993a;
  }
}

.btn-inverse.btn-mat {
  background-color: #303548;

  &:before {
    background-color: #181a23;
  }
}

.btn-info.btn-mat {
  background-color: #62d1f3;

  &:before {
    background-color: #29c0ef;
  }
}

.btn-outline-disabled {
  background-color: #4680fe;

  &:before {
    background-color: #0956fe;
  }
}

/**====== matrialized css end ======**/
/**====== Button-Size css start ======**/

.btn-xlg {
  padding: 14px 20px;
  font-size: 21px;
  line-height: 40px;
}

.btn-lg {
  padding: 12px 18px;
  font-size: 18px;
  line-height: 32px;
}

.btn-md {
  padding: 10px 16px;
  font-size: 15px;
  line-height: 23px;
}

.btn-sm {
  padding: 8px 14px;
  line-height: 16px;
  font-size: 11px;
}

.btn-mini {
  padding: 5px 10px;
  line-height: 14px;
  font-size: 10px;
}

/**====== Button-Size css end ======**/
/**====== Badges-button css start ======**/

.btn-primary .badge,
.sweet-alert button.confirm .badge,
.wizard>.actions a .badge {
  color: #4680ff;
  background-color: #fff;
}

.btn-success .badge {
  color: #93be52;
  background-color: #fff;
}

.btn-info .badge {
  color: #62d1f3;
  background-color: #fff;
}

.btn-warning .badge {
  color: #ffb64d;
  background-color: #fff;
}

.btn-danger .badge {
  color: #fc6180;
  background-color: #fff;
}

/**====== Badges-button css end ======**/
/**====== Social-Buttons css start ======**/

.btn-dribbble,
.btn-dropbox,
.btn-facebook,
.btn-flickr,
.btn-github,
.btn-google-plus,
.btn-instagram,
.btn-linkedin,
.btn-pinterest,
.btn-skype,
.btn-tumblr,
.btn-twitter,
.btn-youtube {
  color: #fff;
}

.btn-dribbble {

  &:focus,
  &:hover {
    color: #fff;
    box-shadow: none;
  }
}

.btn-dropbox {

  &:focus,
  &:hover {
    color: #fff;
    box-shadow: none;
  }
}

.btn-facebook {

  &:focus,
  &:hover {
    color: #fff;
    box-shadow: none;
  }
}

.btn-flickr {

  &:focus,
  &:hover {
    color: #fff;
    box-shadow: none;
  }
}

.btn-github {

  &:focus,
  &:hover {
    color: #fff;
    box-shadow: none;
  }
}

.btn-google-plus {

  &:focus,
  &:hover {
    color: #fff;
    box-shadow: none;
  }
}

.btn-instagram {

  &:focus,
  &:hover {
    color: #fff;
    box-shadow: none;
  }
}

.btn-linkedin {

  &:focus,
  &:hover {
    color: #fff;
    box-shadow: none;
  }
}

.btn-pinterest {

  &:focus,
  &:hover {
    color: #fff;
    box-shadow: none;
  }
}

.btn-skype {

  &:focus,
  &:hover {
    color: #fff;
    box-shadow: none;
  }
}

.btn-tumblr {

  &:focus,
  &:hover {
    color: #fff;
    box-shadow: none;
  }
}

.btn-twitter {

  &:focus,
  &:hover {
    color: #fff;
    box-shadow: none;
  }
}

.btn-youtube {

  &:focus,
  &:hover {
    color: #fff;
    box-shadow: none;
  }
}

.btn-facebook {
  background-color: #3b5998;
}

.btn-twitter {
  background-color: #00aced;
}

.btn-linkedin {
  background-color: #007bb6;
}

.btn-dribbble {
  background-color: #ea4c89;
}

.btn-google-plus {
  background-color: #dd4b39;
}

.btn-instagram {
  background-color: #517fa4;
}

.btn-pinterest {
  background-color: #cb2027;
}

.btn-dropbox {
  background-color: #32506d;
}

.btn-tumblr {
  background-color: #00aced;
}

/**====== Social-Buttons css end ======**/
/**====== Drop-down-Buttons css start ======**/

.dropdown-primary .dropdown-menu a:hover,
.dropdown-split-primary .dropdown-menu a:hover {
  background-color: #4680ff;
  color: #fff;
}

.dropdown-split-success .dropdown-menu a:hover,
.dropdown-success .dropdown-menu a:hover {
  background-color: #93be52;
  color: #fff;
}

.dropdown-info .dropdown-menu a:hover,
.dropdown-split-info .dropdown-menu a:hover {
  background-color: #62d1f3;
  color: #fff;
}

.dropdown-split-warning .dropdown-menu a:hover,
.dropdown-warning .dropdown-menu a:hover {
  background-color: #ffb64d;
  color: #fff;
}

.dropdown-danger .dropdown-menu a:hover,
.dropdown-split-danger .dropdown-menu a:hover {
  background-color: #fc6180;
  color: #fff;
}

.dropdown-inverse .dropdown-item,
.dropdown-split-inverse .dropdown-item {
  transition: all 0.3s ease-in;
}

.dropdown-inverse .dropdown-item:hover,
.dropdown-split-inverse .dropdown-item:hover {
  background-color: #303548;
  color: #fff;
}

/**====== Drop-down-Buttons css end ======**/
/**====== Theme-Buttons css end ======**/
/**  =====================
      Basic form elements css start
==========================  **/

.col-form-label {
  font-size: 14px;
}

.select2-container {
  width: 100% !important;
}

.form-group {
  margin-bottom: 1.25em;

  .form-control-lg {
    font-size: 1.25em;
  }

  .form-control-round {
    border-radius: 50px;
  }

  .form-control-static:focus {
    outline: none;
  }
}

.form-control {
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid #cccccc;

  &:focus {
    border: 1px solid #4680ff;
  }

  &.form-control-normal {
    font-weight: 400;
  }

  &.form-control-bold {
    font-weight: 600;
  }

  &.form-control-capitalize {
    text-transform: capitalize;
  }

  &.form-control-uppercase {
    text-transform: uppercase;
  }

  &.form-control-lowercase {
    text-transform: lowercase;
  }

  &.form-control-variant {
    font-variant: small-caps;
  }

  &.form-control-left {
    text-align: left;
  }

  &.form-control-center {
    text-align: center;
  }

  &.form-control-right {
    text-align: right;
  }

  &.form-control-rtl {
    direction: rtl;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

/*===== Border Inputs start =====*/

.form-control-primary {
  border-color: #4680ff;
  color: #4680ff;

  &:focus {
    border-color: #4680ff;
  }
}

.input-group-primary {
  .input-group-addon {
    background-color: #4680ff;
  }

  .form-control {
    border-color: #4680ff;
  }
}

.form-control-warning {
  border-color: #ffb64d;
  color: #ffb64d;

  &:focus {
    border-color: #ffb64d;
  }
}

.input-group-warning {
  .input-group-addon {
    background-color: #ffb64d;
  }

  .form-control {
    border-color: #ffb64d;
  }
}

.form-control-default {
  border-color: #e0e0e0;
  color: #e0e0e0;

  &:focus {
    border-color: #e0e0e0;
  }
}

.input-group-default {
  .input-group-addon {
    background-color: #e0e0e0;
  }

  .form-control {
    border-color: #e0e0e0;
  }
}

.form-control-danger {
  border-color: #fc6180;
  color: #fc6180;

  &:focus {
    border-color: #fc6180;
  }
}

.input-group-danger {
  .input-group-addon {
    background-color: #fc6180;
  }

  .form-control {
    border-color: #fc6180;
  }
}

.form-control-success {
  border-color: #93be52;
  color: #93be52;

  &:focus {
    border-color: #93be52;
  }
}

.input-group-success {
  .input-group-addon {
    background-color: #93be52;
  }

  .form-control {
    border-color: #93be52;
  }
}

.form-control-inverse {
  border-color: #303548;
  color: #303548;

  &:focus {
    border-color: #303548;
  }
}

.input-group-inverse {
  .input-group-addon {
    background-color: #303548;
  }

  .form-control {
    border-color: #303548;
  }
}

.form-control-info {
  border-color: #62d1f3;
  color: #62d1f3;

  &:focus {
    border-color: #62d1f3;
  }
}

.input-group-info {
  .input-group-addon {
    background-color: #62d1f3;
  }

  .form-control {
    border-color: #62d1f3;
  }
}

/*===== Form Input-Text start =====*/

.form-txt-primary {
  color: #4680ff;

  &:focus {
    color: #4680ff;
  }

  &::-moz-placeholder {
    color: #4680ff;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #4680ff;
  }
}

.form-txt-warning {
  color: #ffb64d;

  &:focus {
    color: #ffb64d;
  }

  &::-moz-placeholder {
    color: #ffb64d;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #ffb64d;
  }
}

.form-txt-default {
  color: #e0e0e0;

  &:focus {
    color: #e0e0e0;
  }

  &::-moz-placeholder {
    color: #e0e0e0;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #e0e0e0;
  }
}

.form-txt-danger {
  color: #fc6180;

  &:focus {
    color: #fc6180;
  }

  &::-moz-placeholder {
    color: #fc6180;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #fc6180;
  }
}

.form-txt-success {
  color: #93be52;

  &:focus {
    color: #93be52;
  }

  &::-moz-placeholder {
    color: #93be52;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #93be52;
  }
}

.form-txt-inverse {
  color: #303548;

  &:focus {
    color: #303548;
  }

  &::-moz-placeholder {
    color: #303548;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #303548;
  }
}

.form-txt-info {
  color: #62d1f3;

  &:focus {
    color: #62d1f3;
  }

  &::-moz-placeholder {
    color: #62d1f3;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #62d1f3;
  }
}

/*===== Form Background-inputs start =====*/

.form-bg-primary {
  background-color: #4680ff;
  border-color: #4680ff;
  color: #fff;

  &:focus {
    background-color: #4680ff;
    border-color: #4680ff;
    color: #fff;
  }

  &::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #fff;
  }
}

.form-bg-warning {
  background-color: #ffb64d;
  border-color: #ffb64d;
  color: #fff;

  &:focus {
    background-color: #ffb64d;
    border-color: #ffb64d;
    color: #fff;
  }

  &::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #fff;
  }
}

.form-bg-default {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  color: #fff;

  &:focus {
    background-color: #e0e0e0;
    border-color: #e0e0e0;
    color: #fff;
  }

  &::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #fff;
  }
}

.form-bg-danger {
  background-color: #fc6180;
  border-color: #fc6180;
  color: #fff;

  &:focus {
    background-color: #fc6180;
    border-color: #fc6180;
    color: #fff;
  }

  &::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #fff;
  }
}

.form-bg-success {
  background-color: #93be52;
  border-color: #93be52;
  color: #fff;

  &:focus {
    background-color: #93be52;
    border-color: #93be52;
    color: #fff;
  }

  &::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #fff;
  }
}

.form-bg-inverse {
  background-color: #303548;
  border-color: #303548;
  color: #fff;

  &:focus {
    background-color: #303548;
    border-color: #303548;
    color: #fff;
  }

  &::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #fff;
  }
}

.form-bg-info {
  background-color: #62d1f3;
  border-color: #62d1f3;
  color: #fff;

  &:focus {
    background-color: #62d1f3;
    border-color: #62d1f3;
    color: #fff;
  }

  &::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #fff;
  }
}

/*===== Form Validation states start =====*/

.has-success {
  .col-form-label {
    color: #93be52;
  }

  .form-control-success {
    border-color: #93be52;
  }
}

.has-warning {
  .col-form-label {
    color: #ffb64d;
  }

  .form-control-success {
    border-color: #ffb64d;
  }
}

.has-danger {
  .col-form-label {
    color: #fc6180;
  }

  .form-control-success {
    border-color: #fc6180;
  }
}

/*===== Form-icon css starts =====*/

.left-icon-control {
  position: relative;

  input {
    padding-left: 50px;
  }

  .form-icon {
    position: absolute;
    top: 50%;
    left: 17px;
    margin-top: -12px;
  }
}

.right-icon-control {
  position: relative;

  input {
    padding-right: 50px;
  }

  .form-icon {
    position: absolute;
    top: 7px;
    right: 17px;
  }
}

.right0-icon-control {
  position: relative;

  input {
    padding-right: 50px;
  }

  .form-icon {
    position: absolute;
    top: 5px;
    right: 0px;
  }
}

/*===== Form-icon css ends =====*/
/*===== Basic form elements ends =====*/
/**  =====================
      Form group-add-on css start
==========================  **/

.input-group {
  margin-bottom: 1.25em;
}

.input-group-addon {
  background-color: #4680ff;
  color: #fff;
}

.input-group-button {
  [class*="btn-"] {
    margin-bottom: 0;
  }

  .input-group-addon {
    transition: all ease-in 0.3s;

    &:hover {
      background-color: #79a3ff;
      border-color: #79a3ff;

      .btn {
        background-color: #79a3ff;
        border-color: #79a3ff;
      }
    }
  }
}

.input-group-dropdown [class*="btn-"] {
  margin-bottom: 0;
}

.input-group-text {
  background: #4680ff;
  color: #fff;
}

/*===== Form group-add-on ends =====*/
/**  =====================
      Switches css start
==========================  **/

.js-dynamic-disable,
.js-dynamic-enable {
  vertical-align: inherit;
}

/*===== Switches css ends =====*/
/**  =====================
      Radio-button css start
==========================  **/

.form-radio {
  position: relative;

  .form-help {
    position: absolute;
    width: 100%;
  }

  label {
    position: relative;
    padding-left: 1.5rem;
    text-align: left;
    color: #333;
    display: block;
    line-height: 1.8;
  }

  input {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
  }
}

.radio {
  .helper {
    position: absolute;
    top: -0.15rem;
    left: -0.25rem;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #999;

    &::after {
      transform: scale(0);
      background-color: #4680ff;
      border-color: #4680ff;
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      margin: 0.25rem;
      width: 1rem;
      height: 1rem;
      -webkit-transition: -webkit-transform 0.28s ease;
      transition: -webkit-transform 0.28s ease;
      transition: transform 0.28s ease, -webkit-transform 0.28s ease;
      border-radius: 50%;
      border: 0.125rem solid #4680ff;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      margin: 0.25rem;
      width: 1rem;
      height: 1rem;
      -webkit-transition: -webkit-transform 0.28s ease;
      transition: -webkit-transform 0.28s ease;
      transition: transform 0.28s ease, -webkit-transform 0.28s ease;
      border-radius: 50%;
      border: 0.125rem solid #4680ff;
    }
  }

  label:hover .helper {
    color: #4680ff;
  }

  input:checked~.helper {
    &::after {
      transform: scale(0.5);
    }

    &::before {
      color: #4680ff;
    }
  }

  &.radiofill {
    input:checked~.helper::after {
      transform: scale(1);
    }

    .helper::after {
      background-color: #4680ff;
    }
  }

  &.radio-outline {
    input:checked~.helper::after {
      transform: scale(0.6);
    }

    .helper::after {
      background-color: #fff;
      border: 0.225rem solid #4680ff;
    }
  }

  &.radio-matrial input {
    ~.helper::after {
      background-color: #fff;
    }

    &:checked~.helper {
      &::after {
        transform: scale(0.5);
        box-shadow: 0 1px 7px -1px rgba(0, 0, 0, 0.72);
      }

      &::before {
        background-color: #4680ff;
      }
    }
  }

  &.radio-disable {
    opacity: 0.7;

    label {
      cursor: not-allowed;
    }
  }
}

.radio-inline {
  display: inline-block;
  margin-right: 20px;
}

.radio {
  &.radiofill.radio-primary .helper {
    &::after {
      background-color: #4680ff;
      border-color: #4680ff;
    }

    &::before {
      border-color: #4680ff;
    }
  }

  &.radio-outline.radio-primary .helper {
    &::after {
      background-color: #fff;
      border: 0.225rem solid #4680ff;
    }

    &::before {
      border-color: #4680ff;
    }
  }

  &.radio-matrial.radio-primary input~.helper {
    &::after {
      background-color: #fff;
      border-color: #fff;
    }

    &::before {
      background-color: #4680ff;
      border-color: #4680ff;
    }
  }

  &.radiofill.radio-warning .helper {
    &::after {
      background-color: #ffb64d;
      border-color: #ffb64d;
    }

    &::before {
      border-color: #ffb64d;
    }
  }

  &.radio-outline.radio-warning .helper {
    &::after {
      background-color: #fff;
      border: 0.225rem solid #ffb64d;
    }

    &::before {
      border-color: #ffb64d;
    }
  }

  &.radio-matrial.radio-warning input~.helper {
    &::after {
      background-color: #fff;
      border-color: #fff;
    }

    &::before {
      background-color: #ffb64d;
      border-color: #ffb64d;
    }
  }

  &.radiofill.radio-default .helper {
    &::after {
      background-color: #e0e0e0;
      border-color: #e0e0e0;
    }

    &::before {
      border-color: #e0e0e0;
    }
  }

  &.radio-outline.radio-default .helper {
    &::after {
      background-color: #fff;
      border: 0.225rem solid #e0e0e0;
    }

    &::before {
      border-color: #e0e0e0;
    }
  }

  &.radio-matrial.radio-default input~.helper {
    &::after {
      background-color: #fff;
      border-color: #fff;
    }

    &::before {
      background-color: #e0e0e0;
      border-color: #e0e0e0;
    }
  }

  &.radiofill.radio-danger .helper {
    &::after {
      background-color: #fc6180;
      border-color: #fc6180;
    }

    &::before {
      border-color: #fc6180;
    }
  }

  &.radio-outline.radio-danger .helper {
    &::after {
      background-color: #fff;
      border: 0.225rem solid #fc6180;
    }

    &::before {
      border-color: #fc6180;
    }
  }

  &.radio-matrial.radio-danger input~.helper {
    &::after {
      background-color: #fff;
      border-color: #fff;
    }

    &::before {
      background-color: #fc6180;
      border-color: #fc6180;
    }
  }

  &.radiofill.radio-success .helper {
    &::after {
      background-color: #93be52;
      border-color: #93be52;
    }

    &::before {
      border-color: #93be52;
    }
  }

  &.radio-outline.radio-success .helper {
    &::after {
      background-color: #fff;
      border: 0.225rem solid #93be52;
    }

    &::before {
      border-color: #93be52;
    }
  }

  &.radio-matrial.radio-success input~.helper {
    &::after {
      background-color: #fff;
      border-color: #fff;
    }

    &::before {
      background-color: #93be52;
      border-color: #93be52;
    }
  }

  &.radiofill.radio-inverse .helper {
    &::after {
      background-color: #303548;
      border-color: #303548;
    }

    &::before {
      border-color: #303548;
    }
  }

  &.radio-outline.radio-inverse .helper {
    &::after {
      background-color: #fff;
      border: 0.225rem solid #303548;
    }

    &::before {
      border-color: #303548;
    }
  }

  &.radio-matrial.radio-inverse input~.helper {
    &::after {
      background-color: #fff;
      border-color: #fff;
    }

    &::before {
      background-color: #303548;
      border-color: #303548;
    }
  }

  &.radiofill.radio-info .helper {
    &::after {
      background-color: #62d1f3;
      border-color: #62d1f3;
    }

    &::before {
      border-color: #62d1f3;
    }
  }

  &.radio-outline.radio-info .helper {
    &::after {
      background-color: #fff;
      border: 0.225rem solid #62d1f3;
    }

    &::before {
      border-color: #62d1f3;
    }
  }

  &.radio-matrial.radio-info input~.helper {
    &::after {
      background-color: #fff;
      border-color: #fff;
    }

    &::before {
      background-color: #62d1f3;
      border-color: #62d1f3;
    }
  }
}

/*===== Radio Button css ends =====*/
/*===== Border-checkbox css start =====*/

.border-checkbox-section {
  .border-checkbox-group {
    display: inline-block;

    .border-checkbox:checked+.border-checkbox-label:after {
      -webkit-animation: check linear 0.5s;
      animation: check linear 0.5s;
      opacity: 1;
      border-color: #4680ff;

      .border-checkbox-label:before {
        border-color: #eee;
      }
    }

    .border-checkbox-label {
      position: relative;
      display: inline-block;
      cursor: pointer;
      height: 20px;
      line-height: 20px;
      padding-left: 30px;
      margin-right: 15px;

      &:after {
        content: "";
        display: block;
        width: 6px;
        height: 12px;
        opacity: 0.9;
        border-right: 2px solid #eee;
        border-top: 2px solid #eee;
        position: absolute;
        left: 4px;
        top: 11px;
        -webkit-transform: scaleX(-1) rotate(135deg);
        transform: scaleX(-1) rotate(135deg);
        -webkit-transform-origin: left top;
        transform-origin: left top;
      }

      &:before {
        content: "";
        display: block;
        border: 2px solid #4680ff;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
      }
    }
  }

  .border-checkbox {
    display: none;

    &:disabled~.border-checkbox-label {
      cursor: no-drop;
      color: #ccc;
    }
  }

  .border-checkbox-group-primary {
    .border-checkbox-label:before {
      border: 2px solid #4680ff;
    }

    .border-checkbox:checked+.border-checkbox-label:after {
      border-color: #4680ff;
    }
  }

  .border-checkbox-group-warning {
    .border-checkbox-label:before {
      border: 2px solid #ffb64d;
    }

    .border-checkbox:checked+.border-checkbox-label:after {
      border-color: #ffb64d;
    }
  }

  .border-checkbox-group-default {
    .border-checkbox-label:before {
      border: 2px solid #e0e0e0;
    }

    .border-checkbox:checked+.border-checkbox-label:after {
      border-color: #e0e0e0;
    }
  }

  .border-checkbox-group-danger {
    .border-checkbox-label:before {
      border: 2px solid #fc6180;
    }

    .border-checkbox:checked+.border-checkbox-label:after {
      border-color: #fc6180;
    }
  }

  .border-checkbox-group-success {
    .border-checkbox-label:before {
      border: 2px solid #93be52;
    }

    .border-checkbox:checked+.border-checkbox-label:after {
      border-color: #93be52;
    }
  }

  .border-checkbox-group-inverse {
    .border-checkbox-label:before {
      border: 2px solid #303548;
    }

    .border-checkbox:checked+.border-checkbox-label:after {
      border-color: #303548;
    }
  }

  .border-checkbox-group-info {
    .border-checkbox-label:before {
      border: 2px solid #62d1f3;
    }

    .border-checkbox:checked+.border-checkbox-label:after {
      border-color: #62d1f3;
    }
  }
}

@-webkit-keyframes check {
  0% {
    height: 0;
    width: 0;
  }

  25% {
    height: 0;
    width: 6px;
  }

  50% {
    height: 12px;
    width: 6px;
  }
}

@keyframes check {
  0% {
    height: 0;
    width: 0;
  }

  25% {
    height: 0;
    width: 6px;
  }

  50% {
    height: 12px;
    width: 6px;
  }
}

/*===== zoom Fade-in-checkbox css start =====*/

.checkbox-fade,
.checkbox-zoom {
  display: inline-block;
  margin-right: 15px;
}

.checkbox-fade label,
.checkbox-zoom label {
  line-height: 20px;
}

.checkbox-fade label input {

  &[type="checkbox"],
  &[type="radio"] {
    display: none;
  }
}

.checkbox-zoom label input {

  &[type="checkbox"],
  &[type="radio"] {
    display: none;
  }
}

.checkbox-fade label input {

  &[type="checkbox"]:checked+.cr>.cr-icon,
  &[type="radio"]:checked+.cr>.cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
  }
}

.checkbox-zoom label input {

  &[type="checkbox"]:checked+.cr>.cr-icon,
  &[type="radio"]:checked+.cr>.cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
  }
}

.checkbox-fade label input {

  &[type="checkbox"]+.cr>.cr-icon,
  &[type="radio"]+.cr>.cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all 0.3s ease-in;
  }
}

.checkbox-zoom label input {

  &[type="checkbox"]+.cr>.cr-icon,
  &[type="radio"]+.cr>.cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all 0.3s ease-in;
  }
}

.checkbox-fade label:after,
.checkbox-zoom label:after {
  content: "";
  display: table;
  clear: both;
}

.checkbox-fade.fade-in-disable {

  .cr,
  label {
    color: #ccc;
    cursor: no-drop;
  }
}

.checkbox-zoom.fade-in-disable {

  .cr,
  label {
    color: #ccc;
    cursor: no-drop;
  }
}

.checkbox-fade .cr,
.checkbox-zoom .cr {
  border-radius: 0;
  border: 2px solid #4680ff;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 20px;
  margin-right: 0.5em;
  position: relative;
  width: 20px;
}

.checkbox-fade .cr .cr-icon,
.checkbox-zoom .cr .cr-icon {
  color: #4680ff;
  font-size: 0.8em;
  left: 0;
  line-height: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
}

.checkbox-fade label {
  line-height: 20px;

  input {

    &[type="checkbox"],
    &[type="radio"] {
      display: none;
    }

    &[type="checkbox"]:checked+.cr>.cr-icon,
    &[type="radio"]:checked+.cr>.cr-icon {
      transform: scale(1) rotateZ(0deg);
      opacity: 1;
    }

    &[type="checkbox"]+.cr>.cr-icon,
    &[type="radio"]+.cr>.cr-icon {
      transform: scale(3) rotateZ(-20deg);
      opacity: 0;
      transition: all 0.3s ease-in;
    }
  }
}

.checkbox-zoom label {
  line-height: 20px;

  input {

    &[type="checkbox"],
    &[type="radio"] {
      display: none;
    }

    &[type="checkbox"]:checked+.cr>.cr-icon,
    &[type="radio"]:checked+.cr>.cr-icon {
      transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
      opacity: 1;
    }

    &[type="checkbox"]+.cr>.cr-icon,
    &[type="radio"]+.cr>.cr-icon {
      transform: scale3d(0.2, 0.2, 0.1) translate3d(0, 0, 0);
      opacity: 0;
      transition: all 0.3s ease-in;
    }
  }
}

.checkbox-fade {

  &.fade-in-primary .cr,
  &.zoom-primary .cr {
    border: 2px solid #4680ff;
  }
}

.checkbox-zoom {

  &.fade-in-primary .cr,
  &.zoom-primary .cr {
    border: 2px solid #4680ff;
  }
}

.checkbox-fade {

  &.fade-in-primary .cr .cr-icon,
  &.zoom-primary .cr .cr-icon {
    color: #4680ff;
  }
}

.checkbox-zoom {

  &.fade-in-primary .cr .cr-icon,
  &.zoom-primary .cr .cr-icon {
    color: #4680ff;
  }
}

.checkbox-fade {

  &.fade-in-warning .cr,
  &.zoom-warning .cr {
    border: 2px solid #ffb64d;
  }
}

.checkbox-zoom {

  &.fade-in-warning .cr,
  &.zoom-warning .cr {
    border: 2px solid #ffb64d;
  }
}

.checkbox-fade {

  &.fade-in-warning .cr .cr-icon,
  &.zoom-warning .cr .cr-icon {
    color: #ffb64d;
  }
}

.checkbox-zoom {

  &.fade-in-warning .cr .cr-icon,
  &.zoom-warning .cr .cr-icon {
    color: #ffb64d;
  }
}

.checkbox-fade {

  &.fade-in-default .cr,
  &.zoom-default .cr {
    border: 2px solid #e0e0e0;
  }
}

.checkbox-zoom {

  &.fade-in-default .cr,
  &.zoom-default .cr {
    border: 2px solid #e0e0e0;
  }
}

.checkbox-fade {

  &.fade-in-default .cr .cr-icon,
  &.zoom-default .cr .cr-icon {
    color: #e0e0e0;
  }
}

.checkbox-zoom {

  &.fade-in-default .cr .cr-icon,
  &.zoom-default .cr .cr-icon {
    color: #e0e0e0;
  }
}

.checkbox-fade {

  &.fade-in-danger .cr,
  &.zoom-danger .cr {
    border: 2px solid #fc6180;
  }
}

.checkbox-zoom {

  &.fade-in-danger .cr,
  &.zoom-danger .cr {
    border: 2px solid #fc6180;
  }
}

.checkbox-fade {

  &.fade-in-danger .cr .cr-icon,
  &.zoom-danger .cr .cr-icon {
    color: #fc6180;
  }
}

.checkbox-zoom {

  &.fade-in-danger .cr .cr-icon,
  &.zoom-danger .cr .cr-icon {
    color: #fc6180;
  }
}

.checkbox-fade {

  &.fade-in-success .cr,
  &.zoom-success .cr {
    border: 2px solid #93be52;
  }
}

.checkbox-zoom {

  &.fade-in-success .cr,
  &.zoom-success .cr {
    border: 2px solid #93be52;
  }
}

.checkbox-fade {

  &.fade-in-success .cr .cr-icon,
  &.zoom-success .cr .cr-icon {
    color: #93be52;
  }
}

.checkbox-zoom {

  &.fade-in-success .cr .cr-icon,
  &.zoom-success .cr .cr-icon {
    color: #93be52;
  }
}

.checkbox-fade {

  &.fade-in-inverse .cr,
  &.zoom-inverse .cr {
    border: 2px solid #303548;
  }
}

.checkbox-zoom {

  &.fade-in-inverse .cr,
  &.zoom-inverse .cr {
    border: 2px solid #303548;
  }
}

.checkbox-fade {

  &.fade-in-inverse .cr .cr-icon,
  &.zoom-inverse .cr .cr-icon {
    color: #303548;
  }
}

.checkbox-zoom {

  &.fade-in-inverse .cr .cr-icon,
  &.zoom-inverse .cr .cr-icon {
    color: #303548;
  }
}

.checkbox-fade {

  &.fade-in-info .cr,
  &.zoom-info .cr {
    border: 2px solid #62d1f3;
  }
}

.checkbox-zoom {

  &.fade-in-info .cr,
  &.zoom-info .cr {
    border: 2px solid #62d1f3;
  }
}

.checkbox-fade {

  &.fade-in-info .cr .cr-icon,
  &.zoom-info .cr .cr-icon {
    color: #62d1f3;
  }
}

.checkbox-zoom {

  &.fade-in-info .cr .cr-icon,
  &.zoom-info .cr .cr-icon {
    color: #62d1f3;
  }
}

/*===== Color-checkbox css start =====*/

.checkbox-color {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;

  label {
    display: inline-block;
    position: relative;
    padding-left: 10px;
    line-height: 20px;

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      width: 20px;
      height: 20px;
      left: 0;
      right: 0;
      text-align: center;
      margin-left: -20px;
      border: 1px solid #ccc;
      border-radius: 0;
      background-color: #fff;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    }

    &::after {
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: -6px;
      top: 0;
      margin-left: -17px;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 20px;
      color: #fff;
    }
  }

  input[type="checkbox"] {
    opacity: 0;

    &:focus+label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }

    &:checked+label::after {
      font-family: "IcoFont";
      content: "\efad";
    }

    &:disabled+label {
      opacity: 0.65;

      &::before {
        background-color: #eee;
        cursor: not-allowed;
      }
    }
  }

  &.checkbox-circle label::before {
    border-radius: 50%;
  }

  &.checkbox-inline {
    margin-top: 0;
  }
}

.checkbox-danger input[type="checkbox"]:checked+label::after,
.checkbox-info input[type="checkbox"]:checked+label::after,
.checkbox-primary input[type="checkbox"]:checked+label::after,
.checkbox-success input[type="checkbox"]:checked+label::after,
.checkbox-warning input[type="checkbox"]:checked+label::after {
  color: #fff;
}

.checkbox-primary input[type="checkbox"]:checked+label::before {
  background-color: #2196f3;
  border-color: #2196f3;
  background-color: #4680ff;
}

.checkbox-warning input[type="checkbox"]:checked+label::before {
  background-color: #ffb64d;
}

.checkbox-default input[type="checkbox"]:checked+label::before {
  background-color: #e0e0e0;
}

.checkbox-danger input[type="checkbox"]:checked+label::before {
  background-color: #fc6180;
}

.checkbox-success input[type="checkbox"]:checked+label::before {
  background-color: #93be52;
}

.checkbox-inverse input[type="checkbox"]:checked+label::before {
  background-color: #303548;
}

.checkbox-info input[type="checkbox"]:checked+label::before {
  background-color: #62d1f3;
}

/*===== Tag input css start =====*/

.bootstrap-tagsinput {
  border: 1px solid #4680ff;
  line-height: 30px;
  border-radius: 2px;

  .tag {
    padding: 6px;
    border-radius: 2px;
  }
}

/**  =====================
      Select-2 css start
==========================  **/

.select2-container--default {
  &:focus {
    border-color: #4680ff;
  }

  &.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #fff transparent;
  }

  .select2-search__field:focus {
    border: 1px solid #4680ff;
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: #4680ff;
  }

  .select2-selection--multiple {
    padding: 3px;

    .select2-selection__choice {
      background-color: #4680ff;
      border: 1px solid #4680ff;
      padding: 5px 15px;
      color: #fff;

      span {
        color: #fff;
      }
    }

    .select2-search__field {
      border: none;
    }
  }

  &.select2-container--focus .select2-selection--multiple {
    border: 1px solid #4680ff;
  }

  .select2-selection--single {
    color: #fff;
    height: auto;

    .select2-selection__rendered {
      background-color: #4680ff;
      color: #fff;
      padding: 8px 30px 8px 20px;
    }

    .select2-selection__arrow {
      top: 10px;
      right: 15px;

      b {
        border-color: #fff transparent transparent transparent;
      }
    }
  }
}

/*===== Select-2 css ends =====*/
/**  =====================
      Multi-select css start
==========================  **/

.ms-container {
  .ms-list.ms-focus {
    border-color: #4680ff;
    box-shadow: inset 0 1px 1px rgba(26, 188, 156, 0.49),
      0 0 8px rgba(26, 188, 156, 0.5);
  }

  .ms-selectable li.ms-hover,
  .ms-selection li.ms-hover {
    background-color: #4680ff;
  }
}

.ms-selectable .custom-header,
.ms-selection .custom-header {
  background-color: #4680ff;
  color: #fff;
  text-align: center;
}

.ms-list {
  display: block !important;

  li {
    width: 100%;
  }
}

/*===== Multi-select css ends =====*/
/**  =====================
      Validation-forms css start
==========================  **/

.form-group {
  .messages p {
    margin-bottom: 0;
    transition: all ease-in 0.3s;
  }

  .popover-valid {
    position: absolute;
    right: 40px;
    top: 7px;
  }
}

/*===== Validation-forms css end =====*/
/**  =====================
      Form-wizards css start
==========================  **/

.wizard>.steps .current a {
  background: #4680ff;
  transition: all ease-in 0.3s;
  cursor: pointer;
  border-radius: 2px;
  outline: 1px solid #fff;
  outline-offset: -7px;

  &:hover {
    background: #6092ff;
    outline-offset: 0;
  }
}

#design-wizard .steps li {
  position: relative;
  z-index: 99;
  margin-bottom: 20px;

  &:after {
    content: "";
    position: absolute;
    height: 2px;
    background: #4680ff;
    width: 100%;
    top: 30px;
    z-index: -1;
  }

  a {
    width: 20%;
    margin: 0 auto;
    text-align: center;
    border-radius: 2px;
  }
}

.wizard>.steps .done a {
  background: #e0e0e0;
}

.card .card-block .wizard-form {
  .steps ul {
    display: block;
  }

  li {
    margin-right: 0;
  }
}

.wizard> {
  .actions a {
    padding: 5px 15px;
  }

  .content {
    border: 1px solid #ccc;
    border-radius: 2px;
    background: #fff;
  }
}

/*===== Form-wizard forms css end =====*/
/**  =====================
      Label-Badges css start
==========================  **/

.label {
  border-radius: 4px;
  font-size: 75%;
  padding: 4px 7px;
  margin-right: 5px;
  font-weight: 700;
  color: #fff !important;
}

.label-main {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 5px;
}

.badge {
  border-radius: 10px;
  padding: 3px 7px;
}

.label {
  &.label-lg {
    padding: 8px 21px;
  }

  &.label-md {
    padding: 6px 14px;
  }
}

.badge-lg {
  padding: 5px 9px;
  font-size: 14px;
}

.badge-md {
  padding: 4px 8px;
  font-size: 14px;
}

span.badge {
  display: inline-block !important;
}

.label-default {
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0;
  box-shadow: none;
  color: #fff;
}

.label-inverse-default {
  border: 1px solid;
  border-color: #e0e0e0;
  color: #e0e0e0 !important;
}

.label-inverse-primary {
  border: 1px solid;
  border-color: #4680ff;
  color: #4680ff !important;
}

.label-inverse-success {
  border: 1px solid;
  border-color: #93be52;
  color: #93be52 !important;
}

.label-inverse-warning {
  border: 1px solid;
  border-color: #ffb64d;
  color: #ffb64d !important;
}

.label-inverse-danger {
  border: 1px solid;
  border-color: #fc6180;
  color: #fc6180 !important;
}

.label-inverse-info {
  border: 1px solid;
  border-color: #62d1f3;
  color: #62d1f3 !important;
}

.label-inverse-info-border {
  border: 1px solid;
  border-color: #303548;
  color: #303548 !important;
}

.badge-inverse-default {
  border: 1px solid;
  border-color: #e0e0e0;
  color: #e0e0e0 !important;
}

.badge-inverse-primary {
  border: 1px solid;
  border-color: #4680ff;
  color: #4680ff !important;
}

.badge-inverse-success {
  border: 1px solid;
  border-color: #93be52;
  color: #93be52 !important;
}

.badge-inverse-warning {
  border: 1px solid;
  border-color: #ffb64d;
  color: #ffb64d !important;
}

.badge-inverse-danger {
  border: 1px solid;
  border-color: #fc6180;
  color: #fc6180 !important;
}

.badge-inverse-info {
  border: 1px solid;
  border-color: #303548;
  color: #303548 !important;
}

.label-icon label {
  position: absolute;
}

.icofont.icofont-envelope {
  font-size: 20px;
}

/*===== Label and Badges css end =====*/
/* ======================
    All Icon Common Page css
======================== */

.data-table-main {

  &.icon-list-demo [class*="col-"],
  &.icon-svg-demo [class*="col-"] {
    margin-bottom: 10px;
  }
}

.icon-list-demo {
  i {
    border: 1px solid #eceeef;
    border-radius: 3px;
    color: rgba(43, 61, 81, 0.7);
    display: inline-block;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    margin: 0 12px 0 0;
    text-align: center;
    vertical-align: middle;
    width: 50px;
  }

  div {
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 10px;
  }

  i:hover {
    color: #64b0f2;
  }
}

/**====== Flag-icon css start ======**/

.flags .f-item {
  padding: 12px;
  border: 1px solid #ddd;
  margin-right: 15px;
  display: inline-block;
}

.data-table-main.flags [class*="col-"] {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.content-flag {
  label {
    margin-bottom: 0;
    cursor: pointer;
  }

  .txt-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 120px;
    overflow: hidden;
  }
}

.outer-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

label.txt-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 135px;
}

.flags .f-item {

  .name,
  .capital {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 0;
  }

  .code {
    display: block;
    font-size: 90%;
    color: #999;
    padding-bottom: 0;
  }
}

/**====== All icon common page css end ======**/
/**  =====================
      Data-table css start
==========================  **/

table.table-bordered.dataTable tbody {

  th:focus,
  td:focus {
    outline: none;
  }
}

.card .card-block ul.pagination li {
  margin-right: 0;
}

.page-item.active .page-link {
  background-color: #4680ff;
  border-color: #4680ff;
}

.page-link {
  color: #222;
}

td.highlight {
  font-weight: 700;
  color: #62d1f3;
  background-color: #f5f5f5;
}

.table.compact {

  td,
  th {
    padding: 0.45rem;
  }
}

.dataTables_paginate .pagination {
  float: right;
}

#multi-table_wrapper .dataTables_paginate .pagination {
  float: none;
}

#footer-search tfoot .form-control,
#footer-select tfoot .form-control,
#form-input-table .form-control,
.search-api .form-control,
#dt-live-dom .form-control {
  width: 90%;
}

.search-api {

  .global_filter,
  .column_filter {
    margin: 0 auto;
  }

  .checkbox-fade {
    display: block;
    text-align: center;
  }
}

#row-select .selected,
#row-delete .selected {
  background-color: #4680ff;
  color: #fff;
}

/*===== Autofill data-table ===== */

div.dt-autofill-list div.dt-autofill-button button {
  background-color: #4680ff;
  border-color: #4680ff;
}

table.dataTable {
  border-collapse: collapse !important;

  tbody>tr {

    &.selected,
    >.selected {
      background-color: #4680ff;
      color: #fff;
    }
  }

  td.select-checkbox:before,
  th.select-checkbox:before {
    margin-top: 0;
  }

  tr.selected {

    td.select-checkbox:after,
    th.select-checkbox:after {
      margin-top: -6px;
      margin-left: -5px;
    }
  }
}

/*===== Button data-table ===== */

button.dt-button,
div.dt-button,
a.dt-button,
button.dt-button:focus:not(.disabled),
div.dt-button:focus:not(.disabled),
a.dt-button:focus:not(.disabled) {
  background-color: #4680ff;
  border-color: #4680ff;
  border-radius: 2px;
  color: #fff;
  background-image: none;
  font-size: 14px;
}

button.dt-button {

  &:active:not(.disabled),
  &.active:not(.disabled) {
    background-color: #4680ff;
    border-color: #4680ff;
    border-radius: 2px;
    color: #fff;
    background-image: none;
    font-size: 14px;
  }
}

div.dt-button {

  &:active:not(.disabled),
  &.active:not(.disabled) {
    background-color: #4680ff;
    border-color: #4680ff;
    border-radius: 2px;
    color: #fff;
    background-image: none;
    font-size: 14px;
  }
}

a.dt-button {

  &:active:not(.disabled),
  &.active:not(.disabled) {
    background-color: #4680ff;
    border-color: #4680ff;
    border-radius: 2px;
    color: #fff;
    background-image: none;
    font-size: 14px;
  }
}

button.dt-button.btn-warning,
div.dt-button.btn-warning,
a.dt-button.btn-warning {
  background-color: #ffb64d;
  border-color: #ffb64d;
  border-radius: 2px;
  color: #fff;
  background-image: none;
}

button.dt-button.btn-danger,
div.dt-button.btn-danger,
a.dt-button.btn-danger {
  background-color: #fc6180;
  border-color: #fc6180;
  border-radius: 2px;
  color: #fff;
  background-image: none;
}

button.dt-button.btn-inverse,
div.dt-button.btn-inverse,
a.dt-button.btn-inverse {
  background-color: #303548;
  border-color: #303548;
  border-radius: 2px;
  color: #fff;
  background-image: none;
}

button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled) {
  background-image: none;
  background-color: #6092ff;
  border-color: #4680ff;
}

button.dt-button.btn-warning:hover:not(.disabled),
div.dt-button.btn-warning:hover:not(.disabled),
a.dt-button.btn-warning:hover:not(.disabled) {
  background-image: none;
  background-color: #ffcb80;
  border-color: #ffb64d;
}

button.dt-button.btn-danger:hover:not(.disabled),
div.dt-button.btn-danger:hover:not(.disabled),
a.dt-button.btn-danger:hover:not(.disabled) {
  background-image: none;
  background-color: #fd93a8;
  border-color: #fc6180;
}

button.dt-button.btn-inverse:hover:not(.disabled),
div.dt-button.btn-inverse:hover:not(.disabled),
a.dt-button.btn-inverse:hover:not(.disabled) {
  background-image: none;
  background-color: #444c67;
  border-color: #303548;
}

div {
  &.dt-button-collection {
    button.dt-button {

      &:active:not(.disabled),
      &.active:not(.disabled) {
        background-color: #4680ff;
        border-color: #4680ff;
        background-image: none;
      }
    }

    div.dt-button {

      &:active:not(.disabled),
      &.active:not(.disabled) {
        background-color: #4680ff;
        border-color: #4680ff;
        background-image: none;
      }
    }

    a.dt-button {

      &:active:not(.disabled),
      &.active:not(.disabled) {
        background-color: #4680ff;
        border-color: #4680ff;
        background-image: none;
      }
    }
  }

  &.dt-buttons {
    clear: both;
  }
}

.card .table-card-header {
  b {
    display: block;
    color: #4680ff;
    margin-top: 15px;
  }

  span {
    color: #135dff;
    display: inline-block;
    margin-top: 0;
  }
}

div.dataTables_wrapper div.dataTables_info {
  display: inline-block;
}

/*===== Colreorder data-table ===== */

table.DTCR_clonedTable.dataTable {
  position: absolute !important;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 202;
}

div.DTCR_pointer {
  width: 1px;
  background-color: #0259c4;
  z-index: 201;
}

/*===== Fixed-column data-table ===== */

table.DTFC_Cloned {

  thead,
  tfoot {
    background-color: white;
  }
}

div {
  &.DTFC_Blocker {
    background-color: white;
  }

  &.DTFC_LeftWrapper table.dataTable,
  &.DTFC_RightWrapper table.dataTable {
    margin-bottom: 0;
    z-index: 2;
  }

  &.DTFC_LeftWrapper table.dataTable.no-footer,
  &.DTFC_RightWrapper table.dataTable.no-footer {
    border-bottom: none;
  }
}

th,
td {
  white-space: nowrap;
}

/*===== Fixed-header data-table ===== */

table {
  &.fixedHeader-floating {
    position: fixed !important;
    background-color: white;

    &.no-footer {
      border-bottom-width: 0;
    }
  }

  &.fixedHeader-locked {
    position: absolute !important;
    background-color: white;
  }

  &.dataTable {
    th.focus {
      outline: 3px solid #4680ff;
      outline-offset: -1px;
    }

    td.focus {
      outline: 3px solid #4680ff;
      outline-offset: -1px;
      outline: 1px solid #fc6180;
      outline-offset: -3px;
      background-color: #f8e6e6 !important;
    }
  }
}

@media print {
  table.fixedHeader-floating {
    display: none;
  }
}

/*===== Key-table data-table ===== */

#events {
  margin-bottom: 1em;
  padding: 1em;
  background-color: #f6f6f6;
  border: 1px solid #999;
  border-radius: 3px;
  height: 100px;
  overflow: auto;
}

/*===== Row-Re-order data-table ===== */

table.dt-rowReorder-float {
  position: absolute !important;
  opacity: 0.8;
  table-layout: fixed;
  outline: 2px solid #888;
  outline-offset: -2px;
  z-index: 2001;
}

tr.dt-rowReorder-moving {
  outline: 2px solid #555;
  outline-offset: -2px;
}

body.dt-rowReorder-noOverflow {
  overflow-x: hidden;
}

table.dataTable td.reorder {
  text-align: center;
  cursor: move;
}

#result {
  border: 1px solid #888;
  background: #f7f7f7;
  padding: 1em;
  margin-bottom: 1em;
}

/*===== Scroller data-table ===== */

div.DTS {
  display: block !important;

  tbody {

    th,
    td {
      white-space: nowrap;
    }
  }

  div {
    &.DTS_Loading {
      z-index: 1;
    }

    &.dataTables_scrollBody table {
      z-index: 2;
    }

    &.dataTables_paginate,
    &.dataTables_length {
      display: none;
    }
  }
}

/**====== Data-table css end ======**/
/**  =====================
      Foo-table css start
==========================  **/

.footable .pagination> {
  .active> {
    a {
      background-color: #4680ff;
      border-color: #4680ff;

      &:focus,
      &:hover {
        background-color: #4680ff;
        border-color: #4680ff;
      }
    }

    span {
      background-color: #4680ff;
      border-color: #4680ff;

      &:focus,
      &:hover {
        background-color: #4680ff;
        border-color: #4680ff;
      }
    }
  }

  li> {

    a,
    span {
      color: #222;
    }
  }
}

.footable-details.table,
.footable.table {
  margin-bottom: 0;
}

table {
  &.footable>tfoot>tr.footable-paging>td>span.label {
    margin-bottom: 0;
  }

  &.footable-paging-center>tfoot>tr.footable-paging>td {
    padding-bottom: 0;
  }
}

/**====== Foo-table css end ======**/
/**  =====================
      Handson css start
==========================  **/

.make-me-red {
  color: red;
}

.scroll-container {
  .wtHolder {
    height: 350px !important;
  }

  #highlighting .wtHolder,
  #populating .wtHolder,
  #paginating .wtHolder,
  #searching .wtHolder,
  #drag .wtHolder,
  #validation .wtHolder,
  #readOnly .wtHolder,
  #nonEditable .wtHolder,
  #numericData .wtHolder,
  #dateDate .wtHolder,
  #timeData .wtHolder,
  #checkbox .wtHolder,
  #select .wtHolder,
  #dropdown .wtHolder,
  #autocomplete .wtHolder,
  #jQuery .wtHolder,
  #chromaJS .wtHolder,
  #context .wtHolder,
  #configuration .wtHolder,
  #copyPaste .wtHolder,
  #buttons .wtHolder,
  #comments .wtHolder {
    height: auto !important;
  }
}

.handson-pagination {
  margin-top: 30px;
}

.currentRow {
  background-color: #f9f9fb !important;
}

.currentCol {
  background-color: #e7e8ef !important;
}

.contact-table tr td {
  &:nth-child(n-2) {
    text-align: center;
  }

  &:last-child {
    position: relative;

    .dropdown-menu {
      top: 52px;
    }
  }
}

/**====== Handson css end ======**/

#checkbox-select tr {

  td.select-checkbox,
  th.select-checkbox {
    padding-left: 30px;
  }

  td.select-checkbox {

    &:before,
    &:after {
      left: 15px;
    }
  }

  th.select-checkbox {

    &:before,
    &:after {
      left: 15px;
    }
  }
}

.bg-facebook {
  background-color: #3b5997;
}

.text-facebook {
  color: #3b5997;
}

.bg-twiter {
  background-color: #42c0fb;
}

.text-twiter {
  color: #42c0fb;
}

.bg-dribble {
  background-color: #ec4a89;
}

.text-dribble {
  color: #ec4a89;
}

.bg-pinterest {
  background-color: #bf2131;
}

.text-pinterest {
  color: #bf2131;
}

.bg-youtube {
  background-color: #e0291d;
}

.text-youtube {
  color: #e0291d;
}

.bg-googleplus {
  background-color: #c73e2e;
}

.text-googleplus {
  color: #c73e2e;
}

.bg-instagram {
  background-color: #aa7c62;
}

.text-instagram {
  color: #aa7c62;
}

.bg-viber {
  background-color: #7b519d;
}

.text-viber {
  color: #7b519d;
}

.bg-amazon {
  background-color: #000;
}

.text-amazon {
  color: #000;
}

.bg-c-blue {
  background-color: #4680ff;
}

.text-c-blue {
  color: #4680ff;
}

.bg-c-pink {
  background-color: #fc6180;
}

.text-c-pink {
  color: #fc6180;
}

.bg-c-green {
  background-color: #93be52;
}

.text-c-green {
  color: #93be52;
}

.bg-c-yellow {
  background-color: #ffb64d;
}

.text-c-yellow {
  color: #ffb64d;
}

.bg-c-orenge {
  background-color: #fe8a7d;
}

.text-c-orenge {
  color: #fe8a7d;
}

.bg-c-lite-green {
  background-color: #69cec6;
}

.text-c-lite-green {
  color: #69cec6;
}

.card .card-bg-inverce {
  background-color: #353c4e;
}

.widget-card-1 {
  margin-top: 40px;
  text-align: right;

  .card1-icon {
    width: 60px;
    height: 60px;
    position: absolute;
    top: -15px;
    font-size: 35px;
    border-radius: 8px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
  }

  .card-block>span {
    color: #919aa3;
  }

  h4 {
    font-weight: 400;
    margin-top: 10px;
  }

  &:hover .card1-icon {
    top: -25px;
  }
}

#viewyourshots {
  height: 450px;

  .chartist-tooltip {
    position: absolute;
    display: inline-block;
    opacity: 0;
    min-width: 50px;
    padding: 5px 10px;
    border-radius: 5px;
    background: #4680ff;
    color: #fff;
    font-weight: 500;
    text-align: center;
    pointer-events: none;
    z-index: 1;
    transition: all 0.3s linear;

    &:before {
      content: "";
      position: absolute;
      border: 5px solid transparent;
      border-right-color: #4680ff;
      border-bottom-color: #4680ff;
      transform: rotate(45deg);
      bottom: -5px;
      left: 50%;
      margin-left: -5px;
    }

    &.tooltip-show {
      opacity: 1;
    }
  }

  .ct-series-a {

    .ct-area,
    .ct-slice-pie {
      fill: rgba(252, 97, 128, 0.7);
    }

    .ct-line {
      stroke: #fc6180;
      stroke-width: 6px;
    }
  }
}

.widget-statstic-card {
  position: relative;
  overflow: hidden;

  .st-icon {
    color: #fff;
    font-size: 23px;
    padding: 40px 40px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
    right: -30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &:hover .st-icon {
    font-size: 50px;
  }

  i {
    color: #ccc;
  }

  h2 {
    font-weight: 600;
    display: inline-block;
  }

  span {
    border-radius: 30px;
    background-color: #c6d8ff;
    padding: 5px 20px;
    color: #4680ff;
    font-weight: 600;
  }
}

.prod-order-card {
  #solid-gauge {
    height: 305px;
  }

  ul li {
    display: inline-block;
    width: 32.5%;
    margin-top: 30px;

    .legend-icon {
      display: inline-block;
      vertical-align: super;

      span {
        display: block;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }

    .legend-cont {
      display: inline-block;

      p {
        margin-bottom: 0;
        line-height: 1;
        font-weight: 900;
        font-size: 14px;
      }

      span {
        color: #919aa3;
      }
    }
  }
}

.widget-last-task .to-do-list {
  border: 0;
  padding: 0;
  margin: 0;

  .check-task {
    margin-bottom: 20px;
  }

  .cr {
    border-radius: 5px;
    border: 2px solid #cfd4d8;
    cursor: pointer;
    display: inline-block;
    float: left;
    top: 10px;
    height: 22px;
    margin-right: 1.2em;
    position: relative;
    width: 22px;
  }

  p {
    display: block;
    margin-bottom: 0;
  }

  .check-task span {
    display: table-cell;
    font-weight: 600;
  }
}

.card-power {
  h2 {
    font-weight: 600;
    display: inline-block;
  }

  span {
    font-size: 20px;
    margin-left: 5px;
  }

  .map-area {
    h6 {
      display: inline-block;
      font-weight: 600;
    }

    span {
      font-size: inherit;
      margin-left: 0;
      font-weight: 600;
    }

    p {
      line-height: 18px;
    }
  }
}

.widget-visitor-card {
  overflow: hidden;
  padding: 10px 0;

  i {
    color: #fff;
    font-size: 80px;
    position: absolute;
    bottom: -10px;
    opacity: 0.3;
    left: -10px;
    transform: rotate(15deg);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &:hover i {
    -webkit-transform: rotate(0deg) scale(1.4);
    transform: rotate(0deg) scale(1.4);
    opacity: 0.5;
  }
}

.widget-card-user {
  .card-user {
    h4 {
      font-size: 20px;
      margin-bottom: 0;
    }

    .card-bedges {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .card-footer {
    background-color: #f6f7fb;
    border-top: 0;
    padding: 1rem 1.25rem;

    .footer-menu+.footer-menu {
      border-left: 2px solid #f0f1f5;
    }
  }
}

.card-current-prog .table tr {
  .last-line {
    display: inline-block;

    p {
      position: relative;

      &:after {
        content: "|";
        position: absolute;
        color: #ccc;
        font-size: 23px;
        padding: 0 40px;
        top: -5px;
      }
    }
  }

  td {
    vertical-align: middle;
    width: calc(100% / 3);
    padding-bottom: 5px;

    .card-img {
      display: inline-block;
      top: -10px;
      position: relative;
      width: auto;

      img {
        width: 40px;
      }
    }

    .card-contain {
      display: inline-block;
      margin-left: 10px;
    }

    i {
      display: inline-block;
      margin-right: 20px;
    }

    p {
      display: inline-block;
      margin-bottom: 0;
    }

    h6 {
      font-weight: 600;
      margin-bottom: 0;
    }

    .card-icon-time {
      display: inline-block;
      margin-left: 80px;

      +.card-icon-time {
        margin-left: 40px;
      }
    }

    .progress {
      width: 55%;
      height: 10px;
      text-align: center;
      margin: 0 auto;
      background-color: #cdd8dc;
      border-radius: 30px;

      .progress-bar {
        border-radius: 30px;
      }
    }
  }

  &:first-child td {
    border-top: none;
  }
}

.customer-card h3 {
  font-weight: 600;
}

.profile-view {
  p {
    font-weight: 600;
    font-size: 14px;
  }

  span {
    font-size: 26px;
  }
}

.profile-comp-block input.dial {
  color: #353c4e !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  padding: 22px !important;
  height: auto !important;
  margin-top: 37px !important;
}

.cal-card .calendar-arrow {
  .next {
    position: absolute;
    top: 50%;
    background-color: #fc6180;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: #fff;
  }

  .prev {
    position: absolute;
    top: 50%;
    background-color: #fc6180;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: #fff;
    left: -15px;
    padding: 1px 6px;
  }

  .next {
    right: -15px;
    padding: 1px 8px;
  }
}

.cal-event .inverse-card {
  width: 100%;
  height: 100%;
  border-radius: 0 7px 7px 0;
  padding: 35px;
  background-color: #353c4e;

  h5 {
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 10px;
    margin-right: 0;
    display: block;

    &:after {
      content: "";
      position: absolute;
      width: 60px;
      height: 3px;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
      background-color: #5e6575;
    }
  }

  p {
    position: relative;
    color: #5e6575;
    padding-bottom: 5px;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 20px;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      left: 0;
      background-color: #5e6575;
    }

    .event-close {
      border-radius: 50%;
      padding: 2px;
      color: #222;
      font-size: 10px;
      position: absolute;
      font-weight: 600;
      top: 30px;
      right: -4px;
    }
  }

  .btn-event {
    padding: 4px 7px;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 6px;
    background-color: #242936;
  }
}

.card-notification {
  position: relative;

  &:before {
    content: "";
    width: 15px;
    height: 15px;
    border: 2px solid;
    border-radius: 50%;
    position: absolute;
  }

  &:nth-child(1):before {
    border-color: #4680ff;
  }

  &:nth-child(2):before {
    border-color: #fc6180;
  }

  &:nth-child(3):before {
    border-color: #93be52;
  }

  &:nth-child(4):before {
    border-color: #ffb64d;
  }

  &:nth-child(5):before {
    border-color: #fe8a7d;
  }

  &:nth-child(6):before {
    border-color: #69cec6;
  }

  &:after {
    content: "";
    background: #e0e0e0;
    height: 100%;
    width: 1px;
    position: absolute;
    top: 22px;
    left: 7px;
  }

  &:last-child:after {
    display: none;
  }

  small {
    font-size: 12px;
  }

  p {
    font-size: 14px;
  }

  .card-noti-conatin {
    margin-left: 30px;
  }
}

.client-description {
  .client {
    margin: 0 auto;
    display: block;
    width: 130px;
  }

  a,
  p {
    font-weight: 600;
  }
}

.marketing-card {
  .table {
    thead th {
      border-bottom: none;
      border-top: none;
    }

    tbody {
      tr:first-child td {
        border-top: none;
      }

      .marketing-header {
        background-color: #f7f7f7;
      }

      td {
        vertical-align: middle;

        p {
          margin-bottom: 0;
          display: inline-block;
        }

        .table-contain {
          display: inline-block;
          margin-left: 10px;
        }

        span {
          font-weight: 600;
        }

        h6 {
          margin-bottom: 0;
          font-size: 0.9rem;
          font-weight: 600;
          line-height: 1.5;
        }

        .dropdown-toggle {
          background-color: transparent;
          border: none;
          padding: 0;

          i {
            margin-right: 0;
          }

          &:after {
            display: none;
          }
        }

        .social-icon {
          width: 15px;
          height: 15px;
          padding: 6px 8px;
          border-radius: 50%;
          color: #fff;
          font-size: 20px;
          top: -10px;
          position: relative;
        }

        .btn-action {
          position: relative;
          padding: 6px 18px 6px 32px;

          &:after {
            content: "";
            position: absolute;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            left: 12px;
            top: 10px;
          }

          &.btn-bg-c-blue:after {
            background-color: #4680ff;
          }

          &.btn-bg-c-pink:after {
            background-color: #fc6180;
          }

          &.btn-bg-c-green:after {
            background-color: #93be52;
          }

          &.btn-bg-c-yellow:after {
            background-color: #ffb64d;
          }

          &.btn-bg-c-orenge:after {
            background-color: #fe8a7d;
          }

          &.btn-bg-c-lite-green:after {
            background-color: #69cec6;
          }
        }
      }
    }
  }

  .marketing-card-footer .tab-cont>p {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    font-weight: 900;
    margin-top: -8px;

    i {
      font-size: 20px;
      margin: 0 5px;
    }
  }
}

#visitoranalsis .pieLabel>div {
  font-size: 13px !important;
}

.client-map {
  img {
    vertical-align: top;
    width: 90px;
  }

  .client-detail {
    margin-bottom: 20px;
  }

  .client-profile {
    float: left;
  }

  .client-contain {
    display: inline-block;
    margin-left: 20px;

    h5 {
      color: #222;
      display: block;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 18px;
      padding-bottom: 10px;
    }
  }

  .client-card-box {
    border: 1px solid #ccc;
    border-radius: 5px;

    .client-border {
      border-right: 1px solid #ccc;
    }

    .client-border-card {
      border-top: 1px solid #ccc;
    }
  }
}

.card-blue {
  background-color: #5087ff;
}

.card-pink {
  background-color: #fc6b88;
}

.card-green {
  background-color: #98c159;
}

.card-yellow {
  background-color: #ffba57;
}

.card-blue,
.card-green,
.card-pink,
.card-yellow {
  overflow: hidden;
}

.card-blue .value-badges,
.card-green .value-badges,
.card-pink .value-badges,
.card-yellow .value-badges {
  position: absolute;
  top: 10px;
  right: 5px;
  letter-spacing: 1.8px;
}

.total-card .text-left {
  h4 {
    color: #fff;
    font-weight: 600;
    margin-bottom: 5px;
  }

  p {
    color: #fff;
    font-size: 15px;
  }
}

.visitor-map {
  .btn-default {
    background-color: transparent;
    color: #bdc3c7;
    border-color: #bdc3c7;
    cursor: pointer;
    transition: all ease-in 0.3s;
  }

  .visitor-graph {
    height: 400px;
  }
}

.visitor-card {
  h2 {
    font-weight: 400;

    span {
      font-weight: 400;
    }
  }

  .progress {
    height: 8px;

    .progress-bar {
      border-radius: 30px;
    }
  }

  i {
    font-size: 40px;
    float: right;
    opacity: 0.5;
    margin-top: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &:hover i {
    opacity: 1;
    -webkit-transform: scale(1.5) rotate(360deg);
    transform: scale(1.5) rotate(360deg);
  }
}

.review-resourse table tbody td {
  border-top: none;
  padding: 1rem;

  .trafic-resorce {
    height: 40px;
    width: 100%;
  }

  h6 {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .peity {
    width: 100px;
    height: 25px;
  }
}

.review-task {
  overflow: auto;
}

#visitoranalsis {
  width: 100%;
  height: 400px;
}

.review-task .table {
  td {
    vertical-align: middle;

    img {
      display: inline-block;
    }

    p {
      display: inline-block;
      margin-bottom: 0;
    }

    h6 {
      margin-bottom: 0;
      font-size: 0.9rem;
      font-weight: 600;
    }

    .review-date {
      margin-bottom: 0;
      position: relative;
      padding-right: 15px;

      &:after {
        content: "|";
        position: absolute;
        font-size: 15px;
        padding: 0 6px;
      }
    }

    i+i {
      margin-left: 5px;
    }
  }

  tbody tr:first-child td {
    border-top: none;
  }
}

.last-week-card {
  &:hover i {
    opacity: 1;
  }

  i {
    opacity: 0.65;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  p {
    font-weight: 600;
  }

  span {
    letter-spacing: 0.9px;
    padding: 4px 10px;
    font-size: 13px;
    font-weight: 400;
  }
}

.review-project {
  thead th {
    border-bottom: none;
    border-top: none;
  }

  tbody tr {
    &:first-child td {
      border-top: none;
    }

    td {

      h6,
      p {
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1.4;
      }
    }
  }

  .peity {
    width: 30px;
    height: 30px;
  }

  img {
    vertical-align: top;
  }

  .project-contain {
    margin-left: 15px;
    display: inline-block;
  }

  .progress {
    width: 100%;
    height: 8px;
    background-color: #cdd8dc;
    border-radius: 30px;
    position: relative;
    overflow: inherit;

    .progress-bar {
      border-radius: 30px;
    }

    label {
      position: absolute;
      top: -22px;
      right: 0;
      color: #222;
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.whether-card {
  .card-footer h4 small {
    font-size: 60%;
  }

  .daily-whether {
    h5 {
      margin-right: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    >div {
      .climacon {
        display: block;
        margin: 35px auto;
        width: 70px;
        height: 70px;
      }

      &:nth-child(1) {

        .climacon_component-fill,
        .climacon_component-stroke {
          fill: #4680ff;
        }
      }

      &:nth-child(2) {

        .climacon_component-fill,
        .climacon_component-stroke {
          fill: #fc6180;
        }
      }

      &:nth-child(3) {

        .climacon_component-fill,
        .climacon_component-stroke {
          fill: #93be52;
        }
      }

      &:nth-child(4) {

        .climacon_component-fill,
        .climacon_component-stroke {
          fill: #ffb64d;
        }
      }

      &:nth-child(5) {

        .climacon_component-fill,
        .climacon_component-stroke {
          fill: #fe8a7d;
        }
      }

      &:nth-child(6) {

        .climacon_component-fill,
        .climacon_component-stroke {
          fill: #69cec6;
        }
      }
    }
  }
}

.online-form {
  h3 {
    display: inline-block;
  }

  span {
    vertical-align: text-top;
    margin-left: 10px;

    i {
      color: #ddd;
    }
  }
}

.revenue-map {
  p {
    display: inline-block;
  }

  #revenue-generate-graph+svg,
  #tax-deduction-graph+svg {
    height: 150px;
    width: 100%;
  }

  .order-graph+.peity {
    width: 100px;
    height: 100px;
  }
}

.add-task-card {
  .btn-card {
    font-size: 13px;
    padding: 5px 10px;
    margin: 0px 10px;
    color: #fff;
    border-radius: 5px;
  }

  .to-do-list {
    border: 0;
    padding: 0;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
  }

  .task-card-img {
    position: relative;
    top: 0px;
  }
}

.card-progress .progress {
  height: 8px;
  width: 100%;
  position: relative;
  top: 8px;
  left: 0;
  border-radius: 0;
  overflow: inherit;
}

.visitor-chart-card {
  position: relative;

  span {
    position: relative;
    top: -5px;
    margin-left: 10px;

    i {
      color: #ddd;
    }
  }

  .card-time {
    position: absolute;
    top: -35px;
    right: 50px;

    .btn {
      padding: 6px 15px;
      border-radius: 7px;
      font-size: 13px;
    }
  }
}

.recent-candidate-card {
  thead th {
    border-bottom: none;
    border-top: none;
  }

  tbody tr {
    td {
      vertical-align: middle;

      h6 {
        font-weight: 600;
        line-height: 1.4;
        margin: 0;
      }

      p {
        line-height: 1.4;
        margin-bottom: 0;
      }
    }

    &:first-child td {
      border-top: none;
    }
  }

  .recent-contain {
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 3px;
  }

  .border-img-view {

    .btn,
    a+a>img {
      margin-left: -20px;
    }
  }
}

.card-map {

  .card-header .card-header-right i,
  h2,
  h5,
  h6,
  i {
    color: #fff;
  }

  h2 i {
    opacity: 0.5;
  }
}

.card-chart {
  position: relative;

  .card-chart-right {
    position: absolute;
    right: 30px;
    top: 18px;
  }
}

.card-view {
  img {
    vertical-align: top;
  }

  .card-view-contain p {
    color: #f1f1f1;
  }

  .progress {
    width: 100%;
    height: 10px;
    background-color: #f1f1f1;
    border-radius: 4px;

    .progress-bar {
      border-radius: 4px;
    }
  }

  h5,
  p,
  span {
    color: #fff;
  }
}

.monthly-earning {
  position: relative;
  overflow: hidden;

  .monthly-data {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba(231, 231, 231, 0.82);
    text-align: center;

    >div {
      height: 100%;

      >div {
        padding-top: 40px;
        padding-bottom: 40px;

        &:first-child {
          background-color: rgba(70, 128, 255, 0.8);
          height: 100%;

          h3,
          h5 {
            color: #fff;
          }
        }
      }
    }
  }
}

.card-customer .progress {
  width: 100%;
  height: 9px;
  background-color: #ccc;
  border-radius: 30px;

  .progress-bar {
    border-radius: 30px;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
    position: relative;

    &:before {
      content: "";
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      border-radius: 10px;
      -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
      animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    }
  }
}

.card-overview span {
  width: 12px;
  height: 12px;
  border-radius: 3px;
  position: absolute;
  top: 8px;
  left: 15px;
}

.card-comment {
  transition: all ease-in-out 0.5s;

  .card-block-small {
    border-bottom: 1px solid #f1f1f1;

    .comment-desc {
      h6 {
        font-weight: 600;
      }

      .btn-comment {
        font-size: 13px;
        padding: 5px 20px;
        line-height: 1;
        color: #fff;
      }
    }

    &:hover {
      background-color: #f1f1f1;
    }
  }
}

.browser-card .btn-browser {
  padding: 2px 10px;
  color: #fff;
  font-size: 13px;
}

.card-contact-box .card-contain {
  position: relative;

  p {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 90px;
      height: 2px;
      margin: 0 auto;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #919aa3;
    }
  }
}

.weather-update .climacon {
  width: 170px;
  height: 155px;

  .climacon_component-stroke {
    fill: #fc6180;
  }
}

.card-statistics {
  .label {
    border-radius: 30px;
    padding: 3px 10px;
    font-size: 13px;
  }

  .stats-block {
    display: flex;
    align-items: center;
  }
}

.card-feed .feed-card-info {
  i {
    padding: 10px;
    border-radius: 50%;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }

  &:nth-child(1) i {
    color: #0046df;
    background-color: #acc6ff;
  }

  &:nth-child(2) i {
    color: #f20534;
    background-color: #fec5d0;
  }

  &:nth-child(3) i {
    color: #5d7c2e;
    background-color: #c1da9c;
  }

  &:nth-child(4) i {
    color: #e68800;
    background-color: #ffe0b3;
  }

  &:nth-child(5) i {
    color: #fd2f18;
    background-color: #ffe5e2;
  }

  &:nth-child(6) i {
    color: #339e95;
    background-color: #b6e7e3;
  }

  &:nth-child(7) i {
    color: #0046df;
    background-color: #acc6ff;
  }

  &:nth-child(8) i {
    color: #f20534;
    background-color: #fec5d0;
  }

  &:nth-child(9) i {
    color: #5d7c2e;
    background-color: #c1da9c;
  }

  &:nth-child(10) i {
    color: #e68800;
    background-color: #ffe0b3;
  }

  &:nth-child(11) i {
    color: #fd2f18;
    background-color: #ffe5e2;
  }

  &:nth-child(12) i {
    color: #339e95;
    background-color: #b6e7e3;
  }
}

@media only screen and (max-width: 480px) {
  .card-feed .feed-card-info span {
    display: none;
  }
}

.views-card {
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  h2,
  p,
  span {
    color: #fff;
    transition: all 0.5s ease-in-out;
  }

  i {
    width: 10px;
    height: 10px;
    padding: 4px 6px;
    border-radius: 50%;
    font-size: 15px;
    background-color: #fff;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.23);
    transition: all 0.5s ease-in-out;
  }

  &.bg-c-blue {
    border: 1px solid #4680ff;
  }

  &.bg-c-pink {
    border: 1px solid #fc6180;
  }

  &.bg-c-yellow {
    border: 1px solid #ffb64d;
  }

  &.bg-c-green {
    border: 1px solid #93be52;
  }

  &:hover {
    background-color: #fff;

    &.bg-c-blue {

      h2,
      p,
      span {
        color: #4680ff;
      }

      i {
        background-color: #4680ff;
        color: #fff;
        box-shadow: 0 0 0 4px rgba(70, 128, 255, 0.5);
      }
    }

    &.bg-c-pink {

      h2,
      p,
      span {
        color: #fc6180;
      }

      i {
        background-color: #fc6180;
        color: #fff;
        box-shadow: 0 0 0 4px rgba(252, 97, 128, 0.5);
      }
    }

    &.bg-c-yellow {

      h2,
      p,
      span {
        color: #ffb64d;
      }

      i {
        background-color: #ffb64d;
        color: #fff;
        box-shadow: 0 0 0 4px rgba(255, 182, 77, 0.5);
      }
    }

    &.bg-c-green {

      h2,
      p,
      span {
        color: #93be52;
      }

      i {
        background-color: #93be52;
        color: #fff;
        box-shadow: 0 0 0 4px rgba(147, 190, 82, 0.5);
      }
    }
  }
}

.sales-revenue-card {
  .card-header {

    .card-header-right i,
    h5 {
      color: #fff;
    }
  }

  h6 {
    color: #fff;
  }

  h2 {
    color: #fff;
    letter-spacing: 1.2px;
  }
}

.statetic-card {
  position: relative;

  .btn-default {
    background-color: transparent;
    position: absolute;
    right: 50px;
    top: -100px;
    border-radius: 6px;
    padding: 6px 15px;
    color: #666;
    font-size: 13px;
  }

  .icon-stat {
    position: relative;
    top: -8px;
  }
}

.task-sale-card p {
  line-height: 15px;
}

.week-sales-card .card-header {

  h5,
  .card-header-right i {
    color: #fff;
  }
}

.green-contain-card {
  .card-header .card-header-right i {
    color: #fff;
  }

  h4 {
    color: #fff;
    line-height: 1.5;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .card {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.amount-card {
  overflow: hidden;

  #amount-processed+svg,
  #amount-spent+svg,
  #profit-processed+svg {
    width: 100%;
    height: 100px;
  }
}

#realtimeupdate {
  width: 100%;
  height: 120px;
  overflow: hidden;
  border-radius: 0 0 7px 7px;
}

#monthlyprofit-1,
#monthlyprofit-2,
#monthlyprofit-3 {
  text-align: center;
}

#monthlyprofit-1+svg,
#monthlyprofit-2+svg,
#monthlyprofit-3+svg {
  width: 100%;
  height: 30px;
}

#monthlyprofit-1+svg polyline,
#monthlyprofit-2+svg polyline,
#monthlyprofit-3+svg polyline {
  stroke-width: 2;
}

.sentiment-card .sentiment-center {
  align-items: center;
}

.revenue-card {
  position: relative;
  top: 19px;

  span {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
    top: 6px;
  }
}

.widget-chat-box .media {

  .send-chat,
  .receive-chat {
    position: relative;
    background-color: #e6edfd;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 30px;
    max-width: 300px;
    overflow: visible;
  }

  .send-chat:before,
  .receive-chat:before {
    content: "";
    position: absolute;
    height: 12px;
    width: 12px;
    border: 10px solid transparent;
    border-right: 15px solid #e6edfd;
    left: -20px;
    top: 8px;
  }

  .send-chat .time {
    position: absolute;
    bottom: -27px;
    right: 0;
    font-size: 12px;
  }

  .receive-chat {
    .time {
      position: absolute;
      bottom: -27px;
      right: 0;
      font-size: 12px;
    }

    background-color: #f7f7f7;

    &:before {
      left: auto;
      right: -26px;
      border-left: 15px solid #f7f7f7;
      border-right: 15px solid transparent;
    }

    .time {
      left: 0;
    }
  }
}

.tab-card .media {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.feature-card-box {
  .feature-icon {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    color: #fff;
    margin: 0 auto;
    padding: 10px;
    font-size: 35px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &:hover .feature-icon {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.44);
    box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.44);
    border-radius: 0;
  }
}

.sales-menu-card .valign-wrapper {
  align-items: center;

  .sales-icon {
    top: -10px;
    position: relative;
  }
}

.user-status-card {

  .card-header .card-header-right i,
  h2,
  h5,
  h6 {
    color: #fff;
  }

  .progress {
    width: 100%;
    height: 10px;
    background-color: #f1f1f1;
    border-radius: 30px;

    .progress-bar {
      border-radius: 30px;
    }

    .bg-darken-blue {
      background-color: #1d64ff;
    }

    .bg-darken-pink {
      background-color: #fb3960;
    }

    .bg-darken-yellow {
      background-color: #ffa524;
    }
  }
}

.serve-card .serve-chart-list {
  align-items: center;

  h6 {
    position: relative;
    margin-left: 40px;

    &:before {
      content: "";
      position: absolute;
      width: 22px;
      height: 6px;
      border-radius: 30px;
      background-color: #93be52;
      top: 6px;
      left: -39px;
    }

    &:last-child:before {
      background-color: #ccc;
    }
  }
}

.curr-plan-card .plan-icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0 0 0 5px rgba(148, 181, 253, 0.49);
  font-size: 40px;
  color: #fff;
  padding: 14px;
}

.email-sent-card {
  position: relative;

  .card-week {
    position: absolute;
    top: 25px;
    right: 50px;

    .btn {
      padding: 6px 15px;
      border-radius: 7px;
      font-size: 13px;
    }
  }

  p {
    position: relative;
    margin-left: 25px;

    &:before {
      content: "";
      position: absolute;
      width: 18px;
      height: 5px;
      border-radius: 30px;
      background-color: #93be52;
      top: 9px;
      left: -25px;
    }
  }

  .card-block>div>div {
    &:nth-child(2) p:before {
      background-color: #4680ff;
    }

    &:nth-child(3) p:before {
      background-color: #fc6180;
    }

    &:nth-child(4) p:before {
      background-color: #ffb64d;
    }
  }
}

.balance-card .progress {
  width: 100%;
  height: 8px;
  background-color: #e5e5e5;
  border-radius: 30px;

  .progress-bar {
    border-radius: 30px;
  }
}

.feed-card {
  .card-block {
    position: relative;
    padding-left: 15px;

    .border-feed {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      i {
        position: absolute;
        top: calc(50% - 20px);
        left: 0;
        right: 0;
        text-align: center;
        color: #fff;
      }
    }
  }

  &:hover .border-feed {
    &.bg-c-blue {
      background-color: rgba(70, 128, 255, 0.3);

      i {
        color: #4680ff;
      }
    }

    &.bg-c-pink {
      background-color: rgba(252, 97, 128, 0.3);

      i {
        color: #fc6180;
      }
    }

    &.bg-c-green {
      background-color: rgba(147, 190, 82, 0.3);

      i {
        color: #93be52;
      }
    }

    &.bg-c-yellow {
      background-color: rgba(255, 182, 77, 0.3);

      i {
        color: #ffb64d;
      }
    }
  }
}

.user-detail-card {
  .contact-icon .btn-default {
    background-color: #f1f1f1;
    color: #a5b0bd;
    border: 0;
    font-size: 20px;
    padding: 6px 10px;
    border-radius: 5px;
    transition: all ease-in-out 0.3s;

    +.btn-default {
      margin-left: 0;
    }

    &:hover {
      background-color: #c6d8ff;
      color: #4680ff;
    }
  }

  .user-detail {
    i {
      margin-right: 10px;
    }

    div {
      &:nth-child(1) i {
        color: #4680ff;
      }

      &:nth-child(2) i {
        color: #fc6180;
      }

      &:nth-child(3) i {
        color: #93be52;
      }

      &:nth-child(4) i {
        color: #ffb64d;
      }

      &:nth-child(5) i {
        color: #fe8a7d;
      }

      &:nth-child(6) i {
        color: #69cec6;
      }

      &:nth-child(7) i {
        color: #4680ff;
      }

      &:nth-child(8) i {
        color: #fc6180;
      }

      &:nth-child(9) i {
        color: #93be52;
      }

      &:nth-child(10) i {
        color: #ffb64d;
      }

      &:nth-child(11) i {
        color: #fe8a7d;
      }

      &:nth-child(12) i {
        color: #69cec6;
      }
    }

    >div {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e0e0e0;

      &:last-child {
        border-bottom: none;
      }

      h6 {
        margin-bottom: 0;
        line-height: 1.5;
        font-size: 15px;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .user-detail-card .user-detail {
    padding-top: 15px;

    .m-b-30 {
      margin-bottom: 5px;
    }

    .row>div:nth-child(2) h6 {
      margin-left: 25px;
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }
}

.invite-card {
  hr {
    margin-top: 30px;
    margin-bottom: 30px;
    border: 0;
    border-top: 1px solid #ccc;
    width: 100px;
  }

  .invite-card-chart {
    position: relative;

    .invite-card-cont {
      position: absolute;
      width: 100%;
      bottom: 10px;

      i {
        display: block;
        font-size: 35px;
        transform: rotate(25deg);
      }
    }
  }
}

.timing-cloude {
  position: relative;
  line-height: 0;

  .dash {
    display: inline-block;
    width: 70px;
    height: 70px;
    border-radius: 5px;

    .digit {
      display: inline-table;
      color: #fff;
      font-size: 45px;
      line-height: 1.5;
    }

    .delimeter {
      top: 24px;
      left: 0;
      right: 0;
      font-size: 80px;
      color: #919aa3;
      position: absolute;
    }
  }
}

.project-task {
  thead th {
    border-bottom: none;
    border-top: none;
  }

  tbody tr td {
    vertical-align: middle;
  }

  .task-contain h6 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    line-height: 2.5;
    margin-bottom: 0;
    font-weight: 600;
  }

  .progress {
    width: 55%;
    height: 5px;
    text-align: center;
    margin: 0 auto;
    background-color: #e5e5e5;
    border-radius: 30px;

    .progress-bar {
      border-radius: 30px;
    }
  }
}

.past-payment-card {
  thead th {
    border-bottom: none;
    border-top: none;
  }

  tbody tr {
    &:last-child td {
      padding-bottom: 0;
    }

    td {
      vertical-align: middle;
    }

    &:first-child td {
      border-top: none;
    }
  }

  img {
    width: 40px;
  }
}

#design-graph-1,
#design-graph-2,
#design-graph-3 {
  height: 60px;
  width: 150px;
}

.blue-contain-card .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.order-task {
  .order-blue-task {
    .order-icon {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: 2px solid #4680ff;
      background-color: #c6d8ff;
      text-align: center;
      padding: 18px;
    }

    .border-top-blue {
      border-top: 1px solid #4680ff;
    }
  }

  .order-pink-task {
    .order-icon {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      text-align: center;
      padding: 18px;
      border: 2px solid #fc6180;
      background-color: #fedee5;
    }

    .border-top-pink {
      border-top: 1px solid #fc6180;
    }
  }

  .order-yellow-task {
    .order-icon {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      text-align: center;
      padding: 18px;
      border: 2px solid #ffb64d;
      background-color: #ffeacd;
    }

    .border-top-yellow {
      border-top: 1px solid #ffb64d;
    }
  }
}

.view-card {
  .border-img-view {
    border-top: 2px dotted #e0e0e0;

    img {
      width: 40px;
      border-radius: 5px;
    }
  }

  .chart-div {
    position: relative;
    display: inline-block;
    margin: 0 auto;

    .chart-more-icon {
      position: absolute;
      bottom: -25px;
      right: -20px;

      > {
        span {
          background-color: #93be52;
          padding: 7px 13px;
          border: 5px solid #eeeeee;
          color: #fff;
          border-radius: 50%;
          box-shadow: 0 0 13px -3px rgba(0, 0, 0, 0.24);
          font-size: 16px;
          font-weight: 600;
        }

        p {
          margin-top: 15px;
        }
      }
    }

    .dial {
      font-size: 44px !important;
      color: #000 !important;
    }
  }
}

.summery-card .progress {
  width: 85%;
  float: left;
  height: 8px;
  text-align: center;
  margin: 0 auto;
  background-color: #cdd8dc;
  border-radius: 30px;

  .progress-bar {
    border-radius: 30px;
  }
}

#visitor+svg {
  width: 100%;
  height: 200px;
  margin-bottom: -5px;
}

.total-request-card {
  .progress {
    width: 85%;
    float: left;
    height: 5px;
    text-align: center;
    margin: 0 auto;
    background-color: #cdd8dc;
    border-radius: 30px;

    .progress-bar {
      border-radius: 30px;
    }
  }

  hr {
    width: 50px;
    border-top: 1px solid #ccc;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  i {
    position: relative;
    top: 5px;
  }
}

.in-design-card {

  h3,
  h4,
  p {
    color: #fff;
  }

  .inner-dark-card {
    padding: 15px;

    h3 {
      position: relative;
      font-size: 25px;
      margin-right: 5px;
      vertical-align: super;
    }

    p {
      font-size: 11px;
      margin-bottom: 0;
      line-height: 1.4;
    }
  }

  .blue-drak-card {
    background-color: #276bff;
  }

  .pink-drak-card {
    background-color: #fb4368;
  }

  .yellow-drak-card {
    background-color: #ffa92e;
  }
}

.app-design {
  .btn {
    padding: 5px 8px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
  }

  .team-section img {
    width: 35px;
    border-radius: 5px;
  }

  .progress-box {
    p {
      margin-bottom: 0;
    }

    .progress {
      width: calc(100% - 80px);
      height: 8px;
      text-align: center;
      margin: 0 auto;
      background-color: #e5e5e5;
      border-radius: 30px;
      position: relative;
      overflow: inherit;

      .progress-bar {
        border-radius: 30px;

        label {
          position: absolute;
          top: -24px;
          right: 0;
          color: #222;
          font-weight: 600;
          font-size: 13px;
        }
      }
    }
  }
}

.fees-card {
  >div {
    display: flex;
    align-items: center;
  }

  .legend-icon {
    display: inline-block;

    span {
      width: 15px;
      height: 15px;
      display: block;
      border-radius: 4px;
      margin-right: 10px;
    }
  }

  li {
    margin-bottom: 15px;

    .legend-cont {
      display: inline-block;
      vertical-align: top;

      > {
        span {
          color: #919aa3;
          margin: 0;
        }

        p {
          font-weight: 900;
          line-height: 1;
          margin-bottom: 3px;
        }
      }
    }
  }
}

.web-trafic {
  >div {
    display: flex;
    align-items: flex-end;
  }

  .legend-icon {
    display: inline-block;

    span {
      width: 10px;
      height: 10px;
      display: block;
      margin-right: 10px;
    }
  }

  li {
    margin-bottom: 15px;

    .legend-cont {
      display: inline;
      vertical-align: top;

      > {
        span {
          color: #919aa3;
          margin: 0;
          float: right;
        }

        p {
          font-weight: 900;
          line-height: 1;
          display: inline-block;
          margin-bottom: 3px;
        }
      }
    }
  }
}

#stacked-transactions-graph+svg {
  width: 100%;
  height: 130px;
}

#chartdiv {
  width: 100%;
  height: 500px;
  margin: auto;
}

.card-block-big h4 {
  &:first-child {
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;

    small {
      margin-top: 20px;
      font-size: 12px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;

    small {
      margin-top: 20px;
      font-size: 12px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.card.social-network p {
  line-height: 1.6;
}

.card-block h6 {
  word-wrap: break-word;
}

#project-overview-chart .c3-chart-arc text {
  fill: none;
}

.project-overview-chart {
  text-align: center;
}

#project-over-sub-1,
#project-over-sub-2,
#project-over-sub-3 {
  height: 50px;
  width: 100%;
}

#sales-revenue-chart {
  .c3-chart-arcs-title {
    font-size: 20px;
    fill: #fff !important;
  }

  .c3-legend-item {
    display: none;
  }
}

@media only screen and (max-width: 1366px) {
  .sales-prediction h2 {
    font-size: 1.8em;
  }
}

.progressbar-v-1 .chart,
.progressbar-v-2 .chart,
.progressbar-v-3 .chart {
  text-align: center;
}

.progressbar-v-1 .chart-percent {
  font-size: 24px;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  font-weight: 400;
}

#revenue-status-d-graph+svg,
#revenue-status-w-graph+svg {
  width: 100%;
  height: 50px;
}

#new-user-daily+svg,
#page-views-today+svg {
  width: 100%;
  height: 150px;
}

.status-rev-chart {
  display: flex;
  align-items: center;
}

#monthly-earning+svg {
  width: 100%;
  height: 300px;
  margin-bottom: -5px;
}

.happyball,
.sadball {
  width: 80px;
  height: 100px;
  margin: 0 auto;
}

.progressbar-v-1 .chart {
  display: flex;
  align-items: center;
  justify-content: center;
}

.visitor-chart-card {
  h3 {
    font-size: 1.25rem;
  }

  span {
    margin-left: 2px;
    padding: 4px 2px;
    margin-right: 0;
  }
}

.card-comment {
  img {
    float: left;
  }

  .comment-desc {
    padding-left: 80px;
    vertical-align: top;
  }

  .comment-btn {
    display: inline-block;
  }

  .date {
    display: inline-block;
    float: right;
  }
}

.revenue-report h4 {
  margin-bottom: 20px;
}

.last-week-sales {
  width: 100%;
  height: 265px;
}

.green-contain-card p.text-muted {
  margin-bottom: 1.125rem;
}

.unique-visitor-card {
  .progress {
    height: 5px;
  }

  .footer-card {
    background-color: #fff;

    .f-prog {
      border-right: 1px solid #ccc;
    }
  }
}

@media only screen and (max-width: 575px) {
  .unique-visitor-card .footer-card .f-prog {
    border-right: none;
  }
}

@media only screen and (max-width: 1366px) {
  .card-block-big h4:last-child {
    margin-left: 20px;
  }

  .weather-update h4 {
    padding-bottom: 0;
  }

  .green-contain-card .p-t-30 {
    padding-top: 0;
  }

  .client-description button {
    padding: 8px;
  }
}

@media only screen and (max-width: 1357px) {
  .client-map {
    .client-profile {
      text-align: center;
      margin-bottom: 20px;
    }

    .client-contain {
      display: inherit;
      margin-left: 0;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 1299px) {
  .client-map .client-profile {
    float: none;
  }

  h2 {
    font-size: 1.7em;
  }
}

@media only screen and (max-width: 1280px) {
  .feature-card-box p {
    font-size: 13px;
  }

  .contact-icon .btn-default {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .task-sale-card .top {
    margin-bottom: 30px;
  }

  .card-statistics .card-block h2 {
    font-size: 1rem;
  }

  .sentiment-card .card-block-big {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .whether-card {
    .daily-whether>div .climacon {
      margin: 0 auto;
    }

    .daily-whether-block {
      margin-bottom: 30px;
    }
  }

  .widget-card-1 {
    margin-top: 10px;
  }

  .widget-card-user .card-user {
    text-align: center;
  }

  .founder-block {
    margin-bottom: 1.125rem;
  }

  .marketing-card-footer {

    .text-c-green,
    .text-c-pink {
      display: block;
    }
  }
}

@media only screen and (max-width: 991px) {
  .card-statistics {
    text-align: center;

    .card-block h2 {
      margin-top: 20px;
    }
  }

  .prod-order-card ul li {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .cal-event {
    padding-left: 15px !important;

    .inverse-card {
      border-radius: 0;
    }
  }

  .main-body .page-wrapper .page-header-title i {
    margin-right: 8px;
    width: 40px;
    height: 40px;
  }

  .sales-revenue-chart,
  div#donut-department-sales {
    text-align: center;
  }

  .widget-card-user .footer-menu {
    margin-bottom: 1.125rem;
  }

  .order-blue-task h2,
  .order-pink-task h2,
  .order-yellow-task h2 {
    text-align: left;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-comment .date {
    float: none;
    margin-top: 20px;
  }

  .visitor-chart-card .card-time .btn {
    padding: 6px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 568px) {
  .fees-card li {
    margin-bottom: 15px;
    margin-right: 15px;
    display: inline-block;
  }
}

@media only screen and (max-width: 575px) {
  .card-progress label {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .sales-menu-card {
    text-align: center;
  }

  .main-body .page-wrapper .page-header-breadcrumb {
    display: block;
    margin-top: 20px;
  }

  .visitor-chart-card .card-time {
    position: initial;
  }

  .card-view img,
  .visitor-counter {
    margin-bottom: 20px;
  }

  .card-view-contain {
    margin-left: 0;
  }

  .card-comment {
    .comment-desc {
      padding-left: 60px;
    }

    .date {
      float: none;
      margin-top: 20px;
    }
  }

  .statetic-card .btn-default {
    right: 0;
  }

  .sentiment-center h4 {
    margin-top: 10px;
  }

  .card-feed .feed-card-info i {
    margin-right: 2px;
    padding: 8px;
  }

  .monthly-earning .monthly-data>div>div:first-child {
    height: 40%;
    padding-top: 20px;
  }

  .spent {
    height: 60%;
    padding-top: 20px;
  }

  .personal-profile {
    margin-top: 20px;
  }

  .card.social-network .card-block {
    text-align: center;
  }

  .prod-order-card ul li {
    width: 100%;
  }

  .client-description {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .rest-traffic h2 {
    text-align: center;
  }

  .p-l-30 {
    padding-left: 0;
  }

  .timing-cloude .dash {
    width: 60px;
  }
}

#monthly-graph {
  .amcharts-graph-g2 .amcharts-graph-stroke {
    stroke-linejoin: round;
    stroke-linecap: round;
    -webkit-animation: am-moving-dashes 1s linear infinite;
    animation: am-moving-dashes 1s linear infinite;
  }

  .lastBullet {
    -webkit-animation: am-pulsating 1s ease-out infinite;
    animation: am-pulsating 1s ease-out infinite;
  }

  .amcharts-graph-column-front {
    -webkit-transition: all 0.3s 0.3s ease-out;
    transition: all 0.3s 0.3s ease-out;

    &:hover {
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      fill-opacity: 0.4;
    }
  }

  .amcharts-graph-g3 {
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-dasharray: 500%;
    -webkit-animation: am-draw 40s;
    animation: am-draw 40s;
  }
}

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px;
  }
}

@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px;
  }
}

@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}

@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}

@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

/**  =====================
      Alert css start
==========================  **/

.alert {
  font-size: 15px;
  margin-bottom: 2rem;

  &.icons-alert {
    border-left-width: 48px;

    .alert-icon {
      left: 15px;
      position: relative;
      text-align: center;
      top: 0;
      z-index: 3;
    }

    p {
      line-height: 21px;
      margin-bottom: 0;
    }
  }
}

.close {
  font-size: 16px;
  margin-top: 5px;
}

/*===== Primary alert ===== */

.alert-default {
  background-color: #fff;
  border-color: #e0e0e0;
  color: #e0e0e0;
}

.alert-primary {
  background-color: #fff;
  border-color: #4680ff;
  color: #4680ff;
}

.alert-success {
  background-color: #fff;
  border-color: #93be52;
  color: #93be52;
}

.alert-info {
  background-color: #fff;
  border-color: #62d1f3;
  color: #62d1f3;
}

.alert-warning {
  background-color: #fff;
  border-color: #ffb64d;
  color: #ffb64d;
}

.alert-danger {
  background-color: #fff;
  border-color: #fc6180;
  color: #fc6180;
}

/*===== Border alert ===== */

.border-default {
  border-color: transparent;
  border-left: 3px solid #e0e0e0;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #e0e0e0;
}

.border-primary {
  border-color: transparent;
  border-left: 3px solid #4680ff;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #4680ff;
}

.border-success {
  border-color: transparent;
  border-left: 3px solid #93be52;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #93be52;
}

.border-info {
  border-color: transparent;
  border-left: 3px solid #62d1f3;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #62d1f3;
}

.border-warning {
  border-color: transparent;
  border-left: 3px solid #ffb64d;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #ffb64d;
}

.border-danger {
  border-color: transparent;
  border-left: 3px solid #fc6180;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #fc6180;
}

/*===== Solid-background alert ===== */

.background-default {
  background-color: #e0e0e0;
  color: #fff;
}

.background-primary {
  background-color: #4680ff;
  color: #fff;
}

.background-success {
  background-color: #93be52;
  color: #fff;
}

.background-info {
  background-color: #62d1f3;
  color: #fff;
}

.background-warning {
  background-color: #ffb64d;
  color: #fff;
}

.background-danger {
  background-color: #fc6180;
  color: #fff;
}

.icons-alert {
  position: relative;

  &:before {
    color: #fff;
    content: "\f027";
    font-family: "IcoFont" !important;
    font-size: 16px;
    left: -30px;
    position: absolute;
  }
}

[class*="alert-"] code {
  margin-left: 10px;
}

/*======= Alert css ends ===========*/
/* =======================================
      Authentication-page css start
 ========================================= */

.common-img-bg {
  background-size: cover;
  background: url(assets/images/bg.jpg) no-repeat center center / cover;
  height: 100%;
}

.header .login-card {
  margin-top: 30px;
}

@media only screen and (max-width: 991px) {
  .common-img-bg {
    background-size: cover;
    background: url(assets/images/bg.jpg) no-repeat center center / cover;
    height: 100%;
  }

  .header .login-card.auth-body {
    margin-top: 30px;
  }
}

.login-card.auth-body {
  width: 450px;
  margin: 0 auto;
}

.signin-card.auth-body {
  width: 550px;
}

.signup-card.auth-body {
  width: 650px;
}

.login-card {
  border-radius: 30px;
  position: relative;
  display: block;

  h1 {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
  }
}

.login {
  text-align: left;

  ul li label {
    color: #757575;
    font-weight: 600;
    letter-spacing: 1px;
  }

  h3 {
    color: #666666;
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
  }
}

/*
.login.header {
  align-items: flex-start;
}
*/

.social-login {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  padding-bottom: 30px;

  .btn {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.login-card {
  .card-footer {
    background: #fff;
  }

  h1 {
    color: #1b8bf9;
  }

  .forget {
    border-bottom: 1px solid #fff;
    font-size: 24px;
    line-height: 0.6;
  }

  h3 {
    color: #666666;
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
  }
}

.btn-signup .btn-md,
.btn-singin1 .btn-md,
.btn-singin2 .btn-md {
  font-size: 22px;
  font-weight: 400;
  width: 400px;
}

.form-group-main .form-group {
  margin: 30px 0;
}

.login-card {

  .unlock,
  .btn-forgot {
    text-align: center;
  }
}

.imgs-screen .img-circle {
  padding: 4px;
  border: 1px solid #e6e6e4;
}

.sign-in-with {
  font-weight: 600;
  margin: 24px auto 16px;
  position: relative;
  text-align: center;

  .text {
    display: inline-block;
    padding: 0 8px;
    position: relative;
    vertical-align: baseline;
    z-index: 9999;

    &:after {
      left: 100%;
    }

    &:before {
      right: 100%;
    }

    &:after,
    &:before {
      border-top: 1px solid rgba(0, 0, 0, 0.28);
      content: "";
      display: block;
      position: absolute;
      top: 8px;
      width: 60px;
    }
  }
}

.error-500,
.error-503,
.error-404,
.error-400,
.error-403,
.login {
  align-items: center;
  background-color: #2196f3;
  display: flex;
  height: 100%;
  position: fixed;
  text-align: center;
  width: 100%;
}

.auth-box {
  background-color: #fff;
  border-radius: 8px;
  margin: 20px 0 0 0;
  padding: 20px;

  .confirm h3 {
    color: #4680ff;
    font-size: 34px;
  }

  i.icofont-check-circled {
    font-size: 42px;
  }
}

.btn-facebook {
  background-color: #3c5a99;
  border-color: #3c5a99;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
}

.btn-twitter {
  background-color: #41abe1;
  border-color: #41abe1;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
}

.btn-google-plus,
.btn-linkedin,
.btn-pinterest {
  border-radius: 2px;
}

.footer {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 90;

  p {
    margin-bottom: 0;
    padding: 18px;
    width: 100%;
  }
}

.login-card-modal {
  margin: 0 auto;
}

.login.login-modal-main {
  background-color: #fff;
}

.mixed-form {
  .tabbed-modal .nav-link {
    h6 {
      padding: 10px 0;
    }

    background-color: #f5f5f5;
    border-radius: 0;
    color: #888;

    &.active {
      background-color: transparent;
      border-color: transparent;
      color: #303548;
    }
  }

  .modal-body {
    padding: 0;
  }

  .auth-box {
    background-color: #fff;
    border-radius: 8px;
    margin: 10px;
    padding: 10px;

    h3 {
      color: #666666;
      font-size: 22px;
      font-weight: 600;
      margin-top: 20px;
    }

    i.icofont-check-circled {
      font-size: 42px;
    }
  }
}

.auth-box-main .navbar-nav li {
  color: #fff;
  line-height: 40px;
}

.offline-404 {
  background-color: #f3f3f3;

  .auth-body {
    margin-top: -100px;
  }

  h1 {
    color: #2c3e50;
    font-size: 160px;
    font-weight: 600;
    letter-spacing: 5px;
    text-shadow: 3px -2px 4px rgba(128, 128, 128, 0.57);
  }

  h2 {
    color: #aaa;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 30px;
  }
}

/**====== Authentication-page css end ======**/
/**  =====================
      Breadcrumb css start
==========================  **/

.caption-breadcrumb .breadcrumb-header,
.primary-breadcrumb .breadcrumb-header,
.inverse-breadcrumb .breadcrumb-header,
.danger-breadcrumb .breadcrumb-header,
.info-breadcrumb .breadcrumb-header,
.warning-breadcrumb .breadcrumb-header,
.success-breadcrumb .breadcrumb-header {
  display: inline-block;
}

.caption-breadcrumb .breadcrumb-header span,
.primary-breadcrumb .breadcrumb-header span,
.inverse-breadcrumb .breadcrumb-header span,
.danger-breadcrumb .breadcrumb-header span,
.info-breadcrumb .breadcrumb-header span,
.warning-breadcrumb .breadcrumb-header span,
.success-breadcrumb .breadcrumb-header span {
  display: block;
  font-size: 13px;
  margin-top: 5px;
}

.front-icon-breadcrumb {
  .breadcrumb-header {
    display: inline-block;
  }

  .big-icon {
    display: inline-block;

    i {
      font-size: 64px;
      color: #4680ff;
    }
  }

  .d-inline-block span {
    display: block;
    font-size: 13px;
    margin-top: 5px;
  }
}

.primary-breadcrumb,
.inverse-breadcrumb,
.danger-breadcrumb,
.info-breadcrumb,
.warning-breadcrumb,
.success-breadcrumb {
  background-color: #4680ff;
  color: #fff;
}

.primary-breadcrumb h5,
.inverse-breadcrumb h5,
.danger-breadcrumb h5,
.info-breadcrumb h5,
.warning-breadcrumb h5,
.success-breadcrumb h5,
.primary-breadcrumb a,
.inverse-breadcrumb a,
.danger-breadcrumb a,
.info-breadcrumb a,
.warning-breadcrumb a,
.success-breadcrumb a,
.primary-breadcrumb .breadcrumb-title li:last-child a,
.inverse-breadcrumb .breadcrumb-title li:last-child a,
.danger-breadcrumb .breadcrumb-title li:last-child a,
.info-breadcrumb .breadcrumb-title li:last-child a,
.warning-breadcrumb .breadcrumb-title li:last-child a,
.success-breadcrumb .breadcrumb-title li:last-child a,
.primary-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.inverse-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.danger-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.info-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.warning-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.success-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  color: #fff;
}

.inverse-breadcrumb {
  background-color: #303548;
}

.danger-breadcrumb {
  background-color: #fc6180;
}

.info-breadcrumb {
  background-color: #62d1f3;
}

.warning-breadcrumb {
  background-color: #ffb64d;
}

.success-breadcrumb {
  background-color: #93be52;
}

/**====== Breadcrumb css end ======**/
/**  =====================
      Chatting css start
==========================  **/

#main-chat {
  .chat-single-box {
    &.active .chat-header {
      background-color: #4680ff;

      a {
        color: #fff;
      }
    }

    .chat-header a {
      color: #fff;
    }
  }

  .chat-box ul.boxs li.chat-single-box .chat-header.custom-collapsed {
    position: absolute;
    bottom: 0;
    width: 300px;
  }

  .minimized {
    background-color: #1e2730;
  }

  .user-groups {
    padding-left: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;

    .userlist-box {
      border-bottom: none;

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    .media {
      padding-left: 0;
    }

    h6 {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &.work,
      &.frnds {
        position: relative;
        padding-left: 20px;
      }

      &.work:before {
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        background-color: #93be52;
        border-radius: 50px;
        left: 0;
        top: 6px;
      }

      &.frnds:before {
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        background-color: #93be52;
        border-radius: 50px;
        left: 0;
        top: 6px;
        background-color: #fc6180;
      }
    }
  }
}

.user-box {
  height: 100%;
}

.close {
  opacity: 1;
}

.write-msg {
  padding: 12px;
  bottom: 10px;
  border-top: 1px solid #ddd;

  #paper-btn i {
    font-size: 18px;
    margin-right: 0;
    cursor: pointer;
  }
}

.chat-box .secondary:active:hover {
  background-color: #fff;
}

#main-chat .chat-box ul.boxs li {
  &.minimized {
    height: 30px;
    width: 30px;
    bottom: 0;
    position: absolute;
    left: -50px;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    cursor: pointer;

    .count {
      color: #fff;
      text-align: center;
      margin: 5px;
    }

    .chat-dropdown {
      list-style: none;
      display: none;
      position: absolute;
      background-color: #f5f5f5;
      -webkit-box-shadow: 0 1px 8px 0 rgba(5, 5, 5, 0.5);
      -moz-box-shadow: 0 1px 8px 0 rgba(5, 5, 5, 0.5);
      box-shadow: 0 1px 8px 0 rgba(5, 5, 5, 0.5);
      width: 150px;
      z-index: 100;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      padding: 5px 0;

      li {
        padding: 2px 5px;

        div {
          display: inline-block;
        }

        .username {
          width: 85%;
          height: 22px;
        }

        .remove {
          width: 13%;
          padding: 2px 4px;
          float: right;

          &:hover {
            background-color: #c0c0c0 !important;
          }
        }

        &:hover {
          color: black;
        }
      }
    }
  }

  &.hidden {
    display: none;
  }
}

.chat-single-box {
  height: 440px;
  float: right;
  width: 300px;
  margin-right: 15px;
  direction: ltr;
  z-index: 999;
  bottom: 0;

  .chat-header {
    background-color: #e0e0e0;
    color: #fff;
  }
}

.box-live-status {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  border: 1px solid;
}

.chat-msg-img {
  height: 50px;
  width: 50px;
}

.msg-reply {
  padding: 5px;
  position: relative;
  right: 10px;
  border-radius: 2px;
  top: 6px;
  margin-bottom: 10px;
}

.header-users,
.users {
  right: 0;
  top: 103px;
  width: 300px;
  height: 100%;
}

.p-chat-user {
  position: fixed;
  top: 80px;
  z-index: 99;
  border-left: 1px solid #ccc;
}

.users-main {
  height: 100%;
  width: 300px;
  position: fixed;
  border-radius: 0px;
}

.userlist-box {
  cursor: pointer;
  border-bottom: 1px solid #efefef;

  .media-left {
    padding-right: 10px;
  }
}

.users-main .media {
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.user-box .media-object {
  height: 45px;
  width: 45px;
  display: inline-block;
}

.chat-menu-content,
.chat-menu-reply {
  background: #f3f3f3;
  position: relative;
  overflow: visible;
  border-radius: 4px;
}

.chat-menu-content:before {
  position: absolute;
  top: 20px;
  left: -14px;
  width: 0;
  height: 0;
  content: "";
  border: 8px solid transparent;
  border-right-color: #f3f3f3;
}

.chat-menu-reply:before {
  position: absolute;
  top: 20px;
  right: -14px;
  width: 0;
  height: 0;
  content: "";
  border: 8px solid transparent;
  border-left-color: #4680ff;
}

.chat-reply-box {
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  border-top: 1px solid #ccc;
}

.chat-inner-header {
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding: 20px;
}

.chat-send {
  position: absolute;
  bottom: 5px;
  right: 0;
  border: none;
  background-color: transparent;
  color: #4680ff;
  transform: rotate(45deg);
}

.showChat_inner .chat-messages {
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.chat-menu-content .chat-cont,
.chat-menu-reply .chat-cont {
  padding: 10px;
}

.chat-menu-content .chat-time,
.chat-menu-reply .chat-time {
  padding-left: 10px;
  padding-bottom: 10px;
}

.back_chatBox {
  margin: 0 auto;
  font-weight: 600;

  i {
    float: left;
    position: absolute;
    margin-top: 5px;
    left: 20px;
    cursor: pointer;
  }
}

.designation {
  cursor: pointer;
}

.extra-profile-list {
  display: none;
}

.chat-menu-reply {

  .chat-cont,
  .chat-time {
    color: #fff;
  }

  background: #4680ff;
  margin-right: 10px;
}

.users-main {
  .media .chat-header {
    font-size: 14px;
    font-weight: 600;
  }

  .media-body div+div {
    font-size: 12px;
  }
}

.chat-body {
  overflow-y: auto;
  height: 340px;
}

.users-main-fix {
  height: auto;
  position: fixed;
  bottom: 0px;
  top: 151px;
}

.chat-footer,
.chat-link {
  border-color: #4680ff;
}

.chat-footer .input-group-addon {
  padding: 7px 0.75rem;
}

.form-control:focus~.chat-send {
  border-color: #4680ff;
}

.minimized-box {
  padding: 5px 10px;
}

.write-msg {
  input {
    border-radius: 0;
    border-right: 0;
  }

  .btn {
    box-shadow: none;
    border-left: 0;
    font-size: 14px;
  }

  .btn-secondary:hover {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-left: 0;
  }
}

.form-control:focus~.input-group-btn .btn-secondary {
  border-color: #66afe9;
  color: #4680ff;
}

.img-chat-profile {
  height: 45px;
  width: 45px;
}

.chat-minimize {

  .chat-body,
  .chat-footer {
    display: none;
  }
}

.chat-box {
  margin-right: 290px;
  height: 440px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
}

.user-box .media-object {
  height: 45px;
  width: 45px;
  display: inline-block;
}

.friend-box {
  .media-object {
    height: 45px;
    width: 45px;
    display: inline-block;
  }

  img {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.chat-header {
  color: #222222;
}

.live-status {
  height: 10px;
  width: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 100%;
  border: 1px solid;
}

.showChat {
  display: none;
}

.showChat_inner {
  position: fixed;
  top: 80px;
  background-color: white;
  right: 0;
  width: 300px;
  height: calc(100% - 80px);
  z-index: 99;
  border-left: 1px solid #ccc;
}

/**====== Chatting css end ======**/
@media only screen and (min-width: 1367px) and (max-width: 1700px) {
  .timeline-dot {

    &:before,
    &:after {
      left: 34px;
    }

    .social-timelines:before {
      left: 37px;
    }

    .social-timelines-left:after {
      right: -30px;
    }

    .timeline-right .card {
      margin-left: 30px;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .to-do-list {
    margin-bottom: 15px;
  }

  .img-tabs img {
    width: 75px;
  }

  .card .card-block .sub-title {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1279px) and (max-width: 1500px) {
  .cd-horizontal-timeline {
    margin: 0 auto;
  }

  #chartdiv {
    height: 440px;
  }

  .social-card-1 .card-block-big {
    padding: 1.3em;
  }

  #morris-extra-area {
    height: 400px !important;
  }

  .widget-chat-box {

    .send-chat,
    .media .receive-chat,
    .receive-chat {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .similar-job .media {
    display: inline-block;

    .media-body {
      margin-top: 20px;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1258px) {
  .user-head {
    text-align: center;

    .user-face {
      margin: 0 auto;
    }

    .user-name {
      display: none;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .timeline-dot {
    .card {
      margin-left: 50px;
    }

    &:after,
    &:before {
      left: 35px;
    }

    .social-timelines:before {
      left: 38px;
    }

    .social-timelines-left:after {
      right: -50px;
    }
  }
}

@media only screen and (max-width: 1199px) {
  #design-wizard .steps li a {
    padding: 1em 0.5em;
  }

  .blog-page-card {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  #draggablePanelList [class*="col-"] {
    .card-sub {
      margin-bottom: 30px;
    }

    &:last-child .card-sub {
      margin-bottom: 0px;
    }
  }

  .user-head {
    justify-content: center;
  }

  .mail-box-head {
    text-align: center;

    form {
      float: inherit;
    }
  }

  #external-events h6 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .just_edit .input-group {
    width: 100% !important;
  }

  .dotted-line-theme .ibtn_container {
    top: 10px;
    position: inherit;
  }

  .users-card [class*="col-"] {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .timeline-btn {
    text-align: center;
    width: 100%;
    right: 0;
  }

  .social-timeline-left {
    position: inherit;
    top: 0;
    margin-right: 0;
  }

  .timeline-icon {
    position: absolute;
    margin-left: 0;
  }

  .social-timelines:before {
    left: 35px;
  }

  .timeline-dot {

    &:after,
    &:before {
      left: 32px;
    }
  }

  .user-box {
    .social-client-description {
      border-bottom: 1px solid #ddd;
    }

    .f-right {
      float: none;
      display: block;
    }
  }

  .main-timeline {
    padding: 10px;

    &:before,
    &:after {
      margin-left: -94px;
      margin-top: 25px;
      display: none;
    }
  }

  .cd-timeline-content::before {
    top: 15px;
  }

  .cd-date {
    display: block;
    padding-left: 20px !important;
    padding-top: 0 !important;
  }

  .cd-details {
    display: block;
    padding-left: 20px !important;
    padding-top: 0 !important;
    margin-bottom: 20px;
  }

  .cd-timeline-content p {
    margin-bottom: 0;
  }

  .clock-widget .basic-alarm {
    display: none;
  }

  .weather-card-2 {
    .weather-card-temp {
      padding: 0;
    }

    .card-footer {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .user-activity-card .card-block-big {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 992px) {

  .pcoded-main-container {
    margin-top: 80px !important;
  }
}

@media only screen and (max-width: 992px) {
  .main-body .page-wrapper .align-items-end {
    .page-header-title {
      text-align: center;
      display: block;

      i {
        float: none;
        margin-bottom: 10px;
      }
    }

    body .pcoded[vertical-placement="right"] .main-body {
      direction: ltr;
    }

    .page-header-breadcrumb {
      width: 100%;
      text-align: center;
      margin-top: 10px;
      display: block;
    }
  }

  .page-header-breadcrumb .breadcrumb-item {
    display: inline-block;
    float: none;
  }

  .messages-content {
    border-right: none;
  }

  .card-block [class*="col-"] {

    &:nth-last-child(-n + 3) .card.user-card,
    &:last-child .basic-list {
      margin-bottom: 0;
    }
  }

  #footer-search tfoot .form-control,
  #footer-select tfoot .form-control,
  #form-input-table .form-control,
  .search-api .form-control,
  #dt-live-dom .form-control {
    width: 80%;
  }

  .card-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .header-navbar {
    position: fixed !important;
    z-index: 1209;

    &[pcoded-header-position="relative"] {
      position: fixed !important;
      top: 0;
    }

    .navbar-wrapper {
      .navbar-container {
        margin-left: 0;
      }

      .navbar-logo {
        width: 50% !important;

        a img {
          display: block !important;
        }

        .mobile-menu {
          display: block;
          position: absolute;
          left: 20px;
          top: 23px;
          right: auto;
        }

        .mobile-search {
          display: block;
          position: absolute;
          left: 70px;
          top: 30px;
        }

        .mobile-options {
          display: block;
          position: absolute;
          right: 30px;
        }
      }
    }
  }

  .btn-blog {
    text-align: center;
  }

  .animation-image img {
    margin: 20px auto 0;
  }

  .animation-type {
    text-align: center;
  }

  .nav-tabs.md-tabs.tab-timeline li a {
    font-size: 16px;
  }

  .widget-card-user .card-user {
    text-align: center;
  }
}

@media only screen and (min-width: 992px) {
  .btn-blog {
    text-align: right;
  }

  .basic-list,
  .card-block [class*="col-"]:nth-last-child(-n + 3) .card.user-card {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) {
  #draggableWithoutImg [class*="col-"]:last-child .m-t-20 {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 872px) {
  .author-details .header-right li {
    margin-right: 40px;
  }

  .recent-candidate-card .recent-contain {
    margin-left: 0;
  }

  .author-details img~ {

    .dot1,
    .dot2 {
      top: 30%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .card-block.list-tag ul li:last-child {
    margin-bottom: 20px;
  }

  .inline-order-list {
    margin-top: 0;
  }

  h4 {
    font-size: 1rem;
    font-weight: 600;
  }

  .recent-candidate-card .recent-contain {
    margin-left: 0;
  }

  .mail-body-content .form-group:nth-child(2) {
    .form-control {
      margin-bottom: 20px;
    }

    .col-md-6:last-child .form-control {
      margin-bottom: 0;
    }
  }

  .card h5 {
    font-size: 14px;
  }

  .header-navbar .navbar-wrapper .navbar-logo {
    display: flex;

    span {
      display: none;
    }

    a {
      display: inline-block;
    }

    .mobile-options {
      top: 30px;
    }
  }

  body.header-fixed .main-body .page-wrapper {
    padding-bottom: 0;
    padding-top: 15px;
  }

  .card-header-right i:nth-child(n + 2) {
    display: none;
  }

  .dataTables_paginate .pagination {
    display: inherit;
    text-align: center;
    display: inline-block;
    margin-top: 10px !important;
  }

  div.dataTables_wrapper div.dataTables_info {
    display: inherit;
  }

  .wizard>.steps>ul>li {
    float: inherit;
    width: 100%;
  }

  .gallery-page .grid {
    padding: 0;
  }

  figure.effect-steve h2 {
    margin-top: 0;
  }

  .cover-profile .profile-bg-img {
    margin-bottom: 0;
  }

  .card-block.user-info {
    position: inherit;
    text-align: center;
    background-color: #e0e0e0;
    margin-bottom: 20px;
  }

  .user-info {

    .media-left,
    .media-body {
      display: block;
    }
  }

  .cover-btn {
    position: inherit;
    float: none;

    .btn:first-child {
      margin-right: 10px;
    }
  }

  .user-info .user-title {
    position: initial;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .nav-tabs.md-tabs.tab-timeline li a {
    font-size: 14px;
  }

  .user-content {
    h4 {
      font-size: 1rem;
    }

    h5 {
      margin-right: 0;
      font-size: 14px;
    }
  }

  .top-cap-text p {
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .user-card .btn span {
    font-size: 13px;
  }

  .toolbar-page [class*="col-"] {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .offline-404 {
    h1 {
      font-size: 80px;
    }

    h2 {
      font-size: 25px;
    }
  }

  .invoice-contact {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .lng-dropdown {
    display: none;
  }

  .group-widget .card-block-big {
    height: 110px;
  }

  .whether-card .card-footer {
    text-align: center;
  }

  .client-blocks ul li {
    font-size: 30px;
  }

  .widget-profile-card-1~.card-footer [class*="col-"] {
    border: none;
    margin: 0 auto;
  }

  .large-widget-card i {
    top: 15px;
  }

  .user-activity-card {
    .user-number {
      padding: 15px 0;
    }

    .m-b-40 {
      margin-bottom: 10px;
    }
  }

  .blur-user-card .blur-footer [class*="col-"] {
    margin: 0 auto;
    border: none;
  }

  .switchery {
    margin-bottom: 20px;
  }

  .switchery-large {
    margin-bottom: 0;

    ~.switchery-default {
      margin-bottom: 0;
    }
  }

  .switchery-small {
    margin-bottom: 0;
  }

  .js-dynamic-disable,
  .js-dynamic-enable {
    display: block;
    width: 100%;
  }

  .js-dynamic-disable {
    margin-bottom: 20px;
  }

  .radio-inline,
  .border-checkbox-section .border-checkbox-group,
  .checkbox-color {
    display: block;
  }

  .checkbox-fade {
    display: inline-block;
  }

  body {

    &.horizontal-icon .page-header,
    &.horizontal-icon-fixed .page-header {
      margin-top: 0;
    }

    &.horizontal-icon .pcoded .pcoded-navbar .pcoded-item>li>a>.pcoded-micon,
    &.horizontal-icon-fixed .pcoded .pcoded-navbar .pcoded-item>li>a>.pcoded-micon {
      display: inline-block !important;
      margin-right: 20px !important;
    }
  }

  .pagination {
    float: inherit;
    text-align: center;
    display: inline-block;
    margin-top: 10px !important;

    li {
      float: left;
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .similar-job .media {
    display: inline-block;

    .media-body {
      margin-top: 20px;
    }
  }

  .cal-event .inverse-card p:after,
  .login-card.auth-body,
  .signin-card.auth-body,
  .signup-card.auth-body {
    width: 100%;
  }

  .recent-candidate-card .recent-contain {
    margin-left: 10px;
  }

  .author-details .header-right {
    text-align: left;
    margin-top: 20px;
  }

  .post-new-contain textarea:focus {
    border: none;
  }

  .wizard {
    &.vertical> {

      .content,
      .steps {
        width: 100%;
      }
    }

    overflow: visible;
  }

  .comment-input .input-group {
    display: block;

    i {
      text-align: center;
      width: 100%;
    }

    input {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 640px) {
  .dt-button {
    margin-bottom: 5px;
  }

  .email-read {
    .card-header h6 {
      float: none;
      margin-top: 10px;
    }

    .media {
      display: inline-block;

      &.m-b-20 {
        margin-bottom: 0;
      }
    }

    .mail-img [class*="col-"] {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 993px) {
  .navbar {
    width: 100% !important;

    .navbar-logo {
      width: max-content !important;
    }
  }

  .error-500,
  .error-503,
  .error-404,
  .error-400,
  .error-403 {
    display: block !important;
    position: relative !important;
  }

  .header .login-card {
    margin-top: 80px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 573px) {
  .author-details .header-right li {
    margin-right: 20px;
    display: block;
    margin-top: 20px;
  }

  .md-tabs {
    display: initial;
  }

  .md-below-tabs.md-tabs {
    display: block;
  }

  .md-tabs .nav-item,
  .nav-tabs .slide {
    width: calc(100% / 1);
  }

  .toolbar-page [class*="col-"] .sub-title {
    text-align: center;
  }

  #task-container li {
    width: 100%;
  }

  .nav-tabs {
    &.tabs {
      display: inherit;
    }

    .nav-item.show .nav-link {
      border: none;
    }

    .nav-link {
      &.active {
        border: none;
      }

      text-align: center;
    }
  }

  .search-content {
    display: block;
    text-align: center;

    img {
      margin-top: 20px;
    }
  }

  .seacrh-header {
    margin-top: 0;

    .input-group {
      margin-bottom: 0;
    }
  }

  .payment-card .text-right {
    text-align: left !important;
  }

  .payment-tabs .md-tabs .nav-item+.nav-item {
    width: calc(100% / 1);
  }

  .jp-card {
    min-width: 225px !important;
  }

  .payment-form {
    padding: 0;
    padding-top: 20px;
  }

  .modal-mob-btn [class*="col-"]:first-child {
    margin-bottom: 20px;
  }

  .location-mob-btn .btn {
    margin-bottom: 20px;
    margin-right: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .job-cards .media {
    display: inline-block;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;

    p {
      margin-bottom: 0;
    }
  }

  .invoice-btn-group .m-r-20 {
    margin-right: 0;
  }

  .table-card .row-table {
    display: block;
    text-align: center;

    i {
      font-size: 28px;
      margin-bottom: 10px;
      display: inline-block;
    }

    .br {
      border: none;
      border-bottom: 1px solid #ccc;
    }

    >[class*="col-"] {
      display: block;
    }
  }

  .card-block-big {
    padding: 1em;

    #barchart,
    #barchart2 {
      display: inline-block;
      margin-bottom: 10px;
    }
  }

  .widget-primary-card [class*="col-"] {
    padding: 1em;

    h6 {
      margin-bottom: 0;
    }
  }

  .cd-horizontal-timeline .events-content em {
    text-align: left;
  }

  .events-content h2 {
    font-size: 20px;
  }

  .cd-horizontal-timeline {
    margin: 0 auto;
  }

  .social-widget-card i {
    top: 25px;
    font-size: 38px;
  }

  .widget-chat-box .card-header i {
    display: none;
  }

  .mobile-inputs [class*="col-"]:first-child {
    margin-bottom: 20px;
  }

  .pagination-lg .page-link {
    padding: 0.75rem 0.65rem;
  }

  .mobtn {
    padding: 10px;
  }
}

@media only screen and (max-width: 460px) {
  .fc-toolbar .fc-right .fc-button-group {
    margin-right: 0;
  }

  .social-msg a {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .bd-example-modal .modal-footer {
    justify-content: center;

    button {
      margin-right: 0;
    }
  }

  .invoice-total tbody {
    float: none;
  }

  .timeline-right .card {
    margin-left: 20px;
  }

  .user-box .media-left {
    padding-right: 10px;
  }

  figure.effect-moses {
    h2 {
      width: 60%;
      height: 70%;
      padding: 10px;
      font-size: 25px;
    }

    p {
      padding: 5px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .main-body .page-wrapper {
    padding: 10px;
  }

  .blog-page hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .blog-big-user {
    text-align: center;

    img {
      margin-bottom: 20px;
    }
  }

  .blog-s-reply .media .media-left {
    padding-right: 10px;
  }

  .j-forms {
    .divider-text {
      span {
        white-space: pre-line;
        display: block;
        line-height: 1.8;
      }

      height: auto;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .content {
      padding: 25px 10px 0;
    }
  }

  .comment-block .media-left {
    padding-right: 10px;
  }

  .breadcrumb-page .card {
    text-align: center;
  }

  div.dataTables_wrapper div.dataTables_filter input {
    width: 150px;
  }

  #footer-search tfoot .form-control,
  #footer-select tfoot .form-control,
  #form-input-table .form-control,
  .search-api .form-control,
  #dt-live-dom .form-control {
    width: 70%;
  }

  .mytooltip .tooltip-content {
    width: 310px;
    margin: 0 0 20px -90px;

    &:after {
      margin-left: -90px;
    }
  }

  .cd-timeline-content .media {
    display: inherit !important;
    padding-left: 20px;

    .media-left {
      text-align: center;
      margin: 10px auto;
    }
  }

  .summernote-page .modal-footer {
    display: inline-block;
    text-align: center;

    > :not(:first-child) {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .product-detail .txt-muted.d-inline-block {
    margin-top: 10px;

    ~.f-right {
      float: none;
      display: block;
      margin-top: 10px;
    }
  }

  .mob-product-btn {
    text-align: center;

    .btn-primary,
    .sweet-alert button.confirm {
      margin-right: 0;
      display: block;
      margin: 10px auto;
    }
  }

  .sweet-alert .mob-product-btn button.confirm,
  .mob-product-btn .wizard>.actions a,
  .wizard>.actions .mob-product-btn a {
    margin-right: 0;
    display: block;
    margin: 10px auto;
  }
}

@media only screen and (max-width: 420px) {
  #msform {
    width: 320px;
    margin: 20px auto;
  }
}

@media only screen and (min-width: 576px) and (max-width: 1366px) {
  .job-card.card-columns {
    column-count: 2;
  }
}

@media only screen and (max-width: 576px) {
  .header-navbar .navbar-wrapper .navbar-container {

    .header-notification .show-notification,
    .mega-menu-top .show-notification,
    .header-notification .profile-notification,
    .mega-menu-top .profile-notification {
      min-width: 280px;
      left: 0;
      right: auto;
      width: auto;
    }

    .header-notification .profile-notification,
    .mega-menu-top .profile-notification {
      left: auto;
      right: 0;
    }
  }

  .nav-tabs .nav-link.active {
    margin-bottom: 5px;
  }

  .md-tabs .nav-item a {
    /* box-shadow: 0 0 0 1px #d2cece; */
    margin-bottom: 5px;
  }

  .add-task-card .to-do-list h6 span {
    display: inline-block;
  }
}

.badge-top-left {
  margin-right: -10px;
  right: 100%;
  top: -3px;
}

.badge-top-right {
  margin-left: -10px;
  top: -3px;
}

.badge-bottom-left {
  margin-left: -33px;
  bottom: -12px;
  right: 97%;
}

.badge-bottom-right {
  margin-left: -10px;
  bottom: -12px;
}

.label-icon {
  position: relative;
}

.card .md-content h3 {
  background-color: #4680ff;
}

.bar-chart canvas,
.tri-state canvas {
  width: 100% !important;
}

.chart-js1 canvas {
  height: 100px !important;
}

.user-box .media-object,
.friend-box .media-object {
  border-radius: 5px;
}

/* bootstrap 4-beta css start*/

.pull-0 {
  right: auto;
}

.pull-1 {
  right: 8.333333%;
}

.pull-2 {
  right: 16.666667%;
}

.pull-3 {
  right: 25%;
}

.pull-4 {
  right: 33.333333%;
}

.pull-5 {
  right: 41.666667%;
}

.pull-6 {
  right: 50%;
}

.pull-7 {
  right: 58.333333%;
}

.pull-8 {
  right: 66.666667%;
}

.pull-9 {
  right: 75%;
}

.pull-10 {
  right: 83.333333%;
}

.pull-11 {
  right: 91.666667%;
}

.pull-12 {
  right: 100%;
}

.push-0 {
  left: auto;
}

.push-1 {
  left: 8.333333%;
}

.push-2 {
  left: 16.666667%;
}

.push-3 {
  left: 25%;
}

.push-4 {
  left: 33.333333%;
}

.push-5 {
  left: 41.666667%;
}

.push-6 {
  left: 50%;
}

.push-7 {
  left: 58.333333%;
}

.push-8 {
  left: 66.666667%;
}

.push-9 {
  left: 75%;
}

.push-10 {
  left: 83.333333%;
}

.push-11 {
  left: 91.666667%;
}

.push-12 {
  left: 100%;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-sm-0 {
    right: auto;
  }

  .pull-sm-1 {
    right: 8.333333%;
  }

  .pull-sm-2 {
    right: 16.666667%;
  }

  .pull-sm-3 {
    right: 25%;
  }

  .pull-sm-4 {
    right: 33.333333%;
  }

  .pull-sm-5 {
    right: 41.666667%;
  }

  .pull-sm-6 {
    right: 50%;
  }

  .pull-sm-7 {
    right: 58.333333%;
  }

  .pull-sm-8 {
    right: 66.666667%;
  }

  .pull-sm-9 {
    right: 75%;
  }

  .pull-sm-10 {
    right: 83.333333%;
  }

  .pull-sm-11 {
    right: 91.666667%;
  }

  .pull-sm-12 {
    right: 100%;
  }

  .push-sm-0 {
    left: auto;
  }

  .push-sm-1 {
    left: 8.333333%;
  }

  .push-sm-2 {
    left: 16.666667%;
  }

  .push-sm-3 {
    left: 25%;
  }

  .push-sm-4 {
    left: 33.333333%;
  }

  .push-sm-5 {
    left: 41.666667%;
  }

  .push-sm-6 {
    left: 50%;
  }

  .push-sm-7 {
    left: 58.333333%;
  }

  .push-sm-8 {
    left: 66.666667%;
  }

  .push-sm-9 {
    left: 75%;
  }

  .push-sm-10 {
    left: 83.333333%;
  }

  .push-sm-11 {
    left: 91.666667%;
  }

  .push-sm-12 {
    left: 100%;
  }

  .offset-sm-0 {
    margin-left: 0%;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-md-0 {
    right: auto;
  }

  .pull-md-1 {
    right: 8.333333%;
  }

  .pull-md-2 {
    right: 16.666667%;
  }

  .pull-md-3 {
    right: 25%;
  }

  .pull-md-4 {
    right: 33.333333%;
  }

  .pull-md-5 {
    right: 41.666667%;
  }

  .pull-md-6 {
    right: 50%;
  }

  .pull-md-7 {
    right: 58.333333%;
  }

  .pull-md-8 {
    right: 66.666667%;
  }

  .pull-md-9 {
    right: 75%;
  }

  .pull-md-10 {
    right: 83.333333%;
  }

  .pull-md-11 {
    right: 91.666667%;
  }

  .pull-md-12 {
    right: 100%;
  }

  .push-md-0 {
    left: auto;
  }

  .push-md-1 {
    left: 8.333333%;
  }

  .push-md-2 {
    left: 16.666667%;
  }

  .push-md-3 {
    left: 25%;
  }

  .push-md-4 {
    left: 33.333333%;
  }

  .push-md-5 {
    left: 41.666667%;
  }

  .push-md-6 {
    left: 50%;
  }

  .push-md-7 {
    left: 58.333333%;
  }

  .push-md-8 {
    left: 66.666667%;
  }

  .push-md-9 {
    left: 75%;
  }

  .push-md-10 {
    left: 83.333333%;
  }

  .push-md-11 {
    left: 91.666667%;
  }

  .push-md-12 {
    left: 100%;
  }

  .offset-md-0 {
    margin-left: 0%;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-lg-0 {
    right: auto;
  }

  .pull-lg-1 {
    right: 8.333333%;
  }

  .pull-lg-2 {
    right: 16.666667%;
  }

  .pull-lg-3 {
    right: 25%;
  }

  .pull-lg-4 {
    right: 33.333333%;
  }

  .pull-lg-5 {
    right: 41.666667%;
  }

  .pull-lg-6 {
    right: 50%;
  }

  .pull-lg-7 {
    right: 58.333333%;
  }

  .pull-lg-8 {
    right: 66.666667%;
  }

  .pull-lg-9 {
    right: 75%;
  }

  .pull-lg-10 {
    right: 83.333333%;
  }

  .pull-lg-11 {
    right: 91.666667%;
  }

  .pull-lg-12 {
    right: 100%;
  }

  .push-lg-0 {
    left: auto;
  }

  .push-lg-1 {
    left: 8.333333%;
  }

  .push-lg-2 {
    left: 16.666667%;
  }

  .push-lg-3 {
    left: 25%;
  }

  .push-lg-4 {
    left: 33.333333%;
  }

  .push-lg-5 {
    left: 41.666667%;
  }

  .push-lg-6 {
    left: 50%;
  }

  .push-lg-7 {
    left: 58.333333%;
  }

  .push-lg-8 {
    left: 66.666667%;
  }

  .push-lg-9 {
    left: 75%;
  }

  .push-lg-10 {
    left: 83.333333%;
  }

  .push-lg-11 {
    left: 91.666667%;
  }

  .push-lg-12 {
    left: 100%;
  }

  .offset-lg-0 {
    margin-left: 0%;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-xl-0 {
    right: auto;
  }

  .pull-xl-1 {
    right: 8.333333%;
  }

  .pull-xl-2 {
    right: 16.666667%;
  }

  .pull-xl-3 {
    right: 25%;
  }

  .pull-xl-4 {
    right: 33.333333%;
  }

  .pull-xl-5 {
    right: 41.666667%;
  }

  .pull-xl-6 {
    right: 50%;
  }

  .pull-xl-7 {
    right: 58.333333%;
  }

  .pull-xl-8 {
    right: 66.666667%;
  }

  .pull-xl-9 {
    right: 75%;
  }

  .pull-xl-10 {
    right: 83.333333%;
  }

  .pull-xl-11 {
    right: 91.666667%;
  }

  .pull-xl-12 {
    right: 100%;
  }

  .push-xl-0 {
    left: auto;
  }

  .push-xl-1 {
    left: 8.333333%;
  }

  .push-xl-2 {
    left: 16.666667%;
  }

  .push-xl-3 {
    left: 25%;
  }

  .push-xl-4 {
    left: 33.333333%;
  }

  .push-xl-5 {
    left: 41.666667%;
  }

  .push-xl-6 {
    left: 50%;
  }

  .push-xl-7 {
    left: 58.333333%;
  }

  .push-xl-8 {
    left: 66.666667%;
  }

  .push-xl-9 {
    left: 75%;
  }

  .push-xl-10 {
    left: 83.333333%;
  }

  .push-xl-11 {
    left: 91.666667%;
  }

  .push-xl-12 {
    left: 100%;
  }

  .offset-xl-0 {
    margin-left: 0%;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

/* bootstrap 4-beta css end*/

/* components/advance/notifications*/

#toasty .toast {
  width: auto;
  height: 55px;

  .close-button {
    top: calc(50% - 12px);
    opacity: 1;
  }

  .toast-text {
    padding: 5px 35px 5px 60px;
  }
}

/* components/advance/notify*/

.small-icon .icon {
  width: auto !important;
  height: auto !important;
}

.no-icon .icon {
  display: none;
}

.simple-notification-wrapper {
  z-index: 10001 !important;
}

/* components/basic/accordion */

.squeezebox {
  .sb-item {
    /*overflow: hidden;*/
  }

  .sb-item-head a {
    cursor: pointer;
    display: block;
  }

  .sb-item {
    .sb-item-head a .toggle-icon {
      display: block;
      float: right;
      height: 16px;
      position: relative;
      transform: rotate(45deg);
      transition: 0.3s transform;
      width: 16px;
    }

    &.is-collapsed .sb-item-head a .toggle-icon {
      transform: rotate(0);
    }
  }

  .sb-item-head a .toggle-icon {
    &:before {
      background: #000000;
      content: " ";
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      width: 1px;
    }

    &:after {
      background: #000000;
      content: " ";
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      width: 1px;
      transform: rotate(90deg);
    }
  }

  .sb-item {
    .sb-item-body {
      height: 0;
      /*overflow: hidden;*/
      transition: 0.5s height;

      .inner {
        padding: 10px 0 0 0;
      }
    }

    .sb-item-head a .toggle-icon {
      display: none;
    }
  }
}

.sb-item-head a {

  &:focus,
  &:hover {
    color: #014c8c !important;
  }
}

.sb-item {
  &.is-collapsed .sb-item-head>a {
    color: #000;
  }

  .sb-item-head>a {
    color: #014c8c;
  }

  &.is-collapsed .sb-item-head {
    pointer-events: auto;
  }

  .sb-item-head {
    pointer-events: none;
  }
}

.scale-accordion .sb-item .sb-item-head {
  pointer-events: auto;
}

/* components/basic/generic-class */

.md-tabs .nav-link {
  &.active {
    border-bottom: 4px solid #0073aa;
  }

  transition: all 0.4s ease-in-out;
  border-bottom: 4px solid transparent;
}

.tab-card {
  .md-tabs {
    position: relative;
    top: -76px;
  }

  .card-header {
    border-bottom: 0px;
  }
}

.md-tabs .nav-link.active {
  border-bottom: 4px solid #0073aa;

  &:focus,
  &:hover {
    border-bottom: 4px solid #0073aa;
  }
}

/* components/basic/other */

.pager .btn a {
  color: #fff !important;
}

.pagination-center {
  justify-content: center;
}

.pagination-left {
  justify-content: flex-start;
}

.pagination-right {
  justify-content: flex-end;
}

/* components/basic/tabs */
/* below tab bootstrap start */

.below-tabs {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);

  .nav-tabs,
  .tab-content {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .nav-tabs {
    .nav-link {
      border: 1px solid transparent;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    border-top: 1px solid #ddd;
    border-bottom: transparent;

    .nav-item.show .nav-link,
    .nav-link.active {
      color: #464a4c;
      background-color: #fff;
      border-color: #fff #ddd #ddd;
      margin-top: -1px;
    }
  }
}

/* below tab bootstrap end */

/* tab material start */

.md-tabs {
  .nav-link {
    &.active {
      border-bottom: 4px solid #0073aa;
      border-bottom: 4px solid #0073aa;

      &:focus,
      &:hover {
        border-bottom: 4px solid #0073aa;
      }
    }

    transition: all 0.4s ease-in-out;
    border-bottom: 4px solid transparent;
  }

  &.md-below-tabs {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);

    .nav-tabs,
    .tab-content {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    .nav-tabs,
    .nav-item:first-child {
      border-top: 1px solid #ddd;
      border-bottom: 0px solid transparent;
      margin-top: -1px;
    }

    .nav-link {
      &.active {
        border-top: 4px solid #0073aa;
        border-top: 4px solid #0073aa;
        border-bottom: 0px solid transparent;

        &:focus,
        &:hover {
          border-top: 4px solid #0073aa;
          border-bottom: 0px solid transparent;
        }
      }

      transition: all 0.4s ease-in-out;
      border-top: 0px solid transparent;
    }

    .nav-tabs .nav-link {
      &:hover {
        border-top: 4px solid #ddd;
        border-bottom: 0px solid transparent;
      }

      &.active:hover {
        border-top: 4px solid #0073aa;
      }
    }
  }

  &.md-left-tabs {
    .nav-item {
      width: 100%;

      &:first-child {
        border-bottom: 0px;
      }

      .nav-link {
        border: 0px solid transparent;
        padding: 10px 0 !important;
        margin: 10px 0;
      }
    }

    .nav-link.active {
      border-left: 4px solid #0073aa;
      border-bottom: 0px solid transparent;

      &:focus,
      &:hover {
        border-left: 4px solid #0073aa;
        border-bottom: 0px solid transparent;
      }
    }

    .nav-item .nav-link.active {
      border: 0px solid transparent;
      border-left: 4px solid #0073aa;
    }

    .nav-tabs {
      .nav-link {
        &.active:hover {
          border-left: 4px solid #0073aa;
        }

        &:hover {
          border: 0px solid transparent;
          border-left: 4px solid #ddd;
        }
      }

      border-bottom: 0px solid transparent;
      display: table-cell;
      min-width: 120px;
      vertical-align: top;
      width: 150px;
    }

    .tab-content {
      display: table-cell;
      top: 0;
      position: absolute;
      left: 150px;
    }
  }

  &.md-right-tabs {
    .nav-item {
      width: 100%;

      &:first-child {
        border-bottom: 0px;
      }

      .nav-link {
        border: 0px solid transparent;
        padding: 10px 0 !important;
        margin: 10px 0;
      }
    }

    .nav-link.active {
      border-right: 4px solid #0073aa;
      border-bottom: 0px solid transparent;

      &:focus,
      &:hover {
        border-right: 4px solid #0073aa;
        border-bottom: 0px solid transparent;
      }
    }

    .nav-item .nav-link.active {
      border: 0px solid transparent;
      border-right: 4px solid #0073aa;
    }

    .nav-tabs {
      .nav-link {
        &.active:hover {
          border-right: 4px solid #0073aa;
        }

        &:hover {
          border: 0px solid transparent;
          border-right: 4px solid #ddd;
        }
      }

      border-bottom: 0px solid transparent;
      display: table-cell;
      min-width: 120px;
      vertical-align: top;
      width: 150px;
      float: right;
    }

    .tab-content {
      display: table-cell;
      top: 0;
      position: absolute;
      right: 150px;
    }
  }

  &.md-image-tab {
    .nav-link {
      position: relative;
    }

    .nav-item,
    .nav-link,
    .nav-tabs {
      border: 0px solid transparent !important;
    }

    img {
      width: 100px;
      border: 4px solid #0073aa;

      +.quote {
        position: absolute;
        height: 25px;
        width: 25px;
        border-radius: 100%;
        bottom: 10px;
        right: 70px;
      }
    }

    .quote i {
      border-radius: 50%;
      padding: 3px 5px;
    }

    .nav-link {
      opacity: 0.5;

      &.active {
        opacity: 1;
      }
    }
  }
}

/* tab material end */

/* below tab material start */

/* below tab material end */

/* left tab material start */

@media only screen and (max-width: 768px) {
  .md-tabs.md-left-tabs {
    .nav-tabs {
      width: 100%;
      display: block;
    }

    .tab-content {
      display: block;
      position: relative;
      left: 0;
    }
  }
}

/* left tab material end */

/* right tab material start */

@media only screen and (max-width: 768px) {
  .md-tabs.md-right-tabs {
    .nav-tabs {
      width: 100%;
      display: block;
    }

    .tab-content {
      display: block;
      position: relative;
      left: 0;
    }
  }
}

/* right tab material end */

/* image tab material start */

/* image tab material end */

/* components/forms/advance-elements */

.switch {
  vertical-align: middle !important;
}

.ng2-tag-input {
  border-bottom: 1px solid #0073aa !important;
  border: 1px solid #0073aa;
  padding: 5px !important;
}

.tag__text {
  line-height: 2;
  font-size: 11px;
  font-weight: 600;
}

delete-icon svg {
  height: 13px !important;
  line-height: 1;
  vertical-align: top !important;
  margin-top: 5px;
}

.ng2-tags-container tag {
  padding: 0 5px !important;
  height: 22px !important;
}

delete-icon:hover {
  transform: none !important;
}

.ng2-tag-input__text-input {
  height: auto !important;
}

.bootstrap tag {
  background: #4680ff !important;
}

/* components/forms/advance-validations */

.tooltip-form {
  position: relative;
}

.tooltip-error {
  position: absolute;
  right: 30px;
  top: 7px;
}

/* components/forms/picker */
/* bootstrap date-picker start */

.custom-range {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;

  &.focused {
    background-color: #e6e6e6;
  }

  &.range,
  &:hover {
    background-color: #0275d8;
    color: white;
  }
}

.faded {
  opacity: 0.5;
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  border-radius: 0.25rem;
  display: inline-block;
  width: 2rem;

  &:hover,
  &.focused {
    background-color: #e6e6e6;
  }
}

.weekend {
  background-color: #999;
  border-radius: 1rem;
  color: white;
}

.hidden {
  display: none;
}

@media (min-width: 576px) {
  .form-inline .form-group {
    display: block;
    flex: 240px;
  }
}

/* bootstrap date-picker end */

/* material date-picker start */

.md-date-pic {
  position: relative;
  width: 20.5em;
  max-width: 100%;
  border-bottom: 2px solid #ddd;

  .cal-cont {
    padding: 0.5rem 0;
    width: 100%;
  }

  .datepicker {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.datepicker__calendar {
  top: 2.5em !important;
  max-width: 100%;
  height: auto !important;
  z-index: 1025 !important;
}

@media only screen and (max-width: 573px) {

  .datepicker__calendar__label,
  .datepicker__calendar__month__day {
    width: 1.4rem !important;
    height: 1.4rem !important;
    line-height: 1.4rem !important;
  }
}

.datepicker .datepicker__input {
  border: none !important;
  width: 100%;
  background-color: transparent !important;
  height: 100%;
}

.cal-icon {
  position: absolute;
  right: 15px;
  z-index: 0;
  top: 15px;
}

.datepicker__calendar__cancel {
  display: none;
}

/* material date-picker end */

/* color-picker start */

#changeme {
  font-size: 30px;
  font-weight: bolder;
  cursor: pointer;
}

.cmyk {
  margin-left: 11px;

  div {
    height: 72px;
    width: 72px;
    line-height: 72px;
    text-align: center;
    float: left;
  }

  span {
    font-weight: bolder;
    text-shadow: 1px 1px 2px #bbb;
  }
}

#color-comparator {
  button {
    height: 26px;
    width: 26px;
    display: block;
    float: left;
    border: none;
    margin: 0;
    padding: 0;
    border: 2px solid #888;
  }

  >input {
    height: 26px;
    width: 182px;
    display: block;
    float: left;
    border: none;
    margin: 0;
    padding: 0;
  }
}

.array-colors-element {
  width: 100px;
  height: 25px;
  margin-bottom: 15px;
}

/* color-picker end */

/* components/forms/select start */

ng-select.ng-select>div {
  border: none;

  >div.single {
    background-color: #0073aa;
    color: #fff;
    padding: 8px 30px 8px 20px;
    border-radius: 4px;
    height: auto;
  }

  &.disabled>div.single {
    background-color: #aaa;
    color: #fff;
  }

  >div.single>div {

    &.clear:hover,
    &.toggle:hover {
      background-color: transparent;
    }

    &.clear {
      color: #fff;
    }

    &.toggle {
      color: #fff;
      font-size: 9px;
    }

    &.clear,
    &.toggle {
      width: 0;
    }
  }
}

.ng-select select-dropdown .highlighted {
  background-color: #0073aa;
}

.famfamfam-flags {
  display: inline-block;
  margin-right: 6px;
  width: 16px;
}

ng-select.ng-select>div>div {
  &.multiple {
    border: 1px solid #aaa;
    padding: 6px 10px 6px 10px !important;
    border-radius: 4px;

    >div.option {
      span.deselect-option {
        color: #fff !important;
        font-size: 10px !important;
        vertical-align: middle;
        font-weight: bold;
        margin-right: 2px;
      }

      background-color: #0073aa !important;
      border: 1px solid #0073aa !important;
      padding: 5px 15px !important;
      color: #fff !important;
      margin: 0 2px 2px !important;
    }
  }

  &.single>div {
    &.placeholder {
      color: #fff;
    }

    &.clear {
      font-size: 10px;
      margin-right: 14px;
      font-weight: bold;
    }
  }
}

.tag-select {
  .bootstrap tag {
    padding: 5px 10px !important;
    height: auto !important;
    z-index: 0;
  }

  tag-input-form {
    padding: 6px 10px 6px 10px !important;
  }

  .ng2-tag-input {
    border-bottom: 1px solid #aaa !important;
    border: 1px solid #aaa;
    padding: 5px !important;
    border-radius: 4px;
  }
}

/* components/forms/select end */

/* components/tables/data-table start */

ngx-datatable.data-table {
  border: 1px solid #eceeef;
}

.data-table {
  datatable-header {
    border-bottom: 2px solid #ccc;
  }

  datatable-footer {
    border-top: 2px solid #ccc;
    padding: 0 0.75rem;
    background-color: #eceeef;
    box-shadow: inset 0 2px 4px -2px rgba(0, 0, 0, 0.15);
  }

  .empty-row {
    padding: 0.75rem;
    text-align: center;
  }

  .datatable-row-wrapper:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  datatable-body-cell {
    padding: 0.75rem;

    +datatable-body-cell {
      border-left: 1px solid #eceeef;
    }
  }

  datatable-header-cell {
    .datatable-header-cell-label {
      padding: 0.75rem 30px 0.75rem 0.75rem;
    }

    +datatable-header-cell {
      border-left: 1px solid #eceeef;
    }

    &.sortable {

      &:before,
      &:after {
        font-family: "IcoFont" !important;
        position: absolute;
        top: 10px;
        color: #ccc;
        font-size: 20px;
      }
    }
  }
}

.datatable-header-cell-wrapper,
.datatable-header-cell-label {
  display: block;
}

.data-table {
  datatable-header-cell.sortable {
    &:after {
      content: "\eb53";
      right: 10px;
    }

    &:before {
      content: "\eb56";
      right: 15px;
    }

    &.sort-active {

      &.sort-desc:after,
      &.sort-asc:before {
        color: #666;
      }
    }
  }

  &.scroll .datatable-body,
  &.fullscreen .datatable-body {
    height: 500px !important;
  }

  datatable-scroller {
    width: auto !important;
  }
}

.datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 16px;

  .page-count {
    line-height: 50px;
    height: 50px;
    padding: 0 1.2rem;
  }

  .datatable-pager {
    margin: 0 10px;

    li {
      vertical-align: middle;

      &.disabled a {
        color: rgba(0, 0, 0, 0.26) !important;
        background-color: transparent !important;
      }

      &.active a {
        background-color: #0073aa;
        color: #fff;
      }
    }

    a {
      padding: 0 6px;
      border-radius: 3px;
      margin: 6px 3px;
      text-align: center;
      vertical-align: top;
      color: rgba(0, 0, 0, 0.54);
      text-decoration: none;
      vertical-align: bottom;

      &:hover {
        color: rgba(0, 0, 0, 0.75);
        background-color: rgba(158, 158, 158, 0.2);
      }
    }

    .datatable-icon-left,
    .datatable-icon-skip,
    .datatable-icon-right,
    .datatable-icon-prev {
      font-size: 20px;
      line-height: 20px;
      padding: 0 3px;
    }
  }
}

.datatable-icon-right:before {
  content: "\eb27";
  font-family: "icofont";
  font-style: normal;
}

.datatable-icon-skip:before {
  content: "\eb3a";
  font-family: "icofont";
  font-style: normal;
}

.datatable-icon-left:before {
  content: "\eb26";
  font-family: "icofont";
  font-style: normal;
}

.datatable-icon-prev:before {
  content: "\eb39";
  font-family: "icofont";
  font-style: normal;
}

.dt-desc {

  th,
  td {
    padding: 0.75rem;
  }

  padding: 0.45rem 50px;

  table {
    min-width: 400px;
  }
}

.datatable-row-detail {
  background-color: #f9f9f9;
  box-shadow: inset 0px 2px 9px -6px rgba(0, 0, 0, 0.68);
}

.expandable {

  datatable-body-cell+datatable-body-cell,
  datatable-header-cell+datatable-header-cell {
    width: calc(calc(100% - 50px) / 5) !important;
  }

  .datatable-body-row,
  .datatable-header-inner {
    width: 100% !important;
  }
}

.datatable-row-center,
.datatable-scroll,
.data-table.expandable datatable-scroller {
  width: 100% !important;
}

.ngx-datatable.data-table {

  &.single-selection .datatable-body-row.active:hover .datatable-row-group,
  &.multi-selection .datatable-body-row.active:hover .datatable-row-group,
  &.single-selection .datatable-body-row.active .datatable-row-group,
  &.multi-selection .datatable-body-row.active .datatable-row-group {
    background: #0073aa !important;
    color: #fff !important;
  }
}

.selection-cell .datatable-body-cell.active {
  background: #0073aa !important;
  color: #fff !important;
}

.selected-column label {
  margin-bottom: 5px;
}

.dt-chk {
  label {
    margin-bottom: 0;
  }

  .datatable-body-cell {
    line-height: 1 !important;
  }
}

.full-data-search {
  margin-left: 0.5em;
  display: inline-block;
  width: auto !important;
}

.full-data-show-entry {
  width: 75px;
  display: inline-block;
}

.full-data-table {
  .glyphicon {
    font-family: IcoFont;
    position: absolute;
    right: 14px;
    font-size: 20px;
    top: 9px;

    &.glyphicon-triangle-bottom:before {
      content: "\eb53";
    }

    &.glyphicon-triangle-top:before {
      content: "\eb56";
    }
  }

  th {
    position: relative;
  }
}

/* components/tables/data-table end */

/* components/task end */

.card-body .new-task .fade-in-primary .cr {
  margin-top: 0;
}

.task-right {
  .accordion-block p {
    margin-bottom: 1rem;
  }

  .media-left {
    padding-right: 20px;
    padding-left: 2px;
  }
}

.filter-bar .navbar .navbar-nav .dropdown-menu {
  position: absolute;
}

/* components/task end */

/* components/editor/quill editor */

.quill-editor {
  min-height: 16em;
  max-height: 20em;
  overflow-y: auto;
}

/* quill editor end*/

.dropdown-toggle.no-icon::after {
  display: none;
}

.card .card-body.assign-user .dropdown-menu {
  top: 100%;
}

/* components/file-upload/ start */

ngb-progressbar .progress-bar {
  height: 1rem !important;
  color: #fff;
}

.ngb-drop-zone {
  min-height: 1.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: dashed 1px;
  border-radius: 0.125rem;
}

/* components/file-upload/ end */

/* map start */

agm-map,
vector_map {
  width: 100%;
  height: 350px;
  display: flex;
}

.jqvmap-label,
#jqstooltip {
  position: absolute;
  display: none;
  border: solid 1px #cdcdcd;
  border-radius: 3px;
  background: #292929;
  color: white;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px;
}

.jqvmap-zoomin {
  position: absolute;
  left: 10px;
  border-radius: 3px;
  background: #292929;
  padding: 4px 10px;
  color: white;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  box-sizing: content-box;
  top: 10px;
}

.jqvmap-zoomout {
  position: absolute;
  left: 10px;
  border-radius: 3px;
  background: #292929;
  padding: 4px 10px;
  color: white;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  box-sizing: content-box;
  top: 50px;
}

/* map end */

/* widget */

.widget {
  &.peity-chart .peity {
    width: 100%;
    height: 75px;
  }

  &.card-body .new-task .fade-in-primary .cr {
    margin-top: 12px;
  }
}

.ui.table {

  td.active,
  tr.active {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.87) inset;
    background: #e0e0e0 !important;
    color: #fff !important;
  }
}

.widget {
  .calendar {

    &:focus,
    &:active {
      outline: none !important;
    }
  }

  .table {

    td.active,
    tr.active {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.87) inset;
      background: #0073aa !important;
      color: #fff !important;
      /*border-radius: 5px;*/
    }
  }

  td.link {

    &.today,
    &.active.today {
      background-color: #4680ff !important;
      color: #fff !important;
      text-align: center;
      /*border-radius: 5px;*/
    }
  }

  .table {
    th {
      width: calc(100% / 14);
      border: none !important;
      text-align: center;
    }

    thead tr+tr {
      border-top: 1px solid #ededed;
      border-bottom: 3px solid #ededed;
    }

    td {
      border-top: 1px solid transparent;
      text-align: center;
      margin: 10px 5px;
      padding: 6px;
      width: 35px;
      height: 35px;
      display: inline-block;
      border-radius: 50%;

      &.link {
        cursor: pointer;
      }
    }
  }

  .day {
    color: #757575;
  }

  .table {
    tr {
      justify-content: space-evenly;
      display: flex;
    }

    &.month td {
      padding: 12px;
      width: 50px;
      height: 50px;
    }

    &.year td {
      padding: 12px;
      width: 50px;
      height: 50px;
      padding: 13px 10px;
    }

    &.day {
      td {

        &:first-child,
        &:last-child {
          color: #ff4040;
        }
      }

      th {

        &:first-child,
        &:last-child {
          color: #ff4040;
        }
      }
    }

    thead tr:first-child {
      th {
        color: #000;
        width: 100%;
        font-size: 25px;
        color: #757575 !important;
      }

      background-color: #fafafa;
    }

    td.adjacent.disabled {
      opacity: 0.3;
    }
  }

  table tbody tr:last-child td.today {
    width: 20%;
    border-radius: 6px;
    background-color: #0073aa !important;
  }
}

.pcoded[vertical-nav-type="collapsed"] .pcoded-navigatio-lavel {
  display: none;
}

/* animation start */

.main-img {
  position: static !important;
}

/* animation end */

/* authentication start */

.auth-fluid {
  margin: 0 auto;
}

body .pcoded[vertical-placement="right"] {

  .showChat_inner,
  .users {
    right: 100%;
    left: auto;
  }
}

.to-do-list {
  .delete_todolist {
    opacity: 0;
    color: #ccc;
    font-size: 24px;
    transition: all 0.4s ease-in-out;
  }

  &:hover .delete_todolist {
    opacity: 1;
  }
}

#realtimeupdate {
  height: 190px;
}

.dribble-card .card-header,
.fb-card .card-header,
.twitter-card .card-header {
  padding-top: 15px;
  padding-bottom: 15px;
}

.dribble-card .card-header i,
.fb-card .card-header i,
.twitter-card .card-header i {
  border-radius: 50%;
  color: #fff;
  padding: 7px 10px;
  font-size: 25px;
  margin-right: 20px;
  top: -10px;
  position: relative;
}

.dribble-card .card-header h5,
.fb-card .card-header h5,
.twitter-card .card-header h5 {
  color: #fff;
}

.dribble-card .card-header span,
.fb-card .card-header span,
.twitter-card .card-header span {
  font-weight: 100;
  color: #fff;
}

.dribble-card .card-header+.card-block,
.fb-card .card-header+.card-block,
.twitter-card .card-header+.card-block {
  padding-top: 20px;
}

.dribble-card .card-header+.card-block h2,
.fb-card .card-header+.card-block h2,
.twitter-card .card-header+.card-block h2 {
  font-weight: 600;
}

.dribble-card .card-header {
  background-color: #f178a7;

  i {
    background-color: #ec4a89;
  }
}

.fb-card .card-header {
  background-color: #4c70b9;

  i {
    background-color: #3b5997;
  }
}

.twitter-card .card-header {
  background-color: #74d1fc;

  i {
    background-color: #42c0fb;
  }
}

.dribble-card .card-block p,
.fb-card .card-block p,
.twitter-card .card-block p {
  margin-bottom: 0;
}

.table-hover tbody tr:hover {
  cursor: pointer;
}

.done-task {

  span,
  .captions {
    color: #919aa3;
    text-decoration: line-through;
  }
}

.header-navbar .navbar-wrapper .navbar-container .lng-dropdown img {
  width: 25px !important;
  height: 15px;
}

.pcoded #styleSelector {
  a>i {
    padding: 0;
    margin: 0;
    margin-right: 5px;
  }

  .btn {
    padding: 10px 18px;
  }

  .nav-link {
    padding: 0.5rem 1rem !important;
  }
}

.card-header-right {
  z-index: 100 !important;
}

.card-contact-box {
  .f-btn:hover {
    background-color: #f1f1f1;
  }

  img {
    border-radius: 5px;
  }
}

.client-map .client-detail img,
.widget-card-user .card-user img {
  border-radius: 5px;
}

.p-chat-user .slimScrollDiv {
  overflow: visible !important;
}

.model-btn-card ul li {
  display: inline-block;
}

.img-radius {
  border-radius: 5px;
}

.slimScrollDiv,
.sidebar,
.main-friend-list {
  height: calc(100vh - 155px) !important;
}

#styleSelector {

  .slimScrollDiv,
  .sidebar,
  .main-friend-list {
    height: calc(80vh - 335px) !important;
  }
}

/* dark layout css start*/

.pcoded[layout-type="dark"],
body.dark,
.pcoded[layout-type="dark"] a,
body.dark a {
  color: #fff;
}

.pcoded[layout-type="dark"] text,
body.dark text {
  fill: #fff;
}

.pcoded[layout-type="dark"] .card,
body.dark .card {
  background-color: #3a4057;
}

.pcoded[layout-type="dark"] .card code,
body.dark .card code {
  background-color: #444c67;
}

.pcoded[layout-type="dark"] .card .card-header h5,
body.dark .card .card-header h5,
.pcoded[layout-type="dark"] .card .card-header .card-header-right i,
body.dark .card .card-header .card-header-right i {
  color: #fff;
}

.pcoded[layout-type="dark"] .card .card-block code,
body.dark .card .card-block code {
  background-color: #444c67;
}

.pcoded[layout-type="dark"] .card .card-block .dropdown-menu,
body.dark .card .card-block .dropdown-menu {
  background-color: #3e455d;
}

.pcoded[layout-type="dark"] .card .card-footer,
body.dark .card .card-footer {
  background-color: #363c51;
}

.pcoded[layout-type="dark"] .dropdown-menu,
body.dark .dropdown-menu {
  background-color: #3e455d;
}

.pcoded[layout-type="dark"] .dropdown-menu .dropdown-item .dropdown-divider,
body.dark .dropdown-menu .dropdown-item .dropdown-divider {
  border-top: 1px solid #303548;
}

.pcoded[layout-type="dark"] .dropdown-menu .dropdown-item {

  &:focus,
  &:hover {
    background-color: #303548;
  }
}

body.dark .dropdown-menu .dropdown-item {

  &:focus,
  &:hover {
    background-color: #303548;
  }
}

.pcoded[layout-type="dark"] .sub-title,
body.dark .sub-title {
  color: #596285;
}

.pcoded[layout-type="dark"] .table {

  td,
  th {
    border-top: 1px solid #444c67;
  }
}

body.dark .table {

  td,
  th {
    border-top: 1px solid #444c67;
  }
}

.pcoded[layout-type="dark"] .table thead th,
body.dark .table thead th {
  border-bottom: 2px solid #444c67;
}

.pcoded[layout-type="dark"] .thumb-block,
body.dark .thumb-block {
  border: 1px solid #444c67;
}

.pcoded[layout-type="dark"] {

  .b-t-default,
  .b-t-theme,
  .post-new-footer,
  .version .nav li:first-child {
    border-top: 1px solid #444c67;
  }
}

body.dark {

  .b-t-default,
  .b-t-theme,
  .post-new-footer,
  .version .nav li:first-child {
    border-top: 1px solid #444c67;
  }
}

.pcoded[layout-type="dark"] {

  .b-b-default,
  .b-b-theme,
  .job-cards .media,
  .search-content {
    border-bottom: 1px solid #444c67;
  }
}

body.dark {

  .b-b-default,
  .b-b-theme,
  .job-cards .media,
  .search-content {
    border-bottom: 1px solid #444c67;
  }
}

.pcoded[layout-type="dark"] {

  .b-l-default,
  .b-l-theme {
    border-left: 1px solid #444c67;
  }
}

body.dark {

  .b-l-default,
  .b-l-theme {
    border-left: 1px solid #444c67;
  }
}

.pcoded[layout-type="dark"] {

  .b-r-default,
  .b-r-theme,
  .messages-content {
    border-right: 1px solid #444c67;
  }
}

body.dark {

  .b-r-default,
  .b-r-theme,
  .messages-content {
    border-right: 1px solid #444c67;
  }
}

.pcoded[layout-type="dark"] .progress,
body.dark .progress {
  background-color: #444c67;
}

.pcoded[layout-type="dark"] .btn-default,
body.dark .btn-default {
  background-color: #444c67;
  color: #fff;
}

.pcoded[layout-type="dark"] .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
body.dark .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #444c67 !important;
}

.pcoded[layout-type="dark"] .pcoded-main-container,
body.dark .pcoded-main-container {
  background-color: #444c67;
}

.pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .nav-right> {

  .header-notification:nth-child(2) .show-notification li:first-child:hover,
  .mega-menu-top:nth-child(2) .show-notification li:first-child:hover,
  .header-notification:nth-child(2) .profile-notification li:first-child:hover,
  .mega-menu-top:nth-child(2) .profile-notification li:first-child:hover {
    background-color: #303548 !important;
  }
}

body.dark .header-navbar .navbar-wrapper .navbar-container .nav-right> {

  .header-notification:nth-child(2) .show-notification li:first-child:hover,
  .mega-menu-top:nth-child(2) .show-notification li:first-child:hover,
  .header-notification:nth-child(2) .profile-notification li:first-child:hover,
  .mega-menu-top:nth-child(2) .profile-notification li:first-child:hover {
    background-color: #303548 !important;
  }
}

.pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container {

  .header-notification .profile-notification,
  .mega-menu-top .profile-notification,
  .header-notification .show-notification,
  .mega-menu-top .show-notification,
  .header-notification .profile-notification {
    background-color: #303548;
  }

  .mega-menu-top {

    .profile-notification,
    .show-notification,
    .profile-notification {
      background-color: #303548;
    }
  }
}

body.dark .header-navbar .navbar-wrapper .navbar-container {

  .header-notification .profile-notification,
  .mega-menu-top .profile-notification,
  .header-notification .show-notification,
  .mega-menu-top .show-notification,
  .header-notification .profile-notification {
    background-color: #303548;
  }

  .mega-menu-top {

    .profile-notification,
    .show-notification,
    .profile-notification {
      background-color: #303548;
    }
  }
}

.pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container {

  .header-notification .profile-notification a,
  .mega-menu-top .profile-notification a,
  .header-notification .show-notification a,
  .mega-menu-top .show-notification a,
  .header-notification .profile-notification a {
    color: #fff;
  }

  .mega-menu-top {

    .profile-notification a,
    .show-notification a,
    .profile-notification a {
      color: #fff;
    }
  }
}

body.dark .header-navbar .navbar-wrapper .navbar-container {

  .header-notification .profile-notification a,
  .mega-menu-top .profile-notification a,
  .header-notification .show-notification a,
  .mega-menu-top .show-notification a,
  .header-notification .profile-notification a {
    color: #fff;
  }

  .mega-menu-top {

    .profile-notification a,
    .show-notification a,
    .profile-notification a {
      color: #fff;
    }
  }
}

.pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container {

  .header-notification .row.profile-notification li:hover,
  .mega-menu-top .row.profile-notification li:hover,
  .header-notification .show-notification.row li:hover,
  .mega-menu-top .show-notification.row li:hover,
  .header-notification .row.profile-notification li:hover {
    background-color: transparent;
  }

  .mega-menu-top {

    .row.profile-notification li:hover,
    .show-notification.row li:hover,
    .row.profile-notification li:hover {
      background-color: transparent;
    }
  }
}

body.dark .header-navbar .navbar-wrapper .navbar-container {

  .header-notification .row.profile-notification li:hover,
  .mega-menu-top .row.profile-notification li:hover,
  .header-notification .show-notification.row li:hover,
  .mega-menu-top .show-notification.row li:hover,
  .header-notification .row.profile-notification li:hover {
    background-color: transparent;
  }

  .mega-menu-top {

    .row.profile-notification li:hover,
    .show-notification.row li:hover,
    .row.profile-notification li:hover {
      background-color: transparent;
    }
  }
}

.pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container {

  .header-notification .row.profile-notification li:hover .mega-menu-links>li:hover a,
  .mega-menu-top .row.profile-notification li:hover .mega-menu-links>li:hover a,
  .header-notification .show-notification.row li:hover .mega-menu-links>li:hover a,
  .mega-menu-top .show-notification.row li:hover .mega-menu-links>li:hover a,
  .header-notification .row.profile-notification li:hover .mega-menu-links>li:hover a {
    color: #4680ff;
  }

  .mega-menu-top {

    .row.profile-notification li:hover .mega-menu-links>li:hover a,
    .show-notification.row li:hover .mega-menu-links>li:hover a,
    .row.profile-notification li:hover .mega-menu-links>li:hover a {
      color: #4680ff;
    }
  }
}

body.dark .header-navbar .navbar-wrapper .navbar-container {

  .header-notification .row.profile-notification li:hover .mega-menu-links>li:hover a,
  .mega-menu-top .row.profile-notification li:hover .mega-menu-links>li:hover a,
  .header-notification .show-notification.row li:hover .mega-menu-links>li:hover a,
  .mega-menu-top .show-notification.row li:hover .mega-menu-links>li:hover a,
  .header-notification .row.profile-notification li:hover .mega-menu-links>li:hover a {
    color: #4680ff;
  }

  .mega-menu-top {

    .row.profile-notification li:hover .mega-menu-links>li:hover a,
    .show-notification.row li:hover .mega-menu-links>li:hover a,
    .row.profile-notification li:hover .mega-menu-links>li:hover a {
      color: #4680ff;
    }
  }
}

.pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container {

  .header-notification .profile-notification li:hover,
  .mega-menu-top .profile-notification li:hover,
  .header-notification .show-notification li:hover,
  .mega-menu-top .show-notification li:hover,
  .header-notification .profile-notification li:hover,
  .mega-menu-top .profile-notification li:hover {
    background-color: #444c67;
  }
}

body.dark .header-navbar .navbar-wrapper .navbar-container {

  .header-notification .profile-notification li:hover,
  .mega-menu-top .profile-notification li:hover,
  .header-notification .show-notification li:hover,
  .mega-menu-top .show-notification li:hover,
  .header-notification .profile-notification li:hover,
  .mega-menu-top .profile-notification li:hover {
    background-color: #444c67;
  }
}

.pcoded[layout-type="dark"] .header-navbar .morphsearch.open,
body.dark .header-navbar .morphsearch.open {
  background-color: #303548;
}

.pcoded[layout-type="dark"] .header-navbar .morphsearch.open .morphsearch-form,
body.dark .header-navbar .morphsearch.open .morphsearch-form {
  background-color: #444c67;
}

.pcoded[layout-type="dark"] .header-navbar .morphsearch.open .morphsearch-content,
body.dark .header-navbar .morphsearch.open .morphsearch-content {
  background-color: #303548;
}

.pcoded[layout-type="dark"] .header-navbar .morphsearch.open .morphsearch-content .dummy-media-object,
body.dark .header-navbar .morphsearch.open .morphsearch-content .dummy-media-object {
  background-color: #444c67;
}

.pcoded[layout-type="dark"] .p-chat-user,
body.dark .p-chat-user {
  border-left: 1px solid #1c1e29;
}

.pcoded[layout-type="dark"] .p-chat-user .userlist-box,
body.dark .p-chat-user .userlist-box {
  border-bottom: 1px solid #1c1e29;
}

.pcoded[layout-type="dark"] .p-chat-user .userlist-box .chat-header,
body.dark .p-chat-user .userlist-box .chat-header {
  color: #fff;
}

.pcoded[layout-type="dark"] .showChat_inner,
body.dark .showChat_inner {
  background-color: #444c67;
  border-left: 1px solid #1c1e29;
}

.pcoded[layout-type="dark"] .showChat_inner .chat-menu-content,
body.dark .showChat_inner .chat-menu-content {
  background-color: #303548;
}

.pcoded[layout-type="dark"] .showChat_inner .chat-menu-content:before,
body.dark .showChat_inner .chat-menu-content:before {
  border-right-color: #303548;
}

.pcoded[layout-type="dark"] .showChat_inner .chat-inner-header,
body.dark .showChat_inner .chat-inner-header {
  border-bottom: 1px solid #303548;
}

.pcoded[layout-type="dark"] .showChat_inner .chat-menu-reply,
body.dark .showChat_inner .chat-menu-reply {
  background-color: #1c1e29;
}

.pcoded[layout-type="dark"] .showChat_inner .chat-menu-reply:before,
body.dark .showChat_inner .chat-menu-reply:before {
  border-left-color: #1c1e29;
}

.pcoded[layout-type="dark"] .showChat_inner .chat-reply-box,
body.dark .showChat_inner .chat-reply-box {
  background-color: #444c67;
  border-top: 1px solid #303548;
}

.pcoded[layout-type="dark"] .form-control,
body.dark .form-control {
  background-color: #444c67;
  color: #fff;
}

.pcoded[layout-type="dark"] .main-body .page-wrapper .page-header-title h4,
body.dark .main-body .page-wrapper .page-header-title h4 {
  color: #fff;
}

.pcoded[layout-type="dark"] {

  .profile-comp-block input.dial,
  .view-card input.dial {
    color: #fff !important;
  }
}

body.dark {

  .profile-comp-block input.dial,
  .view-card input.dial {
    color: #fff !important;
  }
}

.pcoded[layout-type="dark"] .line-example,
body.dark .line-example {
  border-right: 1px solid #444c67;
}

.pcoded[layout-type="dark"] .marketing-card .table tbody .marketing-header,
body.dark .marketing-card .table tbody .marketing-header {
  background: #1c1e29;
}

.pcoded[layout-type="dark"] .unique-visitor-card .footer-card,
body.dark .unique-visitor-card .footer-card {
  background-color: #1c1e29;
}

.pcoded[layout-type="dark"] .user-detail-card .user-detail>div,
body.dark .user-detail-card .user-detail>div {
  border-bottom: 1px solid #444c67;
}

.pcoded[layout-type="dark"] .user-detail-card .user-detail>div:last-child,
body.dark .user-detail-card .user-detail>div:last-child {
  border-bottom: none;
}

.pcoded[layout-type="dark"] .client-map .client-contain h5,
body.dark .client-map .client-contain h5 {
  color: #fff;
}

.pcoded[layout-type="dark"] .client-map .client-card-box,
body.dark .client-map .client-card-box {
  border: 1px solid #444c67;
}

.pcoded[layout-type="dark"] .client-map .client-card-box .client-border,
body.dark .client-map .client-card-box .client-border {
  border-right: 1px solid #444c67;
}

.pcoded[layout-type="dark"] .client-map .client-card-box .client-border-card,
body.dark .client-map .client-card-box .client-border-card {
  border-top: 1px solid #444c67;
}

.pcoded[layout-type="dark"] .widget-card-user .card-footer,
body.dark .widget-card-user .card-footer {
  background-color: #1c1e29;
}

.pcoded[layout-type="dark"] .widget-card-user .card-footer .footer-menu+.footer-menu,
body.dark .widget-card-user .card-footer .footer-menu+.footer-menu {
  border-left: 2px solid #444c67;
}

.pcoded[layout-type="dark"] .card-contact-box .f-btn:hover,
body.dark .card-contact-box .f-btn:hover {
  background-color: #3a4057;
}

.pcoded[layout-type="dark"] .to-do-list,
body.dark .to-do-list {
  border-bottom: 1px solid #444c67;
}

.pcoded[layout-type="dark"] .widget-chat-box .media {

  .send-chat,
  .receive-chat {
    background-color: #444c67;
  }
}

body.dark .widget-chat-box .media {

  .send-chat,
  .receive-chat {
    background-color: #444c67;
  }
}

.pcoded[layout-type="dark"] .widget-chat-box .media {

  .send-chat:before,
  .receive-chat:before {
    border-right: 15px solid #444c67;
  }
}

body.dark .widget-chat-box .media {

  .send-chat:before,
  .receive-chat:before {
    border-right: 15px solid #444c67;
  }
}

.pcoded[layout-type="dark"] .widget-chat-box .media .receive-chat,
body.dark .widget-chat-box .media .receive-chat {
  background-color: #1c1e29;
}

.pcoded[layout-type="dark"] .widget-chat-box .media .receive-chat:before,
body.dark .widget-chat-box .media .receive-chat:before {
  border-left: 15px solid #1c1e29;
}

.pcoded[layout-type="dark"] .card-comment .card-block-small,
body.dark .card-comment .card-block-small {
  border-bottom: 1px solid #3a4057;
}

.pcoded[layout-type="dark"] .card-comment .card-block-small:hover,
body.dark .card-comment .card-block-small:hover {
  background-color: #3a4057;
}

.pcoded[layout-type="dark"] .alert {

  &.alert-danger,
  &.alert-default,
  &.alert-info,
  &.alert-primary,
  &.alert-success,
  &.alert-warning {
    background-color: #303548;
  }
}

body.dark .alert {

  &.alert-danger,
  &.alert-default,
  &.alert-info,
  &.alert-primary,
  &.alert-success,
  &.alert-warning {
    background-color: #303548;
  }
}

.pcoded[layout-type="dark"] .alert.background-default,
body.dark .alert.background-default {
  background-color: #e0e0e0;
  color: #fff;
}

.pcoded[layout-type="dark"] .alert.background-primary,
body.dark .alert.background-primary {
  background-color: #4680ff;
  color: #fff;
}

.pcoded[layout-type="dark"] .alert.background-success,
body.dark .alert.background-success {
  background-color: #93be52;
  color: #fff;
}

.pcoded[layout-type="dark"] .alert.background-info,
body.dark .alert.background-info {
  background-color: #62d1f3;
  color: #fff;
}

.pcoded[layout-type="dark"] .alert.background-warning,
body.dark .alert.background-warning {
  background-color: #ffb64d;
  color: #fff;
}

.pcoded[layout-type="dark"] .alert.background-danger,
body.dark .alert.background-danger {
  background-color: #fc6180;
  color: #fff;
}

.pcoded[layout-type="dark"] .accordion-msg,
body.dark .accordion-msg {
  color: #fff;
  border-top: 1px solid #444c67;
}

.pcoded[layout-type="dark"] .accordion-desc,
body.dark .accordion-desc,
.pcoded[layout-type="dark"] .md-tabs .nav-item a,
body.dark .md-tabs .nav-item a {
  color: #fff;
}

.pcoded[layout-type="dark"] .md-tabs .nav-item a {

  &.active,
  &:focus,
  &:hover {
    color: #4680ff;
  }
}

body.dark .md-tabs .nav-item a {

  &.active,
  &:focus,
  &:hover {
    color: #4680ff;
  }
}

.pcoded[layout-type="dark"] .md-tabs .nav-item a.active {

  &:focus,
  &:hover {
    color: #4680ff;
  }
}

body.dark .md-tabs .nav-item a.active {

  &:focus,
  &:hover {
    color: #4680ff;
  }
}

.pcoded[layout-type="dark"] .nav-tabs,
body.dark .nav-tabs {
  border-bottom: 1px solid #444c67;
}

.pcoded[layout-type="dark"] .nav-tabs .nav-link {

  &:focus,
  &:hover {
    border-color: #444c67;
  }
}

body.dark .nav-tabs .nav-link {

  &:focus,
  &:hover {
    border-color: #444c67;
  }
}

.pcoded[layout-type="dark"] .tab-below,
body.dark .tab-below {
  border-top: 1px solid #444c67;
  border-bottom: none;
}

.pcoded[layout-type="dark"] .tab-below .nav-link,
body.dark .tab-below .nav-link {
  margin-top: 1px;
}

.pcoded[layout-type="dark"] .scroll-list li,
body.dark .scroll-list li {
  background-color: #3e455d;
  color: #fff;
}

.pcoded[layout-type="dark"] .scroll-list li:nth-child(even),
body.dark .scroll-list li:nth-child(even) {
  background-color: #48506d;
}

.pcoded[layout-type="dark"] .panel,
body.dark .panel {
  background-color: #444c67;
}

.pcoded[layout-type="dark"] .panel .panel-footer,
body.dark .panel .panel-footer {
  background-color: #3e455d;
  border-top: 1px solid #222533;
}

.pcoded[layout-type="dark"] .well,
body.dark .well {
  background-color: #222533;
  border: 1px solid #444c67;
}

.pcoded[layout-type="dark"] .page-link,
body.dark .page-link {
  background-color: #444c67;
  border-color: #222533;
}

.pcoded[layout-type="dark"] .jqpagination input,
body.dark .jqpagination input {
  margin: 0;
  height: 30px;
}

.pcoded[layout-type="dark"] .modal-content,
body.dark .modal-content {
  background-color: #3e455d;
}

.pcoded[layout-type="dark"] .modal-content .modal-header,
body.dark .modal-content .modal-header {
  border-bottom: 1px solid #303548;
}

.pcoded[layout-type="dark"] .modal-content .modal-footer,
body.dark .modal-content .modal-footer {
  border-top: 1px solid #303548;
}

.pcoded[layout-type="dark"] .br-theme-bars-square .br-widget a,
body.dark .br-theme-bars-square .br-widget a {
  background-color: #3e455d;
}

.pcoded[layout-type="dark"] .jstree-anchor.jstree-hovered,
body.dark .jstree-anchor.jstree-hovered {
  background-color: #444c67;
}

.pcoded[layout-type="dark"] .dd-handle,
body.dark .dd-handle {
  color: #fff;
}

.pcoded[layout-type="dark"] .dd3-content,
body.dark .dd3-content {
  color: #fff;
  border: 1px solid #303548;
  background-color: #444c67;
}

.pcoded[layout-type="dark"] .img-thumbnail,
body.dark .img-thumbnail {
  background-color: #303548;
  border: 1px solid #222533;
}

.pcoded[layout-type="dark"] .dotted-line-theme .no_edit:hover,
body.dark .dotted-line-theme .no_edit:hover {
  background-color: #444c67;
  border-bottom: 1px solid #303548;
}

.pcoded[layout-type="dark"] .icon-list-demo i,
body.dark .icon-list-demo i,
.pcoded[layout-type="dark"] .form-radio label,
body.dark .form-radio label {
  color: #fff;
}

.pcoded[layout-type="dark"] .bootstrap-tagsinput,
body.dark .bootstrap-tagsinput {
  background-color: #444c67;
}

.pcoded[layout-type="dark"] #reportrange,
body.dark #reportrange {
  background-color: #444c67 !important;
}

.pcoded[layout-type="dark"] {

  .select2-container--default .select2-selection--multiple,
  .select2-dropdown {
    background-color: #3e455d;
  }
}

body.dark {

  .select2-container--default .select2-selection--multiple,
  .select2-dropdown {
    background-color: #3e455d;
  }
}

.pcoded[layout-type="dark"] .ms-container {

  .ms-selectable,
  .ms-selection {
    background-color: #3e455d;
  }
}

body.dark .ms-container {

  .ms-selectable,
  .ms-selection {
    background-color: #3e455d;
  }
}

.pcoded[layout-type="dark"] .ms-container {

  .ms-selectable li.ms-elem-selectable,
  .ms-selection li.ms-elem-selectable {
    color: #fff;
  }
}

body.dark .ms-container {

  .ms-selectable li.ms-elem-selectable,
  .ms-selection li.ms-elem-selectable {
    color: #fff;
  }
}

.pcoded[layout-type="dark"] .ms-container {

  .ms-selectable li.disabled,
  .ms-selection li.disabled {
    background-color: #3e455d;
    opacity: 0.5;
  }
}

body.dark .ms-container {

  .ms-selectable li.disabled,
  .ms-selection li.disabled {
    background-color: #3e455d;
    opacity: 0.5;
  }
}

.pcoded[layout-type="dark"] .wizard>.content,
body.dark .wizard>.content {
  background-color: #3a4057;
}

.pcoded[layout-type="dark"] .wizard>.steps .disabled a {
  background-color: #3e455d;

  &:active,
  &:hover {
    background-color: #3e455d;
  }
}

body.dark .wizard>.steps .disabled a {
  background-color: #3e455d;

  &:active,
  &:hover {
    background-color: #3e455d;
  }
}

.pcoded[layout-type="dark"] .wizard>.steps .done a,
body.dark .wizard>.steps .done a {
  background-color: #303548;
}

.pcoded[layout-type="dark"] .table-bordered,
body.dark .table-bordered {
  border: 1px solid #444c67;
}

.pcoded[layout-type="dark"] .table-bordered {

  td,
  th {
    border: 1px solid #444c67;
  }
}

body.dark .table-bordered {

  td,
  th {
    border: 1px solid #444c67;
  }
}

.pcoded[layout-type="dark"] {

  a.dt-button.disabled,
  button.dt-button.disabled,
  div.dt-button.disabled {
    background-image: none;
    opacity: 0.5;
  }
}

body.dark {

  a.dt-button.disabled,
  button.dt-button.disabled,
  div.dt-button.disabled {
    background-image: none;
    opacity: 0.5;
  }
}

.pcoded[layout-type="dark"] .fixedHeader-floating,
body.dark .fixedHeader-floating {
  background-color: #444c67;
}

.pcoded[layout-type="dark"] table.DTFC_Cloned {

  tfoot,
  thead {
    background-color: #3e455d;
  }
}

body.dark table.DTFC_Cloned {

  tfoot,
  thead {
    background-color: #3e455d;
  }
}

.pcoded[layout-type="dark"] .dt-responsive input,
body.dark .dt-responsive input {
  background-color: #444c67;
  border: 1px solid #3a4057;
}

.pcoded[layout-type="dark"] {

  .footable-details.table-striped>tbody>tr:nth-child(odd),
  .footable.table-striped>tbody>tr:nth-child(odd) {
    background: #3a4057;
  }
}

body.dark {

  .footable-details.table-striped>tbody>tr:nth-child(odd),
  .footable.table-striped>tbody>tr:nth-child(odd) {
    background: #3a4057;
  }
}

.pcoded[layout-type="dark"] {
  .footable-details.table>thead>tr> {

    td,
    th {
      border-bottom: 2px solid #303548;
    }
  }

  .footable.table>thead>tr> {

    td,
    th {
      border-bottom: 2px solid #303548;
    }
  }
}

body.dark {
  .footable-details.table>thead>tr> {

    td,
    th {
      border-bottom: 2px solid #303548;
    }
  }

  .footable.table>thead>tr> {

    td,
    th {
      border-bottom: 2px solid #303548;
    }
  }
}

.pcoded[layout-type="dark"] {

  .c3-tooltip td,
  .morris-hover {
    background-color: #303548;
  }
}

body.dark {

  .c3-tooltip td,
  .morris-hover {
    background-color: #303548;
  }
}

.pcoded[layout-type="dark"] {

  #chart_Candlestick rect,
  #chart_Combo rect,
  #chart_Donut rect,
  #chart_Exploading rect,
  #chart_Threshold rect,
  #chart_Waterfall rect,
  #chart_area rect,
  #chart_bar rect,
  #chart_bubble rect,
  #chart_stacking rect {
    fill: #3a4057 !important;
  }
}

body.dark {

  #chart_Candlestick rect,
  #chart_Combo rect,
  #chart_Donut rect,
  #chart_Exploading rect,
  #chart_Threshold rect,
  #chart_Waterfall rect,
  #chart_area rect,
  #chart_bar rect,
  #chart_bubble rect,
  #chart_stacking rect {
    fill: #3a4057 !important;
  }
}

.pcoded[layout-type="dark"] .auth-box .form-control,
body.dark .auth-box .form-control {
  background-color: #fff;
}

.pcoded[layout-type="dark"] .chat-header,
body.dark .chat-header {
  color: #fff;
}

.pcoded[layout-type="dark"] .social-msg a span,
body.dark .social-msg a span {
  color: #f2f2f2;
}

.pcoded[layout-type="dark"] .bg-white,
body.dark .bg-white {
  background: #3a4057 !important;
}

.pcoded[layout-type="dark"] .list-group-item,
body.dark .list-group-item {
  background: #3a4057;
}

.pcoded[layout-type="dark"] ul.list-contacts a,
body.dark ul.list-contacts a {
  color: #fff;
}

.pcoded[layout-type="dark"] .card-header-img {

  h5,
  h6 {
    color: #fff;
  }
}

body.dark .card-header-img {

  h5,
  h6 {
    color: #fff;
  }
}

.pcoded[layout-type="dark"] .prod-item .prod-info,
body.dark .prod-item .prod-info,
.pcoded[layout-type="dark"] .email-card .user-body,
body.dark .email-card .user-body {
  background: #3a4057;
}

.pcoded[layout-type="dark"] .email-card .user-body .page-list li.mail-section a,
body.dark .email-card .user-body .page-list li.mail-section a {
  color: #fff;
}

.pcoded[layout-type="dark"] .email-card .user-body .page-list li.mail-section a {

  &.active,
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

body.dark .email-card .user-body .page-list li.mail-section a {

  &.active,
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

.pcoded[layout-type="dark"] .email-card .user-body .label-list a,
body.dark .email-card .user-body .label-list a {
  color: #fff;
}

.pcoded[layout-type="dark"] .email-card .mail-body-content tr:hover,
body.dark .email-card .mail-body-content tr:hover {
  background: rgba(255, 255, 255, 0.2);
}

.pcoded[layout-type="dark"] .email-card .mail-body-content .unread a,
body.dark .email-card .mail-body-content .unread a {
  color: #fff;
}

.pcoded[layout-type="dark"] .email-card .mail-body-content .read a,
body.dark .email-card .mail-body-content .read a {
  color: #e6e6e6;
}

.pcoded[layout-type="dark"] .email-card .user-mail h6,
body.dark .email-card .user-mail h6 {
  color: #fff;
}

.pcoded[layout-type="dark"] .wall-img-preview .wall-item,
body.dark .wall-img-preview .wall-item {
  border: 2px solid #3a4057;
}

.pcoded[layout-type="dark"] .msg-send,
body.dark .msg-send {
  background: #404760;
}

.pcoded[layout-type="dark"] .filter-bar>.navbar,
body.dark .filter-bar>.navbar {
  background: #3a4057;
}

.pcoded[layout-type="dark"] .navbar-light .navbar-nav {
  .active>.nav-link {
    color: #fff;
  }

  .nav-link {
    color: #fff;

    &.active,
    &.show {
      color: #fff;
    }
  }

  .show>.nav-link {
    color: #fff;
  }
}

body.dark .navbar-light .navbar-nav {
  .active>.nav-link {
    color: #fff;
  }

  .nav-link {
    color: #fff;

    &.active,
    &.show {
      color: #fff;
    }
  }

  .show>.nav-link {
    color: #fff;
  }
}

.pcoded[layout-type="dark"] #task-container li,
body.dark #task-container li {
  background: #444c67;
  color: #fff;
}

.pcoded[layout-type="dark"] .text-inverse,
body.dark .text-inverse {
  color: #fff !important;
}

.pcoded[layout-type="dark"] .note-card .note-write,
body.dark .note-card .note-write {
  background: -webkit-linear-gradient(top, #596285 0%, #717ba0 5%) 0 0;
  background-size: 100% 35px;
}

.pcoded[layout-type="dark"] .note-card .note-box-aside,
body.dark .note-card .note-box-aside {
  border-right: 1px solid #444c67;
}

.pcoded[layout-type="dark"] .invoice-table,
body.dark .invoice-table {
  border-left: none;
}

.pcoded[layout-type="dark"] {

  .invoice-total,
  .thead-default th {
    background: #303548;
  }
}

body.dark {

  .invoice-total,
  .thead-default th {
    background: #303548;
  }
}

.pcoded[layout-type="dark"] {

  #external-events .fc-event,
  .danger-nav,
  .fc-state-default,
  .info-nav,
  .light-nav-border,
  .primary-nav,
  .warning-nav,
  .success-nav,
  .info-nav,
  .danger-nav,
  .primary-nav,
  .success-nav,
  .warning-nav {
    background-color: #444c67;
    color: #fff;
  }
}

body.dark {

  #external-events .fc-event,
  .danger-nav,
  .fc-state-default,
  .info-nav,
  .light-nav-border,
  .primary-nav,
  .warning-nav,
  .success-nav,
  .info-nav,
  .danger-nav,
  .primary-nav,
  .success-nav,
  .warning-nav {
    background-color: #444c67;
    color: #fff;
  }
}

.pcoded[layout-type="dark"] {

  .jFiler-input-dragDrop,
  .jFiler-theme-default .jFiler-input {
    background-color: #363c51;
  }
}

body.dark {

  .jFiler-input-dragDrop,
  .jFiler-theme-default .jFiler-input {
    background-color: #363c51;
  }
}

.pcoded[layout-type="dark"] {

  .danger-nav .navbar-varient-submenu,
  .info-nav .navbar-varient-submenu,
  .light-nav-border .navbar-varient-submenu,
  .primary-nav .navbar-varient-submenu,
  .warning-nav .navbar-varient-submenu,
  .success-nav .navbar-varient-submenu,
  .info-nav .navbar-varient-submenu,
  .danger-nav .navbar-varient-submenu,
  .primary-nav .navbar-varient-submenu,
  .success-nav .navbar-varient-submenu,
  .warning-nav .navbar-varient-submenu {
    background-color: #3e455d;
    color: #fff;
  }
}

body.dark {

  .danger-nav .navbar-varient-submenu,
  .info-nav .navbar-varient-submenu,
  .light-nav-border .navbar-varient-submenu,
  .primary-nav .navbar-varient-submenu,
  .warning-nav .navbar-varient-submenu,
  .success-nav .navbar-varient-submenu,
  .info-nav .navbar-varient-submenu,
  .danger-nav .navbar-varient-submenu,
  .primary-nav .navbar-varient-submenu,
  .success-nav .navbar-varient-submenu,
  .warning-nav .navbar-varient-submenu {
    background-color: #3e455d;
    color: #fff;
  }
}

.pcoded[layout-type="dark"] {

  .danger-nav .navbar-varient-submenu a:hover,
  .info-nav .navbar-varient-submenu a:hover,
  .light-nav-border .navbar-varient-submenu a:hover,
  .primary-nav .navbar-varient-submenu a:hover,
  .warning-nav .navbar-varient-submenu a:hover,
  .success-nav .navbar-varient-submenu a:hover,
  .info-nav .navbar-varient-submenu a:hover,
  .danger-nav .navbar-varient-submenu a:hover,
  .primary-nav .navbar-varient-submenu a:hover,
  .success-nav .navbar-varient-submenu a:hover,
  .warning-nav .navbar-varient-submenu a:hover {
    background-color: #444c67;
  }
}

body.dark {

  .danger-nav .navbar-varient-submenu a:hover,
  .info-nav .navbar-varient-submenu a:hover,
  .light-nav-border .navbar-varient-submenu a:hover,
  .primary-nav .navbar-varient-submenu a:hover,
  .warning-nav .navbar-varient-submenu a:hover,
  .success-nav .navbar-varient-submenu a:hover,
  .info-nav .navbar-varient-submenu a:hover,
  .danger-nav .navbar-varient-submenu a:hover,
  .primary-nav .navbar-varient-submenu a:hover,
  .success-nav .navbar-varient-submenu a:hover,
  .warning-nav .navbar-varient-submenu a:hover {
    background-color: #444c67;
  }
}

.pcoded[layout-type="dark"] .card.version .card-header .card-header-right,
body.dark .card.version .card-header .card-header-right {
  background-color: #3a4057;
  color: #fff;
}

.pcoded[layout-type="dark"] .version .nav li:first-child,
body.dark .version .nav li:first-child {
  color: #fff;
}

.pcoded[layout-type="dark"] .version .nav li:hover,
body.dark .version .nav li:hover {
  background-color: #444c67;
}

.pcoded[layout-type="dark"] .version .nav li a,
body.dark .version .nav li a {
  color: #fff;
}

.pcoded[layout-type="dark"] .cd-timeline-content .bg-white,
body.dark .cd-timeline-content .bg-white {
  background-color: #fff;
}

.pcoded[layout-type="dark"] .cd-timeline-content p,
body.dark .cd-timeline-content p,
.pcoded[layout-type="dark"] #styleSelector,
body.dark #styleSelector,
.pcoded[layout-type="dark"] #styleSelector .form-control,
body.dark #styleSelector .form-control {
  color: #303548;
}

/* dark layout css end*/

.theme-loader {
  height: 100%;
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 999999;
  top: 0;
}

.ball-scale {
  left: 50%;
  top: 50%;
  position: absolute;
  height: 50px;
  width: 75px;
  margin: -25px 0 0 -37.5px;

  .contain>div {
    width: 3px;
    height: 50px;
    border-radius: 5px;
    margin: 0;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
    -webkit-animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
    animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);

    &:nth-child(4),
    &:nth-child(6) {
      -webkit-animation-delay: -0.4s !important;
      animation-delay: -0.4s !important;
    }

    &:nth-child(3),
    &:nth-child(7) {
      -webkit-animation-delay: -0.3s !important;
      animation-delay: -0.3s !important;
    }

    &:nth-child(2),
    &:nth-child(8) {
      -webkit-animation-delay: -0.2s !important;
      animation-delay: -0.2s !important;
    }

    &:nth-child(1),
    &:nth-child(9) {
      -webkit-animation-delay: -0.1s !important;
      animation-delay: -0.1s !important;
    }

    width: 3px;
    height: 50px;
    border-radius: 5px;
    margin: 0;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
    vertical-align: middle;
    -webkit-animation: line-scale-pulse-out-rapid 0.9s -0.5s infinite cubic-bezier(0.11, 0.49, 0.38, 0.78);
    animation: line-scale-pulse-out-rapid 0.9s -0.5s infinite cubic-bezier(0.11, 0.49, 0.38, 0.78);

    &:nth-child(4),
    &:nth-child(6) {
      -webkit-animation-delay: -0.3s !important;
      animation-delay: -0.3s !important;
    }

    &:nth-child(3),
    &:nth-child(7) {
      -webkit-animation-delay: -0.2s !important;
      animation-delay: -0.2s !important;
    }

    &:nth-child(2),
    &:nth-child(8) {
      -webkit-animation-delay: -0.1s !important;
      animation-delay: -0.1s !important;
    }

    &:nth-child(1),
    &:nth-child(9) {
      -webkit-animation-delay: 0s !important;
      animation-delay: 0s !important;
    }

    &:nth-child(1) {
      background-color: #93be52;
    }

    &:nth-child(2) {
      background-color: #fe8a7d;
    }

    &:nth-child(3) {
      background-color: #ffb64d;
    }

    &:nth-child(4) {
      background-color: #fc6180;
    }

    &:nth-child(5) {
      background-color: #4680ff;
    }

    &:nth-child(6) {
      background-color: #fc6180;
    }

    &:nth-child(7) {
      background-color: #ffb64d;
    }

    &:nth-child(8) {
      background-color: #fe8a7d;
    }

    &:nth-child(9) {
      background-color: #93be52;
    }
  }
}

@-webkit-keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }

  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

@keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }

  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

@-webkit-keyframes line-scale-pulse-out-rapid {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  80% {
    -webkit-transform: scaley(0.3);
    transform: scaley(0.3);
  }

  90% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

@keyframes line-scale-pulse-out-rapid {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  80% {
    -webkit-transform: scaley(0.3);
    transform: scaley(0.3);
  }

  90% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

/* st5 icon */

.pcoded[nav-type="st5"] {
  .pcoded-item.pcoded-left-item>li>a>.pcoded-micon b {
    display: none;
  }

  .d-color>ul {
    &:nth-child(1)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #4680ff;
      }
    }

    &:nth-child(2)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #fc6180;
      }
    }

    &:nth-child(3)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #93be52;
      }
    }

    &:nth-child(4)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #ffb64d;
      }
    }

    &:nth-child(5)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #ab7967;
      }
    }

    &:nth-child(6)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #39adb5;
      }
    }

    &:nth-child(7)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #7c4dff;
      }
    }

    &:nth-child(8)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #ff5370;
      }
    }

    &:nth-child(9)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #4680ff;
      }
    }

    &:nth-child(10)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #fc6180;
      }
    }

    &:nth-child(11)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #93be52;
      }
    }

    &:nth-child(12)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #ffb64d;
      }
    }

    &:nth-child(13)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #ab7967;
      }
    }

    &:nth-child(14)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #39adb5;
      }
    }

    &:nth-child(15)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #7c4dff;
      }
    }

    &:nth-child(16)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #ff5370;
      }
    }

    &:nth-child(17)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #4680ff;
      }
    }

    &:nth-child(18)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #fc6180;
      }
    }

    &:nth-child(19)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #93be52;
      }
    }

    &:nth-child(20)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #ffb64d;
      }
    }

    &:nth-child(21)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #ab7967;
      }
    }

    &:nth-child(22)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #39adb5;
      }
    }

    &:nth-child(23)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #7c4dff;
      }
    }

    &:nth-child(24)>li>a>.pcoded-micon {
      background-color: transparent;

      i {
        color: #ff5370;
      }
    }
  }
}

@media only screen and (max-width: 575px) {

  #monthly-profit-1+svg,
  #monthly-profit-2+svg,
  #monthly-profit-3+svg {
    display: none;
  }

  .social-network .card-body,
  .online-form {
    text-align: center;
  }

  .breadcrumb-title {
    margin-bottom: 1rem;
  }

  .datatable-footer-inner {
    height: auto !important;
    display: block !important;
  }
}

/* by amit */

#real-time-update {
  width: 100% !important;

  canvas {
    width: 100% !important;
  }
}

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-control:lang(en)::before {
  content: "Browse";
}

.custom-file-control::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-file-control:lang(en):empty::after {
  content: "Choose file...";
}

.edit-button {
  color: #93be52 !important;
  margin-left: 10px;
  cursor: pointer;
}
.edit-button-without-margin {
  color: #93be52 !important;
  margin-left: 0px;
  cursor: pointer;
}

.delete-button {
  color: #dc3545 !important;
  margin-left: 10px;
  cursor: pointer;
}

.upload-button {
  color: #ffb64d !important;
  margin-left: 10px;
  cursor: pointer;
}

.add-emp-button {
  color: #007bff !important;
  margin-left: 10px;
  cursor: pointer;
}

.view-button {
  color: #007bff !important;
  margin-left: 10px;
  cursor: pointer;
}

.active-button {
  color: #93be52 !important;
  margin-left: 10px;
  cursor: pointer;
}

.block-button {
  color: #fd93a8 !important;
  margin-left: 10px;
  cursor: pointer;
}

.float-right {
  float: right;
}
.form-check-input:checked {
  background-color: #0d6efd;
    border-color: #0d6efd;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
